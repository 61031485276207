import { GlobalCSS, keyframes } from "@classdojo/web/nessie/stylingLib";

const popupBounce = keyframes`
0% { -webkit-transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, 0, 0, 0, 1, 0, 0, 25, 0, 1); transform: matrix3d(0.5, 0, 0, 0, 0, 0.5, 0, 0, 0, 0, 1, 0, 0, 25, 0, 1); opacity: 0.5}
1.3% { -webkit-transform: matrix3d(0.563, 0, 0, 0, 0, 0.563, 0, 0, 0, 0, 1, 0, 0, 20.348, 0, 1); transform: matrix3d(0.563, 0, 0, 0, 0, 0.563, 0, 0, 0, 0, 1, 0, 0, 20.348, 0, 1); opacity: 0.55}
2.6% { -webkit-transform: matrix3d(0.646, 0, 0, 0, 0, 0.646, 0, 0, 0, 0, 1, 0, 0, 16.63, 0, 1); transform: matrix3d(0.646, 0, 0, 0, 0, 0.646, 0, 0, 0, 0, 1, 0, 0, 16.63, 0, 1); opacity: 0.6}
3.4% { -webkit-transform: matrix3d(0.703, 0, 0, 0, 0, 0.703, 0, 0, 0, 0, 1, 0, 0, 14.569, 0, 1); transform: matrix3d(0.703, 0, 0, 0, 0, 0.703, 0, 0, 0, 0, 1, 0, 0, 14.569, 0, 1); opacity: 0.65}
5.16% { -webkit-transform: matrix3d(0.833, 0, 0, 0, 0, 0.833, 0, 0, 0, 0, 1, 0, 0, 10.487, 0, 1); transform: matrix3d(0.833, 0, 0, 0, 0, 0.833, 0, 0, 0, 0, 1, 0, 0, 10.487, 0, 1); opacity: 0.7}
6.81% { -webkit-transform: matrix3d(0.946, 0, 0, 0, 0, 0.946, 0, 0, 0, 0, 1, 0, 0, 7.222, 0, 1); transform: matrix3d(0.946, 0, 0, 0, 0, 0.946, 0, 0, 0, 0, 1, 0, 0, 7.222, 0, 1); opacity: 0.75}
7.76% { -webkit-transform: matrix3d(1.004, 0, 0, 0, 0, 1.004, 0, 0, 0, 0, 1, 0, 0, 5.645, 0, 1); transform: matrix3d(1.004, 0, 0, 0, 0, 1.004, 0, 0, 0, 0, 1, 0, 0, 5.645, 0, 1); opacity: 0.8}
10.21% { -webkit-transform: matrix3d(1.113, 0, 0, 0, 0, 1.113, 0, 0, 0, 0, 1, 0, 0, 2.645, 0, 1); transform: matrix3d(1.113, 0, 0, 0, 0, 1.113, 0, 0, 0, 0, 1, 0, 0, 2.645, 0, 1); opacity: 0.85}
10.31% { -webkit-transform: matrix3d(1.116, 0, 0, 0, 0, 1.116, 0, 0, 0, 0, 1, 0, 0, 2.554, 0, 1); transform: matrix3d(1.116, 0, 0, 0, 0, 1.116, 0, 0, 0, 0, 1, 0, 0, 2.554, 0, 1); opacity: 0.9}
13.61% { -webkit-transform: matrix3d(1.166, 0, 0, 0, 0, 1.166, 0, 0, 0, 0, 1, 0, 0, 0.6, 0, 1); transform: matrix3d(1.166, 0, 0, 0, 0, 1.166, 0, 0, 0, 0, 1, 0, 0, 0.6, 0, 1); opacity: 0.95}
15.47% { -webkit-transform: matrix3d(1.154, 0, 0, 0, 0, 1.154, 0, 0, 0, 0, 1, 0, 0, 0.151, 0, 1); transform: matrix3d(1.154, 0, 0, 0, 0, 1.154, 0, 0, 0, 0, 1, 0, 0, 0.151, 0, 1); opacity: 1}
17.52% { -webkit-transform: matrix3d(1.12, 0, 0, 0, 0, 1.12, 0, 0, 0, 0, 1, 0, 0, -0.063, 0, 1); transform: matrix3d(1.12, 0, 0, 0, 0, 1.12, 0, 0, 0, 0, 1, 0, 0, -0.063, 0, 1); opacity: 1}
20.62% { -webkit-transform: matrix3d(1.05, 0, 0, 0, 0, 1.05, 0, 0, 0, 0, 1, 0, 0, -0.126, 0, 1); transform: matrix3d(1.05, 0, 0, 0, 0, 1.05, 0, 0, 0, 0, 1, 0, 0, -0.126, 0, 1); opacity: 1}
21.32% { -webkit-transform: matrix3d(1.035, 0, 0, 0, 0, 1.035, 0, 0, 0, 0, 1, 0, 0, -0.122, 0, 1); transform: matrix3d(1.035, 0, 0, 0, 0, 1.035, 0, 0, 0, 0, 1, 0, 0, -0.122, 0, 1); opacity: 1}
25.23% { -webkit-transform: matrix3d(0.969, 0, 0, 0, 0, 0.969, 0, 0, 0, 0, 1, 0, 0, -0.075, 0, 1); transform: matrix3d(0.969, 0, 0, 0, 0, 0.969, 0, 0, 0, 0, 1, 0, 0, -0.075, 0, 1); opacity: 1}
29.03% { -webkit-transform: matrix3d(0.948, 0, 0, 0, 0, 0.948, 0, 0, 0, 0, 1, 0, 0, -0.037, 0, 1); transform: matrix3d(0.948, 0, 0, 0, 0, 0.948, 0, 0, 0, 0, 1, 0, 0, -0.037, 0, 1); opacity: 1}
36.74% { -webkit-transform: matrix3d(0.989, 0, 0, 0, 0, 0.989, 0, 0, 0, 0, 1, 0, 0, -0.006, 0, 1); transform: matrix3d(0.989, 0, 0, 0, 0, 0.989, 0, 0, 0, 0, 1, 0, 0, -0.006, 0, 1); opacity: 1}
44.44% { -webkit-transform: matrix3d(1.016, 0, 0, 0, 0, 1.016, 0, 0, 0, 0, 1, 0, 0, -0.001, 0, 1); transform: matrix3d(1.016, 0, 0, 0, 0, 1.016, 0, 0, 0, 0, 1, 0, 0, -0.001, 0, 1); opacity: 1}
50% { -webkit-transform: matrix3d(1.008, 0, 0, 0, 0, 1.008, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.008, 0, 0, 0, 0, 1.008, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); opacity: 1}
59.86% { -webkit-transform: matrix3d(0.995, 0, 0, 0, 0, 0.995, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.995, 0, 0, 0, 0, 0.995, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); opacity: 1}
75.28% { -webkit-transform: matrix3d(1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); opacity: 1}
90.69% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); opacity: 1}
100% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); opacity: 1} 
`;

export default function BounceAnimationSetup() {
  return (
    <GlobalCSS
      styles={{
        ".bounceAnimation-enter": {
          WebkitAnimation: `${popupBounce} 1300ms linear both`,
          animation: `${popupBounce} 1300ms linear both`,
        },
        ".bounceAnimation-exit": {
          transition: "opacity 100ms ease-in-out",
          WebkitTransition: "opacity 100ms ease-in-out",
          opacity: 1,
        },
        ".bounceAnimation-exit.bounceAnimation-exit-active": {
          opacity: 0,
        },
      }}
    />
  );
}
