import { AT, autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";
/* eslint-disable jsx-a11y/no-static-element-interactions */
import maxBy from "lodash/maxBy";
import * as React from "react";
import { MouseEvent, useEffect, useRef, useState } from "react";
import { NumberBadge } from "../../nessie";
import { RAW_cssValue, ThemeUIStyleObject } from "../../nessie/stylingLib";
import { TranslatedString } from "../../pods/i18n/translate";
import logEvent from "../../utils/logEvent";
import { Image } from "../misc/Image";
import PopoverTrigger from "../overlay/PopoverTrigger";
import NotificationItem from "./NotificationItem";
import NotificationModal from "./NotificationModal";
import { Notification, NotificationWithSecondaryView } from "./types";

export type JoinClassRequest = {
  teacher: { _id: string; title?: string; lastName?: string; avatarURL?: string };
  class: { _id: string; name: string };
  lastUpdated?: string;
  acceptJoinClassRequest: React.ComponentPropsWithoutRef<typeof NotificationItem>["acceptAction"];
  rejectJoinClassRequest: React.ComponentPropsWithoutRef<typeof NotificationItem>["declineAction"];
};

export type NotificationCenterProps = {
  purple?: boolean;
  notifications?: Notification[];
  parentRequestCount?: number;
  studentRequestCount?: number;
  schoolTeacherRequestCount?: number;
  joinClassRequests?: JoinClassRequest[];
  openStoryComposeArea?: React.ComponentPropsWithoutRef<typeof NotificationModal>["openStoryComposeArea"];
  onClickRequests?: (e: MouseEvent) => void;
  lastRequest?: string;
  onClickSchoolRequests?: (e: MouseEvent) => void;
  lastSchoolRequest?: string;
  markRead: (_id: string, createdAt: string) => void;
};

export default function NotificationCenter({
  purple,
  notifications,
  parentRequestCount,
  studentRequestCount,
  schoolTeacherRequestCount,
  joinClassRequests,
  openStoryComposeArea,
  onClickRequests,
  lastRequest,
  onClickSchoolRequests,
  lastSchoolRequest,
  markRead,
}: NotificationCenterProps) {
  const unreadNotificationIds = useRef<string[]>([]);

  const logReceivedUnreadNotifications = (notificationIds: string[]) => {
    notificationIds.forEach((id) => {
      logEvent({ eventName: "web.in_app_notification.receive", eventValue: id });
    });
  };

  const logViewUnreadNotificationsEvent = (notificationIds: string[]) => {
    notificationIds.forEach((id) => {
      logEvent({ eventName: "web.in_app_notification.viewInList", eventValue: id });
    });
  };

  useEffect(() => {
    const receivedUnreadIds =
      (notifications &&
        notifications.filter(isUnreadClassDojoNotification).map(
          (notification) =>
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            notification._id!,
        )) ||
      [];
    receivedUnreadIds.sort();

    if (
      unreadNotificationIds.current.length !== receivedUnreadIds.length ||
      unreadNotificationIds.current.some((x) => !receivedUnreadIds.includes(x))
    ) {
      unreadNotificationIds.current = receivedUnreadIds;
      logReceivedUnreadNotifications(unreadNotificationIds.current);
    }
  }, [notifications]);

  const onOpen = () => {
    if (!notifications) return;
    logViewUnreadNotificationsEvent(unreadNotificationIds.current);

    const latest = maxBy(notifications, (n) => n.createdAt && Date.parse(n.createdAt));
    if (!latest || !latest._id || !latest.createdAt) return;
    markRead(latest._id, latest.createdAt);
  };

  // This needs to be controlled here and not by the individual notifications,
  // because otherwise withClickOutside will close the modal at weird times.
  const [showingSecondaryViewFor, setShowingSecondaryViewFor] = useState<string | undefined>(undefined);
  const notificationWithSecondaryView = findNotificationWithSecondaryView(notifications, showingSecondaryViewFor);

  const contents = (
    <NotificationCenterContents
      notifications={notifications}
      parentRequestCount={parentRequestCount}
      studentRequestCount={studentRequestCount}
      onClickRequests={onClickRequests}
      joinClassRequests={joinClassRequests}
      lastRequest={lastRequest}
      schoolTeacherRequestCount={schoolTeacherRequestCount}
      onClickSchoolRequests={onClickSchoolRequests}
      lastSchoolRequest={lastSchoolRequest}
      openStoryComposeArea={openStoryComposeArea}
      showSecondaryView={(notificationId: string) => setShowingSecondaryViewFor(notificationId)}
    />
  );

  return (
    <PopoverTrigger
      popoverContent={contents}
      popoverCaretOffset="60%"
      popoverCaretSize="1rem"
      popoverPadding="0"
      popoverGutter={"dt_s"}
      popoverMaxWidth={MENU_WIDTH}
      popoverPosition="bottom"
      aria-label={
        !notifications?.length
          ? autoTranslate("There are no notifications")
          : notifications.length === 1
            ? autoTranslate("There is 1 notification")
            : autoTranslate("There are __count__ notifications", { count: String(notifications.length) })
      }
      popoverTextColor={"dt_content_primary"}
      onOpen={onOpen}
      data-name="notificationCenterButton"
    >
      <div sx={styles.container}>
        <Image alt="" src={purple ? BELL_PURPLE_ICON : BELL_ICON} sx={styles.bell} />
        <NotificationBadge
          parentRequestCount={parentRequestCount}
          studentRequestCount={studentRequestCount}
          schoolTeacherRequestCount={schoolTeacherRequestCount}
          joinClassRequests={joinClassRequests}
          notifications={notifications}
        />
      </div>
      {notificationWithSecondaryView && (
        <NotificationModal
          notification={notificationWithSecondaryView}
          hide={() => setShowingSecondaryViewFor(undefined)}
          openStoryComposeArea={openStoryComposeArea}
        />
      )}
    </PopoverTrigger>
  );
}

function NotificationCenterContents({
  notifications,
  parentRequestCount,
  studentRequestCount,
  onClickRequests,
  joinClassRequests,
  lastRequest,
  schoolTeacherRequestCount,
  onClickSchoolRequests,
  lastSchoolRequest,
  openStoryComposeArea,
  showSecondaryView,
}: Pick<
  NotificationCenterProps,
  | "notifications"
  | "parentRequestCount"
  | "studentRequestCount"
  | "onClickRequests"
  | "joinClassRequests"
  | "lastRequest"
  | "schoolTeacherRequestCount"
  | "onClickSchoolRequests"
  | "lastSchoolRequest"
  | "openStoryComposeArea"
> & {
  showSecondaryView: (notificationId: string) => void;
}) {
  if (!notifications) {
    // loading state
    return (
      <div
        data-name="notificationCenterLoading"
        sx={{ ...styles.dropdownContainer, ...styles.dropdownContainerEmpty }}
        onClick={(e) => e.stopPropagation()}
      >
        <span sx={styles.dropdownHeader}>
          <AT>Notifications</AT>
        </span>

        <div sx={styles.dropdownEmptyContents}>
          <AT>Loading notifications...</AT>
        </div>
      </div>
    );
  }

  if (notifications.length === 0) {
    // no notifications
    return (
      <div data-name="notificationCenterEmpty" sx={{ ...styles.dropdownContainer, ...styles.dropdownContainerEmpty }}>
        <ConnectionRequestNotification
          parentRequestCount={parentRequestCount}
          studentRequestCount={studentRequestCount}
          onClickRequests={onClickRequests}
          joinClassRequests={joinClassRequests}
          lastRequest={lastRequest}
        />

        <SchoolTeacherRequestsNotification
          schoolTeacherRequestCount={schoolTeacherRequestCount}
          onClickSchoolRequests={onClickSchoolRequests}
          lastSchoolRequest={lastSchoolRequest}
        />

        <div onClick={(e) => e.stopPropagation()}>
          <span sx={styles.dropdownHeader}>
            <AT>Notifications</AT>
          </span>

          <div sx={styles.dropdownEmptyContents}>
            <AT>No recent notifications</AT>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div sx={styles.dropdownContainer}>
      <ConnectionRequestNotification
        parentRequestCount={parentRequestCount}
        studentRequestCount={studentRequestCount}
        onClickRequests={onClickRequests}
        joinClassRequests={joinClassRequests}
        lastRequest={lastRequest}
      />

      <SchoolTeacherRequestsNotification
        schoolTeacherRequestCount={schoolTeacherRequestCount}
        onClickSchoolRequests={onClickSchoolRequests}
        lastSchoolRequest={lastSchoolRequest}
      />

      <span sx={styles.dropdownHeader}>
        <AT>Notifications</AT>
      </span>

      {notifications.map((notification, i) => (
        <NotificationItem
          key={notification._id}
          notification={notification}
          isFirst={i === 0}
          showSecondaryView={showSecondaryView}
          openStoryComposeArea={openStoryComposeArea}
        />
      ))}
    </div>
  );
}

function NotificationBadge({
  parentRequestCount = 0,
  studentRequestCount = 0,
  schoolTeacherRequestCount = 0,
  joinClassRequests = [],
  notifications,
}: Pick<
  NotificationCenterProps,
  "parentRequestCount" | "studentRequestCount" | "schoolTeacherRequestCount" | "joinClassRequests" | "notifications"
>) {
  const requestCount = parentRequestCount + studentRequestCount + schoolTeacherRequestCount + joinClassRequests.length;
  const count = (notifications?.filter((n) => !n.read).length || 0) + requestCount;
  if (count <= 0) return null;

  return (
    <div sx={{ ...styles.badge, ...styles.unreadBadge }}>
      <NumberBadge data-name="badge" value={count} size="xs" kind="danger" noBorder />
    </div>
  );
}

function ConnectionRequestNotification({
  parentRequestCount = 0,
  studentRequestCount = 0,
  onClickRequests,
  joinClassRequests,
  lastRequest,
}: Pick<
  NotificationCenterProps,
  "parentRequestCount" | "studentRequestCount" | "onClickRequests" | "joinClassRequests" | "lastRequest"
>) {
  const displaySchoolTeacherConnections = onClickRequests && parentRequestCount + studentRequestCount > 0;
  const displayJoinClassRequests = joinClassRequests && joinClassRequests.length;
  if (!displaySchoolTeacherConnections && !displayJoinClassRequests) return null;

  return (
    <div>
      <span sx={styles.dropdownHeader}>
        <AT>Pending connection requests</AT>
      </span>

      <StudentParentConnectionRequests
        parentRequestCount={parentRequestCount}
        studentRequestCount={studentRequestCount}
        onClickRequests={onClickRequests}
        lastRequest={lastRequest}
      />

      <JoinClassRequests joinClassRequests={joinClassRequests} />
    </div>
  );
}

function StudentParentConnectionRequests({
  parentRequestCount = 0,
  studentRequestCount = 0,
  onClickRequests,
  lastRequest = "",
}: Pick<NotificationCenterProps, "parentRequestCount" | "studentRequestCount" | "onClickRequests" | "lastRequest">) {
  if (!onClickRequests || parentRequestCount + studentRequestCount <= 0) return null;

  let notification;
  if (studentRequestCount > 0) {
    notification = {
      content: { text: generateRequestString(parentRequestCount, studentRequestCount) },
      actor: { type: "connectionRequests" },
      createdAt: lastRequest,
    };
  } else {
    // to preserve existing translations
    notification = {
      content: {
        text:
          parentRequestCount === 1
            ? autoTranslate("You have __count__ parent connection request to review.", { count: "1" })
            : autoTranslate("You have __count__ parent connection requests to review.", {
                count: String(parentRequestCount),
              }),
      },
      actor: { type: "connectionRequests" },
      createdAt: lastRequest,
    };
  }
  return (
    <NotificationItem
      key="requests"
      notification={notification}
      isFirst={true}
      customOnClick={onClickRequests}
      customAvatar={
        <div sx={styles.requestIcon}>
          <Image alt="" height={50} src={REQUEST_ICON} />
          <div sx={{ ...styles.parentBadge, ...styles.badge }}>
            <NumberBadge
              data-name="badge"
              value={parentRequestCount + studentRequestCount}
              size="xs"
              kind="danger"
              noBorder
            />
          </div>
        </div>
      }
    />
  );
}

// for classroom owners to know about requests to join their class
function JoinClassRequests({ joinClassRequests = [] }: Pick<NotificationCenterProps, "joinClassRequests">) {
  if (!joinClassRequests.length) return null;

  return (
    <>
      {joinClassRequests.map(
        ({ teacher, class: classroom, lastUpdated, acceptJoinClassRequest, rejectJoinClassRequest }) => {
          if (!acceptJoinClassRequest || !rejectJoinClassRequest) return null;

          return (
            <NotificationItem
              key={`join-class-requests-${classroom._id}-${teacher._id}`}
              notification={{
                content: {
                  text: autoTranslate("__teacherTitle__ __teacherLastName__ is requesting to join __className__", {
                    teacherTitle: teacher.title!,
                    teacherLastName: teacher.lastName!,
                    className: classroom.name,
                  }),
                },
                actor: { type: "connectionRequests" },
                createdAt: lastUpdated,
              }}
              isFirst={true}
              customAvatar={
                <div sx={styles.requestIcon}>
                  <img alt="" height={50} src={teacher.avatarURL} />
                </div>
              }
              acceptAction={acceptJoinClassRequest}
              declineAction={rejectJoinClassRequest}
            />
          );
        },
      )}
    </>
  );
}

// for mentors and school leaders to know about pending teachers
function SchoolTeacherRequestsNotification({
  schoolTeacherRequestCount = 0,
  onClickSchoolRequests,
  lastSchoolRequest,
}: Pick<NotificationCenterProps, "schoolTeacherRequestCount" | "onClickSchoolRequests" | "lastSchoolRequest">) {
  if (!onClickSchoolRequests || schoolTeacherRequestCount <= 0) return null;

  return (
    <div>
      <span sx={styles.dropdownHeader}>
        <AT>Pending school requests</AT>
      </span>

      <NotificationItem
        key="requests"
        notification={{
          content: {
            text: autoTranslate("__schoolTeacherRequestCount__ teacher(s) want to join your school.", {
              schoolTeacherRequestCount: String(schoolTeacherRequestCount),
            }),
          },
          actor: { type: "connectionRequests" },
          createdAt: lastSchoolRequest,
        }}
        isFirst={true}
        customOnClick={onClickSchoolRequests}
        customAvatar={
          <div sx={styles.requestIcon}>
            <Image alt="" height={50} src={SCHOOL_REQUEST_ICON} />

            <div sx={{ ...styles.parentBadge, ...styles.badge }}>
              <NumberBadge data-name="badge" value={schoolTeacherRequestCount} size="xs" kind="danger" noBorder />
            </div>
          </div>
        }
      />
    </div>
  );
}

function hasSecondaryView(notification?: Notification): notification is NotificationWithSecondaryView {
  return !!notification && !!notification.secondaryView;
}

const findNotificationWithSecondaryView = (
  notifications: Notification[] | undefined,
  notificationId: string | undefined,
): NotificationWithSecondaryView | undefined => {
  if (!notifications || !notificationId) return;

  const notification = notifications.find((n) => n._id === notificationId);
  return hasSecondaryView(notification) ? notification : undefined;
};

// Check if is a notification created by our marketing team.
function isClassDojoNotification(notification: Notification) {
  return notification && !!notification._id && notification.actor && notification.actor.type === "dojo";
}

const isUnreadClassDojoNotification = (notification: Notification) =>
  isClassDojoNotification(notification) && notification.read === false;

function generateRequestString(parentCount: number, studentCount: number): TranslatedString {
  if (parentCount === 0 && studentCount === 1) {
    return autoTranslate("You have __studentCount__ student connection request to review.", {
      studentCount: String(studentCount),
    });
  } else if (parentCount === 1 && studentCount === 0) {
    return autoTranslate("You have __parentCount__ parent connection request to review.", {
      parentCount: String(parentCount),
    });
  } else if (parentCount === 0 && studentCount > 1) {
    return autoTranslate("You have __studentCount__ student connection requests to review.", {
      studentCount: String(studentCount),
    });
  } else if (parentCount > 1 && studentCount === 0) {
    return autoTranslate("You have __parentCount__ parent connection requests to review.", {
      parentCount: String(parentCount),
    });
  } else if (parentCount === 1 && studentCount === 1) {
    return autoTranslate(
      "You have __studentCount__ student connection request and __parentCount__ parent connection request to review.",
      { parentCount: String(parentCount), studentCount: String(studentCount) },
    );
  } else if (parentCount === 1 && studentCount > 1) {
    return autoTranslate(
      "You have __studentCount__ student connection requests and __parentCount__ parent connection request to review.",
      { parentCount: String(parentCount), studentCount: String(studentCount) },
    );
  } else if (parentCount > 1 && studentCount === 1) {
    return autoTranslate(
      "You have __studentCount__ student connection request and __parentCount__ parent connection requests to review.",
      { parentCount: String(parentCount), studentCount: String(studentCount) },
    );
  } else {
    return autoTranslate(
      "You have __studentCount__ student connection requests and __parentCount__ parent connection requests to review.",
      { parentCount: String(parentCount), studentCount: String(studentCount) },
    );
  }
}

const MENU_WIDTH = "36rem";

const BELL_ICON = "components/notification_center/bell.png";
const BELL_PURPLE_ICON = "components/notification_center/bell_purple.png";
const REQUEST_ICON = "components/notification_center/pending-parents.png";
const SCHOOL_REQUEST_ICON = "components/notification_center/pending-teachers.png";

const styles: Record<string, ThemeUIStyleObject> = {
  outerContainer: {
    position: "relative",
  },
  container: {
    lineHeight: 0, // otherwise there's weird padding under the bell.
  },
  bell: {
    height: "2.4rem",
    cursor: "pointer",
  },
  badge: {
    position: "absolute",
    left: "100%",
    transform: "translateX(-65%)",
    lineHeight: 1.2,
    cursor: "pointer",
  },
  unreadBadge: {
    top: "-0.3rem",
  },
  parentBadge: {
    bottom: "0rem",
  },
  dropdownContainer: {
    width: MENU_WIDTH,
    maxHeight: "48rem",
    overflowY: "auto",
    fontSize: "1.4rem",
  },
  dropdownContainerEmpty: {
    minHeight: "12.8rem",
  },
  dropdownHeader: {
    padding: RAW_cssValue("0.8rem 1.2rem"),
    display: "block",
    fontWeight: 600,
    fontSize: "1.4rem",
  },
  dropdownEmptyContents: {
    display: "block",
    borderTop: "dt_divider",
    lineHeight: "10.2rem", // minHeight (128) - header height (26)
    fontWeight: 600,
    textAlign: "center",
    color: "dt_content_disabled",
  },
  requestIcon: {
    position: "relative",
    marginRight: "dt_s",
  },
};
