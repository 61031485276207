import styles from "./NumberShoresBanner.module.css"; // Import CSS module
import { IslandsHeadlineText } from "@web-monorepo/dojo-islands";
import { AT } from "@web-monorepo/vite-auto-translate-plugin/runtime";
import { getImageSource } from "#/app/utils/Image";
import DojoIslandsHighlightLabel from "./DojoIslandsHighlightLabel";
import { DojoIslandsActivityTagsList } from "./DojoIslandsActivityTagsList";
import { useCallback, useState } from "react";
import { LogFirstRender } from "@classdojo/web";
import { DojoIslandsActivityCardVideo } from "./DojoIslandsActivityCardVideo";
import { DDSButton } from "@web-monorepo/dds";
import { IconPlay } from "@web-monorepo/dds-icons";
import { getMonsterWorldUrl, useMonsterverseConfiguration } from "app/pods/dojoIslands";

type NumberShoresBannerProps = {
  className?: string;
  classroomId: string;
};

export const NumberShoresBanner = ({ className, classroomId }: NumberShoresBannerProps) => {
  const backgroundImage = getImageSource("assets/images/dojo_islands/number_shores_blocks.webp");
  const tags = [
    "dojo.dojo_islands_activities:metadata.tags.math",
    "dojo.dojo_islands_activities:metadata.tags.problemSolving",
  ];
  const standards = ["CCSS.MATH.Content.K.OA.A.5"];

  const posterUrl = "https://static.classdojo.com/DojoIslands/activities/number-shores/number_shores_thumb.png";
  const videoUrl =
    "https://static.classdojo.com/DojoIslands/activities/number-shores/number_shores_preview_video_large.mp4";

  const [focused, setFocused] = useState(false);

  const monsterverseConfig = useMonsterverseConfiguration(classroomId);
  const mvLaunchUrl = getMonsterWorldUrl(monsterverseConfig, classroomId, "mathPortalExit");

  const launchIsland = useCallback(() => {
    window.location.assign(mvLaunchUrl);
  }, [mvLaunchUrl]);

  return (
    <LogFirstRender data-name={`dojo_islands_tab:number_shores_banner`} metadata={{ classroomId }}>
      <div
        className={`${styles.container} ${className}`}
        i18n-context="banner for the Number Shores math activity"
        onMouseEnter={() => {
          setFocused(true);
        }}
        onMouseLeave={() => {
          setFocused(false);
        }}
      >
        <div className={styles.leftColumn}>
          <IslandsHeadlineText level={1} as="h1">
            NUMBER SHORES
          </IslandsHeadlineText>
          <DojoIslandsActivityTagsList tags={tags} standards={standards} textStyle="light" />
          <div className={styles.bodyText}>
            <AT>
              An adventure zone where your class can explore new maps, find hidden equations, and use teamwork to solve
              them.
            </AT>
          </div>
          <div className={`${styles.buttonContainer} ddsText`}>
            <DDSButton
              variant="primary"
              size="small"
              data-name="dojo_islands_tab:number_shores_banner:play_button"
              onClick={launchIsland}
            >
              <IconPlay /> Play
            </DDSButton>
          </div>
        </div>
        <img src={backgroundImage} className={styles.backgroundImage} alt="3 number blocks floating in the air" />
        <div className={styles.videoContainer}>
          <div className={styles.videoBorder}>
            <DojoIslandsActivityCardVideo
              src={videoUrl}
              poster={posterUrl}
              focused={focused}
              containerStyle={{ width: "360px" }}
            />
          </div>
        </div>
        <div className={styles.highlightLabel}>
          <DojoIslandsHighlightLabel level={2}>NEW ZONE!</DojoIslandsHighlightLabel>
        </div>
      </div>
    </LogFirstRender>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const showNumberShoresBanner = (variant: string | undefined) => {
  return variant && variant !== "control" && variant !== "off";
};
