import { captureException } from "@web-monorepo/telemetry";
import { useEffect } from "react";

const LOGOUT_SESSION_CHANGE_KEY = "logout";
const ERROR_ALLOWLIST = ["BroadcastChannel", "SecurityError"];

export function useCrossTabLogoutBroadcastListener() {
  useEffect(() => {
    try {
      const bc = new BroadcastChannel(LOGOUT_SESSION_CHANGE_KEY);
      bc.onmessage = () => {
        window.location.assign("/");
        bc.close();
      };
      return () => {
        bc.close();
      };
    } catch (x) {
      if (x instanceof ReferenceError && ERROR_ALLOWLIST.find((allowedMessage) => x.message.includes(allowedMessage))) {
        captureException(x);
      } else throw x;
    }
  }, []);
}

export function sendCrossTabLogoutBroadcast() {
  const bc = new BroadcastChannel(LOGOUT_SESSION_CHANGE_KEY);
  bc.postMessage("logout");
}
