import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";
import { DropdownMenu } from "../../nessie";
import { HelpIcon } from "../../nessie/icons";
import logEvent from "../../utils/logEvent";
import getZendeskLocale from "../../utils/zendeskLocale";

export type HelpCenterProps = {
  type: "teacher" | "parent";
  locale: string;
  purple?: boolean;
};

const articles = {
  helpdesk: { parent: 200185365, teacher: 200185275 },
  submitTicket: { parent: 28909, teacher: 27695 },
} as const;

export default function HelpCenter({ type, locale, purple }: HelpCenterProps) {
  return (
    <DropdownMenu
      data-name="help_center:dropdown_menu"
      label={autoTranslate("Open help center menu")}
      trigger={
        <HelpIcon color={purple ? "dt_content_tertiary" : "dt_content_light"} sx={{ width: "28px", height: "28px" }} />
      }
      options={[
        {
          "data-name": type === "parent" ? "parent_helpdesk" : "teacher_helpdesk",
          label: type === "parent" ? autoTranslate("Parent Helpdesk") : autoTranslate("Teacher Helpdesk"),
          onClick: () => {
            logEvent("help_desk_menu.click_helpdesk");
            const zendeskLocale = getZendeskLocale(locale);
            const article = articles.helpdesk[type];
            window.open(`https://classdojo.zendesk.com/hc/${zendeskLocale}/categories/${article}`, "_blank");
          },
          opensAModal: false,
        },
        {
          label: autoTranslate("Email support"),
          onClick: () => {
            logEvent("help_desk_menu.click_submit_ticket");
            const zendeskLocale = getZendeskLocale(locale);
            const article = articles.submitTicket[type];
            window.open(
              `https://classdojo.zendesk.com/hc/${zendeskLocale}/requests/new?ticket_form_id=${article}`,
              "_blank",
            );
          },
          opensAModal: false,
        },
      ]}
      align="end"
    />
  );
}
