import { autoTranslate, SkipTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";
import { useEffect, useState } from "react";
import { CheckboxRadioGroup } from "../../nessie";

export default function DataTransferLegalSection({
  entityType,
  setDataTransferConsentGranted,
}: {
  entityType?: "teacher" | "parent";
  setDataTransferConsentGranted: (arg: boolean) => void;
}) {
  const [hasApprovedDataTransfer, setHasApprovedDataTransfer] = useState(false);

  useEffect(() => {
    setDataTransferConsentGranted(hasApprovedDataTransfer);
    // don't include `setDataTransferConsentGranted` in effect dependencies so it
    // doesn't execute if the passed function reference changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasApprovedDataTransfer]);

  return (
    <SkipTranslate>
      <CheckboxRadioGroup
        sx={{
          paddingBottom: "dt_s",
          display: "flex",
          borderRadius: "dt_radius_m",
          border: "dt_card",
          boxShadow: "dt_shadow_shadezies",
          marginBottom: "dt_xs",
          padding: "m",
          textAlign: "left",
          position: "relative",
        }}
        groupName="legal"
        groupType="checkbox"
        aria-label={autoTranslate("Legal section")}
        inputs={[
          {
            checked: hasApprovedDataTransfer,
            onChange: (e) => setHasApprovedDataTransfer(e.target.checked),
            labelText:
              entityType === "teacher" ? (
                <>
                  I agree ClassDojo can transfer information generated by my class's use of ClassDojo to the US, for the
                  purpose of providing us with the service.
                </>
              ) : (
                <>
                  I agree ClassDojo can transfer information generated by my family's use of ClassDojo to the US, for
                  the purpose of providing us with the service.
                </>
              ),
          },
        ]}
      />
    </SkipTranslate>
  );
}
