import * as React from "react";
import classes from "./DDSCard.module.css";

export type DDSCardProps = {
  /** The content to be rendered inside the card */
  children?: React.ReactNode;
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  ["data-name"]: string;
};

export const DDSCard = React.forwardRef<HTMLDivElement, DDSCardProps>(
  ({ children, ["data-name"]: dataName, ...props }, ref) => {
    return (
      <div ref={ref} className={classes.card} data-name={dataName} {...props}>
        {children}
      </div>
    );
  },
);

DDSCard.displayName = "DDSCard";

export type DDSCardHeaderProps = {
  children: React.ReactNode;
  className?: string;
};

export const DDSCardHeader = React.forwardRef<HTMLDivElement, DDSCardHeaderProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <div ref={ref} className={[classes.header, className].filter(Boolean).join(" ")} {...props}>
        {children}
      </div>
    );
  },
);

DDSCardHeader.displayName = "DDSCardHeader";

export type DDSCardContentProps = {
  children: React.ReactNode;
  className?: string;
};

export const DDSCardContent = React.forwardRef<HTMLDivElement, DDSCardContentProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <div ref={ref} className={[classes.content, className].filter(Boolean).join(" ")} {...props}>
        {children}
      </div>
    );
  },
);

DDSCardContent.displayName = "DDSCardContent";

export type DDSCardFooterProps = {
  children: React.ReactNode;
  className?: string;
  /** When true, the footer will not have a top border separating it from the content */
  noBorder?: boolean;
};

export const DDSCardFooter = React.forwardRef<HTMLDivElement, DDSCardFooterProps>(
  ({ children, noBorder = false, className, ...props }, ref) => {
    return (
      <>
        <div className={[!noBorder && classes.footerBorder].filter(Boolean).join(" ")}>
          <div ref={ref} className={[classes.footer, className].filter(Boolean).join(" ")} {...props}>
            {children}
          </div>
        </div>
      </>
    );
  },
);

DDSCardFooter.displayName = "DDSCardFooter";
