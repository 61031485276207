import { CollectionFetcherReturnType } from "@web-monorepo/shared/api/apiTypesHelper";
import { makeApiMutation, makeCollectionQuery } from "@web-monorepo/shared/reactQuery";
import intersection from "lodash/intersection";
import { useActiveNonDemoClasses } from "app/pods/classroom";
import { useHomeConnectionSummaryFetcher } from "app/pods/homeConnections/fetchers";

// const acceptParentConnectionRequestHandler = createActionHandler(ACCEPT_PARENT_CONNECTION, (action, state) => {
//   return produce(state, (draftState) => {
//     draftState.acceptError = null;
//   });
// });

// const acceptParentConnectionErrorRequestsHandler = createActionHandler(
//   ACCEPT_PARENT_CONNECTION_ERROR,
//   (payload, state) => {
//     return produce(state, (draftState) => {
//       draftState.acceptErrorFor = payload.requestId;
//     });
//   }
// );

//
// -----------------------------------------
// SELECTORS
//
//
// export const selectAcceptErrorFor = (state) => get([STATE_KEY, "acceptErrorFor"], state);

//
// -------------------------------
// Setup
//

const catchInvitationError = (error: Error): Error => {
  if ("response" in error) {
    const response = error.response as { status: number | undefined };
    if (response.status === 404) {
      return new Error("not found");
    } else if (
      response.status === 400 &&
      error.message.includes("The student reached the maximum number of connections")
    ) {
      return new Error("Student exceeded connection limit");
    }
  }
  throw Error;
};

// ---
// Fetcher
export const useParentConnectionRequestsFetcher = makeCollectionQuery({
  path: "/api/parentConnectionRequest",
  fetcherName: "parentConnectionRequests",
});

export type ParentConnectionRequest = CollectionFetcherReturnType<typeof useParentConnectionRequestsFetcher>;
export type ParentSchoolConnectionRequest = CollectionFetcherReturnType<
  typeof useParentSchoolConnectionRequestsFetcher
> & { teacher?: undefined; studentUser?: undefined };

export const useAcceptParentConnectionOperation = makeApiMutation({
  name: "acceptParentConnection",
  path: "/api/parentConnectionRequest/{requestId}/accept",
  method: "post",
  onSuccess: (_data, params) => {
    useHomeConnectionSummaryFetcher.invalidateQueries();
    useParentConnectionRequestsFetcher.setQueriesData((draft) => {
      return draft.filter((request) => request._id !== params.path.requestId);
    });
  },
  onError: (_error, _params) => {
    useHomeConnectionSummaryFetcher.invalidateQueries();
    useParentConnectionRequestsFetcher.invalidateQueries();
  },
  catchError: catchInvitationError,
});

export const useRejectParentConnectionOperation = makeApiMutation({
  name: "rejectParentConnection",
  path: "/api/parentConnectionRequest/{id}",
  method: "delete",
  onSuccess: (_data, params) => {
    useHomeConnectionSummaryFetcher.invalidateQueries();
    useParentConnectionRequestsFetcher.setQueriesData((draft) => {
      return draft.filter((request) => request._id !== params.path.id);
    });
  },
  catchError: catchInvitationError,
});

export const useAddStudentToClasses = makeApiMutation({
  name: "addStudentToClasses",
  path: "/api/student/{studentId}/addToClasses",
  method: "post",
});

export const useStudentInTeachersClasses = (studentClasses?: string[]) => {
  const { activeClassrooms } = useActiveNonDemoClasses();
  if (!studentClasses || !activeClassrooms || activeClassrooms.length === 0) {
    return false;
  }

  const studentTeacherClassesIntersection = intersection(
    activeClassrooms.map(({ _id }) => _id),
    studentClasses,
  );
  return studentTeacherClassesIntersection.length > 0;
};

export const useParentSchoolConnectionRequestsFetcher = makeCollectionQuery({
  fetcherName: "parentSchoolConnectionRequests",
  path: "/api/school/parentConnectionRequest",
});

export const useRejectParentSchoolConnectionOperation = makeApiMutation({
  name: "rejectParentSchoolConnection",
  path: "/api/school/parentConnectionRequest/{requestId}",
  method: "delete",
  onSuccess: (_data, params) => {
    useParentSchoolConnectionRequestsFetcher.setQueriesData((draft) => {
      return draft.filter((request) => request._id !== params.path.requestId);
    });
  },
});

export const useAcceptParentSchoolConnectionOperation = makeApiMutation({
  name: "acceptParentSchoolConnection",
  path: "/api/school/parentConnectionRequest/{requestId}/accept",
  method: "post",
  onSuccess: (_data, params) => {
    useParentSchoolConnectionRequestsFetcher.setQueriesData((draft) => {
      return draft.filter((request) => request._id !== params.path.requestId);
    });
  },
});

// WIP: create combined connection request list operation that depends on the two above,
// and swap out the UI to use that one.

// export function useCombinedConnectionRequestsFetcher() {
//   const classRequests = useConnectionRequestsFetcher({});
//   const schoolRequests = useSchoolConnectionRequestsFetcher({});

//   if (!classRequests.ready || classRequests.loading || classRequests.error || typeof classRequests.data === "undefined")
//     return classRequests;
//   if (
//     !schoolRequests.ready ||
//     schoolRequests.loading ||
//     schoolRequests.error ||
//     typeof schoolRequests.data === "undefined"
//   )
//     return schoolRequests;

//   return {
//     ready: true,
//     loading: false,
//     error: undefined,
//     data: [...classRequests.data, ...schoolRequests.data],
//     loadMore: () => {},
//     refetch: () => {
//       classRequests.refetch();
//       schoolRequests.refetch();
//     },
//   };
// }
