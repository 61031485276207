import createAction from "@web-monorepo/infra/createAction";
import createActionHandler from "@web-monorepo/infra/createActionHandler";
import { AwardState } from "app/pods/award";

export const RESET_RANDOM = createAction("award/reset-random");

export const resetRandom = () => ({
  type: RESET_RANDOM,

  payload: {},
});

export const resetRandomHandler = createActionHandler(
  RESET_RANDOM,
  () =>
    (state: AwardState): AwardState => ({
      ...state,
      randomSelection: undefined,
    }),
);
