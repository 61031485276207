import { forwardRef } from "react";
import { IconSearchThick } from "@web-monorepo/dds-icons";
import { useForwardedOrLocalRef, useUniqueIds } from "../../shared/hooks";
import { ClearButton } from "./components";
import type { BaseProps } from "./types";
import styles from "./DDSSearchField.module.css";

export const Sizes = ["small", "medium", "large"] as const;
export const Variant = "default";

export interface Props extends BaseProps {
  size?: (typeof Sizes)[number];
  placeholder?: string;
  label?: string;
  autoFocus?: boolean;
}

/**
 * Docs:
 * - {@link https://www.figma.com/design/erhFTAWNMugPVrJGCAWsse/%E2%9D%96-Dojo-Design-System-%E2%80%93-DDS?node-id=8511-18914&m=dev Figma}
 * - {@link https://components.classdojo.com/?path=/story/components-ddssearchfield--default-variant&args=size Storybook}
 */
export const DDSSearchField = forwardRef<HTMLInputElement, Props>(
  (
    {
      ["data-name"]: dataName,
      disabled,
      id,
      label = "Search",
      size = "small",
      placeholder = "Search",
      autoFocus = false,
      ...rest
    },
    forwardedRef,
  ) => {
    const ref = useForwardedOrLocalRef(forwardedRef);
    const [iconId, inputId] = useUniqueIds(["DDSSearchField.icon", "DDSSearchField.input"]);

    const className = [styles.searchField, styles[size]].join(" ");

    //design has same icon for small and medium variant
    const iconSize = (size: string) => {
      switch (size) {
        case "small":
          return "s";
        case "medium":
          return "s";
        case "large":
          return "m";
        default:
          return "s";
      }
    };

    return (
      <label className={className}>
        <IconSearchThick size={iconSize(size)} aria-label={label} id={iconId} />
        <input
          aria-labelledby={iconId}
          className={styles.input}
          data-name={dataName}
          disabled={disabled}
          id={id ?? inputId}
          placeholder={placeholder}
          ref={ref}
          autoFocus={autoFocus}
          {...rest}
        />
        <ClearButton className={styles.clearButton} forwardedRef={ref} />
      </label>
    );
  },
);
