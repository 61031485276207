import { APIResponseError } from "@web-monorepo/infra/responseHandlers";
import { ResponseError } from "superagent";
import getApiError from "app/pods/shared/util/getApiError";

export type CallApiErrorResponse = APIResponseError["response"] | ResponseError["response"];

export default function matchesError(response: CallApiErrorResponse, match: string) {
  const error = getApiError(response);

  if (!error) {
    return false;
  }

  return error.indexOf(match.trim().toLowerCase()) !== -1;
}
