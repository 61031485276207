import find from "lodash/find";
import { makeCollectionQuery, makeApiMutation } from "../reactQuery";
import { useMemo } from "react";

const useFeaturesSeenFetcher = makeCollectionQuery({
  fetcherName: "getFeaturesSeen",
  path: "/api/features/seen",
});

const useFeaturesSeenMutation = makeApiMutation({
  name: "putFeaturesSeen",
  path: "/api/features/seen/{client}/{feature}",
  method: "put",
  onMutate: (params) => {
    if (params.path.client !== "web") {
      throw new Error("client must be web");
    }
    useFeaturesSeenFetcher.setQueriesData((draft) => {
      const now = new Date().toISOString();
      let seenFeature = find(draft, { feature: params.path.feature });
      if (!seenFeature) {
        seenFeature = {
          feature: params.path.feature,
          seenCount: 0,
          firstSeen: now,
          lastSeen: now,
          clients: [],
          contexts: [],
        };
        draft.push(seenFeature);
      }
      seenFeature.seenCount += 1;
      seenFeature.lastSeen = now;
      let seenClient = find(seenFeature.clients, { client: "web" });
      if (!seenClient) {
        seenClient = {
          client: "web",
          seenCount: 0,
          firstSeen: now,
          lastSeen: now,
        };
        seenFeature.clients.push(seenClient);
      }
      seenClient.seenCount += 1;
      seenClient.lastSeen = now;
      if (!(params.body.contextId && params.body.contextType)) return;
      let seenContext = find(seenFeature.contexts, {
        contextId: params.body.contextId,
        contextType: params.body.contextType,
      });
      if (!seenContext) {
        seenContext = {
          contextId: params.body.contextId,
          contextType: params.body.contextType,
          seenCount: 0,
          firstSeen: now,
          lastSeen: now,
          clients: [],
        };
        seenFeature.contexts.push(seenContext);
      }
      seenContext.seenCount += 1;
      seenContext.lastSeen = now;
      let seenContextClient = find(seenContext.clients, { client: "web" });
      if (!seenContextClient) {
        seenContextClient = {
          client: "web",
          seenCount: 0,
          firstSeen: now,
          lastSeen: now,
        };
        seenContext.clients.push(seenContextClient);
      }
      seenContextClient.seenCount += 1;
    });
  },
});

type FeatureSeenReturn<Constants, Data> = Constants &
  (
    | {
        isLoading: true;
        isError: false;
        error: null;
        data: null;
      }
    | {
        isLoading: false;
        isError: true;
        error: any;
        data: null;
      }
    | {
        isLoading: false;
        isError: false;
        error: null;
        data: Data;
      }
  );

type ByContext = {
  contextId: string;
  contextType: string;
  seenCount: number;
  firstSeen: null | string;
  lastSeen: null | string;
  clients: {
    client: string;
    seenCount: number;
    firstSeen: string;
    lastSeen: string;
  }[];
};

type UseByContext = FeatureSeenReturn<
  {
    increment: () => void;
  },
  ByContext
>;

type FeatureSeenReturnWithContexts = FeatureSeenReturn<
  {
    increment: (context?: { contextId: string; contextType: string }) => void;
    useByContext: (context: { contextId: string; contextType: string }) => UseByContext;
  },
  {
    feature: string;
    contexts: ByContext[];
    seenCount: number;
    firstSeen: null | string;
    lastSeen: null | string;
    clients: {
      client: string;
      seenCount: number;
      firstSeen: string;
      lastSeen: string;
    }[];
  }
>;

export function useFeatureSeen<Feature extends string>(feature: Feature): FeatureSeenReturnWithContexts {
  const featuresSeenFetcher = useFeaturesSeenFetcher({});
  const featuresSeenMutation = useFeaturesSeenMutation();

  // distinguish between null & neverSeen
  // null indicates that the data is still loading or an error occurred
  const featureSeen = useMemo(
    () =>
      featuresSeenFetcher.data &&
      (find(featuresSeenFetcher.data, { feature }) || {
        feature,
        seenCount: 0,
        firstSeen: null,
        lastSeen: null,
        clients: [],
        contexts: [],
      }),
    [featuresSeenFetcher.data, feature],
  );

  function increment(context?: { contextId: string; contextType: string }) {
    return featuresSeenMutation.mutate({
      path: { feature, client: "web" },
      body: context || {},
    });
  }

  function useByContext({ contextId, contextType }: { contextId: string; contextType: string }): UseByContext {
    const featureSeenByContext = useMemo<ByContext | undefined | null>(
      () =>
        featureSeen &&
        (find(featureSeen?.contexts, { contextId, contextType }) || {
          contextId,
          contextType,
          seenCount: 0,
          firstSeen: null,
          lastSeen: null,
          clients: [],
        }),
      [contextId, contextType],
    );
    const incrementByContext = () => increment({ contextId, contextType });
    if (featuresSeenFetcher.isLoading) {
      return {
        isLoading: true,
        isError: false,
        error: null,
        data: null,
        increment: incrementByContext,
      };
    }
    if (featuresSeenFetcher.isError) {
      return {
        isLoading: false,
        isError: true,
        error: featuresSeenFetcher.error,
        data: null,
        increment: incrementByContext,
      };
    }
    if (!featureSeenByContext) {
      throw new Error("featureSeenByContext should be defined");
    }

    return {
      isLoading: false,
      isError: false,
      error: null,
      data: featureSeenByContext,
      increment: incrementByContext,
    };
  }

  if (featuresSeenFetcher.isLoading) {
    return {
      isLoading: true,
      isError: false,
      error: null,
      data: null,
      increment,
      useByContext,
    };
  }
  if (featuresSeenFetcher.isError) {
    return {
      isLoading: false,
      isError: true,
      error: featuresSeenFetcher.error,
      data: null,
      increment,
      useByContext,
    };
  }
  if (!featureSeen) {
    throw new Error("featureSeen should be defined");
  }

  return {
    isLoading: false,
    isError: false,
    error: null,
    data: featureSeen,
    increment,
    useByContext,
  };
}
