import type { FunctionComponent, PropsWithChildren } from "react";
import * as Classes from "./DDSBodyText.module.css";

type DDSBodyTextSpanProps = {
  as?: "span";
  htmlFor?: never;
};

type DDSBodyTextLabelProps = {
  as: "label";
  htmlFor?: string;
};

export type DDSBodyTextProps = (DDSBodyTextSpanProps | DDSBodyTextLabelProps) & {
  level?: 1 | 2 | 3;
  heavy?: boolean;
  className?: string;
  id?: string;
};

export const DDSBodyText: FunctionComponent<PropsWithChildren<DDSBodyTextProps>> = ({
  as: tagName = "span",
  level = 1,
  className,
  heavy,
  children,
  ...rest
}) => {
  const Tag = tagName;
  const classNames = [className, Classes[`body${level}`], heavy ? Classes.heavy : null].filter((c) => !!c);

  return (
    <Tag className={classNames.join(" ")} {...rest}>
      {children}
    </Tag>
  );
};
