import { type ReactNode } from "react";
import classes from "./DDSBadge.module.css";

export const Variants = ["primary", "destructive"] as const;
export type BadgeVariant = (typeof Variants)[number];

export type DDSBadgeProps = {
  children: ReactNode;
  variant?: BadgeVariant;
  className?: string;
  "data-name"?: string;
};

export function DDSBadge({ children, variant = "primary", className, "data-name": dataName }: DDSBadgeProps) {
  const badgeClasses = [classes.badge, classes[variant], className].filter(Boolean).join(" ");

  return (
    <div className={badgeClasses} data-name={dataName}>
      {children}
    </div>
  );
}
