import { APIResponse } from "@web-monorepo/shared/api/apiTypesHelper";
import { makeCollectionQuery } from "@web-monorepo/shared/reactQuery";

//
// -------------------------------
// Fetchers
//
export type SchoolClass = APIResponse<"/api/dojoSchool/{schoolId}/class/v2", "get">["_items"][0];
export type SchoolClassWithOwner = SchoolClass & { teacher: string };

export const useSchoolClassesFetcher = makeCollectionQuery({
  path: "/api/dojoSchool/{schoolId}/class/v2",
  query: { withoutInactive: "false", withRequestedCollaborators: "true" },
  fetcherName: "schoolClasses",
});
