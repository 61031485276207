import { type TranslatedString, translate } from "app/utils/translate";
import type Dictionary from "app/utils/dict";
import { School, SchoolTeacher } from "app/pods/school";
import { SchoolHighlightTeacher } from "app/pods/schoolHighlights";
import { TeacherSession } from "app/pods/teacher";

const PRINCIPAL = "principal";
const ASST_PRINCIPAL = "assistant_principal";
const SCHOOL_LEADER = "school_leader";
const SCHOOL_STAFF = "school_staff";
const TEACHER = "teacher";
const OTHER = "other";

const MENTOR = "mentor";
const SCHOOL_ADMIN = "school_admin";

const SCHOOL_LEADER_TYPES = [PRINCIPAL, ASST_PRINCIPAL, SCHOOL_LEADER] as const;

type Strings = Record<TeacherSession["role"], () => TranslatedString>;
const STRINGS: Strings = {
  [PRINCIPAL]: () =>
    translate({
      str: "dojo.teacher_web:user_types.principal",
      fallback: "Principal",
    }),
  [ASST_PRINCIPAL]: () =>
    translate({
      str: "dojo.teacher_web:user_types.assistant_principal",
      fallback: "Assistant Principal",
    }),
  [SCHOOL_LEADER]: () =>
    translate({
      str: "dojo.teacher_web:user_types.school_leader",
      fallback: "School Leader",
    }),
  [SCHOOL_STAFF]: () =>
    translate({
      str: "dojo.teacher_web:user_types.school_staff",
      fallback: "School Staff",
    }),
  [TEACHER]: () =>
    translate({
      str: "dojo.teacher_web:user_types.teacher",
      fallback: "Teacher",
    }),
  [OTHER]: () =>
    translate({
      str: "dojo.teacher_web:user_types.other",
      fallback: "Other school admin",
    }),
};

type TeacherTitleKey = keyof Strings | typeof MENTOR | typeof SCHOOL_ADMIN;
const TEACHER_TITLES: Record<TeacherTitleKey, () => TranslatedString> = {
  ...STRINGS,
  [MENTOR]: () =>
    translate({
      str: "dojo.teacher_web:search_school.classdojo_mentor_short",
      fallback: "Mentor",
    }),
  [SCHOOL_ADMIN]: () =>
    translate({
      str: "dojo.teacher_web:directory.school_admin",
      fallback: "Admin",
    }),
};

export function getTeacherInSchool(
  teacher: string | TeacherSession,
  schoolTeachers?: Dictionary<SchoolTeacher> | SchoolTeacher[],
): SchoolTeacher | undefined {
  if (!teacher) return;
  // in case they pass in a string id
  return toA(schoolTeachers).find(
    typeof teacher === "string" ? ({ _id }) => _id === teacher : ({ _id }) => _id === teacher._id,
  );
}

export function hasSchoolLoaded(school: School, schoolTeachers?: Dictionary<SchoolTeacher> | SchoolTeacher[]) {
  return school != null && schoolTeachers != null;
}

export function hasPendingOrVerifiedSchool(teacher: TeacherSession) {
  return teacher && (teacher.schools.length > 0 || teacher.pendingSchools.length > 0);
}

// can also take just a string for the role
export function isSchoolLeader(
  teacher: SchoolTeacher["role"] | SchoolTeacher | TeacherSession,
): teacher is (typeof SCHOOL_LEADER_TYPES)[number] {
  return teacher && SCHOOL_LEADER_TYPES.includes(typeof teacher === "string" ? teacher : teacher.role);
}

export function isVerifiedSchoolAdmin(teacher: SchoolTeacher | TeacherSession, schoolId?: string) {
  if (schoolId && "schools" in teacher) {
    const school = teacher.schools.find((st) => st.schoolId === schoolId);
    return !!(school && school.admin);
  }
  if (!teacher || !teacher.isSchoolAdmin) return false;
  if ("isVerified" in teacher) return teacher.isVerified;
  return !!teacher.schoolVerified;
}

export function hasMatchingDomain(teacher: TeacherSession, school: School) {
  return teacher && school && school.domain && teacher.emailAddress?.endsWith(school.domain);
}

export function logSchoolLeaderOrTeacher(teacher: SchoolTeacher["role"] | SchoolTeacher | TeacherSession) {
  return isSchoolLeader(teacher) ? "school_leader" : "teacher";
}

// can pass in the school object or the schoolTeachers object
// whichever is convenient.
export function isMentor(
  teacher: string | TeacherSession,
  schoolOrSchoolTeachers: School | Dictionary<SchoolTeacher> | SchoolTeacher[] | undefined,
): boolean {
  if (!schoolOrSchoolTeachers || !teacher) return false;
  if (Array.isArray(schoolOrSchoolTeachers)) {
    const teacherInSchool = getTeacherInSchool(teacher, schoolOrSchoolTeachers);
    return !!teacherInSchool && teacherInSchool.isMentor;
  }
  const teacherId = typeof teacher === "string" ? teacher : teacher._id; // in case they pass in a string id
  return Array.isArray(schoolOrSchoolTeachers.mentorIds) && schoolOrSchoolTeachers.mentorIds.includes(teacherId);
}

export function isVerified(teacher: TeacherSession, schoolId?: string) {
  return !schoolId ? false : !!teacher.schools.find((st) => st.schoolId === schoolId);
}

export function isVerifiedSchoolLeader(
  teacher: TeacherSession,
  schoolTeachers?: Dictionary<SchoolTeacher> | SchoolTeacher[],
) {
  const teacherInSchool = getTeacherInSchool(teacher, schoolTeachers);
  if (!teacherInSchool) return false;

  const verified = teacherInSchool.isVerified;
  const schoolLeader = isSchoolLeader(teacherInSchool);
  return verified && schoolLeader;
}

export function isMentorOrVerifiedSchoolLeader(
  teacher: TeacherSession,
  schoolTeachers?: Dictionary<SchoolTeacher> | SchoolTeacher[],
) {
  const teacherInSchool = getTeacherInSchool(teacher, schoolTeachers);
  if (!teacherInSchool) return false;

  const mentor = teacherInSchool.isMentor;
  const verified = teacherInSchool.isVerified;
  const schoolLeader = isSchoolLeader(teacherInSchool);
  return mentor || (verified && schoolLeader);
}

export function canSeeHighlights(
  teacher: TeacherSession,
  schoolTeachers?: Dictionary<SchoolTeacher> | SchoolTeacher[],
) {
  return isMentorOrVerifiedSchoolLeader(teacher, schoolTeachers);
}

function toA<T>(a?: Dictionary<T> | T[]) {
  if (!a) return [];
  if (Array.isArray(a)) return a;
  return Object.values(a);
}

export function hasAdmin(schoolTeachers?: Dictionary<SchoolTeacher> | SchoolTeacher[]) {
  return toA(schoolTeachers).some((teacher) => teacher.isMentor || (teacher.isVerified && isSchoolLeader(teacher)));
}

export function hasSchoolLeader(schoolTeachers?: Dictionary<SchoolTeacher> | SchoolTeacher[]) {
  return toA(schoolTeachers).some((teacher) => teacher.isVerified && isSchoolLeader(teacher));
}

export function hasMentorOrSchoolAdmin(schoolTeachers?: Dictionary<SchoolTeacher> | SchoolTeacher[]) {
  return toA(schoolTeachers).some(({ isMentor, isSchoolAdmin }) => isMentor || isSchoolAdmin);
}

export function isMentorOrVerifiedAdminOrLeader(
  teacher: TeacherSession,
  schoolTeachers?: Dictionary<SchoolTeacher> | SchoolTeacher[],
) {
  return isMentorOrVerifiedSchoolLeader(teacher, schoolTeachers) || teacher.isSchoolAdmin;
}

// True if at least one teacher other than the mentor is verified
export function hasStartedVerification(schoolTeachers?: Dictionary<SchoolTeacher> | SchoolTeacher[]) {
  return toA(schoolTeachers).filter((t) => !t.isMentor && !isSchoolLeader(t) && t.isVerified).length > 0;
}

export function getMentors(schoolTeachers?: Dictionary<SchoolTeacher> | SchoolTeacher[]): SchoolTeacher[] {
  return toA(schoolTeachers).filter(({ isMentor }) => isMentor);
}

export function getVerifiedSchoolLeaders(
  schoolTeachers?: Dictionary<SchoolTeacher> | SchoolTeacher[],
): SchoolTeacher[] {
  return toA(schoolTeachers).filter((t) => t.isVerified && isSchoolLeader(t));
}

export function getMentorsAndSchoolAdmins(
  schoolTeachers?: Dictionary<SchoolTeacher> | SchoolTeacher[],
): SchoolTeacher[] {
  return toA(schoolTeachers).filter(({ isMentor, isSchoolAdmin }) => isMentor || isSchoolAdmin);
}

export function getSchoolAdmins(schoolTeachers?: Dictionary<SchoolTeacher> | SchoolTeacher[]): SchoolTeacher[] {
  return toA(schoolTeachers).filter(({ isSchoolAdmin }) => isSchoolAdmin);
}

export function getVerifiedTeachers(schoolTeachers?: Dictionary<SchoolTeacher> | SchoolTeacher[]): SchoolTeacher[] {
  return toA(schoolTeachers).filter(({ isVerified }) => isVerified);
}

export function getUnverifiedTeachers(schoolTeachers?: Dictionary<SchoolTeacher> | SchoolTeacher[]): SchoolTeacher[] {
  return toA(schoolTeachers).filter(({ isVerified }) => !isVerified);
}

export function getUnverifiedAndNotPreapprovedTeachers(
  schoolTeachers?: Dictionary<SchoolTeacher> | SchoolTeacher[],
): SchoolTeacher[] {
  return toA(schoolTeachers).filter(({ isVerified, isPreapproved }) => !isVerified && !isPreapproved);
}

export function getTranslatedRole(
  teacher: TeacherSession["role"] | TeacherSession | SchoolTeacher | SchoolHighlightTeacher,
) {
  return STRINGS[typeof teacher === "string" ? teacher : teacher.role]() || STRINGS[TEACHER]();
}

export function getTranslatedTeacherTitles(schoolTeacher: SchoolTeacher | TeacherSession, showRole: boolean) {
  const teacherTitles: TeacherTitleKey[] = [];
  if (schoolTeacher.isSchoolAdmin) {
    teacherTitles.push(SCHOOL_ADMIN);
  }

  if (schoolTeacher.isMentor) {
    teacherTitles.push(MENTOR);
  }

  if (schoolTeacher.role !== TEACHER && showRole) {
    teacherTitles.push(schoolTeacher.role);
  }

  const translatedTitles = teacherTitles.map((teacherTitle) => TEACHER_TITLES[teacherTitle]());
  return translatedTitles.join(", ");
}

type AddessContainer = {
  address?: Address;
};
type Address = {
  street?: string;
  city?: string;
  state?: string;
  country?: string;
};
export function formatSchoolAddress(school: AddessContainer = {}) {
  const address = school.address;
  if (!address) return "";
  return [address.street, address.city, address.state, address.country].filter(Boolean).join(", ");
}
