import callApi, { CallApiDefaultResponse } from "@web-monorepo/infra/callApi";
import { APIRequestParameters, APIResponse } from "@web-monorepo/shared/api/apiTypesHelper";
import { makeCollectionQuery, makeMutation } from "@web-monorepo/shared/reactQuery";
// ---
// Fetcher & Operations
//

export type ClassroomGroup = APIResponse<"/api/dojoClass/{classId}/group", "get">["_items"][number];

export const useClassroomGroupsFetcher = makeCollectionQuery({
  fetcherName: "classroomGroups",
  path: "/api/dojoClass/{classId}/group",
  dontThrowOnStatusCodes: [403],
});

type DeleteGroupParams = Partial<APIRequestParameters<"/api/dojoClass/{classId}/group/{id}", "delete">["path"]> & {
  classroomId: string;
  groupId: string;
};

export const useDeleteGroupOperation = makeMutation<DeleteGroupParams, CallApiDefaultResponse | void>({
  name: "deleteGroup",
  fn: async ({ classroomId, groupId }) => {
    try {
      await callApi({
        method: "DELETE",
        path: `/api/dojoClass/${classroomId}/group/${groupId}`,
      });
    } catch (ex: any) {
      // API will 404 if the group no longer exists
      if (ex?.response?.status === 404) {
        return;
      }
      throw ex;
    }
  },
  onSuccess: (_data, params) => {
    useClassroomGroupsFetcher.setQueriesData(
      (draft) => {
        return draft.filter(({ _id }) => _id !== params.groupId);
      },
      { classId: params.classroomId },
    );
  },
});

type CreateGroupParams = {
  classroomId: string;
  name: string;
  studentIds: string[];
};

export const useCreateGroupOperation = makeMutation<CreateGroupParams, CallApiDefaultResponse>({
  name: "createGroup",
  fn: async ({ classroomId, name, studentIds }) => {
    return await callApi({
      method: "POST",
      path: `/api/dojoClass/${classroomId}/group`,
      body: {
        name,
        studentIds,
      },
    });
  },
  onSuccess: (data, params) => {
    useClassroomGroupsFetcher.setQueriesData(
      (draft) => {
        draft.push(data.body);
      },
      { classId: params.classroomId },
    );
  },
});

type UpdateGroupParams = Partial<APIRequestParameters<"/api/dojoClass/{classId}/group/{id}", "put">["path"]> & {
  classroomId: string;
  group: ClassroomGroup;
};

export const useUpdateGroupOperation = makeMutation<UpdateGroupParams, CallApiDefaultResponse>({
  name: "updateGroup",
  fn: async ({ group, classroomId }) => {
    return await callApi({
      method: "PUT",
      path: `/api/dojoClass/${classroomId}/group/${group._id}`,
      body: {
        _id: group._id,
        classId: group.classId,
        name: group.name,
        studentIds: group.studentIds,
      },
    });
  },
  onMutate: (params) => {
    useClassroomGroupsFetcher.setQueriesData(
      (draft) => {
        const { group } = params;
        const groupIndex = draft.findIndex(({ _id }) => _id === group._id);
        if (groupIndex !== -1) {
          draft[groupIndex] = group;
        }
      },
      { classId: params.classroomId },
    );
  },
});
