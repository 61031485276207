import { useBehaviorsFetcher } from "#/app/pods/behavior";
import { paths } from "@classdojo/ts-api-types/api";
import { makeApiMutation, makeCollectionQuery, makeMemberQuery } from "@web-monorepo/shared/reactQuery";

export type SchoolwideBehavior =
  paths["/api/dojoSchool/{schoolId}/behaviors"]["get"]["responses"]["200"]["content"]["application/json"]["_items"][0];

export type SchoolwidePointsPerClass =
  paths["/api/dojoSchool/{schoolId}/report/class"]["get"]["responses"]["200"]["content"]["application/json"]["classes"][0];

export type SchoolwideBehaviorReport =
  paths["/api/dojoSchool/{schoolId}/report/behavior"]["get"]["responses"]["200"]["content"]["application/json"]["_items"][0];

export type SchoolwideStudentsReport =
  paths["/api/dojoSchool/{schoolId}/report/student"]["get"]["responses"]["200"]["content"]["application/json"]["_items"][0];

export type SchoolwideStudent =
  paths["/api/school/{schoolId}/points/balances"]["get"]["responses"]["200"]["content"]["application/json"]["_items"][0];

export const useCreateSchoolwidePointOperation = makeApiMutation({
  name: "createSchoolWidePoint",
  path: "/api/dojoSchool/{schoolId}/point",
  method: "post",
  onSuccess: () => {
    useSchoolwidePointsDashboardFetcher.invalidateQueries();
    useSchoolwidePointsStudentsFetcher.invalidateQueries();
    useSchoolwideStudentsReportFetcher.invalidateQueries();
  },
});

export const useDeleteSchoolwidePointOperation = makeApiMutation({
  name: "deleteSchoolWidePoint",
  path: "/api/dojoSchool/{schoolId}/award/{awardId}",
  method: "delete",
  onSuccess: () => {
    useSchoolwidePointsDashboardFetcher.invalidateQueries();
    useSchoolwidePointsStudentsFetcher.invalidateQueries();
  },
});

export const useCreateSchoolwideBehaviorOperation = makeApiMutation({
  name: "create schoolwide behaviors",
  path: "/api/dojoSchool/{schoolId}/behaviors",
  method: "post",
  onSuccess: () => {
    useSchoolwideBehaviorsFetcher.invalidateQueries();
    useSchoolwidePointsDashboardFetcher.invalidateQueries();
    useBehaviorsFetcher.invalidateQueries();
  },
});

export const useSendSchoolwidePointsEmail = makeApiMutation({
  name: "sendSchoolwide points notify",
  path: "/api/dojoSchool/{schoolId}/pointNotify",
  method: "post",
});

export const useReplaceSchoolwideBehaviorOperation = makeApiMutation({
  name: "create schoolwide behaviors",
  path: "/api/dojoSchool/{schoolId}/behaviors",
  method: "put",
  onSuccess: (data, { path: { schoolId } }) => {
    useSchoolwideBehaviorsFetcher.setQueriesData(data.body._items, { schoolId });
    useSchoolwidePointsDashboardFetcher.invalidateQueries();
    useBehaviorsFetcher.invalidateQueries();
  },
});

export const useRedeemSchoolwidePointsOperation = makeApiMutation({
  name: "redeem schoolwide points",
  path: "/api/school/{schoolId}/redeemAward",
  method: "post",
  onSuccess: () => {
    useSchoolwidePointsStudentsFetcher.invalidateQueries();
  },
});

export const useSchoolwideBehaviorsFetcher = makeCollectionQuery({
  fetcherName: "schoolwide behaviors",
  path: "/api/dojoSchool/{schoolId}/behaviors",
});

export const useSchoolwidePointsPerClassFetcher = makeMemberQuery({
  fetcherName: "schoolwide points per class",
  path: "/api/dojoSchool/{schoolId}/report/class",
  queryParams: ["from", "to"],
});

export const useSchoolwidePointsDashboardFetcher = makeMemberQuery({
  fetcherName: "schoolwide points dashboard",
  path: "/api/dojoSchool/{schoolId}/pointsDashboard",
  queryParams: ["from", "to", "type"],
});

export const useSchoolwideBehaviorsReportFetcher = makeCollectionQuery({
  fetcherName: "schoolwide behaviors report",
  path: "/api/dojoSchool/{schoolId}/report/behavior",
  queryParams: ["from", "to"],
});

export const useSchoolwideStudentsReportFetcher = makeCollectionQuery({
  fetcherName: "schoolwide students report",
  path: "/api/dojoSchool/{schoolId}/report/student",
  queryParams: ["from", "to"],
});

export const useSchoolwidePointsStudentsFetcher = makeCollectionQuery({
  fetcherName: "schoolwide point student list",
  path: "/api/school/{schoolId}/points/balances",
});
