import * as React from "react";
import { handleActionKeyDown } from "../../utils/keyboard";
import { UnstyledButton } from "../buttons";

interface CardTileButtonProps {
  highContrast?: boolean;
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name": string;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  onClick: (() => void) | undefined;
  "aria-label"?: string;
  "aria-pressed"?: boolean;
  title?: string;
}

export const CardTileButton = ({
  highContrast,
  title,
  "aria-label": ariaLabel,
  "aria-pressed": ariaPressed,
  children,
  "data-name": dataName,
  onClick,
  disabled,
  className,
}: CardTileButtonProps) => {
  return (
    <UnstyledButton
      disabled={disabled}
      aria-label={ariaLabel}
      aria-pressed={ariaPressed}
      title={title}
      onKeyDown={handleActionKeyDown({ onAction: onClick })}
      onClick={onClick}
      data-name={dataName}
      className={className}
      sx={{
        textAlign: "center",
        fontSize: "1.2rem",
        position: "relative",
        height: "8rem",
        width: "16rem",
        marginX: "dt_m",
        marginY: "dt_s",
        backgroundColor: "dt_background_primary",
        borderRadius: "dt_radius_s",
        display: "inline-block",
        boxShadow: highContrast ? "dt_shadow_shadezies_small" : "dt_shadow_shadezies_small",
        ":hover": {
          boxShadow: highContrast ? "dt_shadow_shadezies" : "dt_shadow_shadezies",
        },
        ":disabled": {
          WebkitFilter: "grayscale(100%)",
          filter: "grayscale(100%)",
          cursor: "default",
          opacity: 0.5,
        },
      }}
    >
      {children}
    </UnstyledButton>
  );
};
