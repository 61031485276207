import { logEvent } from "@classdojo/log-client";
import PubnubClient from "@classdojo/web/pods/realtime/adapters/pubnub";
import { Message } from "@classdojo/web/pods/realtime/adapters/types";
import superagent from "superagent";
import { Classroom } from "app/pods/classroom";
import * as Pubnub from "app/pods/pubnub";
import { getStore } from "app/reduxStore";
import env from "app/utils/env";
import { metrics } from "app/utils/metrics";

/* eslint-disable @typescript-eslint/no-namespace, no-var */
declare global {
  namespace NodeJS {
    interface Global {
      appPubnub: typeof PubnubUtil;
    }
  }

  namespace globalThis {
    var appPubnub: typeof PubnubUtil;
  }
}
/* eslint-enable @typescript-eslint/no-namespace, no-var */

/**
 * This module holds a singleton Pubnub client.
 */

type PubnubClientMock = {
  subscribe: () => void;
  unsubscribe: () => void;
  publish: () => void;
};

const PUBNUB_CLIENT_ID_COOKIE = "dojo_pubnub_clientId";

const pubnubKeyCookie = document.cookie
  .split("; ")
  .find((cookie) => cookie.startsWith(`${PUBNUB_CLIENT_ID_COOKIE}`))
  ?.split("=")[1];

let clientId = pubnubKeyCookie;

if (env.isTesting) {
  clientId = "dojo-testingEnv";
}

if (!clientId) {
  const tempArray = new Uint32Array(10);
  const crypto = window.crypto || window.msCrypto;
  const randomNumbers = pubnubKeyCookie ?? crypto.getRandomValues(tempArray)[0];
  clientId = `dojo-${randomNumbers}`;
  document.cookie = `${PUBNUB_CLIENT_ID_COOKIE}=${clientId}`;
}

let client: PubnubClient | PubnubClientMock = {
  subscribe() {},
  unsubscribe() {},
  publish() {},
};

function log(...args: unknown[]) {
  if (env.isDev && !global.hideLogs) {
    // eslint-disable-next-line no-console
    console.log("PUBNUB - ", ...args);
  }
}

type PubnubNotificationCallback = (message: Message) => void;
const pubnubListeners = {} as Record<string, PubnubNotificationCallback[]>;
export function notifyOnPubnub(command: string, callback: PubnubNotificationCallback): void {
  pubnubListeners[command] ??= [];
  pubnubListeners[command].push(callback);
}

const PubnubUtil = {
  client,
  permanentChannels: [] as string[],
  /**
   * @param  {String} teacherId
   * @return {String}
   */
  init: function init(teacherId: string) {
    if (!env.isTesting) {
      client = new PubnubClient({ metrics, logEvent, userId: teacherId });
    }

    this.permanentChannels = [teacherId, "teachers", "global"];
    client.subscribe(this.permanentChannels, this.onMessage);
  },

  publish(message: Message, channel: Classroom["teacher"]) {
    if (channel) {
      log("On channel", channel, "publishing message:", message);
      client.publish(channel, {
        ...message,
        source_id: clientId,
      });
    }
  },

  /**
   * @param {String} channel
   */
  subscribe: function subscribe(channel: string | string[], timetoken: number) {
    log("Subscribing to channels", channel);
    client.subscribe(channel, this.onMessage, timetoken);
  },

  /**
   * @param {String} channel
   */
  unsubscribe: function unsubscribe(channel: string) {
    // Never unsubcribe from the init subscriptions
    if (!this.permanentChannels.includes(channel)) {
      log("Unsubscribed from channel:", channel);
      client.unsubscribe(channel, this.onMessage);
    }
  },

  /**
   * @param {Object} message
   */
  onMessage: (message: Message, channel?: string) => {
    // let's avoid echo chamber
    if (message.source_id === clientId) {
      return;
    }

    const command = message.command || message.action;

    if (command === "emergencyForceRefreshTeach") {
      (async () => {
        const resp = await superagent.get("/VERSION.txt");
        const versionStr = resp.text;
        const [, serverHash, serverVersion] = /([a-f0-9]*):([0-9]*)/.exec(versionStr) || [];
        const [, localVersion, localHash] = /([0-9]*)-([a-f0-9]*)/.exec(window.build) || [];

        if (serverHash !== localHash || serverVersion !== localVersion) {
          forceRefreshWithin10Minutes();
        }
      })();
      return;
    }

    if (command) {
      pubnubListeners[command]?.forEach((listener) => listener(message));
    }

    getStore().dispatch(Pubnub.receiveMessage(command || "", message || "", channel || ""));
  },
};

global.appPubnub = PubnubUtil;

export default PubnubUtil;

function forceRefresh() {
  window.location.reload();
}

function forceRefreshWithin10Minutes() {
  // spread refreshes across 10 minutes in an attempt to not DDOS ourselves
  const delay = Math.random() * 10 * 60 * 1000;

  setTimeout(forceRefresh, delay);
}
