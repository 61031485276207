import { i18n } from "./i18n";

export function $translate({
  k,
  n,
  d,
  s,
}: {
  k: string;
  n: string;
  d: string;
  s?: Record<string, string>;
}) {
  return i18n.t(k, {
    defaultValue: d,
    ns: n,
    ...s,
  });
}
