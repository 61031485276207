// TODO: We want to remove this once we move away from the redux error handling
import error from "@classdojo/web/pods/errorHandling";
import threadMessaging from "@web-monorepo/shared/messaging/actions";
import addEditClassroom from "app/pods/addEditClassroom";
import auth from "app/pods/auth";
import avatarSet from "app/pods/avatarSet";
import award from "app/pods/award";
import broadcast from "app/pods/broadcast";
import classroom from "app/pods/classroom";
import desktopNotification from "app/pods/desktopNotification";
import invitation from "app/pods/invitation";
import leftNav from "app/pods/leftNav";
import messaging from "app/pods/messaging";
import modals from "app/pods/modals";
import pubnub from "app/pods/pubnub";
import schoolTeachers from "app/pods/schoolTeachers";
import signup from "app/pods/signup/components";
import story from "app/pods/story";
import student from "app/pods/student";
import studentMerge from "app/pods/studentMerge";
import timeRange from "app/pods/timeRange";
import toolkit from "app/pods/toolkit";
import transient from "app/pods/transient";
import ui from "app/pods/ui";

export default function getAllPods() {
  return [
    error,
    avatarSet,
    toolkit,
    messaging,
    threadMessaging,
    invitation,
    award,
    auth,
    desktopNotification,
    classroom,
    studentMerge,
    broadcast,
    student,
    addEditClassroom,
    pubnub,
    story,
    leftNav,
    schoolTeachers,
    timeRange,
    ui,
    signup,
    transient,
    modals,
  ];
}
