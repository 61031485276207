enum MESSAGE {
  DISPLAY_OPEN = "DISPLAY_OPEN",
  DISPLAY_CLOSE = "DISPLAY_CLOSE",
  REMOTE_OPEN = "REMOTE_OPEN",
  REMOTE_CLOSE = "REMOTE_CLOSE",
  SHARE_OVER_WIRE_SEND = "SHARE_OVER_WIRE_SEND",

  SEND_MOOD = "SEND_MOOD",
  SEND_QUESTION = "SEND_QUESTION",
  SEND_MOVEMENT = "SEND_MOVEMENT",
  SEND_TIME = "SEND_TIME",
  SEND_RANDOM = "SEND_RANDOM",
  SEND_RANDOM_STUDENT_REQUEST = "SEND_RANDOM_STUDENT_REQUEST",
  SEND_GROUPS = "SEND_GROUPS",
  SEND_RADIO = "SEND_RADIO",
  SEND_NOISE = "SEND_NOISE",
  SEND_MICROPHONE_PERMISSION_ERROR = "SEND_MICROPHONE_PERMISSION_ERROR",
  SEND_NOTES = "SEND_NOTES",
  SEND_LIST = "SEND_LIST",
  SEND_IDEAS = "SEND_IDEAS",
  SEND_PROJECTOR = "SEND_PROJECTOR",
}

export default MESSAGE;
