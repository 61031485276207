/* eslint-disable react-refresh/only-export-components -- breaking this component for fast refresh is worth the readability increase from being in one file. */

import type { TranslatedString } from "@web-monorepo/i18next";
import { proxy, useSnapshot } from "valtio";
import styles from "./DDSScreenReaderAnnouncer.module.css";

type Message = {
  contents: TranslatedString;
  time: Date;
};
interface ScreenReaderAnnouncerState {
  messages: Array<Message>;
}

const screenReaderAnnouncerState = proxy<ScreenReaderAnnouncerState>({ messages: [] });

export const announceToScreenReader = (contents: Message["contents"]) => {
  const thirtySecondsAgo = new Date(Date.now() - 30000);
  screenReaderAnnouncerState.messages = screenReaderAnnouncerState.messages.filter(
    (message) => message.time > thirtySecondsAgo,
  );

  screenReaderAnnouncerState.messages.push({ contents, time: new Date() });
};

export const DDSScreenReaderAnnouncer: React.FC = () => {
  const { messages } = useSnapshot(screenReaderAnnouncerState);

  return (
    <div role="log" aria-live="polite" aria-relevant="additions text" className={styles.visuallyHidden}>
      {messages.map((m, i) => (
        <span key={m.time.getTime() + i}>{m.contents}</span>
      ))}
    </div>
  );
};
