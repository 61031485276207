import throttle from "lodash/throttle";
import env from "app/utils/env";

export const ACTIVE_THRESHOLD = env.isTesting ? 50 : 60000;
export const ACTIVITY_POLL_INTERVAL = env.isTesting ? 1 : 1000;

type Callback = () => void;

let lastActivity = Date.now();
let pendingCallbacks: Callback[] = [];

const onActivity = throttle(() => {
  lastActivity = Date.now();
  while (pendingCallbacks.length > 0) {
    const cb = pendingCallbacks.pop();
    cb && cb();
  }
}, ACTIVITY_POLL_INTERVAL);

export function isActive() {
  return Date.now() - lastActivity < ACTIVE_THRESHOLD;
}

export function cancelCallOnceActive(cb: Callback) {
  pendingCallbacks = pendingCallbacks.filter((f) => f !== cb);
}

export function callOnceActive(cb: Callback) {
  if (isActive()) {
    cb();
  } else {
    cancelCallOnceActive(cb);
    pendingCallbacks.push(cb);
  }
}

export function simulateActivity() {
  onActivity();
}

if (document) {
  document.addEventListener("mousemove", onActivity);
  document.addEventListener("onkeypress", onActivity);
}
