import { APIResponseError } from "@web-monorepo/infra/responseHandlers";
import { CollectionFetcherReturnType } from "@web-monorepo/shared/api/apiTypesHelper";
import { makeCollectionQuery, makeApiMutation } from "@web-monorepo/shared/reactQuery";
import { preload } from "app/utils/preloadImage";

export type Behavior = CollectionFetcherReturnType<typeof useBehaviorsFetcher>;

export const useBehaviorsFetcher = makeCollectionQuery({
  fetcherName: "behaviors",
  path: "/api/dojoClass/{classId}/behavior",
  onSuccess: (data) => {
    // Preloading behavior images once we know which ones we want
    data.forEach((behavior: Behavior) => {
      const url = behavior?._links?.icon?.href;
      if (url) preload(url);
    });
  },
});

export const useCreateBehaviorOperation = makeApiMutation({
  name: "createBehavior",
  path: "/api/dojoClass/{classId}/behavior",
  method: "post",
  onSuccess: (data, params) => {
    if (data) {
      useBehaviorsFetcher.setQueriesData(
        (draft) => {
          draft.push(data.body);
        },
        { classId: params.path.classId },
      );
    }
    useBehaviorsFetcher.invalidateQueries({ classId: params.path.classId });
  },
  onError(_error, variables) {
    useBehaviorsFetcher.invalidateQueries({ classId: variables.path.classId });
  },
  catchError: (error): Error => {
    if (error instanceof APIResponseError && error.response.status === 400 && error.response.body.error.expected) {
      return error;
    }

    throw error;
  },
});

export const useEditBehaviorOperation = makeApiMutation({
  name: "editBehavior",
  path: "/api/dojoClass/{classId}/behavior/{id}",
  method: "put",
  catchError: (error) => error,
  onSuccess: (data, params) => {
    useBehaviorsFetcher.setQueriesData(
      (draft) => {
        const response = data.body;
        const draftBehaviorIndex = draft.findIndex(({ _id }) => _id === response._id);
        if (draftBehaviorIndex !== -1) {
          draft[draftBehaviorIndex] = response;
        }
      },
      { classId: params.path.classId },
    );
  },
});

export const useRemoveBehaviorOperation = makeApiMutation({
  name: "removeBehavior",
  path: "/api/dojoClass/{classId}/behavior/{id}",
  method: "delete",
  catchError: (error) => error,
  onSuccess: (_data, params) => {
    useBehaviorsFetcher.setQueriesData((draft) => draft.filter(({ _id }) => _id !== params.path.id), {
      classId: params.path.classId,
    });
  },
});

export const useImportBehaviorsOperation = makeApiMutation({
  name: "importBehaviors",
  path: "/api/importBehaviors",
  method: "post",
  catchError: (error) => {
    if (error instanceof APIResponseError && error.response.status === 400) {
      return error;
    }

    throw error;
  },
  onSuccess: (_data, params) => {
    useBehaviorsFetcher.invalidateQueries({ classId: params.body.to });
  },
});
