import { BaseTextProps } from "@classdojo/web/nessie/components/typography/baseText";
import { GrandstanderText } from "./GrandstanderText";

const accentTextStyles = {
  1: {
    fontSize: "18px",
    lineHeight: "23px",
    fontWeight: "dt_extraBold",
    letterSpacing: "-0.5px",
  },
  2: {
    fontSize: "15px",
    lineHeight: "18px",
    fontWeight: "dt_extraBold",
    letterSpacing: "-0.5px",
  },
  m: {
    fontSize: "12px",
    lineHeight: "13px",
    fontWeight: "dt_extraBold",
    letterSpacing: "-0.5px",
  },
} as const;

export type AccentTextLevel = keyof typeof accentTextStyles;

export function IslandsAccentText({
  level = 1,
  ...props
}: {
  level?: AccentTextLevel;
} & BaseTextProps) {
  return <GrandstanderText sx={accentTextStyles[level]} {...props} />;
}
