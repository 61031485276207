import * as logClient from "@classdojo/log-client";
import { fullName } from "@classdojo/web/utils/name";
import { MessageThread } from "@web-monorepo/shared/messaging/types";
import orderBy from "lodash/orderBy";
import groupBy from "lodash/groupBy";
import { Message } from "app/pods/messaging";

export function messagesOrderBy<T extends { createdAt: string }>(items: T[]): T[];
export function messagesOrderBy<T extends { createdAt: string }>(items: Record<string, T>): T[];
export function messagesOrderBy(items: unknown[] | Record<string, unknown>): unknown {
  return orderBy(items, "createdAt", "asc");
}

type ClassMessage = Extract<Message, { classId: string }>;

export function messageHasClassId(message: Message): message is ClassMessage {
  return "classId" in message && message.classId !== undefined;
}

type ChannelMessage = Extract<Message, { channelId: string }>;

export function messageHasChannelId(message: Message): message is ChannelMessage {
  return "channelId" in message && message.channelId !== undefined;
}

export type ContactListGroupedByStudent = Record<string, ContactListParticipantItem[]>;
export type ContactListParticipantItem = {
  threadId: string;
  participantName: string;
  avatar?: string;
  studentName: string;
  studentId: string;
};

export function getContactListGroupedByStudent(myId: string, threads?: MessageThread[]): ContactListGroupedByStudent {
  const participantArray = (threads || []).flatMap((thread) => {
    if (thread.type !== "a" || !("student" in thread.metadata)) return [];

    const { student } = thread.metadata;

    return thread.participants
      .filter((p) => p._id !== myId && p.permission !== "none")
      .map((p) => ({
        threadId: thread._id,
        participantName: fullName(p),
        avatar: p.avatarURL,
        studentName: fullName(student),
        studentId: student._id,
      }));
  });

  const groupedByStudent = groupBy(participantArray, "studentId");

  return groupedByStudent;
}

export const handleCreateSchoolChatThreadSuccess = (
  navigate: (path: string) => void,
  messageThreadId: string,
  teacherSelectedSchool?: {
    schoolId: string;
    mentor: boolean;
    admin: boolean;
    selected: boolean;
  },
) => {
  if (teacherSelectedSchool && teacherSelectedSchool.schoolId) {
    navigate(`/schools/${teacherSelectedSchool.schoolId}/messaging/${messageThreadId}`);
  }
};

export const handleCreateChatThreadMessage = ({
  teacherSelectedSchool,
  teacherId,
  classroomId,
  recipients,
  origin,
  createSchoolChatThread,
  createChatThread,
}: {
  teacherSelectedSchool?: {
    schoolId: string;
    mentor: boolean;
    admin: boolean;
    selected: boolean;
  };
  teacherId: string;
  classroomId: string;
  recipients: { _id: string; type: "parent" | "teacher" }[];
  origin: string;
  createSchoolChatThread: (args: {
    path: { schoolId: string; teacherId: string };
    body: { participants: { _id: string; type: "parent" | "teacher" }[]; origin: string };
  }) => void;
  createChatThread: (args: {
    participants: { _id: string; type: "parent" | "teacher" }[];
    teacherId: string;
    classId: string;
    origin: string;
  }) => void;
}) => {
  if (recipients.length === 0) {
    return;
  }
  if (recipients.filter((r) => r.type === "parent").length === 0) {
    // if only teachers are selected, use Staff Messaging
    if (teacherSelectedSchool && teacherSelectedSchool.schoolId) {
      createSchoolChatThread({
        path: { schoolId: teacherSelectedSchool.schoolId, teacherId },
        body: {
          participants: recipients.map((r) => ({
            _id: r._id,
            type: "teacher",
          })),
          origin: "via_class",
        },
      });
    }
  } else {
    logClient.logEvent("web.teacher.messaging.new_message.group.create.tap");
    createChatThread({
      participants: recipients,
      teacherId,
      classId: classroomId,
      origin,
    });
  }
};
