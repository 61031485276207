import * as logClient from "@classdojo/log-client";
import { createContext, useRef } from "react";

const defaultContextValue = new Set<string>([]);

/* eslint-disable-next-line react-refresh/only-export-components */
export const ModalsContext = createContext<{
  addModal?: (modalName: string) => void;
  removeModal?: (modalName: string) => void;
}>({});

type ModalsContextProviderProps = {
  children: React.ReactNode;
};

export function ModalsContextProvider({ children }: ModalsContextProviderProps): JSX.Element {
  const modalsList = useRef<Set<string>>(defaultContextValue);

  const addModal = (modalName: string) => {
    modalsList.current.add(modalName);
    logClient.setMetadata("modalsList", Array.from(modalsList.current.values()).reverse());
  };

  const removeModal = (modalName: string) => {
    modalsList.current.delete(modalName);
    logClient.setMetadata("modalsList", Array.from(modalsList.current.values()).reverse());
  };

  return <ModalsContext.Provider value={{ addModal, removeModal }}>{children}</ModalsContext.Provider>;
}
