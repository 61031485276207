import { logException, logEvent } from "@classdojo/log-client";

export const MEANINGFUL_TAG_NAMES = new Set<string>(["A", "BUTTON", "SELECT", "DETAILS"]);
export const MEANINGFUL_ATTR_VALUES = {
  role: new Set<string>(["button", "link"]),
};
export const LESS_MEANINGFUL_TAG_NAME_WITH_DATA_NAME = new Set<string>(["LABEL"]);

export function _shouldLog(node: Element): boolean {
  for (const [attr, values] of Object.entries(MEANINGFUL_ATTR_VALUES)) {
    const attrValue = node.attributes.getNamedItem(attr)?.value;
    if (attrValue && values.has(attrValue)) {
      return true;
    }
  }

  if (node.attributes.getNamedItem("data-name") && LESS_MEANINGFUL_TAG_NAME_WITH_DATA_NAME.has(node.tagName)) {
    return true;
  }

  return MEANINGFUL_TAG_NAMES.has(node.tagName);
}

const mongoIdRegex = /[0-9a-fA-F]{24}/g;
const replaceMongoId = (str: string): string => str.replace(mongoIdRegex, "{id}");

type JSON = string | number | boolean | null | JSON[] | { [key: string]: JSON };
type JSONObject = { [key: string]: JSON };

export function _findNodeToLogAndAncestorTrace(node: Element | null): {
  nodeToLog: Element | null;
  ancestorTrace: string[];
  metadata: JSONObject;
  anonymizeUser: boolean;
} {
  if (!node) return { nodeToLog: null, ancestorTrace: [], metadata: {}, anonymizeUser: false };

  let nodeToLog: Element | null = null;
  const ancestorTrace: string[] = [];
  let metadata = {};

  let anonymizeUser = false;

  // Climb the DOM tree
  for (let i = 0; node && node !== document.body && i < 100; i++) {
    // Find the closest meaningful node to log
    if (!nodeToLog && _shouldLog(node)) {
      nodeToLog = node;
    } else if (nodeToLog) {
      // Add relevant ancestors, with data-name, to a stack trace for logs
      const name = node.getAttribute("data-name");
      if (name) {
        ancestorTrace.push(name);
      }
    }
    const nodeMetadata = node.getAttribute("data-event-metadata");
    if (nodeMetadata) {
      try {
        const parsed = JSON.parse(nodeMetadata) as any;
        // prefer existing metadata to ancestor metadata
        metadata = { ...parsed, ...metadata };
        // eslint-disable-next-line no-catch-all/no-catch-all
      } catch (x: any) {
        logException(x, ["error collecting node metadata"]);
      }
    }

    anonymizeUser ||= node.getAttribute("data-event-anonymize-user") === "true";

    node = (node as Element).parentElement;
  }
  return { nodeToLog, ancestorTrace, metadata, anonymizeUser };
}

export function _isElement(obj: null | EventTarget | Element): obj is Element {
  return (obj as Element)?.tagName !== undefined;
}

/**
 * As part of the Automatic Events project, creates a logEvent for clicks within a particular
 * app. https://app.asana.com/0/1199891107565084/1204197407086853
 *
 * To configure, call this function as part of a "click" EventListener.
 * Example:
 *    document.body.addEventListener("click", (ev) => logClick(ev, "teach"), { capture: true });
 */
export function logClick(ev: MouseEvent, site: string): void {
  try {
    if (!ev || !_isElement(ev.target)) return;

    const node: Element = ev.target;
    const { nodeToLog, ancestorTrace, metadata, anonymizeUser } = _findNodeToLogAndAncestorTrace(node);

    if (!nodeToLog) return;
    const name = nodeToLog.getAttribute("data-name");
    const experiments = nodeToLog.getAttribute("data-experiments")?.split(",");
    const tagName = nodeToLog.tagName.toLowerCase();
    const isAnchor = tagName === "a";
    if (!name && !isAnchor) return;

    // Passing `null` will overwrite any default values for entityId and userType
    const userTracking = anonymizeUser
      ? {
          entityId: null,
          userType: null,
          sessionId: "unknown",
        }
      : {};

    if (isAnchor) {
      const href = nodeToLog.getAttribute("href");
      logEvent({
        ...userTracking,

        eventName: name ? `${site}.${tagName}.${name}.click` : `${site}.${tagName}.click`,
        automatedEvent: true,
        metadata: {
          ...metadata,
          site,
          href: href ? replaceMongoId(href) : null,
          ancestorTrace,
          immediateAncestor: ancestorTrace.length > 0 ? ancestorTrace[0] : null,
        },
        experiments,
      });
      return;
    }

    logEvent({
      ...userTracking,

      eventName: `${site}.${tagName}.${name}.click`,
      automatedEvent: true,
      metadata: {
        ...metadata,
        site,
        ancestorTrace,
        immediateAncestor: ancestorTrace.length > 0 ? ancestorTrace[0] : null,
      },
      experiments,
    });
    // eslint-disable-next-line no-catch-all/no-catch-all
  } catch (err: any) {
    logException(err, { message: "Error in logClick global event handler" });
  }
}

export function EventAnonymizeUser({ children, className }: React.PropsWithChildren & { className?: string }) {
  return (
    <div className={className} data-event-anonymize-user="true">
      {children}
    </div>
  );
}

// It would be nice to have proper typing, separating children and props, but I couldn't get it to work:
export function EventMetadata({
  children,
  className,
  ...props
}: React.PropsWithChildren<{ [key: string]: JSON | React.ReactElement | React.ReactNode } & { className?: string }>) {
  return (
    <div className={className} data-event-metadata={JSON.stringify(props)}>
      {children}
    </div>
  );
}
