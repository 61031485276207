import { APIRequestBody, APIResponse } from "@web-monorepo/shared/api/apiTypesHelper";
import { makeApiMutation, makeMemberQuery } from "@web-monorepo/shared/reactQuery";

export type SchoolVerificationRequestAttachment = APIRequestBody<
  "/api/teacher/{teacherId}/schoolVerificationRequest",
  "post"
>["attachments"];

export type SchoolVerificationRequest = APIResponse<
  "/api/schoolVerificationRequest/{schoolVerificationRequestId}",
  "get"
>;

export const useSchoolVerificationRequestOperation = makeApiMutation({
  name: "requestSchoolVerification",
  path: "/api/teacher/{teacherId}/schoolVerificationRequest",
  method: "post",
  onSuccess: () => {
    useSchoolVerificationRequestFetcher.invalidateQueries();
  },
});

export const useSchoolVerificationRequestFetcher = makeMemberQuery({
  fetcherName: "schoolVerificationRequestFetcher",
  path: "/api/teacher/{teacherId}/schoolVerificationRequest",
  dontThrowOnStatusCodes: [404],
});
