import { setSignupStage } from "#/app/pods/signup/components";
import * as logClient from "@classdojo/log-client";
import { sendCrossTabLogoutBroadcast } from "@classdojo/web/hooks/useCrossTabLogoutBroadcastListener";
import callApi, { CallApiDefaultResponse } from "@web-monorepo/infra/callApi";
import combineActionHandlers from "@web-monorepo/infra/combineActionHandlers";
import { APIResponseError, isApiResponseError } from "@web-monorepo/infra/responseHandlers";
import { withOTCHandling, UserCancelledCodeEntryError } from "@web-monorepo/one-time-codes";
import {
  APIRequestBody,
  APIRequestParameters,
  APIResponse,
  MemberFetcherReturnType,
} from "@web-monorepo/shared/api/apiTypesHelper";
import { DojoError } from "@web-monorepo/shared/errors/errorTypeMaker";
import { PodInstallFunction } from "@web-monorepo/shared/podInfra";
import { makeApiMutation, makeMemberQuery, makeMutation } from "@web-monorepo/shared/reactQuery";
import errors from "app/errorTypes";
import { SchoolTeacher, useSchoolFetcher } from "app/pods/school";
import matchesError from "app/pods/shared/util/matchesError";
import { useUserConfigFetcher } from "app/pods/userConfig";
import * as location from "app/utils/location";
import { useDispatch, useSelector } from "app/utils/reduxHooks";
import { produce } from "immer";
import { useCallback, useRef } from "react";
import request from "superagent";

const STATE_KEY = "auth2";

type SignUpFlowGlobalState = {
  signUpFlowAccountCreated: boolean;
};

const initialState: SignUpFlowGlobalState = {
  signUpFlowAccountCreated: false,
};

export const useSessionFetcher = makeMemberQuery({
  fetcherName: "sessionFetcher",
  path: "/api/session",
  query: {
    includeExtras: "location",
  },
  dontThrowOnStatusCodes: [401],
  onSuccess: () => {
    // when we refetch the logged-in user's session (for example, pubnub on verification)
    // we refetch school for latest schoolteachers
    useSchoolFetcher.shouldInvalidateQueries((data) => {
      return (data?.schoolTeachers || []).some((teacher: SchoolTeacher) => teacher.isMe);
    });
    useUserConfigFetcher.invalidateQueries();
  },
});

export const useSendPasswordResetEmail = makeApiMutation({
  name: "sendResetPasswordEmail",
  path: "/api/passwordReset",
  method: "post",
});

export type SessionResponse = MemberFetcherReturnType<typeof useSessionFetcher>;

export type UseResetPasswordParams = APIRequestBody<"/api/passwordReset", "post">;
type ResetPasswordResponse = APIResponse<"/api/passwordReset", "post">;
export const useResetPasswordOperation = makeMutation<UseResetPasswordParams, ResetPasswordResponse>({
  name: "resetPassword",
  fn: async ({ emailAddress }) => {
    try {
      return await callApi({
        method: "POST",
        path: "/api/passwordReset",
        body: {
          emailAddress,
        },
      });
    } catch (err: any) {
      if (err.response?.status === 400 || err.response?.status === 404) {
        return err;
      }

      throw err;
    }
  },
});

type SetNewPasswordParams = APIRequestParameters<"/api/passwordReset/{id}", "post">;
type SetNewPasswordRequestBody = APIRequestBody<"/api/passwordReset/{id}", "post">;

type UseSetNewPasswordParams = SetNewPasswordParams["path"] & SetNewPasswordParams["query"] & SetNewPasswordRequestBody;
type SetNewPasswordResponse = APIResponse<"/api/passwordReset/{id}", "post">;
export const useSetNewPasswordOperation = makeMutation<UseSetNewPasswordParams, SetNewPasswordResponse>({
  name: "setNewPassword",
  fn: async ({ h, password, id }) => {
    try {
      return await callApi({
        method: "POST",
        path: `/api/passwordReset/${id}`,
        body: {
          password,
        },
        query: {
          h,
        },
      });
    } catch (err: any) {
      if (err.response?.status === 404) {
        return err;
      }

      throw err;
    }
  },
});

type UseLogoutParams = APIRequestBody<"/api/session", "delete"> | void | undefined;
type UseLogoutResponse = APIResponse<"/api/session", "delete">;
export const useLogoutOperation = makeMutation<UseLogoutParams, UseLogoutResponse>({
  name: "logout",
  fn: async () => {
    try {
      const response = await callApi({
        method: "DELETE",
        path: "/api/session",
      });

      if (response.body?.oidcLogoutUrl) {
        window.location.assign(response.body.oidcLogoutUrl);
        // In this case, we redirect out of Dojo and want nobody else to do
        // anything after this mutation
        await new Promise((resolve) => setTimeout(resolve, 10000000));
      }

      return response.body;
    } catch (err: any) {
      // ignore 401 Unauthorized, since that's what we wanted to do anyway
      if (err.response?.status === 401) {
        return err;
      }

      throw err;
    }
  },
  onSuccess: () => {
    sendCrossTabLogoutBroadcast();
  },
});

export type UseLoginParams = {
  email: string;
  password?: string;
  code?: string;
};
type UseLoginError = DojoError & { remainingAttempts?: number; oidcIssuer?: string };

export const useMakeApiMutationLoginOperation = withOTCHandling(
  makeApiMutation({
    name: "login",
    path: "/api/session",
    method: "post",
    onSuccess: (response, { body: params }) => {
      useSessionFetcher.invalidateQueries();

      const loginType = "code" in params ? "login_code" : "email";

      if (response.body && response.body.teacher) {
        logClient.logEvent({
          entityId: response.body.teacher._id,
          experiments: [],
          eventName: "web.login.success",
          metadata: {
            current_site: "teach",
            login_type: loginType,
            account_type: response.body.teacher.role,
          },
        });
        return response.body;
      } else if (response && response.body.parent) {
        logClient.logEvent({
          entityId: response.body.parent._id,
          experiments: [],
          eventName: "web.login.success",
          metadata: {
            current_site: "teach",
            login_type: loginType,
            ref: "unknown",
            account_type: "parent",
          },
        });
        return location.navigateTo(location.subdomainLink("home"));
      } else if (response && response.body.student) {
        logClient.logEvent({
          entityId: response.body.student._id,
          experiments: [],
          eventName: "web.login.success",
          metadata: {
            current_site: "teach",
            ref: "unknown",
            account_type: "student",
          },
        });
        return location.navigateTo(location.subdomainLink("student"));
      } else {
        throw new Error("Unknown error in login");
      }
    },

    onError: (error) => {
      if (!isApiResponseError(error) && !(error instanceof UserCancelledCodeEntryError)) {
        return;
      }

      const { failureReason, remainingAttempts } = getErrorMetadata(error);

      logClient.logEvent({
        eventName: "web.login.failure",
        experiments: [],
        metadata: {
          current_site: "teach",
          login_type: "email",
          failure_reason: failureReason,
          remainingAttempts,
        },
      });
    },
  }),
  // do not use this for code-only logins (yet)
  { guard: (variables) => "password" in variables.body, productEventNamespace: "login" },
);

type UseLoginBodyType = { login: string; password: string } | { login: string; code: string };
export const useLoginOperation = (options: Parameters<typeof useMakeApiMutationLoginOperation>[0] = {}) => {
  const emailRef = useRef<string>(""); // this ref is weird but idk how else to make that mapper work
  const { mutate: _mutate, mutateAsync: _mutateAsync, error, ...rest } = useMakeApiMutationLoginOperation(options);

  const mutate = useCallback(
    ({ email, password, code }: UseLoginParams) => {
      emailRef.current = email;
      return _mutate({
        query: {
          withUsageReport: "true",
        },
        body: {
          login: email,
          password,
          code,
        } as UseLoginBodyType,
      });
    },
    [_mutate],
  );

  const mutateAsync = useCallback(
    ({ email, password, code }: UseLoginParams) => {
      emailRef.current = email;
      return _mutateAsync({
        query: {
          withUsageReport: "true",
        },
        body: {
          login: email,
          password,
          code,
        } as UseLoginBodyType,
      });
    },
    [_mutateAsync],
  );

  return {
    ...rest,
    error: mapUnknownLoginErrorToDojoError(error, { email: emailRef.current }),
    mutate,
    mutateAsync,
  };
};

const getErrorMetadata = (error: Error) => {
  if (error instanceof UserCancelledCodeEntryError) {
    return {
      remainingAttempts: null,
      failureReason: "user_cancelled_otp_entry",
      wrongPassword: false,
      wrongUsername: false,
      wrongCode: false,
      suspendedUser: false,
      forceResetPassword: false,
      mustUseSso: false,
      userCancelledOTPEntry: true,
    };
  }

  if (!isApiResponseError(error)) {
    return {
      remainingAttempts: null,
      failureReason: null,
      wrongPassword: null,
      wrongUsername: null,
      wrongCode: null,
      suspendedUser: null,
      forceResetPassword: null,
      mustUseSso: null,
      userCancelledOTPEntry: null,
    };
  }

  const remainingAttempts = error?.response?.header?.["remaining-attempts"];
  const wrongPassword = matchesError(error.response, "Incorrect password");
  const wrongUsername = matchesError(error.response, "Incorrect username");
  const wrongCode = matchesError(error.response, "Incorrect code");
  const suspendedUser = matchesError(error.response, "User is suspended");
  const forceResetPassword = error?.response?.body?.error?.code === "ERR_COMPROMISED_PASSWORD";
  const mustUseSso = matchesError(error.response, "Must use SSO");
  const mustUseOtc = error?.response?.body?.error?.code?.startsWith("ERR_MUST_USE_OTC");

  const failureReason = wrongPassword
    ? "wrong_password"
    : wrongUsername
      ? "wrong_username"
      : suspendedUser
        ? "suspended"
        : wrongCode
          ? "forceResetPassword"
          : forceResetPassword
            ? "invalid_code"
            : mustUseSso
              ? "must_use_sso"
              : mustUseOtc
                ? "must_use_otc"
                : "";

  return {
    remainingAttempts,
    failureReason,
    wrongPassword,
    wrongUsername,
    wrongCode,
    suspendedUser,
    forceResetPassword,
    mustUseSso,
    mustUseOtc,
    userCancelledOTPEntry: false,
  };
};

const mapUnknownLoginErrorToDojoError = (error: unknown, { email }: { email: string }): DojoError | undefined => {
  if (!(error instanceof Error)) {
    return undefined;
  }

  const {
    remainingAttempts,
    wrongPassword,
    wrongUsername,
    wrongCode,
    suspendedUser,
    forceResetPassword,
    mustUseSso,
    mustUseOtc,
    userCancelledOTPEntry,
  } = getErrorMetadata(error);

  if (userCancelledOTPEntry) {
    return errors.login.userCancelledOTPEntry();
  }

  if (mustUseOtc) {
    return errors.login.mustUseOtc({ email });
  }

  if (forceResetPassword) {
    return errors.login.forceResetPassword();
  }

  if (suspendedUser) {
    return errors.login.suspended();
  }

  if (wrongPassword || wrongUsername) {
    const error: UseLoginError = errors.login.invalid();

    error.remainingAttempts = parseInt(remainingAttempts, 10);
    return error;
  }

  if (
    error instanceof APIResponseError &&
    matchesError(error.response, "Too many login attempts, user is temporarily locked out of login")
  ) {
    return errors.login.lockout();
  }

  if (wrongCode) {
    const error: UseLoginError = errors.login.code();

    error.remainingAttempts = parseInt(remainingAttempts, 10);
    return error;
  }

  if (error instanceof APIResponseError && mustUseSso) {
    return errors.login.mustUseSso({ oidcIssuer: error?.response?.body?.error?.detail?.oidcIssuer });
  }

  return error as never as DojoError<unknown>;
};

export const useSendLoginCodeOperation = makeMutation<{ email: string }, void>({
  name: "loginCodeOperation",
  fn: async ({ email }) => {
    try {
      await callApi({
        method: "POST",
        path: "/api/oneTimeCode",
        body: { email },
      });
    } catch (err: any) {
      if (err.response?.status === 400 || err.response?.status === 404 || err.response?.status === 429) {
        return err;
      }

      throw err;
    }
  },
});

export const useNeedsDataTransferConsentFetcher = makeMemberQuery({
  path: "/api/needsDataTransferConsent",
  fetcherName: "needsDataTransferConsentMember",
});

export type UseSignUpParams = APIRequestBody<"/api/teacher", "post">;
export type SignupRole = NonNullable<UseSignUpParams["role"]>;
type SignupResponse = request.Response & { created: boolean };
export const useSignUpOperation = makeMutation<UseSignUpParams, CallApiDefaultResponse, DojoError>({
  name: "signUp",
  fn: async ({
    title,
    firstName,
    lastName,
    emailAddress,
    password,
    locale,
    role,
    invitationCode,
    referralId,
    schoolId,
    skipEmailVerification,
    dataTransferConsentGranted,
  }) => {
    try {
      const response = (await callApi({
        method: "POST",
        path: "/api/teacher",
        body: {
          title,
          firstName,
          lastName,
          emailAddress,
          password,
          locale,
          role,
          invitationCode: invitationCode || undefined,
          referralId: referralId || undefined,
          schoolId: schoolId || undefined,
          dataTransferConsent: dataTransferConsentGranted || undefined,
        },
        query: skipEmailVerification ? { skipEmailVerification: true } : undefined,
      })) as SignupResponse;
      if (response.body && response.body.parent) {
        logClient.logEvent({
          entityId: response.body.parent._id,
          eventName: "web.login.success",
          metadata: {
            current_site: "teach",
            account_type: "parent",
            signup_type: "email",
          },
          experiments: [],
        });
        logClient.sendMetrics([
          {
            type: "increment",
            value: 1,
            metricName: "teach.signup.parent_redirect",
          },
        ]);
        location.navigateTo(location.subdomainLink("home"));
        return;
      }

      if (response.body && response.body.student) {
        logClient.logEvent({
          experiments: [],
          entityId: response.body.student._id,
          eventName: "web.login.success",
          metadata: {
            current_site: "teach",
            account_type: "student",
            signup_type: "email",
          },
        });
        logClient.sendMetrics([
          {
            type: "increment",
            value: 1,
            metricName: "teach.signup.student_redirect",
          },
        ]);
        location.navigateTo(location.subdomainLink("student"));
        return;
      }

      if (response.created) {
        logClient.logEvent({
          entityId: response.body._id,
          eventName: "web.signup.success",
          metadata: {
            current_site: "teach",
            account_type: response.body.role,
            signup_type: "email",
          },
          experiments: [],
        });
      } else {
        logClient.logEvent({
          entityId: response.body._id,
          eventName: "web.login.success",
          experiments: [],
          metadata: {
            current_site: "teach",
            account_type: response.body.role,
            signup_type: "email",
          },
        });
      }

      await callApi({
        method: "POST",
        path: "/api/session",
        body: { login: emailAddress, password },
        query: {
          withUsageReport: true,
        },
      });

      return { ...response.body, status: response.status };
    } catch (error: any) {
      if (matchesError(error.response, "Email already exists")) {
        return errors.teacher.emailAlreadyExist();
      }

      if (matchesError(error.response, "Weak password: Password must not contain common passwords.")) {
        return errors.teacher.commonPassword();
      }

      throw error;
    }
  },
});

export const useCreateOIDCTeacher = makeApiMutation({
  name: "completeOIDCSignup",
  path: "/api/oidc/teacher",
  method: "post",
  catchError: (error) => {
    if (error instanceof APIResponseError && error.response.status === 400) {
      return error;
    }

    throw error;
  },
  onSuccess: async () => {
    await useSessionFetcher.invalidateQueries();
  },
});

export const useLinkLogin = makeApiMutation({
  name: "linkLoginOperation",
  path: "/api/oidc/link_login",
  method: "post",
  onSuccess: (data, params) => {
    useSessionFetcher.invalidateQueries();

    const loginType = "code" in params.body ? "login_code" : "email";

    if (data.body?.teacher) {
      logClient.logEvent({
        entityId: data.body?.teacher._id,
        eventName: "web.login.success",
        metadata: {
          current_site: "teach",
          login_type: loginType,
          account_type: data.body?.teacher.role,
        },
      });
    }
  },
  onError: (error) => {
    if (!isApiResponseError(error)) {
      return;
    }

    const { failureReason, remainingAttempts } = getErrorMetadata(error);

    logClient.logEvent({
      eventName: "web.login.failure",
      experiments: [],
      metadata: {
        current_site: "teach",
        login_type: "email",
        failure_reason: failureReason,
        remainingAttempts,
      },
    });
  },
});

//
// We need this global state for the sign up flow due to the screen being mounted/unmounted by the
// application container. If we don't keep track of the sign up state here in redux then we loose the
// local state between mounts.
const SET_SIGNUP_FLOW_ACCOUNT_CREATED = "auth2/setSignUpFlowAccountCreated";
const signupDoneHandler = produce((draft, action) => {
  if (action.type === SET_SIGNUP_FLOW_ACCOUNT_CREATED) {
    draft.signUpFlowAccountCreated = action.payload;
  }
});

type SignUpFlowAccountCreated = SignUpFlowGlobalState["signUpFlowAccountCreated"];
export const useSignUpFlowGlobalState = (): [SignUpFlowAccountCreated, (value: SignUpFlowAccountCreated) => void] => {
  const currentValue = useSelector(
    (state: { [STATE_KEY]: SignUpFlowGlobalState }) => state[STATE_KEY].signUpFlowAccountCreated,
  );
  const dispatch = useDispatch();
  const setValue = useCallback(
    (newValue: boolean) => {
      dispatch({ type: SET_SIGNUP_FLOW_ACCOUNT_CREATED, payload: newValue });
      dispatch(setSignupStage("SELECT_SCHOOL"));
    },
    [dispatch],
  );

  return [currentValue, setValue];
};

//
// Installers
const finalAuthReducer = combineActionHandlers(initialState, [signupDoneHandler]);

const install: PodInstallFunction = (installReducer) => {
  installReducer(STATE_KEY, finalAuthReducer);
};

export default install;
