import { AT } from "@web-monorepo/vite-auto-translate-plugin/runtime";
import { useState } from "react";
import { BodyText, Button } from "../../../nessie";
import { ThemeUIStyleObject } from "../../../nessie/stylingLib";
import { Image } from "../Image";
import LoadingMojo from "../LoadingMojo";
import AwardPieChart from "./AwardPieChart";

export type DonutProps = {
  loading?: boolean;
  enableCountMode?: boolean;
  awards?: {
    _id: string;
    positive?: boolean;
    weight: number;
    name: string;
  }[];
};

const Donut = ({ loading = false, enableCountMode = false, awards = [] }: DonutProps): JSX.Element => {
  const [countMode, setCountMode] = useState(false);

  const onlyNeutralPointsGiven = awards.filter((a) => a.weight !== 0).length === 0;

  const onShowPoints = () => {
    setCountMode(false);
  };

  const onShowCount = () => {
    setCountMode(true);
  };

  if (loading) {
    return (
      <div sx={styles.donutContainer}>
        <AT>Loading awards...</AT>
        <br />
        <br />
        <br />
        <br />
        <LoadingMojo inline={true} />
      </div>
    );
  } else if (awards.length === 0) {
    return (
      <div sx={styles.donutContainer}>
        <br />
        <br />
        <br />
        <Image alt="No points" src="components/donut/no-points.png" sx={styles.noPointsImage} />
        <BodyText>
          <strong>No points</strong>
        </BodyText>
        <BodyText>Please try another date range</BodyText>
      </div>
    );
  } else if (onlyNeutralPointsGiven && !countMode) {
    return (
      <div sx={styles.donutContainer}>
        <AwardPieChart awards={awards} countMode={true} />
      </div>
    );
  } else {
    let modeToggle = null;
    if (enableCountMode) {
      modeToggle = (
        <div sx={{ display: "flex", justifyContent: "center" }}>
          <div sx={{ paddingRight: "dt_xl", paddingLeft: "dt_xl" }}>
            <Button
              data-name="index:donut:donut_points"
              kind="tertiary"
              size={!countMode ? "m" : "s"}
              onClick={() => onShowPoints()}
            >
              Points
            </Button>
          </div>
          {"·"}
          <div sx={{ paddingRight: "dt_xl", paddingLeft: "dt_xl" }}>
            <Button
              data-name="index:donut:donut_count"
              kind="tertiary"
              size={countMode ? "m" : "s"}
              onClick={() => onShowCount()}
            >
              Count
            </Button>
          </div>
        </div>
      );
    }
    return (
      <div sx={styles.donutContainer}>
        <AwardPieChart awards={awards} countMode={countMode} />
        {modeToggle}
      </div>
    );
  }
};

export default Donut;

const styles: Record<string, ThemeUIStyleObject> = {
  donutContainer: {
    backgroundColor: "dt_background_primary",
    textAlign: "center",
    minHeight: "42rem",
  },

  noPointsImage: {
    width: "106px",
    margin: "auto",
  },
};
