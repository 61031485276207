import callApi from "@web-monorepo/infra/callApi";
import { makeMutation } from "../../reactQuery";
import {
  useClassArchivedMessageThreadsFetcher,
  useClassMessageThreadsFetcher,
  useClassMessageThreadsWithArchivedFetcher,
  useMessageThreadFetcher,
  useParentArchivedMessageThreadsFetcher,
  useParentMessageThreadsFetcher,
  useParentMessageThreadsWithArchivedFetcher,
} from "./fetchers";
import { queryClient } from "../../reactQuery/queryClient";

type UpdateThreadSettingsParams = {
  messageThreadId: string;
  entity: { _id: string; type: "parent" | "teacher" };
  body: {
    mutePushNotifications?: boolean;
    hideThread?: boolean;
    hideReadStatus?: boolean;
  };
};

export const useThreadSettingsOperation = makeMutation<UpdateThreadSettingsParams, unknown>({
  name: "updateThreadSettings",
  fn: async ({ entity, messageThreadId, body }) =>
    callApi({
      method: "PUT",
      path: `/api/${entity.type}/${entity._id}/message-thread/${messageThreadId}/settings`,
      body: {
        ...body,
      },
    }),
  onMutate: (params) => {
    useMessageThreadFetcher.setQueriesData(
      (draft) => {
        draft.settings = {
          ...draft.settings,
          ...params.body,
        };
      },
      { messageThreadId: params.messageThreadId },
    );
  },
  onSuccess: (_data, params) => {
    useMessageThreadFetcher.invalidateQueries({ messageThreadId: params.messageThreadId });
    queryClient.invalidateQueries({ queryKey: ["schoolTeacherUnifiedInboxMessageThreads"] });
    queryClient.invalidateQueries({ queryKey: ["schoolTeacherUnifiedInboxMessageThreadsWithArchived"] });
    queryClient.invalidateQueries({ queryKey: ["schoolTeacherUnifiedInboxArchivedMessageThreads"] });
    useClassMessageThreadsWithArchivedFetcher.invalidateQueries();
    useClassMessageThreadsFetcher.invalidateQueries();
    useClassArchivedMessageThreadsFetcher.invalidateQueries();
    useParentMessageThreadsFetcher.invalidateQueries();
    useParentMessageThreadsWithArchivedFetcher.invalidateQueries();
    useParentArchivedMessageThreadsFetcher.invalidateQueries();
  },
});
