import callApi from "@web-monorepo/infra/callApi";
import { APIRequestBody, APIRequestParameters, APIResponse } from "@web-monorepo/shared/api/apiTypesHelper";
import { makeCollectionQuery, makeMemberQuery, makeApiMutation, makeMutation } from "@web-monorepo/shared/reactQuery";
import find from "lodash/find";
import { useStudentsFetcher } from "app/pods/student/fetchers";
import { useStudentsForTeacherFetcher } from "app/pods/teacher";
import { useSchoolwidePointsDashboardFetcher } from "#/src/pages/(sidebar)/schools/[schoolId]/points/_api/fetchers";

export const reloadSchoolStudent = async (
  studentId: string,
  schoolId?: string,
  classroomId?: string,
): Promise<void> => {
  if (!classroomId && !schoolId) {
    throw new Error("Invite requires either a schoolId or a classroomId");
  }

  if (schoolId && !classroomId) {
    const data = await callApi({ method: "GET", path: `/api/dojoSchool/${schoolId}/student/${studentId}` });
    useSchoolStudentsFetcher.setQueriesData(
      (draft) => {
        const updatedStudent = data.body;
        // remove existing student
        // add updated student
        return draft.filter((student) => student._id !== studentId).concat(updatedStudent);
      },
      { schoolId },
    );
  }
};

export const useSchoolStudentsFetcher = makeCollectionQuery({
  path: "/api/dojoSchool/{schoolId}/student",
  query: { withMissingLastNames: "true", includeGraduated: "true" },
  fetcherName: "schoolStudents",
});

export const useSchoolDirectoryStudentsFetcher = makeCollectionQuery({
  path: "/api/dojoSchool/{schoolId}/directory/student",
  fetcherName: "schoolDirectoryStudents",
});

// this fetcher retrieves the minimal of student information that is needed to search for
// and add students to a classroom
export const useSchoolMinimalStudentsFetcher = makeCollectionQuery({
  path: "/api/dojoSchool/{schoolId}/studentList",
  query: { withMissingLastNames: "true", withLatestTeacherGuess: "true" },
  fetcherName: "schoolMinimalStudents",
});

export const useSchoolStudentFetcher = makeMemberQuery({
  path: `/api/dojoSchool/{schoolId}/student/{studentId}`,
  fetcherName: "schoolMinimalStudent",
});

export type CreateSchoolStudentsFromListParams = {
  path: APIRequestParameters<"/api/dojoSchool/{schoolId}/studentBatch", "post">["path"];
  body: APIRequestBody<"/api/dojoSchool/{schoolId}/studentBatch", "post">;
};
export const useCreateSchoolStudentsFromListOperation = makeApiMutation({
  name: "createSchoolStudentsFromList",
  path: "/api/dojoSchool/{schoolId}/studentBatch",
  method: "post",
  onSuccess: (_data, params) => {
    useStudentsForTeacherFetcher.invalidateQueries();
    useSchoolStudentsFetcher.invalidateQueries({ schoolId: params.path.schoolId });
    useSchoolMinimalStudentsFetcher.invalidateQueries({ schoolId: params.path.schoolId });
    useSchoolwidePointsDashboardFetcher.invalidateQueries({ schoolId: params.path.schoolId });
    useSchoolDirectoryStudentsFetcher.invalidateQueries({ schoolId: params.path.schoolId });
  },
});

export const useUpdateSchoolStudentOperation = makeApiMutation({
  name: "updateSchoolStudent",
  path: "/api/dojoSchool/{schoolId}/student/{studentId}",
  method: "put",
  onSuccess: (_data, params) => {
    useStudentsFetcher.setQueriesData((draft) => {
      const { _id, firstName, lastName } = params.body;
      const draftStudent = find(draft, (item) => item._id === _id);
      if (!draftStudent) return;
      draftStudent.firstName = firstName;
      draftStudent.lastName = lastName;
    });

    useSchoolMinimalStudentsFetcher.invalidateQueries({ schoolId: params.path.schoolId });

    useSchoolStudentsFetcher.setQueriesData((draft) => {
      if (draft && draft[0] && params.path.schoolId === draft[0].schoolId) {
        const updatedStudent = params.body;
        const student = find(draft, { _id: updatedStudent._id });
        if (student) {
          student.firstName = updatedStudent.firstName;
          student.lastName = updatedStudent.lastName;
          student.avatar =
            updatedStudent.avatar && typeof updatedStudent.avatar === "string" ? updatedStudent.avatar : student.avatar;
        }
      }
    });
  },
});

export type RemoveSchoolStudentParams = APIRequestParameters<
  "/api/dojoSchool/{schoolId}/student/{studentId}/hide",
  "post"
>;
export const useRemoveSchoolStudentOperation = makeApiMutation({
  name: "removeSchoolStudent",
  path: "/api/dojoSchool/{schoolId}/student/{studentId}/hide",
  method: "post",
  onSuccess: (_data, params) => {
    useSchoolMinimalStudentsFetcher.invalidateQueries({ schoolId: params.path.schoolId });
    useSchoolStudentsFetcher.setQueriesData((draft) => {
      if (draft && draft[0] && params.path.schoolId === draft[0].schoolId) {
        const { studentId } = params.path;

        return draft.filter((student) => student._id !== studentId);
      }
    });
  },
});

export type RemoveSchoolStudentsParams = APIRequestParameters<
  "/api/dojoSchool/{schoolId}/students/hide",
  "post"
>["path"] &
  APIRequestBody<"/api/dojoSchool/{schoolId}/students/hide", "post">;
type RemoveSchoolStudentsResponse = APIResponse<"/api/dojoSchool/{schoolId}/students/hide", "post">;

export const useRemoveSchoolStudentsOperation = makeMutation<RemoveSchoolStudentsParams, RemoveSchoolStudentsResponse>({
  name: "removeSchoolStudents",
  async fn({ schoolId, studentIds }) {
    try {
      return await callApi({
        method: "POST",
        path: `/api/dojoSchool/${schoolId}/students/hide`,
        body: { studentIds },
      });
    } catch (err: any) {
      if (err.response.status === 400) {
        return err;
      }

      throw err;
    }
  },
  onMutate: (params) => {
    useSchoolStudentsFetcher.setQueriesData((draft) => {
      if (params.schoolId === draft[0].schoolId) {
        const { studentIds } = params;
        return draft.filter((student) => !studentIds.includes(student._id));
      }
    });
  },
});

export const isStudentDirectory = () => window.location.hash.includes("directory/students");
