import { TeacherSession } from "app/pods/teacher";
import { translate } from "app/utils/translate";

import {
  format,
  formatLocalTime,
  isToday,
  isTomorrow,
  isYesterday,
  addDays,
  isAfter as datesIsAfter,
  isBefore as datesIsBefore,
} from "@web-monorepo/dates";

export * from "@web-monorepo/shared/utils/time";

type InputType = string | { _id: string };

export function isBeforeLastJuly1st(isoStr: Date) {
  const inputDate = new Date(isoStr);

  if (isNaN(Number(inputDate))) return false;

  const JULY = 6; // 0-indexed;

  const now = new Date();
  const currentMonth = now.getMonth();
  const currentYear = now.getFullYear();

  const july1st = new Date();
  july1st.setDate(1);
  july1st.setMonth(JULY);
  july1st.setFullYear(currentMonth >= JULY ? currentYear : currentYear - 1);
  july1st.setHours(0);
  july1st.setMinutes(0);
  july1st.setSeconds(0);

  return july1st > inputDate;
}

const hasId = (input: InputType): input is { _id: string } => {
  if (typeof input === "string") {
    return false;
  }
  return !!input._id;
};

export function parseDate(input: InputType): Date {
  if (hasId(input)) return parseDate(input._id);
  if (/^[0-9a-f]{24}$/.test(input)) {
    return new Date(parseInt(input.slice(0, 8), 16) * 1000);
  } else {
    return new Date(input);
  }
}

export function isBefore(input: TeacherSession, date: number) {
  return parseDate(input).getTime() < date;
}

export function isDaysOld(input: TeacherSession, days: number) {
  const cutoff = new Date().getTime() - days * 86400000;
  return isBefore(input, cutoff);
}

export function isWeeksOld(input: TeacherSession, weeks: number) {
  return isDaysOld(input, weeks * 7);
}

// replicating moment().calendar() functionality:

export function calendarFormat(date: Date) {
  if (isToday(date)) {
    return translate({
      str: "dojo.teacher_web:calendar.today_at",
      fallback: "Today at __time__",
      subs: { time: formatLocalTime(date) },
    });
  } else if (isTomorrow(date)) {
    return translate({
      str: "dojo.teacher_web:calendar.tomorrow_at",
      fallback: "Tomorrow at __time__",
      subs: { time: formatLocalTime(date) },
    });
  } else if (isYesterday(date)) {
    return translate({
      str: "dojo.teacher_web:calendar.yesterday_at",
      fallback: "Yesterday at __time__",
      subs: { time: formatLocalTime(date) },
    });
  } else if (
    datesIsBefore(date, new Date()) &&
    datesIsAfter(date, addDays(new Date(), -6), { mode: "day", inclusive: true })
  ) {
    return translate({
      str: "dojo.teacher_web:calendar.last_weekday_at",
      fallback: "Last __weekday__ at __time__",
      subs: { weekday: format(date, { weekday: "long" }), time: formatLocalTime(date) },
    });
  } else if (datesIsBefore(date, addDays(new Date(), 6), { mode: "day", inclusive: true })) {
    return translate({
      str: "dojo.teacher_web:calendar.this_weekday_at",
      fallback: "__weekday__ at __time__",
      subs: { weekday: format(date, { weekday: "long" }), time: formatLocalTime(date) },
    });
  } else {
    return format(date, { year: "numeric", month: "2-digit", day: "2-digit" });
  }
}
