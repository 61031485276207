import createAction from "@web-monorepo/infra/createAction";
import { PodInstallFunction } from "@web-monorepo/shared/podInfra";
import produce from "immer";

const STATE_KEY = "signup";

export const Stages = {
  EMAIL_ONLY: "EMAIL_ONLY",
  LOGIN: "LOGIN",
  USER_INFO: "USER_INFO",
  SELECT_SCHOOL: "SELECT_SCHOOL",
  CREATE_SCHOOL: "CREATE_SCHOOL",
  CONFIRM_SCHOOL: "CONFIRM_SCHOOL",
  UPDATE_EMAIL: "UPDATE_EMAIL",
  EMAIL_VERIFICATION: "EMAIL_VERIFICATION",
} as const;

export type StageStep = (typeof Stages)[keyof typeof Stages];

const initialState = {
  stage: Stages.EMAIL_ONLY,
};

const SET_STAGE = createAction("signup/setStage");

export const LOGGED_OUT_STAGES = [Stages.EMAIL_ONLY, Stages.LOGIN, Stages.USER_INFO];

export type Stage = {
  type: string;
  payload: { stage: string };
};
export const setSignupStage = (stage: string): Stage => ({
  type: SET_STAGE,
  payload: { stage },
});

const signupReducer = produce((draft, action) => {
  if (action.type === SET_STAGE) {
    draft.stage = action.payload.stage;
  }
}, initialState);

export const selectEmailOnlyStage = (state: { [STATE_KEY]: typeof initialState }) => state?.[STATE_KEY].stage;
export const selectSignupStage = () => Stages.USER_INFO;

//
// -------------------------------
// Setup
//

const install: PodInstallFunction = (installReducer) => {
  installReducer(STATE_KEY, signupReducer);
};

export default install;
