import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";
import * as React from "react";
import { ComponentPropsWithoutRef } from "react";
import { BodyText, Button, LabelText } from "../../nessie";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";
import ModalCloseButton from "./CloseButton";
import ScrollableModal from "./Scrollable";

type AnnouncementModalProps = {
  showCloseButton?: boolean;
  close: (event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => void;
  text: string;
  cta: {
    text: string;
    props: ComponentPropsWithoutRef<typeof Button>;
  };
  header?: string;
  image?: string;
  imageStyle?: ThemeUIStyleObject;
};

const AnnouncementModal = ({
  showCloseButton,
  close,
  text,
  cta,
  header,
  image,
  imageStyle,
}: AnnouncementModalProps): JSX.Element => {
  return (
    <ScrollableModal
      data-name="announcement:announcement_modal:info_announcement"
      sx={styles.modal}
      onRequestHide={close}
      label={autoTranslate("Announcement")}
    >
      <div sx={styles.modalContents}>
        {showCloseButton && (
          <ModalCloseButton data-name="announcement:announcement_modal:modal_close_button" close={close} />
        )}
        {image && (
          <div
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              borderTopLeftRadius: "dt_radius_s",
              borderTopRightRadius: "dt_radius_s",
              minHeight: "15rem",
              overflow: "hidden",
            }}
          >
            <img alt="" src={image} sx={{ ...styles.image, ...imageStyle }} />
          </div>
        )}
        <div sx={{ display: "flex", flexDirection: "column", textAlign: "center", padding: "dt_xl" }}>
          {header && (
            <LabelText as="h3" sx={{ marginBottom: "dt_xl" }}>
              {header}
            </LabelText>
          )}
          <BodyText kind="secondary" sx={{ marginBottom: "dt_xl" }}>
            {text}
          </BodyText>
          <Button onClick={close} {...cta.props} size="s" sx={{ margin: "auto" }} data-name="announcement_modal:cta">
            {cta.text}
          </Button>
        </div>
      </div>
    </ScrollableModal>
  );
};

export default AnnouncementModal;

const styles: Record<string, ThemeUIStyleObject> = {
  modal: {
    width: "50rem",
  },
  modalContents: {
    overflow: "hidden",
    margin: 0,
  },
  closeButton: {
    color: "dt_content_light",
    textShadow: "#555 0px 1px 5px",
  },
  image: {
    minHeight: "5rem",
    maxWidth: "50.2rem",
  },
};
