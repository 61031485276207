// this is a flag used to determine if app has loaded yet. Don't remove it!
// index.html checks this value and can fallback to non-CDN app scripts
window.__DOJO__ = true;

// We need this polyfill to do not fail on IE11 when trying to
// upload a storypost (because we're using a carousel that needs this function)
import "intersection-observer";
import "extended-proptypes/lib/extend-from-standalone";

// don't swamp the console with well-known React warnings. Remove ASAP :)
import "@web-monorepo/shared/consoleOverrides/ignoreReactUnknownPropWarning";
import "@web-monorepo/shared/consoleOverrides/ignorePropTypesCallWarning";
import "@web-monorepo/shared/consoleOverrides/ignoreReactLifeCycleWarnings";

// throw error for failling PropTypes checks
import "@web-monorepo/shared/consoleOverrides/throwPropTypesErrors";
import "app/initialization";

import "@classdojo/web/polyfills";
import * as logClient from "@classdojo/log-client";
import * as Components from "@classdojo/web";
import { useCrossTabLogoutBroadcastListener } from "@classdojo/web/hooks/useCrossTabLogoutBroadcastListener";
import { NessieThemeProvider } from "@classdojo/web/nessie";
import ApplicationContainer from "#/app/views/ApplicationContainer";
import { useSideEffectOnMount } from "@web-monorepo/hooks";
import { ErrorHandlerContext } from "@classdojo/web/pods/errorHandling";
import ToastRenderingContainer from "#/app/pods/shared/components/ToastRenderingContainer";
import AppTopLevelErrorBoundary from "@classdojo/web/pods/errorHandling/components/AppTopLevelErrorBoundary";
import { ModalsContextProvider } from "@classdojo/web/pods/automatedEvents/modalsContext";
import ErrorBanner from "@classdojo/web/pods/errorHandling/components/ErrorBanner";
import { ProductAreaContextProvider } from "@classdojo/web/pods/errorHandling/productArea";
import { UserActionsHistory } from "@classdojo/web/pods/userActionsHistory";
import FixBadPaths from "@web-monorepo/shared/router/FixBadPaths";
import { RouterNavigateProvider } from "@web-monorepo/shared/router/reactRouterNavigate";
import { MatomoConfiguredProvider } from "#/app/utils/MatomoConfiguredProvider";
import { ReactQueryProvider } from "@web-monorepo/shared/reactQuery/provider";
import {
  scheduleRefresh,
  addMetaTagToRefreshNearMidnight,
  refreshIfOldVersion,
} from "@web-monorepo/shared/utils/midnightRefresh";
import { useState, useEffect } from "react";
import * as React from "react";
import { Provider } from "react-redux";
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router";
import { AlertDismissalSectionContainer } from "#/app/pods/devTools/AlertDismissalSection";
import { ThemesSection } from "@web-monorepo/shared/devTools/ThemesSection";
import * as Telemetry from "@web-monorepo/telemetry";
import { createWebErrorHandler } from "#/app/teachErrorHandling";
import { DevTools } from "app/pods/devTools";
import { setAppFeatureSwitchesConfig } from "app/pods/featureSwitches/config";
import createStore from "app/reduxStore";
import { Outlet } from "react-router-dom";
import uiInit from "app/ui";
import env from "app/utils/env";
import { translate } from "app/utils/translate";
import { ColorThemeContext, useColorTheme } from "@classdojo/web/nessie/components/useColorTheme";
import { DesignSystemVariables, DDSScreenReaderAnnouncer, DDSProvider } from "@web-monorepo/dds";
import { OTCPageProvider } from "@web-monorepo/one-time-codes";

// For hiding the redux ones
window.hideLogs = true;

if (Config.nodeEnv === "production") {
  Telemetry.init({
    dsn: "https://029b3d70711921ed229c881a5c8749e2@sentry.classdojo.com/7",
    tracing: {
      reactRouter: {
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      },
    },
    replays: {
      onErrorSampleRate: 0.05,
    },
  });
}

Components.initialize({
  translate,

  logEvent: (...args) => logClient.logEvent(...args),
});

// Configure featureSwitches abstraction
setAppFeatureSwitchesConfig();

if (env.isDev) {
  global.React = React;
}

scheduleRefresh("teacher");
refreshIfOldVersion({ buildAgeInDays: 3, releaseAgeInDays: 1 });

//
// Initialize Datadog RUM
// https://www.datadoghq.com/dg/real-user-monitoring/overview/
//
// if (Config.nodeEnv === "production") {
//   initializeDatadogRUMForTeach();
// }

const userActionsHistory = new UserActionsHistory();
//
// Register browser history changes in our 'user actions module'
// We are calling this code 'early' and only once. To ensure we track any navigation or url change that happens
// even before we mount the top component in the app.
userActionsHistory.listenToBrowserUrlChanges();

export default function Root() {
  const [errorHandler] = useState(() => createWebErrorHandler(userActionsHistory));
  useCrossTabLogoutBroadcastListener();

  const [{ store }] = useState(() =>
    // Build the redux store with all the reducers configured + middlewares.
    createStore(errorHandler, userActionsHistory),
  );

  useSideEffectOnMount(() => {
    uiInit();
    addMetaTagToRefreshNearMidnight();
  });

  const { selectedTheme, mode, setNewTheme } = useColorTheme();

  return (
    <Provider store={store}>
      <DesignSystemVariables theme="light" />
      <FixBadPaths />
      <DDSScreenReaderAnnouncer />
      <RouterNavigateProvider />
      <ReactQueryProvider>
        <ErrorHandlerContext.Provider value={errorHandler}>
          <ProductAreaContextProvider>
            <DDSProvider>
              <ColorThemeContext.Provider value={{ setNewTheme, mode }}>
                <NessieThemeProvider selectedTheme={selectedTheme}>
                  <ErrorBanner />
                  <AppTopLevelErrorBoundary errorHandler={errorHandler}>
                    <MatomoConfiguredProvider>
                      <>
                        <ModalsContextProvider>
                          <ToastRenderingContainer>
                            <ApplicationContainer>
                              <OTCPageProvider entityType="teacher" translate={translate}>
                                <Outlet />
                              </OTCPageProvider>
                            </ApplicationContainer>
                          </ToastRenderingContainer>
                        </ModalsContextProvider>
                        <DevTools
                          additionalSections={{
                            AlertDismissal: { title: "Alert Dismissals", component: AlertDismissalSectionContainer },
                            Themes: { title: "Themes", component: ThemesSection },
                          }}
                        />
                      </>
                    </MatomoConfiguredProvider>
                  </AppTopLevelErrorBoundary>
                </NessieThemeProvider>
              </ColorThemeContext.Provider>
            </DDSProvider>
          </ProductAreaContextProvider>
        </ErrorHandlerContext.Provider>
      </ReactQueryProvider>
    </Provider>
  );
}
