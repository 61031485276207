import callApi, { CallApiDefaultResponse } from "@web-monorepo/infra/callApi";
import { APIRequestBody, CollectionFetcherReturnType } from "@web-monorepo/shared/api/apiTypesHelper";
import { makeCollectionQuery, makeMutation, NOOP, WAITING_FOR_DEPENDENCIES } from "@web-monorepo/shared/reactQuery";
import find from "lodash/find";
import { useStudentsFetcher } from "app/pods/student/fetchers";
import { isBetween, parseJSON, toDate } from "@web-monorepo/dates";

export type StudentAttendanceStates = {
  [key: string]: AttendanceState;
};

type SaveAttendanceBodyType = APIRequestBody<"/api/attendance", "post">;

type SaveAttendanceParams = Pick<SaveAttendanceBodyType, "date"> & {
  classroomId: string;
  attendanceStates: StudentAttendanceStates;
};

type AttendanceStateSummary = {
  present: string[];
  tardy: string[];
  absent: string[];
  leftEarly: string[];
};

export const useSaveAttendanceOperation = makeMutation<SaveAttendanceParams, CallApiDefaultResponse>({
  name: "saveAttendance",
  fn: async ({ classroomId, attendanceStates, date }) => {
    const results: AttendanceStateSummary = {
      present: [],
      tardy: [],
      absent: [],
      leftEarly: [],
    };

    Object.entries(attendanceStates).forEach(([studentId, state]) => {
      results[state].push(studentId);
    });

    const body: SaveAttendanceBodyType = {
      classId: classroomId,
      date,
      ...results,
    };

    return callApi({
      method: "POST",
      path: "/api/attendance",
      body,
    });
  },
  onSuccess: (data, params) => {
    if (data) {
      const attendanceDay = toDate(`${params.date}T12:00:00`);
      attendanceFetcher.shouldInvalidateQueries((_data, queryParams) => {
        return (
          queryParams.classId === params.classroomId &&
          isBetween(attendanceDay, parseJSON(queryParams.from), parseJSON(queryParams.to), {
            mode: "day",
            inclusivity: "[]",
            tz: "UTC",
          })
        );
      });
    }
    useStudentsFetcher.setQueriesData(
      (draft) => {
        Object.keys(params.attendanceStates).forEach((studentId) => {
          const draftStudent = find(draft, (item) => item._id === studentId);
          if (!draftStudent) return;
          draftStudent.currentAttendance = params.attendanceStates[studentId];
        });
      },
      { classId: params.classroomId },
    );
  },
});

export type Attendance = CollectionFetcherReturnType<typeof attendanceFetcher>;
export type AttendanceRecord = Attendance["records"][0];
export type AttendanceState = AttendanceRecord["state"];

const attendanceFetcher = makeCollectionQuery({
  path: "/api/attendance",
  queryParams: ["classId", "from", "to"],
  query: { showLeftEarly: "true" },
  fetcherName: "attendance",
});

type AttendanceFetcherParams = { classId: string; from: Date; to: Date };
export const useAttendanceFetcher = (
  props: AttendanceFetcherParams | typeof NOOP | typeof WAITING_FOR_DEPENDENCIES,
) => {
  return attendanceFetcher(
    props === NOOP || props === WAITING_FOR_DEPENDENCIES
      ? props
      : {
          classId: props.classId,
          from: props.from ? props.from.toISOString() : new Date("2011-05-05").toISOString(),
          to: props.to ? props.to.toISOString() : new Date().toISOString(),
        },
  );
};
