import * as logClient from "@classdojo/log-client";
import React from "react";
import { useOnFirstRender } from "@web-monorepo/hooks";

type LogFirstRenderProps = {
  metadata?: Record<string, any>;
  experiments?: string[];
  children?: React.ReactNode;
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name": string;
  legacyEvent?: Parameters<typeof logClient.logEvent>[0];

  anonymizeUser?: boolean;
};

const counts = {} as Record<string, number>;

export default function LogFirstRender({
  "data-name": dataName,
  legacyEvent,
  metadata,
  experiments,
  anonymizeUser,
  children,
}: LogFirstRenderProps): null | JSX.Element {
  useOnFirstRender(() => {
    const eventName = `${logClient.getSite()}.${dataName}.first_render`;
    if (!counts[eventName]) counts[eventName] = 0;
    const count = counts[eventName]++;

    const extendedMetadata = { count, msSinceLoad: Math.round(performance.now()), ...metadata };

    const userTracking = anonymizeUser
      ? {
          entityId: null,
          userType: null,
          sessionId: "unknown",
        }
      : {};

    logClient.logEvent({ ...userTracking, eventName, metadata: extendedMetadata, experiments });
    if (legacyEvent) {
      if (typeof legacyEvent == "string") {
        logClient.logEvent({ ...userTracking, eventName: legacyEvent, metadata, experiments });
      } else {
        logClient.logEvent({ ...userTracking, experiments, metadata: extendedMetadata, ...legacyEvent });
      }
    }
  });

  return children ? <>{children}</> : null;
}
