import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";
import { useState } from "react";
import useWatch from "../../hooks/useWatch";
import { StandardModal } from "../modals";
import RangeCalendar from "./RangeCalendar";

export type DateRange = { start: Date; end: Date; from: string; to: string };

export type DateRangePickerProps = {
  isOpen: boolean;
  minDate?: Date;
  initialRange?: { from: string; to: string };
  onSelect: (params: DateRange) => void;
  onRequestHide: () => void;
  noRequestHideOnSelect?: boolean;
};

const startOf = (dateStr: string) => new Date(`${dateStr}T00:00:00`);

export default function DateRangePicker({
  isOpen,
  minDate,
  initialRange,
  onSelect,
  onRequestHide,
  noRequestHideOnSelect,
}: DateRangePickerProps) {
  const [from, setFrom] = useState(initialRange?.from);
  const [to, setTo] = useState(initialRange?.to);

  useWatch(
    [initialRange],
    () => {
      setFrom(initialRange?.from);
      setTo(initialRange?.to);
    },
    { deep: true },
  );

  const handleConfirm = () => {
    if (!from || !to) return;

    const [actualFrom, actualTo] = from < to ? [from, to] : [to, from];

    onSelect({
      from: actualFrom,
      to: actualTo,
      start: startOf(actualFrom),
      end: startOf(actualTo),
    });
    if (!noRequestHideOnSelect) onRequestHide();
  };

  return (
    <StandardModal
      data-name="date_range_picker:standard_modal"
      sx={modalStyle}
      title={autoTranslate("Choose date range")}
      onRequestHide={onRequestHide}
      isOpen={isOpen}
      primaryButton={{
        text: autoTranslate("Select dates"),
        props: {
          "data-name": "date-range-picker-confirm",
          onClick: handleConfirm,
          disabled: !(from && to),
        },
      }}
    >
      <RangeCalendar
        value={{
          start: from ? startOf(from) : new Date(),
          end: to ? startOf(to) : new Date(),
        }}
        onChange={(selectedRange) => {
          setFrom(selectedRange.start.toLocaleDateString("en-CA"));
          setTo(selectedRange.end.toLocaleDateString("en-CA"));
        }}
        maxDate={new Date()}
        minDate={minDate}
      />
    </StandardModal>
  );
}

const modalStyle = {
  display: "inline-block",
  width: "auto",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minHeight: "575px",
};
