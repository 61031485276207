import flow from "lodash/flow";
import map from "lodash/map";
import mapValues from "lodash/mapValues";
import max from "lodash/max";
import uniq from "lodash/uniq";
import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";

export type PersonalInfo = {
  firstName?: string;
  lastName?: string | null;
  minimalLastName?: string;
  title?: string;
  emailAddress?: string | null;
  avatar?: string;
  _id?: string;
  lastAccessedClass?: { name?: string };
};

export function fullName(obj?: PersonalInfo): string {
  if (!obj) return "";
  const { firstName, lastName } = obj;
  if (!firstName) return lastName || "";
  if (!lastName) return firstName;

  // NOTE: I'm keeping this here, but technically this is wrong. We need to ask people what their
  // preferred full name is and use that. The full name displayed for a person is technically not
  // orderable by localization as its depending on the culture of the person being displayed and
  // not the culture of the person viewing the data.
  return autoTranslate("__firstName__ __lastName__", { firstName, lastName });
}

export function capitalizedFullName(obj?: PersonalInfo): string {
  if (!obj) return "";
  const { firstName, lastName } = obj;
  if (!firstName && !lastName) return "";
  return [capitalize(firstName || ""), capitalize(lastName || "")].join(" ").trim();
}

export function publicFullName(obj?: PersonalInfo): string {
  if (!obj) return "";
  const firstName = obj.firstName;
  const lastName = publicLastName(obj);
  if (!firstName && !lastName) return "";
  return [firstName || "", lastName || ""].join(" ").trim();
}

export function lastInitial(obj?: PersonalInfo): string {
  if (!obj) return "";
  return [obj.firstName || "", (obj.lastName || "").slice(0, 1)].join(" ").trim();
}

export function titleLast(obj?: PersonalInfo): string {
  if (!obj) return "";
  const { title, lastName } = obj;
  if (!title && !lastName) return "";
  return [title || "", lastName || ""].join(" ").trim();
}

export function titleFirstLast(obj?: PersonalInfo): string {
  if (!obj) return "";
  const { title, firstName, lastName } = obj;
  if (!title && !lastName && !firstName) return "";
  return [title || "", firstName || "", lastName || ""].join(" ").trim();
}

export function capitalize(word?: string) {
  if (!word) return "";
  return word.length ? word[0].toUpperCase() + word.slice(1) : word;
}

export function capitalizePhrase(phrase?: string) {
  if (!phrase) return "";
  return phrase
    .toLowerCase()
    .split(" ")
    .map((word) => capitalize(word))
    .join(" ");
}

export function splitFirstAndLast(fullName?: string) {
  fullName = fullName?.trim();
  if (!fullName) return [];
  const names = fullName.replace(/\s+/g, " ").split(" ");
  const fn = capitalize(names[0]);
  const ln = capitalize(names.slice(1).join(" "));
  return [fn.trim(), ln.trim()];
}

export function firstName(obj?: PersonalInfo): string {
  if (!obj) return "";
  return (obj.firstName || "").trim();
}

export function lastName(obj?: PersonalInfo): string {
  if (!obj) return "";
  return (obj.lastName || "").trim();
}

export function publicLastName(obj?: PersonalInfo): string | undefined | null {
  if (!obj) return "";
  return obj.minimalLastName !== undefined ? obj.minimalLastName : obj.lastName;
}

// given a list of strings e.g. ["a", "b", "c"]
// listOfNamesToStrings returns "a, b and c"
// for list of length 2 e.g. ["d", "e"] returns "d and e"
// for list of length 1 e.g. ["f"] returns "f"
// for an empty list or undefined input, returns ""
export function listOfNamesToString(list: string[]): string {
  list = list.filter((str) => Boolean(str));
  if (!list || list.length === 0) {
    return "";
  }
  if (list.length === 1) {
    return list[0];
  }
  if (list.length === 2) {
    return `${list[0]} ${autoTranslate("and")} ${list[1]}`;
  }
  // str cannot be undefined given that we are already handling the list.length === 0 scenario.
  let str = list.shift()!;
  list.forEach((name, i) => {
    const finalName = i === list.length - 1;
    str = finalName ? `${str} ${autoTranslate("and")} ${name}` : `${str}, ${name}`;
  });
  return str;
}

type StudentWithMinimalLastName<T> = T & { minimalLastName: string };

// When teachers elect to not display student last names, we still need to show
// something when two students have the same first name. This maps over a list of
// students and adds a `minimalLastName` property, which is just enough characters
// of their last name to distinguish them from other students. For students with
// a unique first name, it will be the empty string.
export function addIdentifiableLastNames<T extends PersonalInfo>(students: T[]): StudentWithMinimalLastName<T>[] {
  // group last names by first name
  const lastNamesByFirstName = students.reduce<Record<string, string[]>>((map, student) => {
    const studentFn = firstName(student).toLowerCase();
    const studentLn = lastName(student).toLowerCase();

    map[studentFn] = map[studentFn] || [];
    map[studentFn].push(studentLn);
    return map;
  }, {});

  // For each first name, figure out how many characters of the last names
  // we would need to take so that all students have unique names
  // If they're the only student with this first name, we do nothing.
  const lastNameCharCountsByFirstName = mapValues(lastNamesByFirstName, (lastNames) => {
    if (lastNames.length === 1) return 0;
    const maxLength = flow((x) => map(x, "length"), max)(lastNames);
    for (let nameLength = 1; nameLength < maxLength; nameLength++) {
      const shortenedLastNames = lastNames.map((name) => name.slice(0, nameLength).trim());
      if (uniq(shortenedLastNames).length === uniq(lastNames).length) return nameLength;
    }
    return maxLength;
  });
  // finally, for each student add their `minimalLastName` property with the new length
  return students.map((student) => {
    const studentFn = firstName(student).toLowerCase();
    const sliceLength = lastNameCharCountsByFirstName[studentFn] || 0;
    const minimalLastName = lastName(student).slice(0, sliceLength).trim();
    return { ...student, minimalLastName };
  });
}
