import identity from "lodash/identity";
import type { AnyAction } from "redux";
import { eventChannel, END } from "redux-saga";
import { put, take, all } from "redux-saga/effects";
import RealtimeClient, { CLIENT_ROLE } from "./RealtimeClient";
import Exchange from "./adapters/exchange";
import IframeAdapter from "./adapters/iframe";
import PubnubAdapter from "./adapters/pubnub";
import MESSAGE from "./message";

type Payload = Record<string, unknown>;

type Message<T = Payload> = {
  type?: string;
  payload?: T;
};

type MapToAction = (message: Message) => AnyAction[] | AnyAction | null;

export function createSubscribeSaga(client: RealtimeClient, mapToAction: MapToAction = identity) {
  return function* subscribeSaga() {
    const chan = eventChannel((emit) => {
      client.on("message", (type, payload) => {
        emit({ type, payload });
      });

      client.on("close", () => emit(END));
      return () => {
        client.close();
      };
    });

    while (true) {
      const received: Message = yield take(chan);
      const action = mapToAction(received);
      if (action) {
        if (Array.isArray(action)) {
          yield all(action.map((a) => put(a)));
        } else {
          yield put(action);
        }
      }
    }
  };
}

type MapFromAction = (action?: AnyAction) => Message | null;

export function createPublishSaga(client: RealtimeClient, mapFromAction: MapFromAction = () => null) {
  return function* publishSaga() {
    while (true) {
      const action: AnyAction = yield take();
      const message = mapFromAction(action);
      if (message && message.type) client.publish(message.type, message.payload);
    }
  };
}

export { CLIENT_ROLE, MESSAGE, RealtimeClient, Exchange, PubnubAdapter, IframeAdapter };
