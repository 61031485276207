import callApi from "@web-monorepo/infra/callApi";
import { makeMutation } from "../../reactQuery";
import { queryClient } from "../../reactQuery/queryClient";
import { makeQueryKey } from "../../reactQuery/queryKey";
import { MessageThread } from "../types";
import { useParentMessageThreadsFetcher } from "./fetchers";
import { navigate } from "../../router/reactRouterNavigate";

type CreateChatThreadParams = {
  participants: Array<{ _id: string }>;
  teacherId: string;
  classId: string;
  origin: string;
};

export const useCreateChatThreadOperation = makeMutation<CreateChatThreadParams, MessageThread>({
  name: "sendChatMessage",
  fn: async ({ teacherId, classId, origin, participants }) => {
    const body = { participants, origin };
    try {
      const res = await callApi({
        method: "POST",
        path: `/api/teacher/${teacherId}/class/${classId}/message-thread`,
        body,
      });

      return res.body;
    } catch (error: any) {
      if (error.response && error.response.status >= 500) {
        return error;
      }

      throw error;
    }
  },
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: makeQueryKey({ fetcherName: "classMessageThreads" }) });
    queryClient.invalidateQueries({
      queryKey: makeQueryKey({ fetcherName: "schoolTeacherUnifiedInboxMessageThreads" }),
    });
  },
});

type CreateParentChatThreadParams = {
  participants: (
    | {
        _id: string;
        type: "parent";
      }
    | {
        _id: string;
        type: "teacher";
      }
  )[];
  type: "c";
  parentId: string;
  origin: string;
};

export const useCreateParentChatThreadOperation = makeMutation<
  CreateParentChatThreadParams,
  { messageThreadId: string; participants: { _id: string; type: "teacher" | "parent" }[]; type: MessageThread["type"] }
>({
  name: "useCreateParentChatThread",
  fn: async ({ parentId, participants, origin, type }) => {
    const body = { participants, origin, type };
    try {
      const res = await callApi({
        method: "POST",
        path: `/api/parent/${parentId}/message-thread`,
        body,
      });
      return { messageThreadId: res.body.messageThreadId, participants, type };
    } catch (error: any) {
      if (error.response && error.response.status >= 500) {
        return error;
      }

      throw error;
    }
  },
  onSuccess: (data, params) => {
    useParentMessageThreadsFetcher.invalidateQueries({ parentId: params.parentId });
    navigate(`/messages/${data.messageThreadId}`);
  },
});
