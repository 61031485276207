import { AT } from "@web-monorepo/vite-auto-translate-plugin/runtime";
import * as React from "react";
import { useSelector } from "react-redux";
import { theme } from "../../../nessie";
import { ThemeUIStyleObject } from "../../../nessie/stylingLib";
import { selectFinishedLoadingLocale } from "../../i18n";
import { ErrorBannerValue, ErrorBannerValues } from "../errorBanner";

declare const global: {
  errorBannerAllowed?: boolean;
};

const GoHome = ({ href, children, sx }: { href: string; children?: React.ReactNode; sx?: ThemeUIStyleObject }) => (
  <a
    sx={sx}
    onClick={() => {
      window.location.assign(href);
      window.location.reload();
    }}
    href={href}
    data-name="error_banner:go_home_a"
  >
    {children}
  </a>
);

const useDefaultIsLocaleLoaded = () => useSelector(selectFinishedLoadingLocale);

// eslint-disable-next-line complexity
const ErrorBanner = ({
  errorType,
  href = "/",
  useIsLocaleLoaded,
}: {
  errorType: ErrorBannerValue;
  href?: string;
  useIsLocaleLoaded: () => boolean;
}): JSX.Element => {
  // The <T> component will not render a fallback if using the component's prop that
  // allows for templating the translation string/fallback and translations aren't loaded.
  // The "loggedOut" error type currently uses templating via the components prop and will
  // not render anything before translations load. Ideally our app is better structured so
  // that we don't end up in this case, but this is causing terrible UX to some users so the
  // interim fix is to render the "unknown" banner until we have translations loaded.
  const isLocaleLoaded = useIsLocaleLoaded();
  // IMPORTANT: Add any errorTypes here that use the components prop in the <T> component.
  if (!isLocaleLoaded && errorType === ErrorBannerValues.loggedOut) errorType = ErrorBannerValues.unknown;

  if (Config.nodeEnv === "test" && !global.errorBannerAllowed) {
    setTimeout(() => {
      throw new Error(`Should not show error banner in tests. Error type was: ${errorType}`);
    }, 0);
  }

  let contents;
  if (errorType === ErrorBannerValues.rateLimit) {
    contents = (
      <span sx={messageStyle}>
        <AT>
          Whoops! Looks like you're making too many requests too quickly. Please wait 10 seconds before continuing to
          use the site.
        </AT>
      </span>
    );
  } else if (errorType === ErrorBannerValues.apiDown) {
    contents = (
      <span sx={messageStyle}>
        <AT>Whoops! Looks like we're having trouble connecting. Please </AT>
        <GoHome href={href}>
          <span sx={refreshButtonStyle}>
            <AT>refresh the page</AT>
          </span>
        </GoHome>
        <AT>.</AT>
        <AT>If the problem persists, you can check the status of our servers </AT>
        <a
          data-name="error_banner:trouble_connecting:error_api_if_problem_persists_link_text"
          target="_blank"
          rel="noopener noreferrer"
          href="http://status.classdojo.com"
        >
          <span sx={refreshButtonStyle}>
            <AT>here</AT>
          </span>
        </a>
        <AT>.</AT>
      </span>
    );
  } else if (errorType === ErrorBannerValues.unknown) {
    // IMPORTANT: DO NOT USE <T fallback={...} components={...} /> in the unknown error banner.
    // See comment at top of component.
    contents = (
      <span sx={messageStyle}>
        <AT>Whoops! Looks like something went wrong. Please </AT>
        <GoHome href={href}>
          <span sx={refreshButtonStyle}>
            <AT>refresh the page</AT>
          </span>
        </GoHome>
        <AT>.</AT>
      </span>
    );
  } else if (errorType === ErrorBannerValues.homeIslands) {
    contents = (
      <span sx={messageStyle}>
        <AT>Whoops! Looks like something went wrong. Please </AT>
        <GoHome href={href}>
          <span sx={refreshButtonStyle}>
            <AT>refresh the page</AT>
          </span>
        </GoHome>
        <AT>.</AT>
      </span>
    );
  } else if (errorType === ErrorBannerValues.homeIslandsLoggedOut) {
    contents = (
      <span sx={messageStyle}>
        <AT>Oops, something went wrong, please try again later</AT>
      </span>
    );
  } else if (errorType === ErrorBannerValues.loggedOut) {
    contents = (
      <span sx={messageStyle}>
        <AT>Whoops! It looks like your session has timed out. </AT>
        <GoHome href={href} key="sign_in">
          <span sx={refreshButtonStyle}>
            <AT>Click here to sign in again</AT>
          </span>
        </GoHome>
        <AT>.</AT>
      </span>
    );
  } else if (errorType === ErrorBannerValues.apiDownLoadShedding) {
    contents = (
      <span sx={messageStyle}>
        <AT>
          We're so sorry - currently ClassDojo is seeing higher than normal usage. We're working hard to resolve this,
          and you can check our status{" "}
        </AT>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://status.classdojo.com"
          data-name="error_banner:api_down_load_shedding:error_api_if_problem_persists_link_text"
        >
          <span sx={refreshButtonStyle}>
            <AT>here</AT>
          </span>
        </a>
        <AT>.</AT>
      </span>
    );
  } else if (errorType === ErrorBannerValues.moduleLoadError) {
    contents = (
      <span sx={messageStyle}>
        <AT>We're having trouble loading. Please </AT>
        <GoHome href={href}>
          <span sx={refreshButtonStyle}>
            <AT>refresh the page</AT>
          </span>
        </GoHome>
        <AT>.</AT>
      </span>
    );
  } else if (errorType === ErrorBannerValues.pubnub) {
    contents = (
      <span sx={messageStyle}>
        <AT>We're having difficulty syncing your actions to other devices. Please </AT>
        <GoHome href={href}>
          <span sx={refreshButtonStyle}>
            <AT>refresh the page</AT>
          </span>
        </GoHome>
        <AT>.</AT>
      </span>
    );
  } else if (errorType === ErrorBannerValues.requestBlocked) {
    contents = (
      <span sx={messageStyle}>
        <AT>Oh no! ClassDojo can't be accessed on this device. Check with your admin to help unblock it.</AT>
      </span>
    );
  } else if (errorType === ErrorBannerValues.studentsNotInClass) {
    contents = (
      <span sx={messageStyle}>
        <AT>You tried to give points to one or more students that are not in this class. </AT>
        <GoHome href={window.location.href}>
          <span sx={refreshButtonStyle}>
            <AT>refresh the page</AT>
          </span>
        </GoHome>
      </span>
    );
  }

  return (
    <div data-name="globalErrorBanner" sx={containerStyle} role="alert" aria-live="polite">
      {contents}
    </div>
  );
};

type ErrorBannerStateProps = { type: ErrorBannerValue | null; refreshUrl?: string };
let _setBannerProps: React.Dispatch<React.SetStateAction<ErrorBannerStateProps>>;

export default function ErrorBannerContainer({
  initialUrl,
  useIsLocaleLoaded = useDefaultIsLocaleLoaded,
}: {
  initialUrl?: string;
  useIsLocaleLoaded?: () => boolean;
}) {
  const [bannerProps, setBannerProps] = React.useState<ErrorBannerStateProps>({
    type: null,
    refreshUrl: initialUrl,
  });
  _setBannerProps = setBannerProps;
  return bannerProps.type ? (
    <ErrorBanner errorType={bannerProps.type} href={bannerProps.refreshUrl} useIsLocaleLoaded={useIsLocaleLoaded} />
  ) : null;
}

// eslint-disable-next-line react-refresh/only-export-components
export const showErrorBanner = (errorType: ErrorBannerValue, refreshUrl?: string) => {
  if (!_setBannerProps) return;
  _setBannerProps({ type: errorType, refreshUrl });
};

const containerStyle: ThemeUIStyleObject = {
  position: "fixed",
  left: 0,
  right: 0,
  textAlign: "center",
  padding: "dt_m",
  backgroundColor: "dt_background_danger",
  zIndex: theme.zIndexes.globalErrorBanner,
  transition: "top .5s ease",
  top: "0px",
} as const;

const messageStyle: ThemeUIStyleObject = {
  color: "dt_content_danger",
  fontWeight: 600,
} as const;

const refreshButtonStyle: ThemeUIStyleObject = {
  color: "dt_content_accent",
  position: "relative",
  border: "none",
  backgroundColor: "transparent",
  padding: "0",
} as const;
