import callApi from "@web-monorepo/infra/callApi";
import createAction from "@web-monorepo/infra/createAction";
import createActionHandler from "@web-monorepo/infra/createActionHandler";
import map from "lodash/map";
import { SagaIterator } from "redux-saga";
import { take, select, call, takeLatest, put, SagaReturnType } from "redux-saga/effects";
import { AwardState, selectHasCompletedAwardWrite } from "app/pods/award";
import { GIVE_POINT_DONE } from "app/pods/award/data/givePoint";
import { Behavior } from "app/pods/behavior";
import { Classroom } from "app/pods/classroom";
import { ClassroomGroup } from "app/pods/group";
import { ClassStudent } from "app/pods/student";

export const UNDO = createAction("award/undo");
export const UNDO_DONE = createAction("award/undo_done");

const RESET_UNDO_STACK = createAction("award/reset-undo-stack");

export const resetUndoStack = () => ({
  type: RESET_UNDO_STACK,
  payload: {},
});

export type LastAward = {
  classroom: Classroom;
  behavior: Behavior;
  students: ClassStudent[];
  groups: ClassroomGroup[];
  local?: boolean;
  awardedAt?: string;
};

export const undo = (lastAward: LastAward) => ({
  type: UNDO,
  payload: lastAward,
});

function* undoAsSoonAsWriteCompletes(action: { type: string; payload: LastAward }): SagaIterator {
  const hasCompletedAwardWrite: SagaReturnType<typeof selectHasCompletedAwardWrite> = yield select(
    selectHasCompletedAwardWrite,
    action.payload,
  );
  if (!hasCompletedAwardWrite) {
    yield take(GIVE_POINT_DONE);
  }

  const { awardedAt, classroom, students } = action.payload;
  const payload = { classroomId: classroom._id, date: awardedAt, studentIds: map(students, "_id") };

  yield call(callApi, {
    method: "POST",
    path: `/api/dojoClass/${classroom._id}/undoAwards`,
    body: {
      studentIds: map(students, "_id"),
      date: awardedAt,
    },
  });

  yield put({
    type: UNDO_DONE,
    payload,
  });
}

export function* undoSaga() {
  yield takeLatest(UNDO, undoAsSoonAsWriteCompletes);
}

export const resetUndoStackHandler = createActionHandler(
  RESET_UNDO_STACK,
  () =>
    (state: AwardState): AwardState => ({
      ...state,
      undoStack: [],
    }),
);

export const undoHandler = createActionHandler(UNDO, ({ awardedAt }: LastAward) => (state: AwardState): AwardState => {
  let newPopupAward = state.popupAward;
  const awardedAtNum = awardedAt ? new Date(awardedAt).valueOf() : new Date().valueOf();

  if (newPopupAward && newPopupAward.awardedAt === awardedAtNum) {
    newPopupAward = undefined;
  }

  return {
    ...state,
    undoStack: state.undoStack.filter((el) => el.awardedAt !== awardedAt),
    popupAward: newPopupAward,
  };
});
