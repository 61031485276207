/** useSideEffectOnMount: is a hook that allows you to run a callback that has side effects when the component is first mounted.
 * If you are using this without side effects, use useOnFirstRender instead
 * @param callback - The callback to run when the component is mounted.
 */
import { captureException } from "@web-monorepo/telemetry";
import { useEffect, useRef } from "react";

// This function is trying to replace useEffect() with an empty dependency array for the
// mounting case, not the unmounting case (see useAfterUnmount). If a callback function
// returns a function, it's likely trying to clean up, which won't happen with this
// hook.

type NotAFunction<T> = T extends () => void ? never : T;
export function useSideEffectOnMount<T>(callback: () => NotAFunction<T>) {
  const hasRun = useRef<boolean>(false);

  // this useEffect is needed as the callback sometimes has async side effects that need to be syncronized with the render loop
  useEffect(() => {
    /* c8 ignore next 3 -- this is defensive, the docs indicate that useEffects get rerun in dev+strict mode, but it doesn't seem to in our test environment */
    if (hasRun.current) {
      return;
    }
    hasRun.current = true;

    const callbackReturn = callback();

    if (typeof callbackReturn === "function") {
      /* c8 ignore next 4 -- we dont run coverage in production */
      if (Config.nodeEnv === "production") {
        captureException(
          new Error("useSideEffectOnMount callback returned a function"),
        );
      } else {
        throw new Error(
          "useSideEffectOnMount does not allow functions to be returned.",
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- this is an onMount thing, we have no dependencies.
  }, []);
}
