// this pod is for holding transient state that should be preserved while
// the window is open, but not necessary to persist beyond that.

import createAction from "@web-monorepo/infra/createAction";
import { PodInstallFunction } from "@web-monorepo/shared/podInfra";
import produce from "immer";
import { useSignUpOperation } from "app/pods/auth";

const STATE_KEY = "transient";

type TransientState = {
  newSignup: boolean;
  verificationEmailSent: boolean;
};

type State = {
  [STATE_KEY]: TransientState;
};

const initialState = {
  newSignup: false,
  verificationEmailSent: false,
};

const SET_NEW_SIGNUP = createAction("transient/set-new-signup");
const SET_VERIFICATION_EMAIL_SENT = createAction("transient/set-verification-email-sent");
const UNSET_VERIFICATION_EMAIL_SENT = createAction("transient/unset-verification-email-sent");

const transientReducer = produce((draft, action) => {
  if (action.type === SET_NEW_SIGNUP) {
    draft.newSignup = true;
  }
  if (action.type === SET_VERIFICATION_EMAIL_SENT || useSignUpOperation.isDoneAction(action)) {
    draft.verificationEmailSent = true;
  }
  if (action.type === UNSET_VERIFICATION_EMAIL_SENT) {
    draft.verificationEmailSent = false;
  }
}, initialState);

export const selectIsNewSignup = (state: State) => state[STATE_KEY].newSignup;

export const selectVerificationEmailSentStatus = (state: State) => state[STATE_KEY].verificationEmailSent;

export const setNewSignup = () => ({
  type: SET_NEW_SIGNUP,
  payload: {},
});

export const setVerificationEmailSent = () => ({
  type: SET_VERIFICATION_EMAIL_SENT,
  payload: {},
});

export const unsetVerificationEmailSent = () => ({
  type: UNSET_VERIFICATION_EMAIL_SENT,
  payload: {},
});

const install: PodInstallFunction = (installReducer) => {
  installReducer(STATE_KEY, transientReducer);
};

export default install;
