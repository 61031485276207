import type { MessageThread, MessageThreadAudienceMember as AudienceMember } from "../types";
import type { useMessageThreadAudienceFetcher } from "./fetchers";

type FetrcherReturnValue = {
  fetcherResult: ReturnType<typeof useMessageThreadAudienceFetcher>;
};

type Parent = Extract<AudienceMember, { type: "parent" }>;
type Teacher = Extract<AudienceMember, { type: "teacher" }>;

export const isValidMessageThreadForAudience = (thread?: MessageThread): boolean => {
  if (!thread) {
    return false;
  } else if (thread.type === "c") {
    return thread.participants.length > 2;
  } else if (thread.type === "e") {
    return true;
  } else {
    return false;
  }
};

export const useMessageThreadAudience = ({ fetcherResult }: FetrcherReturnValue) => {
  const audience: AudienceMember[] = fetcherResult.data ?? [];
  const audienceLoaded = fetcherResult.isSuccess;
  const [parents, teachers] = audience.reduce(
    (acc, next) => {
      if (next.type === "parent") {
        acc[0].push(next);
      } else if (next.type === "teacher") {
        acc[1].push(next);
      }

      return acc;
    },
    [[], []] as [parents: Parent[], teachers: Teacher[]],
  );

  return {
    audience,
    audienceLoaded,
    parents,
    teachers,
  } as const;
};
