import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";
// We're refreshing the site around midnight, so even though this isn't getting regenerated,
// we should be fine
import { endOf, format, parse, startOf, subDays, subMonths, subWeeks } from "@web-monorepo/dates";
import { i18n as i18next } from "@web-monorepo/i18next";
import { type TranslatedString } from "app/utils/translate";

type StandardPageTypeInterval = {
  custom?: false;
  start: Date | null;
  end: Date | null;
  text(): TranslatedString;
};

type CustomPageTypeInterval = {
  custom: true;
  start: Date;
  end: Date;
  text(): TranslatedString;
};

export type PageTypeIntervalPreTranslate = StandardPageTypeInterval | CustomPageTypeInterval;

export type PageTypeInterval = Omit<PageTypeIntervalPreTranslate, "text"> & {
  text: TranslatedString;
};

// Export a function so the translations and times
// get calculated when the views need them.
export default function reportsPageTimeIntervals(): PageTypeIntervalPreTranslate[] {
  return [
    ...getTimeOptionsWithoutCustom(),
    {
      custom: true,
      start: startOf(new Date(), "day"),
      end: endOf(new Date(), "day"),
      text: () => autoTranslate("Custom date range"),
    },
  ];
}

function lastMonth() {
  const d = new Date();
  d.setMonth(d.getMonth() - 1, 1);
  return d;
}

export function getTimeOptionsWithoutCustom(): PageTypeIntervalPreTranslate[] {
  const { start: startOfWeek, end: endOfWeek } = getThisWeekTimeRange();

  return [
    {
      start: startOf(new Date(), "day"),
      end: endOf(new Date(), "day"),
      text: () => autoTranslate("Today"),
    },
    {
      start: startOf(subDays(new Date(), 1), "day"),
      end: endOf(subDays(new Date(), 1), "day"),
      text: () => autoTranslate("Yesterday"),
    },
    {
      start: startOfWeek,
      end: endOfWeek,
      text: () => autoTranslate("This week"),
    },
    {
      start: subWeeks(parse(startOfWeek), 1),
      end: subWeeks(parse(endOfWeek), 1),
      text: () => autoTranslate("Last week"),
    },
    {
      start: startOf(new Date(), "month"),
      end: endOf(new Date(), "month"),
      text: () =>
        autoTranslate("This month (__month__)", {
          month: format(new Date(), { month: "long" }),
        }),
    },
    {
      start: startOf(subMonths(new Date(), 1), "month"),
      end: endOf(subMonths(new Date(), 1), "month"),
      text: () =>
        autoTranslate("Last month (__month__)", {
          month: lastMonth().toLocaleDateString(i18next.language, { month: "long" }),
        }),
    },
    {
      start: null,
      end: null,
      text: () => autoTranslate("All time"),
    },
  ];
}

export function getThisWeekTimeRange(today?: number | Date | string) {
  return {
    start: startOf(today ? parse(today) : new Date(), "week"),
    end: endOf(parse(today), "week"),
  };
}
