import type { FunctionComponent, PropsWithChildren } from "react";
import * as Classes from "./DDSOverlineText.module.css";

export type DDSOverlineTextProps = {
  as?: "span";
  className?: string;
};

export const DDSOverlineText: FunctionComponent<PropsWithChildren<DDSOverlineTextProps>> = ({
  as: tagName = "span",
  className,
  children,
}) => {
  const Tag = tagName;
  const classNames = [className, Classes["overline"]].filter((c) => !!c);
  return <Tag className={classNames.join(" ")}>{children}</Tag>;
};
