import type { FunctionComponent, PropsWithChildren } from "react";
import * as Classes from "./DDSLabelText.module.css";

export type DDSLabelTextProps = {
  // If you're looking for h1 or h2, you will want a DDSHeadlineText.
  as?: "span" | "h3" | "h4" | "h5";
  level?: 1 | 2 | 3;
  className?: string;
};

export const DDSLabelText: FunctionComponent<PropsWithChildren<DDSLabelTextProps>> = ({
  as: tagName = "span",
  level = 1,
  className,
  children,
}) => {
  const Tag = tagName;
  return <Tag className={`${className ?? ""} ${Classes[`label${level}`]}`}>{children}</Tag>;
};
