import useWatch from "@classdojo/web/hooks/useWatch";
import callApi from "@web-monorepo/infra/callApi";
import { makeMutation } from "@web-monorepo/shared/reactQuery";
import { useSearchParams } from "react-router-dom";
import { withOTCHandling } from "@web-monorepo/one-time-codes";
import { makeApiMutation, makeMemberQuery } from "@web-monorepo/shared/reactQuery";

export enum TABS {
  profile = "Edit profile",
  security = "Security",
  language = "Language and region",
  messaging = "Chats",
  story = "Comments",
  advanced = "Advanced",
  privacyAndTerms = "Privacy & Terms",
}

export type TabsKey = keyof typeof TABS;

export function isUserProfileModalTab(value: string | null): value is TabsKey {
  return value !== null && Object.keys(TABS).includes(value);
}

export const useExportCurrentThreadsMutation = makeMutation({
  fn: async ({ includeAttachments }) => {
    await callApi({
      path: `/api/exportCurrentThreads?includeAttachments=${includeAttachments}`,
      method: "PUT",
    });
  },
  name: "exportCurrentThreads",
});

export const useAccountSettingsModal = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const settingsTab = searchParams.get("settingsTab");
  const close = () => setSearchParams((params) => ({ ...params, settingsTab: null }));
  useWatch([settingsTab], () => {
    if (settingsTab && !isUserProfileModalTab(settingsTab)) {
      close();
    }
  });
  return {
    settingsTab,
    close,
    setSettingsTab: (tab: TabsKey = "profile") => {
      setSearchParams((params) => ({ ...params, settingsTab: tab }));
    },
    isOpen: !!settingsTab,
  };
};

export const useSetTeacherMFAState = withOTCHandling(
  makeApiMutation({
    name: "enableTeacherMFA",
    path: "/api/teacher/{teacherId}/mfa",
    method: "put",
  }),
  { productEventNamespace: "opt_in_mfa" },
);

export const useGetTeacherMFAState = makeMemberQuery({
  fetcherName: "getTeacherMFa",
  path: "/api/teacher/{teacherId}/mfa",
});
