import env from "@web-monorepo/shared/utils/env";
import { useMonsterverseConfiguration } from "app/pods/dojoIslands";

export const getMonsterWorldUrl = (
  monsterverseConfig: ReturnType<typeof useMonsterverseConfiguration>,
  classId?: string,
  spawnPoint?: string,
) => {
  const options = { entityType: "teacher", classId, spawnPoint };
  const encodedOptions = encodeURLString(JSON.stringify(options));
  const encodedStartupParams =
    monsterverseConfig?.island.startupParams &&
    encodeURLString(JSON.stringify(monsterverseConfig?.island.startupParams));
  const URL = env.isDev
    ? `http://mw.classdojo.test:8700/`
    : window.MONSTER_WORLD_URL ||
      monsterverseConfig?.island.targetUrl ||
      `https://clients.multiplayer.classdojo.com/launcher/prod/latest`;
  return `${URL}?data=${encodedOptions}&startupParams=${encodedStartupParams}`;
};

function encodeURLString(str: string) {
  // Replace base64 non-url compatible chars (base64url encoding)
  // https://www.rfc-editor.org/rfc/rfc4648#page-7
  return btoa(encodeURIComponent(str)).replace(/\+/g, "-").replace(/\//g, "_");
}
