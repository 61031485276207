import * as logClient from "@classdojo/log-client";
import { logClick } from "@web-monorepo/shared/events/clickLogging";
import { EventRecorder } from "app/pods/devTools";
import deployGroup from "app/utils/deployGroup";
import env from "app/utils/env";
import { metrics } from "app/utils/metrics";

declare global {
  interface Window {
    build: string;
    appVersion: string;
    logClient: typeof logClient;
  }
}

if ((env.isProd && typeof window !== "undefined") || env.isCypress) {
  window.appVersion = Config.appVersion;
  window.build = Config.buildNumber;

  logClient.init({
    env: env.isProd ? "prod" : "testing",
    deployGroup,
    site: "teach",
    customEndpoint: "/logs",
    buildNumber: Config.buildNumber,
    appVersion: Config.appVersion,
  });
} else if (env.isDev) {
  logClient.init({ env: "dev", site: "teach" });
} else if (env.isTesting) {
  logClient.init({ env: "testing", disabledForTest: true, site: "teach" });
}

window.addEventListener("unload", () => {
  logClient.flush();
  metrics.close();
});
EventRecorder.start();

document.body.addEventListener("click", (ev) => logClick(ev, "teach"), { capture: true });
