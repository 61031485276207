import { PodInstallFunction } from "@web-monorepo/shared/podInfra";
import { i18n } from "@web-monorepo/i18next";
import produce from "immer";
import { useCallback } from "react";
import getTimeOptions, { PageTypeInterval, PageTypeIntervalPreTranslate } from "app/data/reportsPageTimeIntervals";
import { useSelector, useDispatch } from "app/utils/reduxHooks";

//
// -----------------------------------
// STATE
//
const STATE_KEY = "timeRange";

//
// -----------------------------------
// ACTIONS
//
const Types = {
  SELECT_TIME_RANGE: "SELECT_TIME_RANGE",
};

type State = {
  [STATE_KEY]: {
    language: string;
    timeRangeOptions: PageTypeIntervalPreTranslate[];
    selectedTimeRange: PageTypeIntervalPreTranslate | null;
    selectedTimeRangeIndex: number;
  };
};

//
// -----------------------------------
// ACTION CREATORS
//

export function setTimeRange(selectedTimeRangeIndex: number | null, customTimeRangeData?: { start: Date; end: Date }) {
  return {
    type: Types.SELECT_TIME_RANGE,
    payload: { selectedTimeRangeIndex, customTimeRangeData },
    meta: { description: "Select dashboard time range" },
  };
}

//
// ----------------------------
// HANDLERS
//
const timeRangeReducer = produce((draft: State["timeRange"], action) => {
  if (!draft) {
    // create initial state here so date values are frozen during testing
    return {
      language: i18n.language,
      timeRangeOptions: getTimeOptions(),
      selectedTimeRangeIndex: 2,
    };
  }

  // Make sure the months option gets translated when the language changes:
  if (draft.language != i18n.language) {
    draft.language = i18n.language;
    draft.timeRangeOptions = getTimeOptions();
  }

  if (action.type === Types.SELECT_TIME_RANGE) {
    const { selectedTimeRangeIndex, customTimeRangeData } = action.payload;
    const selectedTimeRange = draft.timeRangeOptions[selectedTimeRangeIndex];

    if (selectedTimeRange) {
      draft.selectedTimeRangeIndex = selectedTimeRangeIndex;
      if (selectedTimeRange.custom) {
        const { start, end } = customTimeRangeData;
        selectedTimeRange.start = new Date(start);
        selectedTimeRange.end = new Date(end);
      }
    }
  }
});

//
// -----------------------------------------
// SELECTORS
//
//
export const selectTimeRangeOptions = (state: State) => state?.[STATE_KEY].timeRangeOptions;

export const selectCurrentTimeRangeIndex = (state: State) => state?.[STATE_KEY].selectedTimeRangeIndex;

export const selectTimeRangeWithIndex = (state: State, index: number) => state?.[STATE_KEY]?.timeRangeOptions?.[index];

//
// -------------------------------
// Setup
//
const install: PodInstallFunction = (installReducer) => {
  installReducer(STATE_KEY, timeRangeReducer);
};

export default install;

export type TimeRangeOptions = {
  timeRangeOptions: PageTypeInterval[];
  selectedTimeRangeIndex: number;
  selectTimeRange: (i: number | null, customTimeRange?: { start: Date; end: Date }) => void;
};

export const useTimeRangeOptions = (): TimeRangeOptions => {
  const selectedTimeRangeIndex = useSelector(selectCurrentTimeRangeIndex);
  const options = useSelector(selectTimeRangeOptions);
  const timeRangeOptions = options.map((option) => ({
    ...option,
    text: option.text(),
  }));

  const dispatch = useDispatch();
  const selectTimeRange: TimeRangeOptions["selectTimeRange"] = useCallback(
    (selectedTimeRangeIndex, customTimeRangeData) =>
      dispatch(setTimeRange(selectedTimeRangeIndex, customTimeRangeData)),
    [dispatch],
  );

  return { timeRangeOptions, selectedTimeRangeIndex, selectTimeRange };
};
