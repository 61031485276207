import * as React from "react";
import { Button } from "../../nessie";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";
import logEvent from "../../utils/logEvent";
import { parse } from "@web-monorepo/dates";
import { makeTimestampString } from "../../utils/time";
import { UnstyledButton } from "../buttons";
import { useImageSrc } from "../misc/Image";
import ProfilePicture from "../misc/ProfilePicture";
import formatNotificationUrl from "./formatUrl";
import { Notification, StoryPost } from "./types";

type NotificationItemProps = {
  notification: Notification;
  isFirst?: boolean;
  customAvatar?: React.ReactElement;
  showSecondaryView?: (_id: string) => void;
  openStoryComposeArea?: (storyPost: StoryPost) => void;
  customOnClick?: React.MouseEventHandler;
  acceptAction?: () => void;
  declineAction?: () => void;
  acceptText?: string;
  declineText?: string;
  closeDropdown?: () => void;
};

export default function NotificationItem({
  notification,
  customAvatar,
  isFirst,
  showSecondaryView,
  openStoryComposeArea,
  customOnClick,
  acceptAction,
  declineAction,
  acceptText,
  declineText,
  closeDropdown,
}: NotificationItemProps) {
  const url = getUrl(notification);

  const onClick = (e: React.MouseEvent) => {
    logNotificationClickedEvent(notification);

    if (notification.secondaryView && showSecondaryView) {
      notification._id && showSecondaryView(notification._id);
    } else if (notification.storyPost && openStoryComposeArea) {
      openStoryComposeArea(notification.storyPost);
    } else if (customOnClick) {
      customOnClick(e);
    } else if (!url || /^http/.test(url)) {
      // don't close menu for unclickable notifications or external links.
      e.stopPropagation();
    }
  };

  const contents = (
    <UnstyledButton
      sx={{ ...styles.notification, cursor: hasAction(notification, customOnClick) ? "pointer" : "default" }}
      onClick={onClick}
      data-name="notificationItemContent"
    >
      <div sx={isFirst ? styles.innerNotificationTop : styles.innerNotification}>
        <div sx={{ display: "flex", justifyContent: "flex-start" }}>
          <NotificationProfilePicture notification={notification} customAvatar={customAvatar} />

          <div sx={{ flex: 1, display: "flex", flexDirection: "column", marginLeft: "dt_s" }}>
            <div>{notification?.content?.text ?? ""}</div>

            {notification.createdAt && (
              <div sx={{ marginTop: "dt_s", ...styles.time }}>
                {makeTimestampString(parse(notification.createdAt), "en")}
              </div>
            )}

            <NotificationActionButtons
              acceptAction={acceptAction}
              declineAction={declineAction}
              acceptText={acceptText}
              declineText={declineText}
            />
          </div>

          {notification?.content?.image && <NotificationImage image={notification.content.image} />}
        </div>
      </div>
    </UnstyledButton>
  );

  if (url) {
    const { newUrl, target } = formatNotificationUrl(url);
    return (
      <a href={newUrl} target={target} onClick={closeDropdown} sx={styles.link} data-name="notification_item:url">
        {contents}
      </a>
    );
  } else {
    return contents;
  }
}

function NotificationProfilePicture({
  notification: {
    actor: { avatarUrl, _id },
  },
  customAvatar,
}: Pick<NotificationItemProps, "notification" | "customAvatar">) {
  const mojoAvatar = useImageSrc("components/notification_center/mojo_avatar.png");
  if (customAvatar) return customAvatar;

  return <ProfilePicture src={avatarUrl || mojoAvatar} colorKey={_id} size="5rem" />;
}

function NotificationActionButtons({
  acceptAction,
  declineAction,
  acceptText,
  declineText,
}: Pick<NotificationItemProps, "acceptAction" | "declineAction" | "acceptText" | "declineText">) {
  if (!acceptAction || !declineAction) return null;

  return (
    <div
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "baseline",
        marginTop: "dt_xxs",
        gap: "dt_m",
      }}
    >
      {declineText ? (
        <Button
          data-name="notification_item:notification_action_buttons:decline"
          kind="tertiary"
          size="s"
          onClick={declineAction}
          sx={{ flexGrow: 1 }}
        >
          {declineText}
        </Button>
      ) : (
        <Button
          data-name="notification_item:notification_action_buttons:decline"
          kind="tertiary"
          size="s"
          onClick={declineAction}
          sx={{ flexGrow: 1 }}
        >
          Decline
        </Button>
      )}

      {acceptText ? (
        <Button
          data-name="notification_item:notification_action_buttons:accept"
          size="s"
          onClick={acceptAction}
          sx={{ flexGrow: 1 }}
        >
          {acceptText}
        </Button>
      ) : (
        <Button
          data-name="notification_item:notification_action_buttons:accept"
          size="s"
          onClick={acceptAction}
          sx={{ flexGrow: 1 }}
        >
          Accept
        </Button>
      )}
    </div>
  );
}

const NotificationImage = ({ image }: { image: string }) => (
  <div sx={{ marginLeft: "dt_s", ...styles.imageContainer }}>
    <div sx={styles.imageBorder} />

    <div data-name="notificationImage" sx={{ ...styles.imageDiv, backgroundImage: `url('${image}')` }} />
  </div>
);

function getUrl({ destination, storyPost, secondaryView }: Notification) {
  if (storyPost || secondaryView || !destination) return null;

  return destination.web;
}

const hasAction = (
  { storyPost, destination, secondaryView }: Notification,
  customOnClick: React.MouseEventHandler | undefined,
) => !!(storyPost || destination || secondaryView || customOnClick);

function logNotificationClickedEvent({ actor: { type }, _id }: Notification) {
  if (type === "dojo") {
    logEvent({ eventName: "web.in_app_notification.clickInList", eventValue: _id });
  }
}

const styles: Record<string, ThemeUIStyleObject> = {
  notification: {
    lineHeight: "1.4rem",
    width: "100%",
    "&:hover": {
      backgroundColor: "dt_background_secondary",
    },
  },
  innerNotification: {
    borderTop: "dt_divider",
    marginY: "0",
    marginX: "dt_s",
    paddingY: "dt_s",
    paddingX: "0",
  },
  innerNotificationTop: {
    borderTop: "dt_divider",
    padding: "dt_s",
    lineHeight: "14px",
  },
  hovered: {
    backgroundColor: "dt_background_secondary",
  },
  imageContainer: {
    position: "relative",
  },
  imageBorder: {
    position: "absolute",
    top: 0,
    left: 0,
    height: "4.8rem",
    width: "4.8rem",
    borderRadius: "dt_radius_s",
    border: "dt_divider",
  },
  imageDiv: {
    display: "inline-block",
    height: "4.8rem",
    width: "4.8rem",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    backgroundColor: "dt_background_secondary",
    borderRadius: "dt_radius_s",
  },
  time: {
    color: "dt_content_tertiary",
  },
  link: {
    display: "block",
  },
};
