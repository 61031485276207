import classes from "./group-avatar.module.css";
import { DDSAvatar } from "../DDSAvatar/DDSAvatar";

export const Sizes = ["s", "m", "l"] as const;

export type Props = {
  size: (typeof Sizes)[number];
  entities: Array<React.ComponentProps<typeof DDSAvatar>["entityInfo"] | undefined>;
};

export function DDSGroupAvatar({ size = "m", entities }: Props) {
  const bubbleCount = getBubbleCount(entities.length);
  const className = [classes[size], classes.wrapper, bubbleCount].join(" ");
  const bubbles = entities.length > 4 ? entities.slice(0, 3) : entities;

  if (entities.length === 1) {
    return <DDSAvatar entityInfo={entities[0]} size={size} />;
  }

  return (
    <div className={className}>
      {bubbles.map((entity) => (
        <div key={entity?.entityId}>
          <DDSAvatar entityInfo={entity} size={"auto"} />
        </div>
      ))}
      {entities.length > 4 && <div className={classes.number}>{entities.length - 3}</div>}
    </div>
  );
}

function getBubbleCount(count: number) {
  switch (count) {
    case 2:
      return classes.two;
    case 3:
      return classes.three;
    default:
      return classes.four;
  }
}
