import { isBeforeLastJuly1st } from "./time";

export const isActive = (classroom: { inactive: boolean }, forceActiveDate: Date) => {
  if (!classroom.inactive) return true;

  if (forceActiveDate) {
    return !isBeforeLastJuly1st(forceActiveDate);
  }

  return false;
};
