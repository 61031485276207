import callApi from "@web-monorepo/infra/callApi";
import combineActionHandlers from "@web-monorepo/infra/combineActionHandlers";
import createAction from "@web-monorepo/infra/createAction";
import createActionHandler from "@web-monorepo/infra/createActionHandler";
import { APIRequestBody, APIRequestParameters, APIResponse } from "@web-monorepo/shared/api/apiTypesHelper";
import { PodInstallFunction } from "@web-monorepo/shared/podInfra";
import { makeCollectionQuery, makeMutation } from "@web-monorepo/shared/reactQuery";
import type { AnyAction } from "redux";
import { preload } from "app/utils/preloadImage";

const STATE_KEY = "avatarSet";
const SELECT_AVATAR_SET = createAction("student/avatar-set/select");

type AvatarSetState = {
  currentSetId: string | null;
};

export type AvatarSetSlice = {
  [STATE_KEY]: AvatarSetState;
};

const initialState: AvatarSetState = {
  currentSetId: null,
};

export const selectAvatarSet = ({ _id }: { _id: string }) => ({
  type: SELECT_AVATAR_SET,

  payload: {
    _id,
  },
});

export const selectCurrentSetId = (state: AvatarSetSlice): string | null => {
  return state?.[STATE_KEY]?.currentSetId;
};

// TODO
const updateOrCreateAvatarSetHandler = (state: AvatarSetState, action: AnyAction): AvatarSetState => {
  if (useCreateAvatarSetOperation.isDoneAction(action) || useUpdateAvatarSetOperation.isDoneAction(action)) {
    return {
      ...state,
      currentSetId: action.payload.data._id,
    };
  }

  return state;
};

export const selectAvatarSetHandler = createActionHandler(
  SELECT_AVATAR_SET,
  ({ _id }: { _id: string }) =>
    (state: AvatarSetState): AvatarSetState => ({ ...state, currentSetId: _id }),
);

const finalAvatarSetReducer = combineActionHandlers(initialState, [
  selectAvatarSetHandler,
  updateOrCreateAvatarSetHandler,
]);

const install: PodInstallFunction = (installReducer) => {
  installReducer(STATE_KEY, finalAvatarSetReducer);
};

export default install;

export function preloadImagesForAvatarSets(sets: AvatarSet[]) {
  const finalURLs = sets
    .flatMap((set) => set.avatars.map((a) => a.url))
    .map((url) => {
      return /^http/.test(url) ? url : `https://avatars.classdojo.com${url}`;
    });

  preload(finalURLs);
}

export type AvatarSet = APIResponse<"/api/avatarSet", "get">["_items"][0];
export type Avatar = AvatarSet["avatars"][0];

export const useAvatarSetsFetcher = makeCollectionQuery({
  path: "/api/avatarSet",
  fetcherName: "avatarSets",
});

export type CreateAvatarSetParams = APIRequestBody<"/api/avatarSet", "post">;
type CreateAvatarSetResponse = APIResponse<"/api/avatarSet", "post">;

export const useCreateAvatarSetOperation = makeMutation<CreateAvatarSetParams, CreateAvatarSetResponse>({
  name: "createAvatarSet",
  async fn({ name, avatars }) {
    const { body } = await callApi({
      method: "POST",
      path: "/api/avatarSet",
      body: {
        name,
        avatars,
      },
    });
    return body;
  },
  onSuccess: (data) => {
    useAvatarSetsFetcher.setQueriesData((oldData) => {
      return oldData.concat(data);
    });
  },
});

export type DeleteAvatarSetParams = APIRequestParameters<"/api/avatarSet/{id}", "delete">["path"] & { _id: string };

export const useDeleteAvatarSetOperation = makeMutation<DeleteAvatarSetParams, void>({
  name: "deleteAvatarSet",
  async fn({ _id }) {
    await callApi({
      method: "DELETE",
      path: `/api/avatarSet/${_id}`,
    });
  },
  onSuccess: (_data, params) => {
    useAvatarSetsFetcher.setQueriesData((oldData) => {
      return oldData.filter((item: AvatarSet) => item._id !== params._id);
    });
  },
});

export type UpdateAvatarSetParams = APIRequestBody<"/api/avatarSet/{id}", "put">;
type UpdateAvatarSetResponse = APIResponse<"/api/avatarSet/{id}", "put">;

export const useUpdateAvatarSetOperation = makeMutation<UpdateAvatarSetParams, UpdateAvatarSetResponse>({
  name: "updateAvatarSet",
  async fn({ _id, name, creator, avatars }) {
    const { body } = await callApi({
      method: "PUT",
      path: `/api/avatarSet/${_id}`,
      body: {
        name,
        avatars,
        creator,
      },
    });
    return body;
  },
  onSuccess: (data, params) => {
    useAvatarSetsFetcher.setQueriesData((oldData) => {
      return oldData.filter((item: AvatarSet) => item._id !== params._id).concat(data);
    });
  },
});
