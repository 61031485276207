//
// Utility for handle browser location changes from outside our router.
// Most of the logic here is related to the navigation between our two
// teach sites and some small utils for opening a new window or reaching
// an end point in the API.
//

// Public methods

// Build a path to the api
export const apiURL = function (path: string) {
  return `${origin()}/api${path}`;
};

// Build a path to the api from a relative url
export const relativeURLtoApiURL = function (path: string) {
  return `${origin()}/${path}`;
};

// Open url in a new window
export const open = function (url: string) {
  window.open(url, "_blank");
};

// Perform a navigation to a URL
export const navigateTo = function (url: string) {
  // for testing navigation in Cypress
  if (window.Cypress && window.Cypress.navigateTo) {
    return window.Cypress.navigateTo(url);
  }
  window.location.assign(url);
};

export const subdomainLink = function (subdomain: string, path = "") {
  const base = origin()
    .replace("teach", subdomain)
    .replace("8084", subdomain === "home" ? "8085" : subdomain === "student" ? "8081" : "3579")
    .replace("8484", subdomain === "home" ? "8485" : subdomain === "student" ? "8481" : "3579");
  return base + path;
};

// http://tosbourn.com/a-fix-for-window-location-origin-in-internet-explorer/
export const origin = function () {
  return window.location.origin
    ? window.location.origin
    : `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ""
      }`;
};

export const canGoBack = () => {
  return history.length > 2;
};

export const back = () => (canGoBack() ? window.history.back() : navigateTo("/#/launchpad"));

origin.toString = origin;
