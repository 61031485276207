import { TeacherSession } from "app/pods/teacher";

export default function getCountryCode(teacher: TeacherSession) {
  if (teacher == null) return;
  if (teacher.countryCode) return teacher.countryCode;

  if (teacher.locale == null) return;

  const cc = teacher.locale.split("-")[1];
  if (cc == null) return;
  return cc.toLowerCase();
}
