import * as logClient from "@classdojo/log-client";
import { useOnFirstRender } from "@web-monorepo/hooks";

/** useLogEventOnFirstRender is a pretty simple hook, but the pattern is so common it deserves
 * a dedicated hook.
 */

export default function useLogEventOnFirstRenderWithRole(event: Parameters<typeof logClient.logEvent>[0]) {
  useOnFirstRender(() => {
    logClient.logEvent(event);
  });
}
