type Action = {
  type: string;
  meta: {
    operation: string;
  };
};

// A logger for use during testing.
// Not as verbose as the default logger, which uses Chrome's console
// to collapse logs.
// To see output, pass DEBUG=true as an env variable.
export default function testLoggerMiddleware() {
  return function (next: (action: Action) => void) {
    return function (action: Action) {
      if (Config.debug) {
        // eslint-disable-next-line no-console
        console.log(`Dispatching: ${action.type} ${action.meta ? action.meta.operation : ""}`);
      }
      return next(action);
    };
  };
}
