import callApi, { CallApiDefaultResponse } from "@web-monorepo/infra/callApi";
import combineActionHandlers from "@web-monorepo/infra/combineActionHandlers";
import { APIResponseError } from "@web-monorepo/infra/responseHandlers";
import { APIRequestBody, APIRequestParameters, APIResponse } from "@web-monorepo/shared/api/apiTypesHelper";
import { PodInstallFunction } from "@web-monorepo/shared/podInfra";
import { NOOP, makeApiMutation, makeCollectionQuery, makeMutation } from "@web-monorepo/shared/reactQuery";
import { TEACHER_NOTIFICATION_MENTOR_DATE_KEY } from "app/data/userConfigKeys";
import errors from "app/errorTypes";
import { useSessionFetcher } from "app/pods/auth";
import { getSchoolTeachers, useSchoolFetcher, useSchoolsFetcher } from "app/pods/school";
import matchesError from "app/pods/shared/util/matchesError";
import { useUserConfigFetcher } from "app/pods/userConfig";
import produce from "immer";
import assignIn from "lodash/assignIn";
import find from "lodash/find";
import type { AnyAction } from "redux";
import { useSchoolVerificationRequestFetcher } from "app/pods/school/fetchers";
import { useSchoolLinkInvitationFetcher } from "app/pods/schoolDirectory";
import { useSchoolLinkInviteMemberFetcher } from "app/pods/signup";
import { useClassWhereNotVerifiedInSchoolFetcher, useClassesFetcher, useClassroomFetcher } from "app/pods/classroom";
import { useAllClassroomFetcher } from "@web-monorepo/shared/classroom";
import { useSchoolwideCenterMemberFetcher } from "app/pods/schoolwideCenter";
import { useFetchedTeacher } from "#/app/pods/teacher";
import {
  getUnverifiedAndNotPreapprovedTeachers,
  getUnverifiedTeachers,
  isVerifiedSchoolAdmin,
} from "#/app/utils/school";
import { usePendingTeachers } from "#/app/pods/schoolDirectory/queries";

//
// -----------------------------------
// STATE
//
const STATE_KEY = "schoolTeachers";

type SchoolTeachersState = {
  nudgedMentor: Record<string, boolean>;
};

type SchoolTeachersSlice = {
  [STATE_KEY]: SchoolTeachersState;
};

const initialState: SchoolTeachersState = {
  nudgedMentor: {}, // indexed by schoolId
};

//
// ----------------------------
// HANDLERS
//
const schoolTeachersReducer = produce((draft: SchoolTeachersState, action: AnyAction) => {
  if (useTeacherJoinSchoolOperation.isDoneAction(action)) {
    draft.nudgedMentor[action.payload.params.path.schoolId] = true;
  }

  if (useSchoolNudgeMentorTeachersOperation.isDoneAction(action)) {
    draft.nudgedMentor[action.payload.params.path.schoolId] = true;
  }
}, initialState);

//
// -----------------------------------------
// SELECTORS
//

export const selectHasNudgedMentor = (state: SchoolTeachersSlice, schoolId: string) =>
  !!state?.[STATE_KEY]?.nudgedMentor?.[schoolId];

//
// -------------------------------
// Setup
//
const finalReducer = combineActionHandlers(initialState, [schoolTeachersReducer]);

const install: PodInstallFunction = (installReducer) => {
  installReducer(STATE_KEY, finalReducer);
};

export default install;

export type SchoolNudgeMentorTeachersOperationParams = {
  schoolId: string;
};

export const useSchoolNudgeMentorTeachersOperation = makeApiMutation({
  name: "schoolNudgeMentorTeachers",
  path: "/api/dojoSchool/{schoolId}/nudgeMentorsAndSchoolAdmins",
  method: "post",
  onMutate: () => {
    useUserConfigFetcher.setQueriesData((draft) => {
      draft.metaData[TEACHER_NOTIFICATION_MENTOR_DATE_KEY] = Date.now();
    });
  },
  catchError: (error) => {
    if (error instanceof APIResponseError && error.response.status === 400) {
      // Ignore 400 errors
      return error;
    }
    throw error;
  },
});

export const useSchoolApplyToBeMentorOperation = makeApiMutation({
  name: "schoolApplyToBeMentor",
  path: "/api/dojoSchool/{schoolId}/applyToBeMentor",
  method: "post",
});

export type SchoolReportTeacherParams = {
  teacherId: string;
  reason: string;
};

export const useSchoolReportTeacherOperation = makeMutation<SchoolReportTeacherParams, CallApiDefaultResponse>({
  name: "schoolReportTeacher",
  async fn({ teacherId, reason }) {
    return await callApi({
      method: "POST",
      path: `/api/abuseFlag`,
      body: { about: teacherId, reason },
    });
  },
});

export const useRemoveReportOperation = makeApiMutation({
  name: "removeReport",
  path: `/api/removeReport`,
  method: "post",
});

export type TeacherJoinSchoolParams = {
  path: APIRequestParameters<"/api/dojoSchool/{schoolId}/teacher", "post">["path"];
  body: APIRequestBody<"/api/dojoSchool/{schoolId}/teacher", "post">;
};
export const useTeacherJoinSchoolOperation = makeApiMutation({
  name: "teacherJoinSchool",
  path: "/api/dojoSchool/{schoolId}/teacher",
  method: "post",
  onMutate: (params) => {
    useSchoolFetcher.setQueriesData((draft) => {
      // Remove from all old schools
      const { teacherId } = params.body;
      draft.schoolTeachers = (draft.schoolTeachers || []).filter((t) => t._id !== teacherId);
    });
  },
  onSuccess: (_data, params) => {
    useSessionFetcher.invalidateQueries();
    useUserConfigFetcher.invalidateQueries();
    useSchoolFetcher.invalidateQueries({ id: params.path.schoolId });
    useSchoolsFetcher.invalidateQueries();
    useSchoolVerificationRequestFetcher.invalidateQueries();
  },
});

export const useSimpleTeacherJoinSchoolOperation = makeApiMutation({
  name: "teacherJoinSchool",
  path: "/api/dojoSchool/{schoolId}/teacher",
  method: "post",
});

type TeacherJoinSchoolResponse = APIResponse<"/api/dojoSchool/{schoolId}/teacher", "post">;
export type TeacherJoinSchoolWithEmailParams = {
  teacher: { _id: string; emailAddress?: string };
  schoolId: string;
  emailAddress: string;
};
export const useTeacherJoinSchoolWithEmailOperation = makeMutation<
  TeacherJoinSchoolWithEmailParams,
  TeacherJoinSchoolResponse
>({
  name: "teacherJoinSchoolWithEmail",
  async fn({ teacher, schoolId, emailAddress }) {
    try {
      // update teacher email
      await callApi({
        method: "PUT",
        path: `/api/teacher/${teacher._id}`,
        body: assignIn(teacher, { emailAddress }),
      });

      // join school and send verification email
      const { body } = await callApi({
        method: "POST",
        path: `/api/dojoSchool/${schoolId}/teacher`,
        body: { teacherId: teacher._id },
        query: { sendEmailVerification: true },
      });
      return body;
    } catch (error: any) {
      if (matchesError(error.response, "Email already exists")) {
        return errors.teacher.emailAlreadyExist();
      }
      throw error;
    }
  },
  onMutate: () => {
    useSchoolsFetcher.invalidateQueries();
  },
  onSuccess: () => {
    useSessionFetcher.setQueriesData((draft) => {
      draft.teacher!.emailVerified = false;
    });
    useSessionFetcher.invalidateQueries();
    useSchoolsFetcher.invalidateQueries();
    useSchoolVerificationRequestFetcher.invalidateQueries();
  },
});

export const useTeacherAddSchoolOperation = makeApiMutation({
  name: "teacherAddSchool",
  path: "/api/schoolTeacher/school/{schoolId}",
  method: "post",
  catchError: (error): Error => {
    if (error instanceof APIResponseError && error.response.status === 403 && error.response.body.error.expected) {
      return error;
    }

    throw error;
  },
  onSuccess: () => {
    useSessionFetcher.invalidateQueries();
    useSchoolsFetcher.invalidateQueries();
    useSchoolFetcher.invalidateQueries();
    useSchoolLinkInvitationFetcher.invalidateQueries();
    useSchoolLinkInviteMemberFetcher.invalidateQueries();
    useClassroomFetcher.invalidateQueries();
    useClassesFetcher.invalidateQueries();
    useAllClassroomFetcher.invalidateQueries();
    useClassWhereNotVerifiedInSchoolFetcher.invalidateQueries();
  },
});

export const useTeacherSwitchSchoolOperation = makeApiMutation({
  name: "teacherSwitchSchool",
  path: "/api/schoolTeacher/school/{schoolId}",
  method: "put",
  onSuccess: () => {
    useSessionFetcher.invalidateQueries();
    useSchoolsFetcher.invalidateQueries();
    useSchoolFetcher.invalidateQueries();
    useSchoolLinkInvitationFetcher.invalidateQueries();
    useSchoolLinkInviteMemberFetcher.invalidateQueries();
    useSchoolVerificationRequestFetcher.invalidateQueries();
    useSchoolwideCenterMemberFetcher.invalidateQueries();
  },
});

export const useSchoolTeacherLeaveOperation = makeApiMutation({
  name: "schoolTeacherLeaveSchool",
  path: "/api/schoolTeacher/school/{schoolId}",
  method: "delete",
  onSuccess: () => {
    useSessionFetcher.invalidateQueries();
    useSchoolsFetcher.invalidateQueries();
    useSchoolFetcher.invalidateQueries();
    useSchoolVerificationRequestFetcher.invalidateQueries();
    useSchoolLinkInvitationFetcher.invalidateQueries();
    useSchoolLinkInviteMemberFetcher.invalidateQueries();
    useClassWhereNotVerifiedInSchoolFetcher.invalidateQueries();
  },
});

export type TeacherLeaveSchoolParams = {
  path: APIRequestParameters<"/api/dojoSchool/{schoolId}/teacher/{teacherId}", "delete">["path"];
};

// will become deprecated after school picker rollout.
export const useTeacherLeaveSchoolOperation = makeApiMutation({
  name: "teacherLeaveSchool",
  path: "/api/dojoSchool/{schoolId}/teacher/{teacherId}",
  method: "delete",
  onMutate: () => {
    useSessionFetcher.setQueriesData((draft) => {
      // optimistically remove schoolId from teacher; simplifies navigation and avoids
      // re-fetching the school when launchpad loads.
      // When useTeacherLeaveSchoolOperation done action fires,
      // the session will be reloaded anyway just to make sure state is consistent
      draft.teacher!.schoolId = undefined;
    });
  },
  onSuccess: () => {
    useSessionFetcher.invalidateQueries();
  },
});

export const useTeacherRemoveFromSchoolOperation = makeApiMutation({
  name: "teacherRemoveFromSchool",
  path: "/api/dojoSchool/{schoolId}/teacher/{teacherId}",
  queryParams: ["context"],
  method: "delete",
  onMutate: (params) => {
    useSchoolFetcher.setQueriesData(
      (draft) => {
        const { teacherId } = params.path;
        draft.schoolTeachers = (draft.schoolTeachers || []).filter((i) => i._id !== teacherId);
      },
      { id: params.path.schoolId },
    );

    usePendingTeachers.setQueriesData(
      (draft) => {
        const { teacherId } = params.path;
        return draft.filter(({ teacher }) => teacher._id !== teacherId);
      },
      { schoolId: params.path.schoolId },
    );
  },
  onSuccess: (_, { path }) => {
    usePendingTeachers.invalidateQueries({ schoolId: path.schoolId });
  },
  catchError: (error): Error => {
    if (error instanceof APIResponseError && [404, 409].includes(error.response.status)) {
      useSchoolFetcher.invalidateQueries(); // invalidate query to make sure teachers are updated on list.
      return error;
    }

    throw error;
  },
});

export const useVerifySchoolTeacherOperation = makeApiMutation({
  name: "verifySchoolTeacher",
  path: "/api/dojoSchool/{schoolId}/teacher/{teacherId}/verify",
  method: "post",
  onMutate: (params) => {
    useSchoolFetcher.setQueriesData(
      (draft) => {
        const { teacherId } = params.path;
        const teacher = find(draft.schoolTeachers, (i) => i._id === teacherId);
        if (teacher) {
          teacher.isVerified = true;
        }
      },
      { id: params.path.schoolId },
    );

    usePendingTeachers.setQueriesData(
      (draft) => {
        const { teacherId } = params.path;
        return draft.filter(({ teacher }) => teacher._id !== teacherId);
      },
      { schoolId: params.path.schoolId },
    );
  },
  onSuccess: (_, { path }) => {
    usePendingTeachers.invalidateQueries({ schoolId: path.schoolId });
  },
});

export const useTeacherApproveSuggestions = makeCollectionQuery({
  fetcherName: "teacherApproveSuggestions",
  path: "/api/dojoSchool/{schoolId}/teacher/{teacherId}/approveSuggestion",
});

export const useApproveSuggestions = makeApiMutation({
  name: "doApproveSuggestions",
  path: "/api/dojoSchool/{schoolId}/teacher/{teacherId}/approveSuggestion",
  method: "post",
  onSuccess: () => {
    useTeacherApproveSuggestions.invalidateQueries();
  },
});

export const usePreapproveSchoolTeacherOperation = makeApiMutation({
  name: "preapproveSchoolTeacher",
  path: "/api/dojoSchool/{schoolId}/teacher/{teacherId}/verify",
  method: "post",
  onSuccess: (_data, params) => {
    useSchoolFetcher.invalidateQueries({ id: params.path.schoolId });

    usePendingTeachers.setQueriesData(
      (draft) => {
        return draft.filter(({ teacher }) => teacher._id !== params.path.teacherId);
      },
      { schoolId: params.path.schoolId },
    );
    usePendingTeachers.invalidateQueries({ schoolId: params.path.schoolId });
  },
});

export const usePreapprovalForTeacherFetcher = makeCollectionQuery({
  fetcherName: "preapprovalsForTeacher",
  path: "/api/teacher/{teacherId}/preapproval",
});

export const useGetUnverifiedNotPreapprovedTeachers = () => {
  const teacher = useFetchedTeacher();
  const { schoolId } = teacher;
  const canVerify = isVerifiedSchoolAdmin(teacher);
  const canPreapprove = canVerify;
  const { data: school } = useSchoolFetcher(schoolId ? { id: schoolId } : NOOP);
  const schoolTeachers = getSchoolTeachers(school);
  const unverifiedTeachers = canPreapprove
    ? getUnverifiedAndNotPreapprovedTeachers(schoolTeachers)
    : getUnverifiedTeachers(schoolTeachers);

  return unverifiedTeachers;
};
