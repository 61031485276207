import { useCallback, useEffect, useMemo, useState } from "react";
import { LabelText } from "../../nessie";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";
import { parenthesize } from "../../utils/name";
import { fromToday } from "../../utils/time";
import { translate } from "../../utils/translate";
import CloseButton from "../buttons/Close";
import LoadingMojo from "../misc/LoadingMojo";
import ProfilePicture from "../misc/ProfilePicture";
import RichText from "../misc/RichText";
import DeleteModal from "./modals/Delete";
import { parse } from "@web-monorepo/dates";
import { Image } from "../misc/Image";
import { UnstyledButton } from "../buttons";

type CommentProps = {
  _id: string;
  myId: string;
  entityId: string;
  body: string;
  translatedBody?: string;
  translateTo: string;
  language: string;
  canDelete: boolean;
  entityName: string;
  entityRelation?: string;
  entityAvatarURL?: string;
  time?: string;
  delete?: (id: string) => void;
  translate?: (id: string) => void;
  shouldFocusOnLoad?: boolean;
};

const Comment = ({
  _id,
  myId,
  entityId,
  entityName,
  entityAvatarURL,
  entityRelation,
  translateTo,
  language,
  body,
  translatedBody,
  time,
  canDelete,
  delete: deleteFn,
  translate: translateFn,
  shouldFocusOnLoad,
}: CommentProps): JSX.Element => {
  const [showingDeleteModal, setShowingDeleteModal] = useState(false);
  const [showTranslation, setShowTranslation] = useState(!!translatedBody && !(myId === entityId));

  const showTranslationAction = !(myId === entityId) && language !== translateTo;
  const canDeleteComment = !!deleteFn && canDelete;

  const displayedBody = useMemo(
    () => (showTranslation && translatedBody ? translatedBody : body),
    [body, translatedBody, showTranslation],
  );

  const toggleTranslation = useCallback(() => {
    if (translateFn) translateFn(_id);
    setShowTranslation((prev) => !prev);
  }, [translateFn, _id]);

  function deleteId() {
    if (deleteFn) deleteFn(_id);
  }

  function showDeleteModal() {
    if (canDeleteComment) setShowingDeleteModal(true);
  }

  function hideDeleteModal() {
    setShowingDeleteModal(false);
  }

  useEffect(() => {
    if (shouldFocusOnLoad && canDeleteComment) {
      const commentItem = document.getElementById(`comment:close_button:${_id}`);
      if (commentItem instanceof HTMLElement) {
        commentItem.focus();
      }
    }
  }, [shouldFocusOnLoad, canDeleteComment, _id]);

  return (
    <div sx={styles.commentBox} data-name="commentItem">
      <ProfilePicture size="3.6rem" src={entityAvatarURL} colorKey={entityId} sx={{ flexShrink: "0" }} />
      <div sx={{ flexGrow: 1, marginLeft: "dt_l", display: "flex", flexWrap: "wrap", alignItems: "center", gap: "xs" }}>
        <LabelText sx={{ display: "inline" }}>{entityName}</LabelText>
        <span sx={{ color: "dt_content_tertiary" }}>{parenthesize(entityRelation)}</span>
        <RichText text={displayedBody} sx={{ width: "100%" }} />
        {showTranslationAction && (
          <div sx={{ display: "flex", alignItems: "center" }}>
            <UnstyledButton data-name="commentItem:translate" onClick={toggleTranslation}>
              <LabelText level={2} sx={{ color: "dt_content_accent" }}>
                {showTranslation ? "See original" : "Translate"}
              </LabelText>
            </UnstyledButton>
            {showTranslation && <Image sx={styles.googleTranslate} src="shared/google_translate_grey.png" alt="" />}
          </div>
        )}
      </div>
      <div data-name="commentTimeDeleteWrapper">
        {time ? (
          <span sx={styles.commentTimeDeleteContainer}>{fromToday(parse(time), "en")}</span>
        ) : (
          <LoadingMojo block sx={{ img: { width: "30px", height: "30px" } }} />
        )}
      </div>
      {canDeleteComment ? (
        <CloseButton
          data-name="comment:close_button"
          id={`comment:close_button:${_id}`}
          sx={styles.closeButton}
          onClick={showDeleteModal}
          aria-label={translate({
            str: "dojo.common:class_story.comments_delete_modal.delete",
            fallback: "Delete comment",
          })}
        />
      ) : null}

      {showingDeleteModal && (
        <DeleteModal
          forComment={true}
          // If name is not provided, modal will say "delete your comment"
          commenterName={myId === entityId ? undefined : entityName}
          delete={deleteId}
          close={hideDeleteModal}
          data-name="deleteCommentModal"
        />
      )}
    </div>
  );
};

export default Comment;

const styles: Record<string, ThemeUIStyleObject> = {
  commentBox: {
    position: "relative" as const,
    display: "flex",
    borderTop: "dt_divider",
    padding: "m",
    ":hover > button": {
      opacity: 1,
    },
  },
  closeButton: {
    position: "absolute" as const,
    top: 0,
    right: 0,
    padding: "m",
    backgroundColor: "dt_content_light",
    opacity: 0,
    ":focus, :focus-visible": { opacity: 1 },
  },
  commentTimeDeleteContainer: {
    textAlign: "right" as const,
    fontSize: "1.4rem",
    width: "45px",
    color: "dt_content_tertiary",
  },
  googleTranslate: {
    display: "block",
    height: 20,
    marginLeft: "dt_xs",
    marginBottom: "dt_xxs",
  },
};
