import { logEvent } from "@classdojo/log-client";
import useWatch from "@classdojo/web/hooks/useWatch";
import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { APIResponse } from "@web-monorepo/shared/api/apiTypesHelper";
import { makeMemberQuery, makeApiMutation } from "@web-monorepo/shared/reactQuery";
import { NOOP } from "@web-monorepo/shared/reactQuery";
import camelCase from "lodash/camelCase";
import compact from "lodash/compact";
import { useSearchParams } from "react-router-dom";
import {
  CDS_RETURNING_USER_EXPLANATION_DISMISS_DATE,
  HAS_SEEN_SWAG_MODAL,
  HAS_SEEN_CDS_STAFF_COMPLETED_MODAL,
} from "app/data/userConfigKeys";
import { getSchoolTeachers, useSchoolFetcher } from "app/pods/school";
import { useFetchedTeacher } from "app/pods/teacher";
import { getMetadataValue, useUserConfigFetcher } from "app/pods/userConfig";
import { fullName } from "app/utils/name";
import { translate } from "app/utils/translate";
import { isBefore, parse } from "@web-monorepo/dates";

// -------------------------------
// CONSTANTS

const CDS_V3_LAUNCH_DATE = "2023-04-06T00:00:00Z";

export type StageOption = "SIGNUP" | "LEADER_ONBOARD" | "MAKE_OFFICIAL" | "PLAN" | "DIRECTORY" | "LOADING";

// eslint-disable-next-line react-refresh/only-export-components
export const STAGES: { [key: string]: StageOption } = {
  signup: "SIGNUP",
  makeOfficial: "MAKE_OFFICIAL",
  leaderOnboard: "LEADER_ONBOARD",
  plan: "PLAN",
  directory: "DIRECTORY",
  loading: "LOADING",
} as const;

export type SchoolwideCenterDataType = APIResponse<"/api/dojoSchool/{schoolId}/schoolwideCenterTeacher", "get">;

const numberOfTeachers = ["1-10", "11-20", "21-30", "31-40", "41+"];
// eslint-disable-next-line react-refresh/only-export-components
export const teacherRangeOptions = () => {
  const placeholder = {
    value: "",
    label: `${translate({
      str: "dojo.teacher_web:school_signup_verification.select_number_teachers",
      fallback: "Select number of teachers",
    })}`,
  };

  return [placeholder].concat(
    numberOfTeachers.map((numberOfTeacher) => ({
      value: numberOfTeacher,
      label: `${numberOfTeacher} ${translate({
        str: "dojo.teacher_web:school_signup_verification.number_teachers_plural",
        fallback: "teachers",
      })}`,
    })),
  );
};

// -------------------------------
// FETCHERS

// eslint-disable-next-line react-refresh/only-export-components
export const useSchoolwideCenterMemberFetcher = makeMemberQuery({
  path: `/api/dojoSchool/{schoolId}/schoolwideCenterTeacher`,
  fetcherName: "getSchoolwideCenter",
  dontThrowOnStatusCodes: [403],
});

// -------------------------------
// OPERATIONS

// eslint-disable-next-line react-refresh/only-export-components
export const useSchoolwideCenterSignupOperation = makeApiMutation({
  name: "useSchoolwideCenterSignupOperation",
  path: "/api/dojoSchool/{schoolId}/schoolwideCenterTeacher",
  method: "put",
  onSuccess: (data) => {
    useSchoolwideCenterMemberFetcher.setQueriesData(data.body);
  },
});

// --------------------------------
// SHARED STYLES

// eslint-disable-next-line react-refresh/only-export-components
export const ContentBoxStyle: ThemeUIStyleObject = {
  backgroundColor: "dt_background_primary",
  border: "dt_card",
  boxShadow: "dt_shadow_shadezies",
  borderRadius: "dt_radius_s",
  padding: "dt_xl",
  marginBottom: "dt_l",
  backgroundRepeat: "no-repeat",
};

// eslint-disable-next-line react-refresh/only-export-components
export const SignUpContainerStyle: ThemeUIStyleObject = {
  width: "100%",
  maxWidth: 700,
  marginX: "auto",
  marginTop: "dt_xxl",
  display: "flex",
  flexDirection: "column",
};

// --------------------------------
// UTILS

// eslint-disable-next-line react-refresh/only-export-components
export const signedUpBeforeCDSV3 = (schoolwideSignupCreatedAt?: string) => {
  if (!schoolwideSignupCreatedAt) return false;

  return isBefore(parse(schoolwideSignupCreatedAt), parse(CDS_V3_LAUNCH_DATE));
};

// eslint-disable-next-line react-refresh/only-export-components
export const teacherHasExpressedInterest = (teacherId: string, schoolwideCenterData?: SchoolwideCenterDataType) => {
  if (!schoolwideCenterData) return false;

  return (schoolwideCenterData?.interestExpressedBy || []).includes(teacherId);
};

// eslint-disable-next-line react-refresh/only-export-components
export const areSignupStepsComplete = (schoolwideCenterData?: SchoolwideCenterDataType) => {
  if (!schoolwideCenterData) return false;

  if (schoolwideCenterData.decision) return true;

  return !!(signedUpBeforeCDSV3(schoolwideCenterData?.enrolledAt) && schoolwideCenterData?.directoryComplete);
};

// eslint-disable-next-line react-refresh/only-export-components
export const checkAndroidWebview = (searchParams: URLSearchParams): boolean => {
  return searchParams.has("android") || !!window.dojoMobileApp;
};

// --------------------------------
// HOOKS

// TODO: Break this up into sub hooks
// eslint-disable-next-line complexity,react-refresh/only-export-components
export const useGetSchoolwideStage = () => {
  const { schoolId, _id, schoolVerified } = useFetchedTeacher();
  const { data: schoolwideCenterData, isFetching: schoolwideCenterLoading } = useSchoolwideCenterMemberFetcher(
    schoolId && schoolVerified ? { schoolId } : NOOP,
  );

  const [searchParams] = useSearchParams();

  const formattedSearchParamStage = camelCase(searchParams.get("stage") || "");

  const queryStage = STAGES[formattedSearchParamStage] || null;

  const defaultState = {
    stage: queryStage || STAGES.signup,
    loading: false,
  };

  if (schoolwideCenterLoading) return { ...defaultState, loading: true };

  // not yet started on schoolwide journey
  if (!schoolwideCenterData?.enrolled) return defaultState;

  // CDS V1, teacher signup not yet complete
  if (
    signedUpBeforeCDSV3(schoolwideCenterData?.enrolledAt) &&
    !schoolwideCenterData?.directoryComplete &&
    !schoolwideCenterData.decision &&
    !schoolwideCenterData.leaderOnboardCompletedBy
  ) {
    return defaultState;
  }

  // CDS V1, teacher signup complete
  if (signedUpBeforeCDSV3(schoolwideCenterData?.enrolledAt) && schoolwideCenterData?.directoryComplete) {
    return {
      ...defaultState,
      stage: queryStage || STAGES.makeOfficial,
    };
  }

  // CDS V2, complete
  if (schoolwideCenterData?.decision === "yes") {
    return {
      ...defaultState,
      stage: queryStage || STAGES.makeOfficial,
    };
  }

  // CDS V3, complete
  if (schoolwideCenterData?.decision === "yes-v3") {
    return {
      ...defaultState,
      stage: queryStage || STAGES.signup,
    };
  }

  // CDS V3 user already talked to leader
  if (schoolwideCenterData?.leaderOnboardCompletedBy && schoolwideCenterData?.leaderOnboardCompletedBy.includes(_id)) {
    return {
      ...defaultState,
      stage: queryStage || STAGES.makeOfficial,
    };
  }

  // User expressed interest in first step, nothing else.
  if (
    schoolwideCenterData?.interestExpressedBy &&
    schoolwideCenterData?.interestExpressedBy.includes(_id) &&
    schoolwideCenterData?.schoolwideTeacherCount
  ) {
    return {
      ...defaultState,
      stage: queryStage || STAGES.leaderOnboard,
    };
  }

  return defaultState;
};

// eslint-disable-next-line react-refresh/only-export-components
export const useShowReturningUserExplanation = (currentStage: StageOption) => {
  const { schoolId, schoolVerified } = useFetchedTeacher();

  const { data: schoolwideCenterData, isFetching: schoolwideCenterLoading } = useSchoolwideCenterMemberFetcher(
    schoolId && schoolVerified ? { schoolId } : NOOP,
  );

  const { data: userConfig } = useUserConfigFetcher({});
  const previouslyDismissedDate = getMetadataValue<string>(userConfig, CDS_RETURNING_USER_EXPLANATION_DISMISS_DATE);

  if (schoolwideCenterLoading) return false;

  if (currentStage !== STAGES.signup) {
    return false;
  }

  if (previouslyDismissedDate) return false;

  // CDS V1, teacher signup not yet complete
  if (
    !schoolwideCenterData?.decision &&
    signedUpBeforeCDSV3(schoolwideCenterData?.enrolledAt) &&
    !schoolwideCenterData?.directoryComplete
  ) {
    return true;
  }

  return false;
};

// eslint-disable-next-line react-refresh/only-export-components
export const useGetExpressedInterestTeacherNames = () => {
  const teacher = useFetchedTeacher();
  const { data: schoolwideSignupData } = useSchoolwideCenterMemberFetcher(
    teacher.schoolId && teacher.schoolVerified ? { schoolId: teacher.schoolId } : NOOP,
  );
  const { data: school } = useSchoolFetcher(teacher.schoolId ? { id: teacher.schoolId } : NOOP);
  const schoolTeachers = getSchoolTeachers(school);

  return compact(
    (schoolwideSignupData?.interestExpressedBy || []).map((teacherId) => {
      if (teacherId === teacher._id) return null;
      if (!schoolTeachers) return null;
      const teacherObject = schoolTeachers[teacherId];
      if (!teacherObject) return null;
      return fullName(teacherObject);
    }),
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useHasSeenCdsStaffCompletedModal = () => {
  const { data: userConfig } = useUserConfigFetcher({});
  // legacy
  const hasSeenSwagModal = getMetadataValue(userConfig, HAS_SEEN_SWAG_MODAL);
  // newer
  const hasSeenCdsStaffCompletedModal = getMetadataValue(userConfig, HAS_SEEN_CDS_STAFF_COMPLETED_MODAL);

  return hasSeenSwagModal || hasSeenCdsStaffCompletedModal ? true : false;
};

// eslint-disable-next-line react-refresh/only-export-components
export const useLogIsCDSComplete = (source: "schoolwide_center" | "directory") => {
  const teacher = useFetchedTeacher();
  const { data: schoolwideCenterData, isFetching } = useSchoolwideCenterMemberFetcher(
    teacher.schoolId && teacher.schoolVerified ? { schoolId: teacher.schoolId } : NOOP,
  );
  const signupComplete = areSignupStepsComplete(schoolwideCenterData);

  useWatch([signupComplete, isFetching], () => {
    if (!isFetching) {
      const status = signupComplete ? "cds_complete" : "cds_incomplete";
      logEvent({
        eventName: `web.teacher.cdsv3.${status}`,
        metadata: {
          directoryComplete: schoolwideCenterData?.directoryComplete,
          decision: schoolwideCenterData?.decision,
          source,
          schoolId: teacher.schoolId,
        },
      });
    }
  });
};
