import { Trans, useTranslation } from "react-i18next";
import { type TranslatableProps } from "./types";

const omit = (obj: Record<string, any>, props: string[]) => {
  obj = { ...obj };
  props.forEach((prop) => delete obj[prop]);
  return obj;
};

export type TProps<T extends string> = Omit<
  JSX.IntrinsicElements["span"],
  "children" | "dangerouslySetInnerHTML"
> &
  TranslatableProps<T>;

/**
 * Translation component. Refer to our translation function to check how it can
 * be configured.
 *
 * ### [How does translation work?](https://www.notion.so/classdojo/How-Does-Translation-Work-b9ddf058dd6f43ebb5c4c1c4044d3124#9c9e05b364364615acd1074d94e3af42)
 */
const T = <T extends string>(props: TProps<T>): JSX.Element => {
  const { t } = useTranslation();

  if ("components" in props && props.components) {
    return (
      <Trans
        i18nKey={props.str}
        defaults={props.fallback}
        values={props.subs}
        components={props.components}
      />
    );
  }

  const spanProps = omit(props, ["htmlStr", "str", "subs", "fallback"]);

  if (props.htmlStr) {
    const translation = t(props.htmlStr, props.fallback || props.htmlStr);
    return (
      <span {...spanProps} dangerouslySetInnerHTML={{ __html: translation }} />
    );
  }

  if (!props.str) throw new Error("str not specified");

  return (
    <span {...spanProps}>
      {t(
        props.str,
        props.fallback || props.str,
        ("subs" in props && props.subs) || {}
      )}
    </span>
  );
};

export { T };
