import { createElement, Component, PropsWithChildren } from "react";
import ReactDOM from "react-dom";

export const IGNORE_CLASS = "ignore-react-onclickoutside";

type WithClickOutsideProps = {
  wrapper?: string;
  onClickOutside: (evt: Event) => void;
};

const isElement = (value: EventTarget | ParentNode | null): value is Element => {
  return !!value && value instanceof Element;
};

// eslint-disable-next-line react-prefer-function-component/react-prefer-function-component
export default class WithClickOutside extends Component<PropsWithChildren<WithClickOutsideProps>> {
  componentDidMount = (): void => {
    if (!this.props.onClickOutside) {
      throw new Error("Component lacks a onClickOutside prop, for processing outside click events.");
    }

    const thisNode = ReactDOM.findDOMNode(this);
    this.localNode = isElement(thisNode) ? thisNode : null;
    document.addEventListener("mousedown", this.handleClick);
    document.addEventListener("touchstart", this.handleClick);
  };

  componentWillUnmount(): void {
    document.removeEventListener("mousedown", this.handleClick);
    document.removeEventListener("touchstart", this.handleClick);
  }

  render(): JSX.Element {
    const { wrapper = "span", children } = this.props;
    return createElement(wrapper, {}, children);
  }

  localNode: Element | null | undefined;

  handleClick = (evt: Event): void => {
    if (this.props.onClickOutside) {
      let source = isElement(evt.target) ? evt.target : null;
      let found = false;
      // If source=local then this event came from "somewhere"
      // inside and should be ignored. We could handle this with
      // a layered approach, too, but that requires going back to
      // thinking in terms of Dom nodes nesting, running counter
      // to React's "you shouldn't care about the DOM" philosophy.
      while (source && source.parentNode) {
        found = source === this.localNode || ("classList" in source && source.classList.contains(IGNORE_CLASS));
        if (found) return;
        source = isElement(source.parentNode) ? source.parentNode : null;
      }
      this.props.onClickOutside(evt);
    }
  };
}
