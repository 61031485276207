import * as React from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import classes from "./DDSTooltip.module.css";

export const Sides = ["top", "right", "bottom", "left"] as const;
export const Alignments = ["start", "center", "end"] as const;

type DDSTooltipProps = {
  /** The content to display in the tooltip */
  content: React.ReactNode;
  /** The element that triggers the tooltip */
  children: React.ReactElement;
  /** The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  ["data-name"]: string;
  /** The side of the element to show the tooltip */
  side?: (typeof Sides)[number];
  /** The alignment of the tooltip relative to the trigger */
  align?: (typeof Alignments)[number];
  /** The delay in ms before showing the tooltip */
  delayDuration?: number;
  /** Whether the tooltip should be disabled */
  disabled?: boolean;
};

export const DDSTooltip = React.forwardRef<HTMLDivElement, DDSTooltipProps>(
  (
    { children, content, ["data-name"]: dataName, side = "top", align = "center", delayDuration = 0, disabled = false },
    ref,
  ) => {
    if (disabled) {
      return children;
    }

    return (
      <TooltipPrimitive.Root delayDuration={delayDuration}>
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
        <TooltipPrimitive.Portal>
          <TooltipPrimitive.Content
            ref={ref}
            side={side}
            align={align}
            sideOffset={5}
            collisionPadding={10}
            className={classes.tooltipContent}
            data-name={dataName}
          >
            {content}
          </TooltipPrimitive.Content>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    );
  },
);

export const DDSTooltipProvider = TooltipPrimitive.Provider;

DDSTooltip.displayName = "DDSTooltip";
