import { BodyText } from "@classdojo/web/nessie";
import { RAW_cssValue, ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { ClassroomGroup } from "app/pods/group";
import { popupDescriptor } from "app/pods/points/components/AwardDescriptors";
import BounceAnimationSetup from "app/pods/points/components/popups/animations";
import { ClassStudent } from "app/pods/student";
import { useUserConfigFetcher } from "app/pods/userConfig";

// eslint-disable-next-line react-refresh/only-export-components
export const POPUP_DURATION = 3000;
// eslint-disable-next-line react-refresh/only-export-components
export const POPUP_WIDTH = 760;

type GeneralPopupProps = {
  students: ClassStudent[];
  groups: ClassroomGroup[];
  subtitle?: string;
  description?: string;
  rightImage: JSX.Element | null;
  popupConditional: boolean;
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  ["data-name"]?: string;
};

const GeneralPopup = ({
  students,
  groups,
  subtitle,
  description,
  rightImage,
  popupConditional,
  ["data-name"]: dataName,
}: GeneralPopupProps) => {
  const { data: userConfig } = useUserConfigFetcher({});
  const { title, image } = popupDescriptor({ groups, students });
  if (!userConfig) return null;

  return (
    <>
      <BounceAnimationSetup />
      <TransitionGroup>
        {popupConditional && (
          <CSSTransition classNames="bounceAnimation" timeout={{ enter: 1300, exit: 100 }}>
            <div sx={popupStyle} data-name={dataName}>
              <div>{image}</div>
              <div sx={textContainerStyle} role="alert" aria-live="polite">
                {title ? <span sx={nameStyle}>{title}</span> : null}
                {subtitle ? <span sx={pointStyle}>{subtitle}</span> : null}
                {description ? <BodyText level={2}>{description}</BodyText> : null}
              </div>
              {rightImage}
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  );
};

export default GeneralPopup;

const popupStyle: ThemeUIStyleObject = {
  position: "fixed",
  zIndex: 105,
  bottom: 150,
  width: POPUP_WIDTH,
  height: "200px",
  marginLeft: RAW_cssValue(-POPUP_WIDTH / 2),
  left: "50%",
  borderRadius: "dt_radius_s",
  backgroundColor: "dt_background_primary",
  boxShadow: "dt_shadow_shadezies",
  display: "grid",
  alignItems: "center",
  gridTemplateColumns: "20% auto 20%",
  padding: "dt_xl",
};

const nameStyle: ThemeUIStyleObject = {
  fontSize: "3.6rem",
  fontWeight: 600,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  marginBottom: "dt_xxs",
};

const textContainerStyle: ThemeUIStyleObject = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "dt_s",
};

const pointStyle: ThemeUIStyleObject = {
  fontSize: "2.6rem",
  color: "dt_content_primary",
};
