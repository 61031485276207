import callApi from "@web-monorepo/infra/callApi";
import { makeMutation } from "@web-monorepo/shared/reactQuery";
import { useState, useEffect } from "react";
import errors from "app/errorTypes";
import env from "app/utils/env";

const DEFAULT_RETRY_INTERVAL = env.isTesting ? 50 : 2000;
// Try for 1 minute in Production (30 attempts with an interval of 2000 milliseconds);
const MAX_ATTEMPTS = env.isTesting ? 6 : env.isDev ? 3 : 30;

// Polls the API until the provided link is ready to be fetched, or a timeout is reached.
// Once the link is ready, the returned object will contain the property `isSuccess: true`

export const useCheckUntilDownloadLinkIsReady = (link?: string) => {
  const {
    data: checkLinkResult,
    mutate: checkLink,
    error: checkLinkError,
    isLoading: loadingCheckLink,
  } = useCheckDownloadLinkIsReadyOperation();

  const [attempts, setAttempts] = useState(0);

  useEffect(() => {
    // Kick off the task.
    if (attempts === 0 && link) {
      // bulk ignoring existing errors
      // eslint-disable-next-line @web-monorepo/no-setState-in-useEffect
      setAttempts(attempts + 1);
      checkLink({ link });
      return;
    }

    let checkLinkTimeout: NodeJS.Timeout;
    if (attempts === MAX_ATTEMPTS) return;

    if (checkLinkError && !loadingCheckLink) {
      checkLinkTimeout = setTimeout(() => {
        checkLink({ link });
        // bulk ignoring existing errors
        // eslint-disable-next-line @web-monorepo/no-setState-in-useEffect
        setAttempts(attempts + 1);
      }, DEFAULT_RETRY_INTERVAL);
    }

    return () => {
      clearTimeout(checkLinkTimeout);
    };
  }, [attempts, checkLink, checkLinkError, link, loadingCheckLink]);

  //
  // Return based on the different states
  //

  if (checkLinkResult && "status" in checkLinkResult && checkLinkResult.status === 200) {
    return {
      isSuccess: true,
      error: null,
      isLoading: false,
    };
  }

  if (attempts === MAX_ATTEMPTS) {
    return {
      isSuccess: false,
      isLoading: false,
      error: errors.api.timeout(),
    };
  }

  if (!link) {
    return {
      isSuccess: false,
      isLoading: false,
      error: null,
    };
  }

  return {
    isLoading: true,
    isSuccess: false,
    error: null,
  };
};

const useCheckDownloadLinkIsReadyOperation = makeMutation({
  name: "checkDownloadLinkIsReady",
  fn: async ({ link }) => {
    try {
      return await callApi({
        method: "HEAD",
        path: link,
      });
      // eslint-disable-next-line no-catch-all/no-catch-all
    } catch {
      return errors.report.genericError();
    }
  },
});
