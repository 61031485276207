import { forwardRef } from "react";
import { useForwardedOrLocalRef, useUniqueIds } from "../../shared/hooks";
import classes from "./DDSTextarea.module.css";

type HTMLProps = React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;
type EventHandlers = Omit<
  {
    [K in keyof HTMLProps as K extends `on${string}` ? K : never]: HTMLProps[K];
  },
  "onChange"
>;
type OtherForwardedProps = Pick<HTMLProps, "id" | "name" | "value" | "disabled">;

export interface Props extends EventHandlers, OtherForwardedProps {
  /** The name will get used for automated product events */
  ["data-name"]: string;

  /** The label text shown above the textarea */
  label: string;

  /** Placeholder text shown when textarea is empty */
  placeholder?: string;

  /** Whether the textarea shows error styling */
  error?: boolean;

  /** Helper text shown below the textarea */
  hint?: string | React.ReactNode;

  /** Number of visible text rows */
  rows?: number;

  /** Maximum number of characters allowed */
  maxLength?: number;

  /** Callback fired when the value changes */
  onChange?: (value: string, rawEvent: React.ChangeEvent<HTMLTextAreaElement>) => void;

  /** The value of the textarea */
  value?: string;

  /** Whether the textarea should be resizable. Defaults to true */
  resize?: boolean;
}

/**
 * Docs:
 * - {@link https://components.classdojo.com/?path=/story/components-ddstextarea--basic Storybook}
 */
export const DDSTextarea = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      ["data-name"]: dataName,
      disabled,
      id,
      label,
      error,
      hint,
      placeholder = " ",
      rows = 3,
      maxLength,
      onChange,
      value,
      resize = true,
      ...rest
    },
    forwardedRef,
  ) => {
    const ref = useForwardedOrLocalRef(forwardedRef);
    const [labelId, textareaId, hintId] = useUniqueIds(["DDSTextarea.label", "DDSTextarea.input", "DDSTextarea.hint"]);

    const className = [classes.textarea, error ? classes.error : null].filter(Boolean).join(" ");
    const inputClassName = [classes.input, !resize ? classes.noResize : null].filter(Boolean).join(" ");

    return (
      <label className={className}>
        <span id={labelId} className={classes.label}>
          {label}
        </span>
        <div className={classes.inputBox}>
          <textarea
            aria-labelledby={labelId}
            className={inputClassName}
            data-name={dataName}
            disabled={disabled}
            id={id ?? textareaId}
            placeholder={placeholder}
            ref={ref}
            aria-describedby={hintId}
            aria-invalid={error}
            rows={rows}
            maxLength={maxLength}
            onChange={(e) => onChange?.(e.target.value, e)}
            value={value}
            {...rest}
          />
        </div>
        {hint && (
          <span aria-live="polite" id={hintId} className={classes.hint}>
            {hint}
          </span>
        )}
      </label>
    );
  },
);
