import env from "app/utils/env";
import { getImageSource } from "app/utils/Image";

const preloaded: { [keyof: string]: HTMLImageElement } = {};

// Note that you should probably be passing in the getImageSource'd version of the path
function _preload(path: string): string {
  if (env.isTesting) return path;
  if (!preloaded[path]) {
    const image = new Image();
    image.src = path;
    preloaded[path] = image;
  }
  return path;
}

export function preload(image: string): string;
export function preload(image: string[]): string[];
export function preload(image: string | string[]): string | string[] {
  if (Array.isArray(image)) {
    return image.map(_preload);
  }
  return _preload(image);
}

export function requireAndPreload(image: string): string;
export function requireAndPreload(image: string[]): string[];
export function requireAndPreload(image: string | string[]): string | string[] {
  if (Array.isArray(image)) {
    return image.map((image) => _preload(getImageSource(image)));
  }
  return _preload(getImageSource(image));
}

const imagesByRoute: { [keyof: string]: string[] } = {};

export function preloadOnRoute(image: string | string[], ...args: string[]): string | string[] {
  for (let i = 0; i < args.length; i++) {
    const route = args[i];
    imagesByRoute[route] = (imagesByRoute[route] || []).concat(image);
  }
  return image;
}

export function requireAndPreloadOnRoute(image: string, ...args: string[]): string | undefined;
export function requireAndPreloadOnRoute(image: string[], ...args: string[]): string[] | undefined;
export function requireAndPreloadOnRoute(image: string | string[], ...args: string[]): string | string[] | undefined {
  let required: string | string[];
  if (Array.isArray(image)) {
    required = image.map(getImageSource);
  } else {
    required = getImageSource(image);
  }
  return preloadOnRoute(required, ...args);
}

export function onRouteChange(routes: string[]) {
  routes.forEach(function (route: string) {
    const images = imagesByRoute[route] || [];
    let image;
    while ((image = images.pop())) {
      _preload(image);
    }
  });
}
