import { CallApiErrorResponse } from "app/pods/shared/util/matchesError";

export default function getApiError(response: CallApiErrorResponse) {
  const error = response?.body.error;
  if (!error) return null;

  const firstString = [error.detail?.message, error.detail, error.message].find((s) => typeof s === "string");
  if (!firstString) return null;

  return firstString.trim().toLowerCase();
}
