import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";
import * as React from "react";
import { CloseIcon } from "../../nessie/icons";
import { RAW_cssValue, ThemeUIStyleObject } from "../../nessie/stylingLib";
import { UnstyledButton } from "../buttons";
import ChevronButton from "../misc/ChevronButton";
import Video from "../misc/Video";
import BaseModal from "./Base";

// we support either video sources or a single image source
export default function ExpandedPhotoModal(
  props: {
    left?: () => void;
    right?: () => void;
    close: React.ComponentPropsWithoutRef<typeof BaseModal>["onRequestHide"];
  } & ({ sources: { path: string; contentType: string }[]; poster?: string } | { src?: string }),
) {
  let contents: React.ReactNode = null;

  if ("sources" in props) {
    contents = props.sources && (
      <Video
        data-name="expandedVideo"
        sources={props.sources}
        poster={props.poster}
        onClick={(e: React.MouseEvent) => e.stopPropagation()}
        preload="auto"
        containerStyle={{ height: "auto" }}
      />
    );
  }

  if ("src" in props && props.src && !contents) {
    contents = <div data-name="expandedPhotoImage" sx={{ ...styles.image, backgroundImage: `url(${props.src})` }} />;
  }

  return (
    <BaseModal
      data-name="expandedPhotoModal"
      sx={styles.modal}
      onRequestHide={props.close}
      requestHideOnOverlayClick={true}
      label={autoTranslate("Attachment")}
    >
      <>
        <UnstyledButton
          data-name="photo:close_dialog_x"
          onClick={props.close}
          aria-label="Close dialog"
          sx={{
            position: "absolute",
            top: "dt_xl",
            left: "dt_xl",
            borderRadius: "dt_radius_m",
            backgroundColor: RAW_cssValue("rgba(26, 25, 45, 0.6)"), // this should be dt_content_media
            width: "54px",
            height: "54px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
          }}
        >
          <CloseIcon size="m" color={"dt_content_light"} sx={{ margin: "auto" }} />
        </UnstyledButton>
        {/* This is necessary to make the modal more easily closeable for sighted users. We don't want this to be a clickable element for a11y users though as that can be confusing.
        This is not a good solution, we should replace this component entirely with one that is more accessible. */}
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div data-name="photo:close_dialog" onClick={props.close} sx={styles.verticalAlign}>
          {contents}
        </div>
        {props.left && (
          <ChevronButton
            direction="left"
            sx={{
              position: "absolute",
              top: "50%",
              left: "5%",
              transform: "translateY(-50%)",
              cursor: "pointer",
            }}
            aria-label="Previous"
            onClick={props.left}
            data-name="post_contents_text_and_attachment:previous"
          />
        )}

        {props.right && (
          <ChevronButton
            direction="right"
            sx={{
              position: "absolute",
              top: "50%",
              right: "5%",
              transform: "translateY(-50%)",
              cursor: "pointer",
            }}
            aria-label="Next"
            onClick={props.right}
            data-name="post_contents_text_and_attachment:next"
          />
        )}
      </>
    </BaseModal>
  );
}

const styles: Record<string, ThemeUIStyleObject> = {
  modal: {
    outline: "none",
    border: "none",
    background: "transparent",
    boxShadow: "initial",
    position: "absolute" as const,
    top: "5%",
    left: "5%",
    width: "90%",
    height: "90%",
    transform: "none",
    WebkitTransform: "none",
    margin: 0,
  },

  verticalAlign: {
    position: "absolute" as const,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  image: {
    width: "100%",
    height: "100%",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 50%",
  },

  blocker: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
};
