import EventEmitter from "events";
import map from "lodash/map";
import uuid from "uuid/v4";
import { Adapter, MessageHandler, RealtimeMessage } from "./types";

export default class Exchange implements Adapter {
  private _emitter: EventEmitter;
  private _adapters: Adapter[];
  private _subscriptions: Map<string, Map<Adapter, MessageHandler>>;
  private _uuid: string;

  constructor(adapters: Adapter[]) {
    this._emitter = new EventEmitter();
    this._adapters = adapters;
    this._subscriptions = new Map();
    this._uuid = uuid();
  }

  private _wrapMessage(message: RealtimeMessage): RealtimeMessage {
    return Object.assign({ exchangeUUID: this._uuid }, message);
  }

  private _subscribeAdapter(channel: string, adapter: Adapter): void {
    const onMessage = (message: RealtimeMessage) => {
      // don't do anything if the message was published from this instance
      if (this._uuid !== message.exchangeUUID) {
        this._emitter.emit(channel, { adapter, message });
      }
    };
    adapter.subscribe?.(channel, onMessage);
    const adapterToHandler = this._subscriptions.get(channel);
    if (adapterToHandler) {
      adapterToHandler.set(adapter, onMessage);
    }
  }

  addAdapter(adapter: Adapter): void {
    this._adapters.push(adapter);
    this._subscriptions.forEach((_, channel) => {
      this._subscribeAdapter(channel, adapter);
    });
  }

  publish(channel: string, message: RealtimeMessage) {
    return Promise.all(map(this._adapters, (adapter) => adapter.publish?.(channel, this._wrapMessage(message))));
  }

  subscribe(channel: string, onMessage: MessageHandler) {
    if (!this._subscriptions.has(channel)) {
      this._emitter.on(channel, ({ adapter, message }) => {
        onMessage(message, channel);
        map(this._adapters, (other) => {
          if (other !== adapter) return other.publish?.(channel, this._wrapMessage(message));
        });
      });

      this._subscriptions.set(channel, new Map());
      map(this._adapters, (adapter) => this._subscribeAdapter(channel, adapter));
    }
  }

  unsubscribe(channel: string) {
    const adapterToHandler = this._subscriptions.get(channel);
    if (adapterToHandler) {
      adapterToHandler.forEach((handler, adapter) => {
        adapter.unsubscribe?.(channel, handler);
      });
    }
  }

  close() {
    this._emitter.removeAllListeners();
    this._subscriptions.forEach((adapterToHandler, channel) => {
      adapterToHandler.forEach((handler, adapter) => {
        adapter.unsubscribe?.(channel, handler);
      });
    });
  }
}
