import type { FC } from "react";
import { IconCheck, IconAlertCircleFilled } from "@web-monorepo/dds-icons";
import styles from "./DDSToast.module.css";

export const Variants = ["neutral", "success", "error"] as const;
export type Variant = (typeof Variants)[keyof typeof Variants];

type IconType = FC<{ className: string }>;

type PropsWithIcon = {
  variant?: "neutral";
  icon?: IconType;
  text: string;
};

type PropsWithoutIcon = {
  variant: Variant;
  text: string;
  icon?: never;
};

const NoIcon: IconType = () => null;

const iconForVariant = (variant: Variant): IconType => {
  switch (variant) {
    case "success":
      return IconCheck;
    case "error":
      return IconAlertCircleFilled;
  }

  return NoIcon;
};

export type Props = PropsWithIcon | PropsWithoutIcon;

export const DDSToast: FC<Props> = ({ variant = "neutral", icon: ProvidedIcon, text }) => {
  const Icon = ProvidedIcon ?? iconForVariant(variant);

  return (
    <aside className={[styles.toast, styles[variant as keyof typeof styles]].join(" ")}>
      <Icon className={styles.icon} />
      <p className={styles.text}>{text}</p>
    </aside>
  );
};
