import { createSaveMessageDraftSaga } from "@classdojo/web/pods/messaging/index";
import combineActionHandlers from "@web-monorepo/infra/combineActionHandlers";
import createAction from "@web-monorepo/infra/createAction";
import { APIResponse } from "@web-monorepo/shared/api/apiTypesHelper";
import { useClassMessageThreadsFetcher } from "@web-monorepo/shared/messaging/hooks";
import { Draft } from "@web-monorepo/shared/messaging/types";
import { PodInstallFunction } from "@web-monorepo/shared/podInfra";
import { NOOP } from "@web-monorepo/shared/reactQuery";
import set from "lodash/set";
import sumBy from "lodash/sumBy";
import type { AnyAction } from "redux";
import { messagesOrderBy } from "app/pods/messaging/utils";
import { useFetchedTeacher } from "app/pods/teacher";
import { localStorage } from "@web-monorepo/safe-browser-storage";

const STATE_KEY = "messagingTeacher";

export type PendingMessage = {
  tempId: string;
  body: string;
  createdAt: string;
  failed?: boolean;
  pending?: boolean;
};

type MessagingState = {
  pendingMessages: Record<string, Record<string, PendingMessage>>;
  drafts: Record<string, Draft>;
};

export type MessagingTeacherSlice = {
  [STATE_KEY]: MessagingState;
};

const initialState: MessagingState = {
  // All of the following are by channel or class (for broadcast channel)
  pendingMessages: {},
  drafts: {},
};

const SAVE_MESSAGE_DRAFT = createAction("messaging/save_message_draft");

export const saveDraft = (targetId: string, body: string, attachments?: MessageAttachment[]) => ({
  type: SAVE_MESSAGE_DRAFT,
  payload: { targetId, body, attachments },
});

const saveDraftHandler = (state: MessagingState, action: AnyAction) => {
  if (action.type === SAVE_MESSAGE_DRAFT) {
    const { targetId, body, attachments } = action.payload;
    return set(state, `drafts.${targetId}`, { body, attachments });
  }

  return state;
};

export const selectDraft = (state: MessagingTeacherSlice, channelId: string) => {
  if (state[STATE_KEY].drafts[channelId] && state[STATE_KEY].drafts[channelId]?.body != "") {
    return state[STATE_KEY].drafts[channelId];
  } else {
    const draft: Draft = { body: localStorage.getItem(`message_draft.${channelId}`) };
    return draft;
  }
};

export const selectPendingMessagesForChannel = (state: MessagingTeacherSlice, channelId: string) =>
  messagesOrderBy(state[STATE_KEY].pendingMessages[channelId]);

const reducer = combineActionHandlers(initialState, [saveDraftHandler]);

const install: PodInstallFunction = (installReducer, installSaga) => {
  installReducer(STATE_KEY, reducer);
  installSaga(createSaveMessageDraftSaga(SAVE_MESSAGE_DRAFT, localStorage));
};

export default install;

export type UnconnectedDirectChannel = Extract<MessageChannel, { isConnected: false }>;

export type ConnectedDirectChannel = Extract<MessageChannel, { _type: "connectedDirect" }>;

export type ClassBroadcastChannel = Extract<MessageChannel, { type: "classBroadcast" }>;

export type AboutStudent = UnconnectedDirectChannel["about"];

// Includes Message entity and extra state properties added by manual cache updates.
export type Message = APIResponse<"/api/message-thread/{messageThreadId}/message", "get">["_items"][number] & {
  translation?: string;
  translationDisplayed?: boolean;
  translationRequested?: boolean;
  translationFailed?: boolean;
  channelId?: string;
  classId?: string;
};

type InnerMessageAttachment = NonNullable<Message["attachments"]>[number];
type MessageAttachmentType = InnerMessageAttachment["type"] | "video" | "media";

export type MessageAttachment = Omit<InnerMessageAttachment, "type"> & {
  type: MessageAttachmentType;
};

export const getDMThreads = (threads?: ClassMessageThread[]): DMThread[] => {
  if (!threads) return [];
  return threads.filter(isDMThread);
};
const isDMThread = (thread: ClassMessageThread): thread is DMThread =>
  thread.type === "a" && "student" in thread.metadata && "classIds" in thread.metadata;

export const useClassroomUnreadThreadMessageCount = ({ classId }: { classId?: string }) => {
  const teacher = useFetchedTeacher();
  const { data: threads } = useClassMessageThreadsFetcher(
    teacher && classId ? { teacherId: teacher._id, classId } : NOOP,
  );

  return sumBy(threads, "unreadCount");
};

export type ClassMessageThread = APIResponse<
  "/api/teacher/{teacherId}/class/{classId}/message-thread",
  "get"
>["_items"][0];

export type DMThread = ClassMessageThread & {
  metadata: {
    student: {
      _id: string;
      firstName: string;
      lastName: string;
    };
    classIds: string[];
  };
};
