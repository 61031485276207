import { APIResponseError } from "@web-monorepo/infra/responseHandlers";
import throttle from "lodash/throttle";
import { useMemo, useEffect } from "react";
import { CUSTOM_HEADERS, NOOP } from "../../reactQuery";
import { useLocalStorage } from "@web-monorepo/safe-browser-storage";
import useTranslationLanguage from "../../utils/useTranslationLanguage";
import { MessageDetailsWithThreadId } from "../types";
import { useMessagesFetcher } from "./fetchers";
import { useSendThreadMessageOperation } from "./useSendMessageThreadOperation";
import { ErrorType } from "./useThreads";
import { announceToScreenReader } from "@web-monorepo/dds";
import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";

export const useMessages = (
  entityId: string,
  messageThreadId?: string,
  options?: {
    onErrorCallback?: Record<ErrorType, () => void>;
    onSendSuccessCallback?: (data: any, params: any) => void;
  },
) => {
  const [failedMessages, setFailedMessages] = useLocalStorage<MessageDetailsWithThreadId[]>(
    `failedMessages.${messageThreadId}`,
    [],
  );

  const newFailedMessages = [...failedMessages];

  const { mutate: sendThreadMessage, data: newThreadMessage } = useSendThreadMessageOperation({
    onError: (e, params) => {
      if (e) {
        setFailedMessages([...newFailedMessages, params]);
      }
    },
    onSuccess: (data, params) => {
      if (data && data.scheduled === false && failedMessages.includes(params)) {
        failedMessages.splice(failedMessages.indexOf(params), 1);
        setFailedMessages(failedMessages);
      }
      if (options?.onSendSuccessCallback) {
        options.onSendSuccessCallback(data, params);
      }
      announceToScreenReader(autoTranslate("Message sent"));
    },
  });

  const { translationLanguage } = useTranslationLanguage(entityId);

  const {
    data: messages,
    refetch: refetchMessages,
    fetchNextPage: loadMoreMessagesFn,
    hasNextPage: hasMoreMessages,
    isFetchingNextPage: loadingMoreMessages,
    isFetching: loadingSelectedThread,
    isLoading: loadingMessages,
  } = useMessagesFetcher(
    messageThreadId
      ? {
          messageThreadId,
          [CUSTOM_HEADERS]: {
            "Accept-Language": translationLanguage,
          },
        }
      : NOOP,
    {
      onError: (error) => {
        if (error instanceof APIResponseError) {
          const errorType = error.response?.status === 403 ? ErrorType.ForbiddenThread : undefined;
          if (errorType && options && options.onErrorCallback) {
            options.onErrorCallback[errorType]?.();
          }
        }
      },
    },
  );

  const loadMoreMessages = useMemo(() => {
    return throttle(
      () => {
        loadMoreMessagesFn({ cancelRefetch: false });
      },
      300,
      { leading: true },
    );
  }, [loadMoreMessagesFn]);

  const clearFailedMessage = (message: MessageDetailsWithThreadId) => {
    if (message) {
      newFailedMessages.splice(failedMessages.indexOf(message), 1);
      setFailedMessages(newFailedMessages);
    }
  };

  const resendFailedMessage = (message: MessageDetailsWithThreadId & { language: string }) => {
    clearFailedMessage(message);
    sendThreadMessage(message);
  };

  useEffect(() => {
    if (!loadingMessages) {
      refetchMessages({ cancelRefetch: false });
    }
  }, [messageThreadId, loadingMessages, refetchMessages]);

  return {
    messages,
    failedMessages,
    resendFailedMessage,
    clearFailedMessage,
    loadMoreMessages: hasMoreMessages ? loadMoreMessages : undefined,
    loadingMoreMessages,
    sendThreadMessage,
    loadingSelectedThread,
    newThreadMessage,
  };
};
