import { type TranslatedString } from "./types";
import { i18n } from "./i18n";

export function translate(
  object: (
    | {
        str: string;
        htmlStr?: undefined;
      }
    | {
        str?: undefined;
        /** @deprecated - use str instead, only here for legacy support */
        htmlStr: string;
      }
  ) & {
    fallback?: string;
    subs?: Record<string, string | number | undefined>;
  }
): TranslatedString;

export function translate(
  key: string,
  subs?: Record<string, number | string | undefined>
): TranslatedString;

export function translate(
  key: string,
  fallback: string,
  sub?: Record<string, number | string | undefined>
): TranslatedString;

export function translate(key: string, fallback?: string): TranslatedString;

export function translate(...args: any): TranslatedString {
  const keyOrObject = args[0];
  if (typeof keyOrObject === "string") {
    if (typeof args[1] === "string") {
      const possibleSubstitutions = args[2];
      if (possibleSubstitutions && typeof possibleSubstitutions === "object") {
        const translated = i18n.t(keyOrObject, args[1], {
          ...possibleSubstitutions,
          returnDetails: false,
        });

        if (typeof translated !== "string") {
          throw new Error("Unexpected details object from i18n.t");
        }

        return translated as TranslatedString;
      }
      return i18n.t(keyOrObject, args[1]) || (args[1] as TranslatedString);
    }
    return i18n.t(...args) || ("" as TranslatedString);
  }
  if (typeof keyOrObject === "object") {
    // htmlStr is for legacy support for `web` module:
    const { str, htmlStr, fallback, subs } = keyOrObject;
    const res = i18n.t(str || htmlStr, fallback, {
      ...subs,
      returnDetails: false,
    });
    if (typeof res === "object") {
      throw new Error("Translation object returned");
    }
    return res as TranslatedString;
  }
  return "" as TranslatedString;
}
