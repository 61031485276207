import { FunctionComponent, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

/** $T: You should not use this component directly, it should only show up in
 * compiled code as a way of avoiding insertion of useTranslation hooks.
 */
export const $T: FunctionComponent<
  PropsWithChildren<{
    k: string;
    n: string;
    d: string;
    s: Record<string, string>;
  }>
> = ({ k, s, n, d }) => {
  const { t } = useTranslation(n, { useSuspense: false });
  return <>{t(k, d, s)}</>;
};
