import { errorTypeMaker } from "@web-monorepo/shared/errors/errorTypeMaker";

const errors = {
  api: {
    auth: errorTypeMaker("api-auth"),
    down: errorTypeMaker("api-down"),
    unknown: errorTypeMaker("api-unknown"),
    rateLimit: errorTypeMaker("api-rateLimit"),

    // note: not for all 403s and 404s, just for ones that should redirect you to a special page.
    forbidden: errorTypeMaker("api-forbidden"),
    notFound: errorTypeMaker("api-notFound"),
    timeout: errorTypeMaker("api-timeout"),
  },

  login: {
    invalid: errorTypeMaker("login-invalid"),
    lockout: errorTypeMaker("login-lockout"),
    email: errorTypeMaker("login-email"),
    password: errorTypeMaker("login-password"),
    parent: errorTypeMaker("login-parent"),
    student: errorTypeMaker("login-student"),
    session: errorTypeMaker("login-session"),
    suspended: errorTypeMaker("login-suspended"),
    code: errorTypeMaker("login-code"),
    forceResetPassword: errorTypeMaker("otc-compromisedPassword"),
    mustUseSso: errorTypeMaker<{ oidcIssuer: string | undefined }>("login-mustUseSso"),
    mustUseOtc: errorTypeMaker<{ email: string | undefined }>("login-mustUseOtc"),
    userCancelledOTPEntry: errorTypeMaker("login-userCancelledOTPEntry"),
  },
  signup: {
    email: errorTypeMaker("signup-email"),
  },
  reset: {
    email: errorTypeMaker("reset-email"),
    expired: errorTypeMaker("reset-expired"),
  },
  class: {
    session: errorTypeMaker("class-session"),
  },
  invitation: {
    carrier: errorTypeMaker("invitation-carrier"),
    invalid: errorTypeMaker("invitation-invalid"),
    landline: errorTypeMaker("invitation-landline"),
    failure: errorTypeMaker("invitation-failure"),
    notFound: errorTypeMaker("invitation-notFound"),
  },
  teacher: {
    emailAlreadyExist: errorTypeMaker("teacher-emailAlreadyExist"),
    commonPassword: errorTypeMaker("teacher-commonPassword"),
    wrongPassword: errorTypeMaker("teacher-wrongPassword"),
  },
  number: {
    notVerified: errorTypeMaker("number-notVerified"),
    invalid: errorTypeMaker("number-invalid"),
  },
  school: {
    notFound: errorTypeMaker("school-notFound"),
  },
  story: {
    forbidden: errorTypeMaker("story-forbidden"),
  },
  report: {
    genericError: errorTypeMaker("report-genericError"),
  },

  connectionRequests: {
    // n-student already has a student user
    studentTaken: errorTypeMaker("connectionRequests-studentTaken"),
  },
  studentMerge: {
    invalid: errorTypeMaker("studentMerge-invalid"),
  },
};

export default errors;
