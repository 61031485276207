import { PodInstallFunction } from "@web-monorepo/shared/podInfra";
import produce from "immer";

//
// -----------------------------------
// STATE
//
const STATE_KEY = "ui";

type UIState = {
  tabOpen: boolean;
};

type State = {
  [STATE_KEY]: UIState;
};

const initialState: UIState = {
  // Is the user looking at this tab?
  tabOpen: true,
};

//
// -----------------------------------
// ACTIONS
//
const Types = {
  TOGGLE_TAB: "TOGGLE_TAB",
};

//
// -----------------------------------
// ACTION CREATORS
//

export function toggleTab(open: boolean) {
  return {
    type: Types.TOGGLE_TAB,
    payload: { open },
    meta: { description: "focus or blur on classdojo tab" },
  };
}

//
// ----------------------------
// HANDLERS
//
const uiReducer = produce((draft, action) => {
  if (action.type === Types.TOGGLE_TAB) {
    draft.tabOpen = action.payload.open;
  }
}, initialState);

//
// -----------------------------------------
// SELECTORS
//
//

export const selectIsTabOpen = (state: State) => !!state?.[STATE_KEY]?.tabOpen;

//
// -------------------------------
// Setup
//
const install: PodInstallFunction = (installReducer) => {
  installReducer(STATE_KEY, uiReducer);
};

export default install;
