import { useEffect } from "react";
import { translate } from "../../utils/translate";

type MainContentWrapperProps = {
  children: React.ReactNode;
  className?: string;
};

const MAIN_CONTENT_ID = "skip-to-main-content-target";

/**
 * For accessibility reasons, keyboard users need to be able to skip to the main content.
 * This wrapper provides a dynamically injected "Skip to Main Content" link for accessibility.
 * Use this wrapper every time you add a new route.
 */
function MainContentWrapper({ className, children }: MainContentWrapperProps) {
  const handleClick = () => {
    const mainContent = document.getElementById(MAIN_CONTENT_ID);
    if (mainContent) {
      mainContent.setAttribute("tabIndex", "-1");
      mainContent.focus();
    }
  };

  useEffect(() => {
    const pageBody = document.body;

    const skipToMainContentLink = document.createElement("a");
    skipToMainContentLink.href = `#${MAIN_CONTENT_ID}`;
    skipToMainContentLink.onclick = (e) => {
      e.preventDefault();
      handleClick();
    };
    skipToMainContentLink.textContent = translate({
      str: "dojo.common:actions.skip_to_main",
      fallback: "SKIP TO MAIN CONTENT",
    });
    skipToMainContentLink.className = "skip-to-main-content-link";
    skipToMainContentLink.setAttribute("id", "skip-to-main-content-link");

    pageBody.prepend(skipToMainContentLink);
    return () => {
      skipToMainContentLink.remove();
    };
  }, []);
  return (
    <main
      className={className}
      id={MAIN_CONTENT_ID}
      onBlur={(e) => {
        if (e.currentTarget === e.target) {
          e.currentTarget.removeAttribute("tabIndex");
        }
      }}
    >
      {children}
    </main>
  );
}

export default MainContentWrapper;

const skipLinkStyles = `
  #skip-to-main-content-link {
    position: fixed;
    top: 0;
    left: 0;
    background: #eaecf5;
    color: #2c2a50;
    font-weight: bold;
    padding: 12px;
    font-size: 16px;
    border: none;
    text-decoration: underline;
    z-index: 1000;
    transform: translateY(-100%); /* Hidden by default */
    transition: transform 200ms linear;
  }

  #skip-to-main-content-link:focus,
  #skip-to-main-content-link:hover {
    transform: translateY(0); /* Visible when focused or hovered */
  }
`;

if (typeof document !== "undefined") {
  if (!document.getElementById("skip-to-main-content-styles")) {
    const styleTag = document.createElement("style");
    styleTag.id = "skip-to-main-content-styles";
    styleTag.innerHTML = skipLinkStyles;
    document.head.appendChild(styleTag);
  }
}
