import * as React from "react";
import * as SelectPrimitive from "@radix-ui/react-select";
import { IconCheck, IconTriangleDown } from "@web-monorepo/dds-icons";
import classes from "./select.module.css";

/** DDSSelect - based off of [ShadCN](https://ui.shadcn.com/docs/components/select). See [storybook](https://components.classdojo.com/?path=/story/components-ddsselect--default) for usage. */
const DDSSelectRoot: React.FC<{
  /** The controlled value of the select. Must be used in conjunction with onValueChange */
  value?: string;
  /** The value of the select when initially rendered. Use when you do not need to control the state of the select */
  defaultValue?: string;
  /** Event handler called when the value changes */
  onValueChange?(value: string): void;
  /** The controlled open state of the select. Must be used in conjunction with onOpenChange */
  open?: boolean;
  /** The open state of the select when it is initially rendered. Use when you do not need to control its open state */
  defaultOpen?: boolean;
  /** Event handler called when the open state of the select changes */
  onOpenChange?(open: boolean): void;
  /** The size of the select component */
  size?: "small" | "medium" | "large";
  /** The reading direction of text in the select. If omitted, inherits globally from DirectionProvider or assumes LTR (left-to-right) reading mode */
  dir?: SelectPrimitive.Direction;
  /** The name of the select. Submitted with its owning form as part of a name/value pair */
  name?: string;
  /** The autocomplete attribute of the select */
  autoComplete?: string;
  /** When true, prevents the user from interacting with the select */
  disabled?: boolean;
  /** When true, indicates that the user must select a value before the owning form can be submitted */
  required?: boolean;
  /** The content of the select - usually SelectTrigger and SelectContent */
  children?: React.ReactNode;
  /** Additional className to apply to the select root */
  className?: string;
}> = ({ size = "medium", className, ...props }) => (
  <SelectPrimitive.Root {...props}>
    <div className={[classes[size], className].filter(Boolean).join(" ")}>{props.children}</div>
  </SelectPrimitive.Root>
);

const DDSSelectGroup = SelectPrimitive.Group;

const DDSSelectValue = SelectPrimitive.Value;

const DDSSelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Trigger ref={ref} className={[classes.trigger, className].filter(Boolean).join(" ")} {...props}>
    {children}
    <SelectPrimitive.Icon asChild>
      <IconTriangleDown className={classes.icon} size="m" />
    </SelectPrimitive.Icon>
  </SelectPrimitive.Trigger>
));
DDSSelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const DDSSelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = "popper", ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      className={[classes.content, className].filter(Boolean).join(" ")}
      position={position}
      {...props}
    >
      <SelectPrimitive.Viewport className={classes.viewport}>{children}</SelectPrimitive.Viewport>
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
));
DDSSelectContent.displayName = SelectPrimitive.Content.displayName;

const DDSSelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label ref={ref} className={[classes.label, className].filter(Boolean).join(" ")} {...props} />
));
DDSSelectLabel.displayName = SelectPrimitive.Label.displayName;

const DDSSelectItem: React.ForwardRefExoticComponent<
  {
    value: string;
    disabled?: boolean;
    textValue?: string;
    children?: React.ReactNode;
  } & React.RefAttributes<HTMLDivElement>
> = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item ref={ref} className={[classes.item, className].filter(Boolean).join(" ")} {...props}>
    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    <SelectPrimitive.ItemIndicator>
      <IconCheck className={classes.icon} size="m" />
    </SelectPrimitive.ItemIndicator>
  </SelectPrimitive.Item>
));
DDSSelectItem.displayName = SelectPrimitive.Item.displayName;

const DDSSelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator
    ref={ref}
    className={[classes.separator, className].filter(Boolean).join(" ")}
    {...props}
  />
));
DDSSelectSeparator.displayName = SelectPrimitive.Separator.displayName;

export {
  DDSSelectRoot as DDSSelect,
  DDSSelectGroup,
  DDSSelectValue,
  DDSSelectTrigger,
  DDSSelectContent,
  DDSSelectLabel,
  DDSSelectItem,
  DDSSelectSeparator,
};
