import assert from "assert";
import { useParams, useSearchParams } from "react-router-dom";

export const NEW_CLASS_SEARCH_PARAM = "newlyCreatedClass";

export const isNewClass = () => {
  const hash = window.location.hash;
  // in development the querystring comes in the hash due to the anchor #
  const hashSearch = hash.indexOf("?") !== -1 ? hash.substring(hash.indexOf("?")) : "";
  const search = window.location.search || hashSearch;
  const params = new URLSearchParams(search);

  return !!params.get(NEW_CLASS_SEARCH_PARAM);
};

export const classStateMetadata = () => {
  return { class_status: isNewClass() ? "class_created" : "class_edit" };
};

type StandardTab = "basicInfo" | "students" | "parents" | "behaviors" | "sharing" | "settings";
export type TabContainerName = StandardTab | "parentsNewClass";

function assertIsMaybeTab(tab: null | string): null | TabContainerName {
  assert(
    tab === null ||
      tab === "basicInfo" ||
      tab === "students" ||
      tab === "parents" ||
      tab === "behaviors" ||
      tab === "sharing" ||
      tab === "settings" ||
      tab === "parentsNewClass",
    `Invalid tab ${tab}`,
  );
  return tab as null | TabContainerName;
}

export function useEditClassModalSearchParams() {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const isNewClass = searchParams.has(NEW_CLASS_SEARCH_PARAM);

  let tab = searchParams.get("editClass");
  if (tab === "parents" && isNewClass) {
    tab = "parentsNewClass";
  }

  const classroomId = params.classroomId || searchParams.get("editClassId");
  if (tab) {
    assert(classroomId, "?editClassId must be set when editClass is set if no classroomId is in the URL");
  }

  const openWithClass = (opts: { tab: TabContainerName; classroomId: string }) =>
    setSearchParams((params) => {
      const nextParams = new URLSearchParams(params);
      nextParams.set("editClass", opts.tab);
      nextParams.set("editClassId", opts.classroomId);
      return nextParams;
    });

  const setTab = (tab: TabContainerName) =>
    setSearchParams((params) => {
      const nextParams = new URLSearchParams(params);
      nextParams.set("editClass", tab);
      return nextParams;
    });

  const onRequestHide = () =>
    setSearchParams((params) => {
      const nextParams = new URLSearchParams(params);
      nextParams.delete("editClass");
      nextParams.delete("editClassId");
      return nextParams;
    });

  const isOpen = !!tab && !!classroomId;

  return { isOpen, classroomId, setTab, openWithClass, onRequestHide, tab: assertIsMaybeTab(tab) };
}
