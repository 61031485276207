import React, { FC, KeyboardEventHandler } from "react";
import ReactSelect, { components as ReactSelectComponents, GroupBase, OptionProps, MultiValue } from "react-select";
import type { ActionMeta } from "react-select";
import CreatableReactSelect from "react-select/creatable";
import { NessieThemeProvider, theme } from "../../nessie";

export type ChipSelectOptionComponent = typeof ReactSelectComponents.Option;
export type ChipSelectMenuListComponent = typeof ReactSelectComponents.MenuList;
export type ChipSelectMultiValueComponent<T> = MultiValue<T>;
export type ChipSelectNoOptionsComponent = typeof ReactSelectComponents.NoOptionsMessage;

export type SelectOption<T> = { label?: string; value: T };

type MultiSelectProps<T> = {
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  ["data-name"]?: string;
  ["aria-label"]?: string;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement> | undefined;
  autoFocus?: boolean;
  hideArrow?: boolean;
  isClearable?: boolean;
  isSearchable?: boolean;
  openOnFocus?: boolean;
  options?: SelectOption<T>[];
  placeholder?: string;
  tether?: boolean;
  value?: MultiValue<SelectOption<T>>;
  filterOption?: (option: SelectOption<T>, rawInput: string) => boolean;
  menuListComponent?: ChipSelectMenuListComponent;
  multiValueComponent?: FC<any>;
  noOptionsMessage?: ChipSelectNoOptionsComponent;
  onBlur?: () => void;
  onChange?: (value: MultiValue<SelectOption<T>>) => void;
  onInputChange?: (value: string) => void;
  onFocus?: () => void;
  optionComponent?: (
    props: OptionProps<
      {
        value: any;
      },
      any,
      GroupBase<{
        value: any;
      }>
    >,
  ) => React.ReactElement<any, any> | null;
};

export const MultiSelect = function <T>({
  autoFocus,
  isClearable,
  isSearchable,
  options = [],
  onKeyDown,
  placeholder,
  value,
  filterOption,
  menuListComponent,
  multiValueComponent,
  noOptionsMessage,
  optionComponent,
  onChange,
  onInputChange,
  ...props
}: MultiSelectProps<T>) {
  let customComponents: Record<string, React.FC<any>> = {
    IndicatorSeparator: () => null,
  };

  if (optionComponent) {
    customComponents = { ...customComponents, Option: optionComponent };
  }

  if (menuListComponent) {
    customComponents = { ...customComponents, MenuList: menuListComponent };
  }

  if (multiValueComponent) {
    customComponents = { ...customComponents, MultiValue: multiValueComponent };
  }

  if (noOptionsMessage) {
    customComponents = { ...customComponents, NoOptionsMessage: noOptionsMessage };
  }

  const _onChange = (newValue: MultiValue<SelectOption<T>>, actionMeta: ActionMeta<SelectOption<T>>) => {
    if (!onChange) return;

    if (!value) return onChange(newValue);

    if (actionMeta?.action === "remove-value") {
      onChange(value?.filter((t) => t !== actionMeta?.removedValue));
    }

    if ((actionMeta?.action === "select-option" || actionMeta?.action === "deselect-option") && actionMeta?.option) {
      return onChange([...value, actionMeta.option]);
    }

    return onChange(newValue);
  };

  const _filterOption = ({ data }: { data: SelectOption<T> }, rawInput: string) => {
    return filterOption?.(data, rawInput) ?? true;
  };

  return (
    <NessieThemeProvider>
      <ReactSelect
        aria-label={props["aria-label"]}
        autoFocus={autoFocus}
        components={customComponents}
        filterOption={_filterOption}
        isClearable={isClearable}
        isMulti={true}
        isSearchable={isSearchable}
        onChange={_onChange}
        options={options}
        placeholder={placeholder}
        styles={getStyles(true)}
        hideSelectedOptions={false}
        value={value}
        data-name={props["data-name"]}
        data-some-other-thing={"whateverj"}
        tabSelectsValue={false}
        onInputChange={onInputChange}
        //   prevent modal from closing when pressing escape to close select options
        onKeyDown={(e) => {
          if (e.key === "Escape") {
            e.stopPropagation();
          }
          if (onKeyDown) {
            onKeyDown(e);
          }
        }}
      />
    </NessieThemeProvider>
  );
};

export const CreatableSelect = function ({
  autoFocus,
  isClearable,
  placeholder,
  value,
  multiValueComponent,
  onChange,
  onFocus,
  onBlur,
  ...props
}: MultiSelectProps<string>) {
  const [internalValue, setInternalValue] = React.useState(value || []);
  const [inputValue, setInputValue] = React.useState("");

  const handleChange = (newValue: MultiValue<SelectOption<string>>, actionMeta: ActionMeta<SelectOption<string>>) => {
    if (!onChange) return setInternalValue(newValue);

    if (actionMeta?.action === "remove-value") {
      const calculatedValue = internalValue?.filter((t) => t !== actionMeta?.removedValue) ?? [];
      setInternalValue(calculatedValue);
      onChange(calculatedValue);
      return;
    }

    if ((actionMeta?.action === "select-option" || actionMeta?.action === "deselect-option") && actionMeta?.option) {
      const calculatedValue = [...(internalValue ?? []), actionMeta.option];
      setInternalValue(calculatedValue);
      onChange(calculatedValue);
    }
    return onChange(newValue);
  };

  const handleInputChange = (inputValue: string) => {
    setInputValue(inputValue);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (!inputValue || !internalValue) return;

    switch (event.key) {
      case "Enter":
      case "Tab":
      case ",":
        setInputValue("");
        setInternalValue([...internalValue, { label: inputValue, value: inputValue }]);
        onChange && onChange([...internalValue, { label: inputValue, value: inputValue }]);
        event.preventDefault();
        break;
      default:
        return;
    }
  };

  let customComponents: Record<string, React.FC> = {
    IndicatorSeparator: () => null,
    DropdownIndicator: () => null,
  };

  if (multiValueComponent) {
    customComponents = { ...customComponents, MultiValue: multiValueComponent };
  }

  return (
    <NessieThemeProvider>
      <CreatableReactSelect
        aria-label={props["aria-label"]}
        aria-labelledby="aria-label"
        autoFocus={autoFocus}
        components={customComponents}
        inputValue={inputValue}
        isClearable={isClearable}
        isMulti={true}
        onBlur={onBlur}
        menuIsOpen={false}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onInputChange={handleInputChange}
        onFocus={onFocus}
        placeholder={placeholder}
        styles={getStyles(false)}
        value={internalValue}
        data-name={props["data-name"]}
      />
    </NessieThemeProvider>
  );
};

const SELECT_HEIGHT = "60px";

const getStyles = (
  wrap: boolean,
): Record<string, (provided: React.CSSProperties, state: { isFocused: boolean }) => React.CSSProperties> => ({
  container: (provided) => ({
    ...provided,
    minWidth: "100%",
  }),
  control: (_provided, state) => {
    return {
      fontSize: 18,
      lineHeight: "22px",
      letterSpacing: "-.25px",
      fontWeight: 600,
      border: "2px solid",
      borderColor: state.isFocused ? theme.colors.dt_border_active : theme.colors.dt_border_stylistic,
      borderRadius: theme.radii.dt_radius_l,
      minHeight: SELECT_HEIGHT,
      color: theme.colors.dt_content_primary,
      boxShadow: undefined,
      boxSizing: "border-box",
      cursor: "default",
      display: "flex",
      justifyContent: "space-between",
      label: "control",
      transition: "all 100ms",
      minWidth: "100%",
      paddingLeft: theme.space.dt_l,
      paddingRight: theme.space.dt_l,
      backgroundColor: theme.colors.dt_background_primary,
    };
  },
  input: (provided) => ({
    ...provided,
    color: theme.colors.dt_content_primary,
    wordWrap: "break-word",
    wordBreak: "break-all",
  }),
  valueContainer: (provided) => ({
    ...provided,
    flexWrap: wrap ? "wrap" : "nowrap",
    overflowX: wrap ? "hidden" : "auto",
    overflowY: "hidden",
    alignItems: "center",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: theme.colors.dt_content_tertiary,
    margin: "0",
  }),
  dropdownIndicator: () => ({
    paddingRight: theme.space.dt_l,
    color: theme.colors.dt_content_tertiary,
    lineHeight: 0,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  menu: (provided) => ({
    ...provided,
    border: "2px solid",
    borderColor: "#D3D7EC",
    boxSizing: "border-box",
    marginTop: "7px",
    boxShadow: "0px 6px 0px rgba(45, 64, 150, 0.06)",
    borderRadius: theme.radii.dt_radius_s,
    overflow: "hidden",
  }),
  menuList: (provided) => ({
    ...provided,
    borderRadius: theme.radii.dt_radius_s,
    padding: 0,
  }),
  option: () => ({
    color: theme.colors.dt_content_tertiary,
    fontSize: "15px",
    fontWeight: 600,
    paddingLeft: theme.space.dt_m,
    paddingTop: theme.space.dt_s,
    paddingBottom: theme.space.dt_s,
    ":hover": {
      backgroundColor: theme.colors.dt_background_tertiary,
    },
  }),
  singleValue: () => ({
    marginBottom: 0,
    marginLeft: 0,
  }),
});
