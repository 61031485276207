const ENFORCE_LAST_NAME_LANGUAGES = [
  "en", // English
  "es", // Spanish
  "fr", // French
  "tr", // Turkish
  "nl", // Dutch
  "pt", // Portugese
  "ca", // Catalan
  "vi", // Vietnamese
  "ru", // Russian
  "de", // German
  "lt", // Lithuanian
];

export function shouldEnforceLastNames(locale: string) {
  const language = (locale || "en").split("-")[0];
  return contains(ENFORCE_LAST_NAME_LANGUAGES, language.toLowerCase());
}

const LAST_NAME_EXCEPTION_COUNTRIES = [
  "kr", // S. Korea
  "cn", // China
  "hk", // Hong Kong
  "tw", // Taiwan
  "sg", // Singapore
];

type UserWithLocale = { locale?: string; countryCode?: string };
export function shouldEnforceLastNameForUser(user: UserWithLocale = {}) {
  const { locale = "en", countryCode = "us" } = user;
  if (!shouldEnforceLastNames(locale)) return false;
  return !contains(LAST_NAME_EXCEPTION_COUNTRIES, countryCode.toLowerCase());
}

export const shouldEnforceLastNameForParent = shouldEnforceLastNameForUser;
export const shouldEnforceLastNameForTeacher = shouldEnforceLastNameForUser;

function contains(haystack: string[], needle: string) {
  return haystack.indexOf(needle) !== -1;
}

const ZENDESK_LOCALE_MAP: { [key: string]: string } = {
  "en-US": "en-us",
  "en-GB": "en-gb",
  "es-MX": "es-mx",
  "fr-FR": "fr",
  "fr-CA": "fr-ca",
  "ja-JP": "ja",
  "ko-KR": "ko",
  "pt-PT": "pt",
  "pt-BR": "pt-br",
  "tr-TR": "tr",
};

export function getZendeskUrlWithLocale(locale = "en-US", article?: string) {
  return `https://classdojo.zendesk.com/hc/${ZENDESK_LOCALE_MAP[locale]}${article ? `/${article}` : ""}`;
}
