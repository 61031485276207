import { StudentAvatar } from "@classdojo/web";
import { ThemeUIStyleObject, RAW_cssValue } from "@classdojo/web/nessie/stylingLib";
import { translate, type TranslatedString } from "app/utils/translate";
import difference from "lodash/difference";
import GroupAvatar from "app/pods/points/components/GroupAvatar";
import { Group } from "app/pods/points/components/modals/awardModal/types";
import { ClassStudent } from "app/pods/student";
import { publicFullName } from "app/utils/name";
import { requireAndPreloadOnRoute } from "app/utils/preloadImage";
import { getImageSource } from "app/utils/Image";

const studentAvatarStyle: ThemeUIStyleObject = {
  height: "14rem",
  width: "14rem",
};

const groupAvatarStyle: ThemeUIStyleObject = {
  position: "relative",
  height: "10rem",
};

const multiAwardIconStyle: ThemeUIStyleObject = {
  height: "14rem",
  marginLeft: RAW_cssValue("2rem"),
};

const multiAwardIcon = (
  <img
    src={requireAndPreloadOnRoute("assets/images/points/whole_class.png", "points")}
    sx={multiAwardIconStyle}
    alt=""
  />
);

// eslint-disable-next-line react-refresh/only-export-components
const STRINGS = {
  ONE_STUDENT: {
    MODAL(student: ClassStudent) {
      return translate({
        str: "dojo.teacher_web:class_view.give_feedback_single",
        subs: { studentName: publicFullName(student) },
      });
    },

    POPUP(student: ClassStudent) {
      return {
        title: publicFullName(student),
        image: <StudentAvatar src={student.avatar} sx={studentAvatarStyle} />,
        imageSrc: student.avatar,
      };
    },
  },
  MULTIPLE_STUDENTS: {
    MODAL(count: number) {
      return translate({
        str: "dojo.teacher_web:class_view.give_feedback_multiple",
        subs: { count },
      });
    },

    POPUP(count: number) {
      return {
        title: translate({
          str: "dojo.teacher_web:class_view.n_students",
          subs: { count },
        }),
        image: multiAwardIcon,
        imageSrc: getImageSource("assets/images/points/whole_class.png"),
      };
    },
  },
  ONE_GROUP: {
    MODAL(group: Group, _students?: ClassStudent[]) {
      return translate({
        str: "dojo.teacher_web:class_view.give_feedback_single",
        subs: { studentName: group.name },
      });
    },

    POPUP(group: Group, students: ClassStudent[]) {
      return {
        title: group.name,
        image: (
          <div sx={groupAvatarStyle}>
            <GroupAvatar students={students} />
          </div>
        ),
        imageSrc: getImageSource("assets/images/points/whole_class.png"),
      };
    },
  },
  MULTIPLE_GROUPS: {
    MODAL(count: number) {
      return translate({
        str: "dojo.teacher_web:class_view.give_feedback_to_groups",
        subs: { count },
      });
    },

    POPUP(count: number) {
      return {
        title: translate({
          str: "dojo.teacher_web:class_view.n_groups",
          subs: { count },
        }),
        image: multiAwardIcon,
      };
    },
  },
  NO_STUDENTS: {
    MODAL() {
      return translate("dojo.teacher_web:class_view.give_points");
    },

    POPUP() {
      return {
        title: null,
        image: null,
      };
    },
  },
};

/*
  This logic is shared between the point awarded popup and the give points modal.
  However, they use slightly different strings.  This util helps keep the logic
  consistent while still returning the correct string.
*/

const modes = { MODAL: "MODAL", POPUP: "POPUP" } as const;

type AwardInput = {
  groups: { studentIds?: string[]; _id: string; name: string }[];
  students: ClassStudent[];
};

type PopupReturn = {
  title: string | null;
  image: JSX.Element | null;
  imageSrc?: string;
};

function getAwardContext(award: AwardInput) {
  let { groups, students } = award;

  students = students.filter((s) => s);
  groups = groups.filter((g) => g);

  const numGroups = groups.length;
  const numStudents = students.length;

  const studentIds = students.map(({ _id }) => _id);
  const groupStudentIds = [...new Set(groups.flatMap(({ studentIds }) => studentIds))];

  const allStudentsAreGroupStudents = difference(studentIds, groupStudentIds).length === 0;

  return {
    groups,
    students,
    numGroups,
    numStudents,
    allStudentsAreGroupStudents,
  };
}

export function modalDescriptor(award: AwardInput): TranslatedString {
  const { groups, students, numGroups, numStudents, allStudentsAreGroupStudents } = getAwardContext(award);

  const mode = modes.MODAL;

  if (allStudentsAreGroupStudents && numGroups === 1) {
    return STRINGS.ONE_GROUP[mode](groups[0], students);
  } else if (allStudentsAreGroupStudents && numGroups > 1) {
    return STRINGS.MULTIPLE_GROUPS[mode](numGroups);
  } else if (numStudents === 1) {
    return STRINGS.ONE_STUDENT[mode](students[0]);
  } else if (numStudents > 1) {
    return STRINGS.MULTIPLE_STUDENTS[mode](numStudents);
  } else {
    return STRINGS.NO_STUDENTS[mode]();
  }
}

export function popupDescriptor(award: AwardInput): PopupReturn {
  const { groups, students, numGroups, numStudents, allStudentsAreGroupStudents } = getAwardContext(award);

  const mode = modes.POPUP;

  if (allStudentsAreGroupStudents && numGroups === 1) {
    return STRINGS.ONE_GROUP[mode](groups[0], students);
  } else if (allStudentsAreGroupStudents && numGroups > 1) {
    return STRINGS.MULTIPLE_GROUPS[mode](numGroups);
  } else if (numStudents === 1) {
    return STRINGS.ONE_STUDENT[mode](students[0]);
  } else if (numStudents > 1) {
    return STRINGS.MULTIPLE_STUDENTS[mode](numStudents);
  } else {
    return STRINGS.NO_STUDENTS[mode]();
  }
}
