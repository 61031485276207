import type { FunctionComponent, PropsWithChildren } from "react";
import * as Classes from "./DDSDisplayText.module.css";

export type DDSDisplayTextProps = {
  as?: "h1" | "h2" | "h3" | "h4" | "h5";
  level?: 1 | 2 | 3 | 4;
  className?: string;
};

export const DDSDisplayText: FunctionComponent<PropsWithChildren<DDSDisplayTextProps>> = ({
  as: tagName = "h1",
  level = 1,
  className,
  children,
}) => {
  const Tag = tagName;
  return <Tag className={`${className ?? ""} ${Classes[`display${level}`]}`}>{children}</Tag>;
};
