// adapted from http://bgrins.github.io/devtools-snippets/#console-save
// and copied from Mojo
export default function downloadFile({
  contents,
  filename,
  type,
}: {
  contents: string;
  filename: string;
  type: string;
}) {
  let blob: Blob | null = new Blob([contents], {
    type,
  });

  const URL = window.URL || window.webkitURL;
  const evt = document.createEvent("MouseEvents");
  const link = document.createElement("a");
  link.download = filename.replace(/\//g, "_").replace(/ /g, "_");
  const url = (link.href = URL.createObjectURL(blob));
  link.dataset.downloadurl = [type, link.download, link.href].join(":");
  evt.initMouseEvent("click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
  link.dispatchEvent(evt);
  // http://stackoverflow.com/questions/20654531/ie-frees-blob-urls
  return setTimeout(function () {
    URL.revokeObjectURL(url);
    blob = null;
  }, 1000);
}
