import { useMemo } from "react";
import type { Simplify } from "type-fest";
import callApi from "@web-monorepo/infra/callApi";
import {
  APIRequestBody,
  APIRequestParameters,
  APIResponse,
  CollectionFetcherParamsType,
  CollectionFetcherReturnType,
  MemberFetcherReturnType,
} from "@web-monorepo/shared/api/apiTypesHelper";
import {
  makeCollectionQuery,
  makeMemberQuery,
  makeMutation,
  NOOP,
  WAITING_FOR_DEPENDENCIES,
} from "@web-monorepo/shared/reactQuery";
import find from "lodash/find";
import {
  CombinedTargetTypeFetcher,
  useClassStoryPostsFetcher,
  useSchoolStoryPostsFetcher,
  useStoryPostFetcher,
} from "app/pods/story";
import { useStudentsFetcher } from "app/pods/student/fetchers";
import { useSchoolStudentsFetcher } from "app/pods/schoolStudents";
import { SignupResponseWithStudent } from "./components/CalendarEventComposer/components/form-segments/SignUps/models";

export type CalendarEventsCollectionItem = CollectionFetcherReturnType<typeof useClassCalendarEventsFetcher>;

// TODO: TSM: Calendar Events have 2 diferent :targetTypes (student and class), but both use the same response interface.
export type CalendarEventMemberFetcherResponse = MemberFetcherReturnType<typeof useSchoolCalendarEventMemberFetcher>;
type CreateCalendarEventOperationRequestBody = APIRequestBody<"/api/dojoClass/{targetId}/calendarEvent", "post">;
export type CreateCalendarEventOperationResponse = APIResponse<"/api/dojoClass/{targetId}/calendarEvent", "post">;
type EditCalendarEventOperationRequestBody = APIRequestBody<"/api/dojoClass/{targetId}/calendarEvent/{eventId}", "put">;
export type EditCalendarEventOperationResponse = APIResponse<
  "/api/dojoClass/{targetId}/calendarEvent/{eventId}",
  "put"
>;
type CreateCalendarEventCommentOperationRequestBody = APIRequestBody<
  "/api/dojoClass/{classId}/calendarEvent/{eventId}/comments",
  "post"
>;

type CalendarEventsParams = {
  targetType: "dojoClass" | "dojoSchool";
  targetId: string;
};

export const useClassCalendarEventsFetcher = makeCollectionQuery({
  path: "/api/dojoClass/{targetId}/calendarEvent",
  query: { hidePastEvents: "true" },
  queryParams: ["hideEventsAfterDate"],
  fetcherName: "fetchClassCalendarEvents",
});

export const useSchoolCalendarEventsFetcher = makeCollectionQuery({
  path: "/api/dojoSchool/{targetId}/calendarEvent",
  query: { hidePastEvents: "true" },
  queryParams: ["hideEventsAfterDate"],
  fetcherName: "fetchSchoolCalendarEvents",
});

const _usePastClassCalendarEventsInternalFetcher = makeCollectionQuery({
  path: "/api/class/{classId}/past-calendar-event",
  fetcherName: "fetchPastClassCalendarEvents",
  queryParams: ["before", "limit"],
});

const _usePastSchoolCalendarEventsInternalFetcher = makeCollectionQuery({
  path: "/api/school/{schoolId}/past-calendar-event",
  fetcherName: "fetchPastSchoolCalendarEvents",
  queryParams: ["before", "limit"],
});

type UsePastCalendarEventsParams = {
  targetType: "class" | "school";
  targetId: string;
  before?: string;
  limit?: string;
};

export const usePastCalendarEventsFetcher: CombinedTargetTypeFetcher<
  UsePastCalendarEventsParams,
  typeof _usePastClassCalendarEventsInternalFetcher | typeof _usePastSchoolCalendarEventsInternalFetcher,
  "/api/class/{classId}/past-calendar-event" | "/api/school/{schoolId}/past-calendar-event"
> = (params, options) => {
  let classParams: CollectionFetcherParamsType<typeof _usePastClassCalendarEventsInternalFetcher> = NOOP;
  let schoolParams: CollectionFetcherParamsType<typeof _usePastSchoolCalendarEventsInternalFetcher> = NOOP;

  if (params === NOOP || params === WAITING_FOR_DEPENDENCIES) {
    classParams = params;
    schoolParams = params;
  } else if (params.targetType === "class") {
    classParams = { classId: params.targetId, before: params.before, limit: params.limit };
  } else if (params.targetType === "school") {
    schoolParams = { schoolId: params.targetId, before: params.before, limit: params.limit };
  } else {
    throw new Error(`Invalid calendar event type: ${params.targetType}`);
  }

  const classResults = _usePastClassCalendarEventsInternalFetcher(classParams, options);
  const schoolResults = _usePastSchoolCalendarEventsInternalFetcher(schoolParams, options);

  return params === NOOP || params === WAITING_FOR_DEPENDENCIES || params.targetType === "class"
    ? classResults
    : schoolResults;
};

export interface CreateCalendarEventOperationParams
  extends CalendarEventsParams,
    Omit<CreateCalendarEventOperationRequestBody, "startDateTime" | "endDateTime" | "timezone"> {
  startDateTime: Date;
  endDateTime: Date;
}

// TODO: TSM: expecting dates to be moment types as opposed to strings we have on the API response type, so overriding here.
export const useCreateCalendarEventOperation = makeMutation<
  CreateCalendarEventOperationParams,
  CreateCalendarEventOperationResponse
>({
  name: "createClassCalendarEvent",
  fn: async ({
    targetType,
    targetId,
    title,
    description,
    startDateTime,
    endDateTime,
    allDayEvent,
    remindersToCreate,
    bannerUrl,
    signups,
  }) => {
    try {
      return await callApi({
        method: "POST",
        path: `/api/${targetType}/${targetId}/calendarEvent`,
        body: {
          title,
          description,
          startDateTime: startDateTime.toISOString(),
          endDateTime: endDateTime.toISOString(),
          allDayEvent,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          remindersToCreate,
          bannerUrl,
          signups,
        },
      });
      // eslint-disable-next-line no-catch-all/no-catch-all
    } catch (ex: any) {
      return ex;
    }
  },
  onSuccess: (_data, params) => {
    useClassCalendarEventsFetcher.invalidateQueries({ targetId: params.targetId });
    useSchoolCalendarEventsFetcher.invalidateQueries({ targetId: params.targetId });
    useUpcomingClassCalendarEventsFetcher.invalidateQueries({ targetId: params.targetId });
    useUpcomingSchoolCalendarEventsFetcher.invalidateQueries({ targetId: params.targetId });
    useClassStoryPostsFetcher.invalidateQueries({ classId: params.targetId });
    useSchoolStoryPostsFetcher.invalidateQueries({ schoolId: params.targetId });
  },
});

interface CalendarEventParams extends CalendarEventsParams {
  eventId: string;
}

export type EditCalendarEventOperationParams = CalendarEventParams &
  Omit<EditCalendarEventOperationRequestBody, "startDateTime" | "endDateTime" | "timezone"> & {
    startDateTime: Date;
    endDateTime: Date;
  };

export const useEditCalendarEventOperation = makeMutation<
  EditCalendarEventOperationParams,
  EditCalendarEventOperationResponse
>({
  name: "editClassCalendarEvent",
  fn: async ({
    eventId,
    targetId,
    title,
    description,
    startDateTime,
    endDateTime,
    allDayEvent,
    targetType,
    remindersToCreate,
    bannerUrl,
    signup,
  }) => {
    try {
      return await callApi({
        method: "PUT",
        path: `/api/${targetType}/${targetId}/calendarEvent/${eventId}`,
        body: {
          title,
          description,
          startDateTime: startDateTime.toISOString(),
          endDateTime: endDateTime.toISOString(),
          allDayEvent,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          remindersToCreate,
          bannerUrl,
          signup,
        },
      });
      // eslint-disable-next-line no-catch-all/no-catch-all
    } catch (ex: any) {
      return ex;
    }
  },
  onSuccess: (_data, params) => {
    useClassCalendarEventsFetcher.invalidateQueries({ targetId: params.targetId });
    useClassCalendarEventMemberFetcher.invalidateQueries({ targetId: params.targetId, eventId: params.eventId });
    useSchoolCalendarEventsFetcher.invalidateQueries({ targetId: params.targetId });
    useSchoolCalendarEventMemberFetcher.invalidateQueries({ targetId: params.targetId, eventId: params.eventId });
    useUpcomingClassCalendarEventsFetcher.invalidateQueries({ targetId: params.targetId });
    useUpcomingSchoolCalendarEventsFetcher.invalidateQueries({ targetId: params.targetId });
    useClassStoryPostsFetcher.invalidateQueries({ classId: params.targetId });
    useSchoolStoryPostsFetcher.invalidateQueries({ schoolId: params.targetId });
    useStoryPostFetcher.invalidateQueries(); // we don't have a story post id so we have to do all of them...
  },
});

// TODO: TSM: Figure out how to type a response object; seems to be a superagent response.
export const useDeleteCalendarEventOperation = makeMutation<CalendarEventParams, unknown>({
  name: "deleteClassCalendarEvent",
  fn: async ({ targetId, targetType, eventId }) => {
    return await callApi({
      method: "DELETE",
      path: `/api/${targetType}/${targetId}/calendarEvent/${eventId}`,
    });
  },
  onSuccess: (_data, params) => {
    useClassCalendarEventsFetcher.invalidateQueries({ targetId: params.targetId });
    useSchoolCalendarEventsFetcher.invalidateQueries({ targetId: params.targetId });
    useSchoolCalendarEventMemberFetcher.invalidateQueries({ targetId: params.targetId, eventId: params.eventId });
    useUpcomingClassCalendarEventsFetcher.invalidateQueries({ targetId: params.targetId });
    useUpcomingSchoolCalendarEventsFetcher.invalidateQueries({ targetId: params.targetId });
    useClassStoryPostsFetcher.invalidateQueries({ classId: params.targetId });
    useSchoolStoryPostsFetcher.invalidateQueries({ schoolId: params.targetId });
    useClassStoryPostsFetcher.setQueriesData(
      (draft) => {
        const { eventId } = params;
        return draft.filter((post) => post._id !== eventId);
      },
      { classId: params.targetId },
    );
    useSchoolStoryPostsFetcher.setQueriesData(
      (draft) => {
        const { eventId } = params;
        return draft.filter((post) => post._id !== eventId);
      },
      { schoolId: params.targetId },
    );
  },
});

export const useSchoolCalendarEventMemberFetcher = makeMemberQuery({
  path: "/api/dojoSchool/{targetId}/calendarEvent/{eventId}",
  fetcherName: "fetcherSchoolCalendarEventMember",
});

export const useClassCalendarEventMemberFetcher = makeMemberQuery({
  path: "/api/dojoClass/{targetId}/calendarEvent/{eventId}",
  fetcherName: "fetcherClassCalendarEventMember",
});

type EventResponse = NonNullable<ReturnType<typeof useClassCalendarEventMemberFetcher>["data"]>;
export type AugmentedEvent = Simplify<Omit<EventResponse, "signup"> & { signup?: SignupResponseWithStudent }>;

export const useSchoolCalendarEvent = (params: Simplify<Parameters<typeof useSchoolCalendarEventMemberFetcher>[0]>) => {
  const { data: event, ...eventRes } = useSchoolCalendarEventMemberFetcher(params);
  const studentsParams = typeof params === "string" ? params : { schoolId: params.targetId };
  const { data: students, ...studentsRes } = useSchoolStudentsFetcher(studentsParams);

  const isFetching = eventRes.isFetching || studentsRes.isFetching;
  const isLoading = eventRes.isLoading || studentsRes.isLoading;
  const isError = eventRes.isError || studentsRes.isError;
  const error = eventRes.error ?? studentsRes.error;

  return useMemo(() => {
    const data = event as AugmentedEvent;
    if (event && students && event.signup) {
      const signup = event.signup;
      signup.spots = signup.spots.map((spot: any) => {
        if (spot.entityId) {
          spot.student = students.find((s) => s.parentConnections.some((p) => (p as any)._id === spot.entityId));
        }

        return spot;
      });

      data.signup = signup;
    }

    return {
      isFetching,
      isLoading,
      isError,
      error,
      data,
    };
  }, [event, students, isFetching, isLoading, isError, error]);
};

export const useClassCalendarEvent = (params: Simplify<Parameters<typeof useClassCalendarEventMemberFetcher>[0]>) => {
  const { data: event, ...eventRes } = useClassCalendarEventMemberFetcher(params);
  const studentsParams = typeof params === "string" ? params : { classId: params.targetId };
  const { data: students, ...studentsRes } = useStudentsFetcher(studentsParams);

  const isFetching = eventRes.isFetching || studentsRes.isFetching;
  const isLoading = eventRes.isLoading || studentsRes.isLoading;
  const isError = eventRes.isError || studentsRes.isError;
  const error = eventRes.error ?? studentsRes.error;

  return useMemo(() => {
    const data = event as AugmentedEvent;
    if (event && students && event.signup) {
      const signup = event.signup;
      signup.spots = signup.spots.map((spot: any) => {
        if (spot.entityId) {
          spot.student = students.find((s) => s.parentConnections.some((p) => (p as any)._id === spot.entityId));
        }

        return spot;
      });

      data.signup = signup;
    }

    return {
      isFetching,
      isLoading,
      isError,
      error,
      data,
    };
  }, [event, students, isFetching, isLoading, isError, error]);
};

interface DeleteCalendarEventCommentOperationParams extends CalendarEventParams {
  commentId: string;
}

export const useDeleteCalendarEventCommentOperation = makeMutation<DeleteCalendarEventCommentOperationParams, unknown>({
  name: "deleteClassCalendarEventComment",
  fn: async ({ targetId, targetType, eventId, commentId }) => {
    return await callApi({
      method: "DELETE",
      path: `/api/${targetType}/${targetId}/calendarEvent/${eventId}/comments/${commentId}`,
    });
  },
  onMutate: (params) => {
    useClassStoryPostsFetcher.setQueriesData(
      (draft) => {
        const post = find(draft, (post) => post._id === params.eventId);
        if (post && "calendarEvent" in post && post.calendarEvent) {
          post.calendarEvent.commentCount = post.calendarEvent.commentCount - 1;
        }
      },
      { classId: params.targetId },
    );
    useSchoolStoryPostsFetcher.setQueriesData(
      (draft) => {
        const post = find(draft, (post) => post._id === params.eventId);
        if (post && "calendarEvent" in post && post.calendarEvent) {
          post.calendarEvent.commentCount = post.calendarEvent.commentCount - 1;
        }
      },
      { schoolId: params.targetId },
    );
  },
  onSuccess: (_data, params) => {
    useClassCalendarEventMemberFetcher.invalidateQueries({ targetId: params.targetId, eventId: params.eventId });
    useSchoolCalendarEventCommentsFetcher.invalidateQueries({ targetId: params.targetId, eventId: params.eventId });
    useClassCalendarEventCommentsFetcher.invalidateQueries({ classId: params.targetId, eventId: params.eventId });
    useSchoolCalendarEventMemberFetcher.invalidateQueries({ targetId: params.targetId, eventId: params.eventId });
  },
});

type CreateCalendarEventCommentOperationParams = APIRequestParameters<
  "/api/dojoClass/{classId}/calendarEvent/{eventId}/comments",
  "post"
>["query"] &
  CalendarEventParams & { comment: string };

export const useCreateCalendarEventCommentOperation = makeMutation<
  CreateCalendarEventCommentOperationParams,
  CreateCalendarEventCommentOperationRequestBody
>({
  name: "createClassCalendarEventComment",
  fn: async ({ targetId, targetType, eventId, comment }) => {
    try {
      return await callApi({
        method: "POST",
        path: `/api/${targetType}/${targetId}/calendarEvent/${eventId}/comments`,
        body: {
          body: comment,
        },
      });
      // eslint-disable-next-line no-catch-all/no-catch-all
    } catch (ex: any) {
      return ex;
    }
  },
  onMutate: (params) => {
    useClassStoryPostsFetcher.setQueriesData(
      (draft) => {
        const post = find(draft, (post) => post._id === params.eventId);
        if (post && "calendarEvent" in post && post.calendarEvent) {
          post.calendarEvent.commentCount = post.calendarEvent.commentCount + 1;
        }
      },
      { classId: params.targetId },
    );
    useSchoolStoryPostsFetcher.setQueriesData(
      (draft) => {
        const post = find(draft, (post) => post._id === params.eventId);
        if (post && "calendarEvent" in post && post.calendarEvent) {
          post.calendarEvent.commentCount = post.calendarEvent.commentCount + 1;
        }
      },
      { schoolId: params.targetId },
    );
  },
  onSuccess: (_data, params) => {
    useClassCalendarEventMemberFetcher.invalidateQueries({ targetId: params.targetId, eventId: params.eventId });
    useSchoolCalendarEventCommentsFetcher.invalidateQueries({ targetId: params.targetId, eventId: params.eventId });
    useClassCalendarEventCommentsFetcher.invalidateQueries({ classId: params.targetId, eventId: params.eventId });
    useSchoolCalendarEventMemberFetcher.invalidateQueries({ targetId: params.targetId, eventId: params.eventId });
  },
});

export type CalendarEventCommentItem = CollectionFetcherReturnType<typeof useClassCalendarEventCommentsFetcher>;

export const useClassCalendarEventCommentsFetcher = makeCollectionQuery({
  path: "/api/dojoClass/{classId}/calendarEvent/{eventId}/comments",
  fetcherName: "fetcherClassCalendarEventComments",
  onSuccess: (comments, params) => {
    useClassStoryPostsFetcher.setQueriesData(
      (draft) => {
        const post = find(draft, (post) => post._id === params.eventId);
        if (post) {
          post.commentCount = comments.length;
        }
      },
      { classId: params.classId },
    );
  },
});

export const useSchoolCalendarEventCommentsFetcher = makeCollectionQuery({
  path: "/api/dojoSchool/{targetId}/calendarEvent/{eventId}/comments",
  fetcherName: "fetcherSchoolCalendarEventComments",
  onSuccess: (comments, params) => {
    useSchoolStoryPostsFetcher.setQueriesData(
      (draft) => {
        const post = find(draft, (post) => post._id === params.eventId);
        if (post) {
          post.commentCount = comments.length;
        }
      },
      { schoolId: params.targetId },
    );
  },
});

export const useUpcomingClassCalendarEventsFetcher = makeCollectionQuery({
  path: "/api/dojoClass/{targetId}/calendarEvent",
  query: { limit: "3", hidePastEvents: "true" },
  queryParams: ["hideEventsAfterDate"],
  fetcherName: "fetcherUpcomingClassCalendarEvents",
});

export const useUpcomingSchoolCalendarEventsFetcher = makeCollectionQuery({
  path: "/api/dojoSchool/{targetId}/calendarEvent",
  query: { limit: "3", hidePastEvents: "true" },
  queryParams: ["hideEventsAfterDate"],
  fetcherName: "fetcherUpcomingSchoolCalendarEvents",
});

export type CalendarEventViewsFetcherResponse = CollectionFetcherReturnType<typeof useClassCalendarEventViewsFetcher>;

export const useClassCalendarEventViewsFetcher = makeCollectionQuery({
  path: "/api/dojoClass/{targetId}/calendarEvent/{eventId}/views",
  fetcherName: "fetcherClassCalendarEventViews",
});

export const useSchoolCalendarEventViewsFetcher = makeCollectionQuery({
  path: "/api/dojoSchool/{targetId}/calendarEvent/{eventId}/views",
  fetcherName: "fetcherSchoolCalendarEventViews",
});

export type CalendarEventReminderType = CalendarEventMemberFetcherResponse["reminders"][0]["reminderType"];

export const useCalendarEventActOnSpotOperation = makeMutation<
  { signupId: string; spotId: string; eventId: string; action: "claim" | "release" },
  Response
>({
  name: "claimSpot",
  fn: async ({ signupId, spotId, action }) => {
    try {
      return await callApi({
        method: "POST",
        path: `/api/signup/${signupId}/spot/${spotId}/${action}`,
      });
      // eslint-disable-next-line no-catch-all/no-catch-all
    } catch (ex: any) {
      return ex;
    }
  },
  onSuccess: (_data, params) => {
    useStoryPostFetcher.invalidateQueries({ postId: params.eventId });
  },
});
