import * as logClient from "@classdojo/log-client";
import { showErrorBanner } from "@classdojo/web/pods/errorHandling/components/ErrorBanner";
import callApi from "@web-monorepo/infra/callApi";
import createAction from "@web-monorepo/infra/createAction";
import createActionHandler from "@web-monorepo/infra/createActionHandler";
import createAsyncActions from "@web-monorepo/infra/createAsyncActions";
import sumBy from "lodash/sumBy";
import compact from "lodash/compact";
import map from "lodash/map";
import { SagaIterator } from "redux-saga";
import { take, put, fork, call, delay } from "redux-saga/effects";
import genPointKey from "app/pods/award/util/genPointKey";
import { Behavior } from "app/pods/behavior";
import { Classroom } from "app/pods/classroom";
import { ClassroomGroup } from "app/pods/group";
import { POPUP_DURATION } from "app/pods/points/components/popups/GeneralPopup";
import { ClassStudent } from "app/pods/student";
import { AwardState } from "app/pods/award";
import { ErrorBannerValues } from "@classdojo/web/pods/errorHandling/errorBanner";
import { formatRFC3339 } from "@web-monorepo/dates";

// import { showErrorBanner } from "@classdojo/web/pods/errorHandling/errorBanner";

export const [GIVE_POINT, GIVE_POINT_DONE, GIVE_POINT_ERROR] = createAsyncActions("award/givePoint");
const GIVE_POINT_NO_ONE = createAction("award/noOne");

const CLEAR_POPUP_AWARD = createAction("award/clearPopup");

type GivePointProps = {
  classroom: Classroom;
  behavior: Behavior;
  students: ClassStudent[];
  groups: ClassroomGroup[];
  local?: boolean;
  awardedAt?: string;
};

export const givePoint = ({ classroom, behavior, students, groups, local }: GivePointProps) => {
  const truthyStudents = compact(students || []);
  if (truthyStudents.length < 1) {
    logClient.logException(
      new Error(
        `bad popupAward givePoint: behavior=${JSON.stringify(behavior)} groups=${JSON.stringify(
          groups,
        )} local=${JSON.stringify(local)}`,
      ),
      [],
    );
    showErrorBanner(ErrorBannerValues.unknown);
    return { type: GIVE_POINT_NO_ONE };
  }

  return {
    type: GIVE_POINT,
    payload: {
      classroom,
      behavior,
      students: truthyStudents,
      groups,
      awardedAt: formatRFC3339(new Date()),
      type: getPointType(classroom, students, groups),
      local: local === undefined ? true : local,
    },
  };
};

export function* givePointSaga(): SagaIterator<void> {
  while (true) {
    const { payload } = yield take(GIVE_POINT);

    yield fork(clearPopupAward);

    const { classroom, behavior, students, groups, type, awardedAt, local } = payload;

    if (!local) {
      continue;
    }

    try {
      const { response } = yield call(callApi, {
        method: "POST",
        path: `/api/dojoClass/${classroom._id}/behavior/${behavior._id}/awardBatch`,
        body: {
          students: map(students, "_id"),
          groups: groups ? map(groups, "_id") : undefined,
          type,
          awardedAt,
        },
      });

      yield put({
        type: GIVE_POINT_DONE,
        payload: {
          ...payload,
          response,
        },
      });
    } catch (e) {
      yield put({
        type: GIVE_POINT_ERROR,
        payload: {
          error: e,
        },
      });

      // The 400 error triggered when the student is not in class is handled in teachErrorHandling.ts so it
      // display a better error message and it is not logged in DD.
      throw e;
    }
  }
}

export const givePointHandler = createActionHandler(
  GIVE_POINT,

  ({ classroom, behavior, students, groups, awardedAt, local }: GivePointProps) =>
    (x: AwardState): AwardState => {
      return {
        ...x,
        randomSelection: undefined,
        popupAward: {
          behavior,
          students,
          groups,
          awardedAt: awardedAt ? new Date(awardedAt).valueOf() : new Date().valueOf(),
        },
        undoStack: (x.undoStack || []).concat([{ classroom, behavior, students, groups, awardedAt, local }]),
      };
    },
);
export const givePointErrorHandler = createActionHandler(GIVE_POINT_ERROR, (x: AwardState): AwardState => {
  return {
    ...x,
    popupAward: undefined,
    undoStack: x.undoStack?.slice(0, -1) || [],
  };
});

type GivePointDoneHandlerProps = {
  classroom: Classroom;
  awardedAt: string;
};

export const givePointDoneHandler = createActionHandler(
  GIVE_POINT_DONE,
  (payload: GivePointDoneHandlerProps) =>
    (x: AwardState): AwardState => {
      return {
        ...x,
        completedAwardWrites: {
          ...x.completedAwardWrites,
          [genPointKey(payload)]: true,
        },
      };
    },
);

export const clearPopupAwardHandler = createActionHandler(
  CLEAR_POPUP_AWARD,
  (_action: unknown, state: AwardState): AwardState => ({
    ...state,
    popupAward: undefined,
  }),
);

function* clearPopupAward() {
  yield delay(POPUP_DURATION);
  yield put({
    type: CLEAR_POPUP_AWARD,
  });
}

function getPointType(classroom: Classroom, students: ClassStudent[], groups: ClassroomGroup[]) {
  const totalOfStudentsInGroups = groups ? sumBy(groups, (g) => (g.studentIds ? g.studentIds.length : 0)) : 0;

  if (totalOfStudentsInGroups === students.length) {
    return "group";
  }

  if (students.length === classroom.studentCount) {
    return "class";
  }

  if (students.length === 1) {
    return "single";
  }

  return "multi";
}
