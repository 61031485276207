import { makeApiMutation } from "../../reactQuery";
import {
  useClassArchivedMessageThreadsFetcher,
  useClassMessageThreadsFetcher,
  useClassMessageThreadsWithArchivedFetcher,
  useParentArchivedMessageThreadsFetcher,
  useParentMessageThreadsFetcher,
  useParentMessageThreadsWithArchivedFetcher,
} from "./fetchers";

export const useArchiveThreadOperation = makeApiMutation({
  name: "useArchiveThread",
  path: "/api/message-thread/{messageThreadId}/archive",
  method: "put",
  onMutate: ({ path: { messageThreadId }, body: { archived } }) => {
    if (archived) {
      useClassMessageThreadsFetcher.setQueriesData((cacheData) => {
        return cacheData.filter((thread) => thread._id !== messageThreadId);
      });
      useParentMessageThreadsFetcher.setQueriesData((cacheData) => {
        return cacheData.filter((thread) => thread._id !== messageThreadId);
      });
    }
  },
  onSuccess: () => {
    useClassMessageThreadsWithArchivedFetcher.invalidateQueries();
    useClassMessageThreadsFetcher.invalidateQueries();
    useClassArchivedMessageThreadsFetcher.invalidateQueries();
    useParentMessageThreadsFetcher.invalidateQueries();
    useParentMessageThreadsWithArchivedFetcher.invalidateQueries();
    useParentArchivedMessageThreadsFetcher.invalidateQueries();
  },
});
