import * as logClient from "@classdojo/log-client";
import combineActionHandlers from "@web-monorepo/infra/combineActionHandlers";
import createAction from "@web-monorepo/infra/createAction";
import createActionHandler from "@web-monorepo/infra/createActionHandler";
import { PodInstallFunction } from "@web-monorepo/shared/podInfra";
import type { AnyAction } from "redux";
import { put, call, takeLatest, CallEffect, PutEffect } from "redux-saga/effects";

type DesktopNotificationState = {
  isSupported: boolean | null;
  hasPermission: boolean | null;
  isRequestingPermission: boolean;
};

const Notification = window.Notification;

const STATE_KEY = "desktopNotification";

type DesktopNotificationSlice = {
  [STATE_KEY]: DesktopNotificationState;
};

const initialState: DesktopNotificationState = {
  isSupported: null,
  hasPermission: null,
  isRequestingPermission: false,
};

const REQUEST_PERMISSION = createAction("desktopNotification/requestPermission");
const PERMISSION_ALLOWED = createAction("desktopNotification/permissionAllowed");
const PERMISSION_DENIED = createAction("desktopNotification/permissionDenied");
const IS_SUPPORTED = createAction("desktopNotification/isSupported");

export const requestPermission = () => ({
  type: REQUEST_PERMISSION,
  payload: {},
});

export const permissionAllowed = () => ({
  type: PERMISSION_ALLOWED,
  payload: {},
});

export const permissionDenied = () => ({
  type: PERMISSION_DENIED,
  payload: {},
});

export const isSupported = (isSupported: boolean) => ({
  type: IS_SUPPORTED,
  payload: isSupported,
});

export function createNotification(message: string, data?: NotificationOptions) {
  let notification;

  try {
    notification = new Notification(message, data);
    logClient.logEvent("teacher:classroom:shown:desktop_notification");
    // eslint-disable-next-line no-catch-all/no-catch-all
  } catch {
    // Ignore error
  }

  return notification;
}

function requestPermissionAsync(): Promise<NotificationPermission> {
  return new Promise((resolve) => {
    Notification.requestPermission(resolve);
  });
}

function* askUserForPermissionSaga(): Generator<
  CallEffect<NotificationPermission> | PutEffect<AnyAction>,
  void,
  NotificationPermission
> {
  if (Notification.permission === "granted") {
    yield put(permissionAllowed());
  } else {
    logClient.logEvent("teacher:classroom:tapped:request_desktop_notification_permission");

    const permission = yield call(requestPermissionAsync);
    if (permission === "granted") {
      logClient.logEvent("teacher:classroom:tapped:allow_desktop_notification_permission");
      yield put(permissionAllowed());
    } else if (permission === "denied") {
      logClient.logEvent("teacher:classroom:tapped:deny_desktop_notification_permission");
      yield put(permissionDenied());
    }
  }
}

function* requestPermissionSaga() {
  if (Notification) {
    yield put(isSupported(true));

    if (Notification.permission === "granted") {
      yield put(permissionAllowed());
    } else if (Notification.permission === "denied") {
      yield put(permissionDenied());
    }

    yield takeLatest(REQUEST_PERMISSION, askUserForPermissionSaga);
  } else {
    yield put(isSupported(false));
  }
}

const requestPermissionHandler = createActionHandler(
  REQUEST_PERMISSION,
  () =>
    (state: DesktopNotificationState): DesktopNotificationState => ({
      ...state,
      isRequestingPermission: true,
    }),
);

const permissionAllowedHandler = createActionHandler(
  PERMISSION_ALLOWED,
  () =>
    (state: DesktopNotificationState): DesktopNotificationState => ({
      ...state,
      isSupported: true,
      hasPermission: true,
      isRequestingPermission: false,
    }),
);

const permissionDeniedHandler = createActionHandler(
  PERMISSION_DENIED,
  () =>
    (state: DesktopNotificationState): DesktopNotificationState => ({
      ...state,
      isSupported: true,
      hasPermission: false,
      isRequestingPermission: false,
    }),
);

const isSupportedHandler = createActionHandler(
  IS_SUPPORTED,
  (isSupported: boolean) =>
    (state: DesktopNotificationState): DesktopNotificationState => ({
      ...state,
      isSupported,
    }),
);

const reducer = combineActionHandlers(initialState, [
  requestPermissionHandler,
  permissionAllowedHandler,
  permissionDeniedHandler,
  isSupportedHandler,
]);

const install: PodInstallFunction = (installReducer, installSaga) => {
  installReducer(STATE_KEY, reducer);
  installSaga(requestPermissionSaga);
};

export default install;

function createSelector(stateKey: typeof STATE_KEY, selector: (state: DesktopNotificationState) => boolean) {
  return (state: DesktopNotificationSlice) => selector(state[stateKey]);
}

const getIsSupported = (object: DesktopNotificationState) => object.isSupported || false;
const getHasPermission = (object: DesktopNotificationState) => object.hasPermission || false;

export const selectIsSupported = createSelector(STATE_KEY, getIsSupported);
export const selectHasPermission = createSelector(STATE_KEY, getHasPermission);
