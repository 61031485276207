import EventEmitter from "events";
import once from "lodash/once";
import throttle from "lodash/throttle";
import Exchange from "./adapters/exchange";
import { Adapter, RealtimeMessage } from "./adapters/types";

const Protocol = {
  // a remote pings on entry
  PING: "protocol::ping",
  // existing hosts will pong in reply
  PONG: "protocol::pong",

  // a host fires an enter event on enter
  ENTER: "protocol::enter",
  // and a depart event when closing
  DEPART: "protocol::depart",
};

export const CLIENT_ROLE: Record<string, Role> = {
  HOST: "host",
  REMOTE: "remote",
  DISPLAY: "display",
};
export type Role = "host" | "remote" | "display";

const INITIAL_PING_TIMEOUT = 1000;
const THROTTLE_PING_INTERVAL = 5000;

declare global {
  interface Window {
    __TOOLKIT_DEBUG__: boolean;
  }
}

type Listener = (...args: unknown[]) => void;

export default class RealtimeClient extends EventEmitter {
  _role: Role;
  _channel: string;
  _client: Adapter;
  _peers: {
    [id: string]: number;
  };
  _id: string;
  _hasPingedForHosts: boolean;

  constructor(role: Role, channel: string, client: Adapter) {
    super();

    if (!(client && client.publish && client.subscribe && client.unsubscribe)) {
      throw new Error("Invalid client provided");
    }

    this._role = role;
    this._channel = channel;
    this._client = client;
    this._peers = {};
    this._id = generateClientId(role);

    // NOTE: these are only used when role is remote
    this._hasPingedForHosts = false;

    this._client.subscribe?.(channel, this._onMessage);

    this.on("message", this._debug);

    // NOTE: we can't be sure this will actually fire when the window unloads, which
    // is why we have the loop that evicts stale peers. Usually it seems to work when
    // the window is being closed but haven't figure out yet how to make this happen
    // when an iframe is unmounted
    window.addEventListener(
      "beforeunload",
      once(() => {
        this.close();
        // NOTE: have to explicitly return nothing so that IE doesn't display prompt
        // saying "Are you sure you want to leave this page?"
        return;
      }),
    );

    if (this._role === CLIENT_ROLE.HOST) {
      this.publish(Protocol.ENTER);
    }
  }

  _debug(...args: unknown[]) {
    if (window.__TOOLKIT_DEBUG__ == null) return;
    // eslint-disable-next-line no-console
    console.log("realtime message: ", ...args);
  }

  addAdapter(adapter: Adapter): void {
    if (!(this._client instanceof Exchange)) {
      this._client = new Exchange([this._client]);
    }

    this._client instanceof Exchange && this._client.addAdapter(adapter);
  }

  // all internal methods as well as external callers should publish via this
  publish(topic: string, payload = {}) {
    if (this._role === CLIENT_ROLE.REMOTE) {
      return this.hasHostConnected().then((hasHost) => {
        if (!hasHost) {
          return;
        }

        return this._actualPublish(topic, payload);
      });
    } else {
      return this._actualPublish(topic, payload);
    }
  }

  // in the rare chance you need to bypass normal publish logic, you can go straight here
  _actualPublish(topic: string, payload = {}) {
    const message = {
      sender: this._id,
      topic,
      payload,
    };

    return this._client.publish?.(this._channel, message);
  }

  close() {
    const isHost = this._role === CLIENT_ROLE.HOST;
    const cleaningUp = isHost ? this.publish(Protocol.DEPART) : Promise.resolve();
    return cleaningUp?.then(() => {
      this._client.unsubscribe?.(this._channel, this._onMessage);
      this.emit("close");
    });
  }

  private _checkingForHosts = throttle(() => {
    let checking = Promise.resolve<unknown>(undefined);
    if (!this._hasPingedForHosts) {
      this._hasPingedForHosts = true;
      checking = new Promise<unknown>((resolve) => {
        // wait for a pong event, but give up after a certain timeout and allow it to attempt again later if needed
        this.once(Protocol.PONG, resolve);
        setTimeout(() => {
          this._hasPingedForHosts = false;
          resolve(undefined);
        }, INITIAL_PING_TIMEOUT);
      });
      // we bypass normal `publish` logic because `publish` calls `hasHostConnected`
      this._actualPublish(Protocol.PING);
    }
    return checking;
  }, THROTTLE_PING_INTERVAL);

  async hasHostConnected() {
    if (this._role !== CLIENT_ROLE.REMOTE) {
      throw new Error("Only remotes can call hasHostConnected()");
    }

    if (Object.keys(this._peers).length > 0) {
      return Promise.resolve(true);
    }

    return this._checkingForHosts()!.then(() => {
      return Object.keys(this._peers).length > 0;
    });
  }

  on(eventType: string, listener: Listener) {
    super.on(eventType, listener);

    if (eventType === "peerChange") {
      // now that we know the consumer is interested in peers, kick off logic to check for hosts
      this.hasHostConnected();
    }

    return this;
  }

  once(eventType: string, listener: Listener) {
    super.once(eventType, listener);

    if (eventType === "peerChange") {
      // now that we know the consumer is interested in peers, kick off logic to check for hosts
      this.hasHostConnected();
    }

    return this;
  }

  _addPeer(id: string) {
    const preCount = Object.keys(this._peers).length;
    this._peers[id] = Date.now();
    const postCount = Object.keys(this._peers).length;
    if (preCount !== postCount) {
      this.emit("peerChange");
    }
  }

  _removePeer(id: string) {
    const preCount = Object.keys(this._peers).length;
    delete this._peers[id];
    const postCount = Object.keys(this._peers).length;
    if (preCount !== postCount) {
      this.emit("peerChange");
    }
  }

  // NOTE: this needs to be a bound method because it is passed to a subscriber above
  _onMessage = (message: RealtimeMessage) => {
    const { sender, topic, payload } = message;

    if (sender === this._id) {
      return;
    }

    // emit specialized events for protocol messages
    // as of now protocol messages don't carry a payload, only the sender id is relevant
    if (topic?.startsWith("protocol::")) {
      // for internal use
      this.emit(topic, {
        id: sender,
      });

      if (topic === Protocol.PING && this._role === CLIENT_ROLE.HOST) {
        this.publish(Protocol.PONG);
      }

      // REMOTE keeps track of peers
      if (this._role === CLIENT_ROLE.REMOTE) {
        if (
          // peer responded with PONG
          topic === Protocol.PONG ||
          // host running recent code sends ENTER message
          topic === Protocol.ENTER ||
          // this is for a new remote hearing from an old host which sent a PING instead of ENTER
          (topic === Protocol.PING && /host_dojo/.test(sender))
        ) {
          this._addPeer(sender);
        }

        if (topic === Protocol.DEPART) {
          this._removePeer(sender);
        }
      }

      return;
    }

    this.emit("message", topic, payload);
  };
}

function generateClientId(role: Role) {
  return `${role}_dojo_realtime_${Math.random().toString(36).slice(2, 8)}`;
}
