import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";
import * as React from "react";
import { Button } from "../../nessie";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";
import { Image } from "../misc/Image";
import BaseModal from "./Base";
import CloseButton from "./CloseButton";

export default function ConfirmSubmitModal({
  text,
  confirmText,
  cancelText,
  confirm,
  cancel,
  "data-name": dataName = "confirmSubmitModal",
}: {
  text?: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  confirm: (e: React.MouseEvent) => void;
  cancel: () => void;
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name"?: string;
}) {
  return (
    <BaseModal onRequestHide={cancel} sx={styles.modal} data-name={dataName} label={autoTranslate("Confirm request")}>
      <CloseButton data-name="confirm_submit:confirm_submit_modal:close_button" close={cancel} />

      <div sx={styles.imageContainer}>
        <Image alt="" sx={styles.image} src="components/compose_area/mojo_stereo.png" />
      </div>

      <div sx={styles.textContainer}>
        {text}

        <div sx={{ display: "flex", paddingTop: "dt_xxl", gap: "dt_xl", width: "100%" }}>
          {cancelText ? (
            <Button
              onClick={cancel}
              size="s"
              data-name="cancelOnConfirmSendMessage"
              kind="tertiary"
              sx={{ border: "dt_active", flexGrow: 1 }}
            >
              {cancelText}
            </Button>
          ) : (
            <Button
              onClick={cancel}
              size="s"
              data-name="cancelOnConfirmSendMessage"
              kind="tertiary"
              sx={{ border: "dt_active", flexGrow: 1 }}
            >
              Cancel
            </Button>
          )}

          {confirmText ? (
            <Button onClick={confirm} data-name="confirmSendMessage" size="s" sx={{ flexGrow: 1 }}>
              {confirmText}
            </Button>
          ) : (
            <Button onClick={confirm} data-name="confirmSendMessage" size="s" sx={{ flexGrow: 1 }}>
              OK
            </Button>
          )}
        </div>
      </div>
    </BaseModal>
  );
}

const IMAGE_WIDTH = "10rem";
const IMAGE_CONTAINER_WIDTH = "18rem";

const styles: Record<string, ThemeUIStyleObject> = {
  modal: {
    fontSize: "18px",
    minHeight: "230px",
    display: "flex",
    overflow: "hidden",
    alignItems: "stretch",
  },
  imageContainer: {
    backgroundColor: "dt_background_warning",
    width: IMAGE_CONTAINER_WIDTH,
    position: "relative",
    padding: "dt_xl",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: IMAGE_WIDTH,
  },
  textContainer: {
    marginY: "dt_xl",
    marginRight: "dt_xxl",
    marginLeft: "dt_l",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
};
