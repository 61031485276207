import { AT } from "@web-monorepo/vite-auto-translate-plugin/runtime";
import * as logClient from "@classdojo/log-client";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DisplayText, LabelText } from "../../nessie";
import { RAW_cssValue } from "../../nessie/stylingLib";

export type NotFoundProps = { errorDelay?: number };

export default function NotFound({ errorDelay = 500 }: NotFoundProps) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.includes("classdojo.com/ul/")) {
      let attemptedSite = "unknown";
      if (location.pathname.includes("classdojo.com/ul/t/")) {
        attemptedSite = "teach";
      } else if (location.pathname.includes("classdojo.com/ul/p/")) {
        attemptedSite = "home";
      } else if (location.pathname.includes("classdojo.com/ul/s/")) {
        attemptedSite = "student";
      }

      logClient.sendMetrics([
        {
          type: "increment",
          value: 1,
          metricName: "web.deeplink_redirect",
          tags: { attemptedSite, pathname: location.pathname },
        },
      ]);
      navigate("/");
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    const currentLocation = window.location.href;

    let expired = false;
    const timer = setTimeout(() => {
      expired = true;
      logClient.logRequestError("not_found", { method: "GET", url: currentLocation }, {}, []);
    }, errorDelay);

    return () => {
      clearTimeout(timer);
      // send a metric if the timer was cancelled before it fired, to see if quick mount/unmount
      // is the culprit behind PAGE_NOT_FOUND on routes that do exist.
      if (!expired) {
        logClient.sendMetrics([
          {
            type: "increment",
            value: 1,
            metricName: "not_found_cancelled",
          },
        ]);
      }
    };
  }, [errorDelay]);

  return (
    <div>
      <div sx={{ textAlign: "center", paddingTop: RAW_cssValue("10rem") }}>
        <DisplayText level={4} sx={{ marginBottom: "dt_l" }} as="h1">
          <AT>Page not found!</AT>
        </DisplayText>

        <LabelText href="/">Back to ClassDojo.com</LabelText>
      </div>

      <img
        sx={{ position: "absolute", bottom: "0" }}
        src="https://c.classdojo.com/img/page_500/hero-500.png"
        alt="Page not found!"
      />
    </div>
  );
}
