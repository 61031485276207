import { CollectionFetcherReturnType } from "@web-monorepo/shared/api/apiTypesHelper";
import { makeCollectionQuery } from "@web-monorepo/shared/reactQuery";

export const useStudentsFetcher = makeCollectionQuery({
  path: "/api/dojoClass/{classId}/student",
  query: { showLeftEarly: "true" },
  fetcherName: "students",
  dontThrowOnStatusCodes: [403],
});

export type Student = CollectionFetcherReturnType<typeof useStudentsFetcher>;
