import React from "react";
import type { Middleware } from "redux";
import { UserActionsHistory } from "../userActionsHistory";
import { showErrorBanner } from "./components/ErrorBanner";
import { ErrorBannerValue } from "./errorBanner";
import * as logClient from "@classdojo/log-client";
import { removeSensitiveData } from "./removeSensitiveData";
import { APIResponseError } from "@web-monorepo/infra/responseHandlers";
import { ProductAreaValues } from "./productArea";

export enum ERROR_CATCH_TYPE {
  SAGA_TASK = "SagaTask",
  REDUX = "Redux",
  REACT_ERROR_BOUNDARY = "ReactErrorBoundary",
  GLOBAL_ERROR = "GlobalError",
  UNHANDLED_REJECTION = "UnhandledRejection",
  MODULE_LOAD_ERROR = "ModuleLoadError",
  UNKNOWN = "Unknown",
}

export interface ErrorContext {
  catchType: ERROR_CATCH_TYPE;
  recreated?: boolean;
  productArea?: ProductAreaValues;
  componentStack?: string;
  history?: string[];
  [key: string]: unknown;
}

type ErrorHandlerAPI = {
  showErrorBanner(type: ErrorBannerValue, refreshUrl?: string): void;
  logRequestErrorWithContext(
    type: "rateLimit" | "down" | "auth.401" | "auth.403" | "badRequest" | "request.404",
    error: Error,
  ): void;
  logExceptionWithContext(error: Error): void;
};
type OnError = (error: Error, context: ErrorContext, api: ErrorHandlerAPI) => void;

export class ErrorHandler {
  _userActionsHistory: UserActionsHistory;
  _onError: OnError;
  _active = false;

  constructor({ userActionsHistory, onError }: { userActionsHistory: UserActionsHistory; onError: OnError }) {
    this._userActionsHistory = userActionsHistory;
    this._onError = onError;

    if (typeof window !== "undefined") {
      window.addEventListener("vite:preloadError", (e) => {
        // for some reason, vite exposes the internal error as "payload" instead of cause:
        // https://github.com/vitejs/vite/blob/ed5ecd90fdddcd6a6bdce1858735ae47a6a1e2da/packages/vite/src/node/plugins/importAnalysisBuild.ts#L29
        this.handleError(new Error("vite:preloadError", { cause: "payload" in e ? e.payload : e }), {
          catchType: ERROR_CATCH_TYPE.MODULE_LOAD_ERROR,
        });
      });
    }
  }

  getErrorLogContext(error: Error, providedContext: ErrorContext) {
    /**
     * Ignore product area when error is 401 as this error can be triggered from every route
     * and we don't want to polute team error loggings with it but we still want
     * log it to datadog to set up alarms in case of unexpected spikes
     *
     * Example:
     * Error: Unexpected API error in memberFetcher
     * Member: classroom
     * Url: /api/dojoClass/52336bbeb74a7a0000000011?withRequestedCollaborators=true
     * Status code: 401
     */
    const errorToIgnoreProductArea = "Status code: 401";
    const overrideContext: Record<string, unknown> = {};
    if (error.message.includes(errorToIgnoreProductArea)) {
      overrideContext.productArea = "";
    }

    const defaultContext = {
      catchType: ERROR_CATCH_TYPE.UNKNOWN,
      recreated: false,
      history: [`[Error Context: ${providedContext.catchType}]`, ...this._userActionsHistory.getHistory()],
    };

    return { ...defaultContext, ...providedContext, ...overrideContext };
  }

  handleError(error: Error, context: ErrorContext) {
    if (window.isBrowserSupported === false) {
      return;
    }

    const decoratedContext = this.getErrorLogContext(error, context);

    const errorHandlerUtils: ErrorHandlerAPI = {
      showErrorBanner(type, refreshUrl) {
        showErrorBanner(type, refreshUrl);
      },
      logExceptionWithContext: (error) => {
        logClient.logException(error, context);
      },
      logRequestErrorWithContext: (type, error) => {
        this.logRequestErrorWithContext(type, error, decoratedContext);
      },
    };

    this._onError(error, decoratedContext, errorHandlerUtils);
  }

  reduxMiddleware: Middleware = () => (next) => (action) => {
    try {
      return next(action);
      // eslint-disable-next-line no-catch-all/no-catch-all
    } catch (e: any) {
      this.handleError(e, { catchType: ERROR_CATCH_TYPE.REDUX });
    }
  };

  logRequestErrorWithContext(type: string, error: Error, context: ErrorContext) {
    let scrubbedRequest = {};
    let scrubbedResponse = {};
    try {
      if (error instanceof APIResponseError) {
        const { response } = error;
        const { request } = error;
        if (request) {
          scrubbedRequest = {
            method: request.method,
            url: request.url,
            body: removeSensitiveData(request.body),
          };
        }
        if (response) {
          scrubbedResponse = {
            headers: response.headers,
            status: response.status,
            text: response.text,
            type: response.type,
            error: response.error,
          };
        }
      }
      // eslint-disable-next-line no-catch-all/no-catch-all
    } catch (err: any) {
      // if something fails trying to filter the request/response data,
      // log an error so we can search and track it on the logs,
      // at least until we are confident this will work for all cases
      logClient.logException(new Error(`[Remove sensitive data error] ${err.message}`), context);
    }
    return logClient.logRequestError(type, scrubbedRequest, scrubbedResponse, context);
  }
}

export const ErrorHandlerContext = React.createContext<ErrorHandler | void>(undefined);
