/** useOnFirstRender: is a hook that allows you to run a callback when the component is first rendered.
 * Preferred to useEffect per https://react.dev/learn/you-might-not-need-an-effect#initializing-the-application.
 * If you are using this with side effects, use useSideEffectOnMount instead
 * @param callback - The callback to run when the component is mounted.
 */
import { captureException } from "@web-monorepo/telemetry";
import { useRef } from "react";

// This function is trying to replace useEffect() with an empty dependency array for the
// first-render case, returning a function likely means it's an incorrect useEffect->useOnFirstRender conversion.

type NotAFunction<T> = T extends () => void ? never : T;
export function useOnFirstRender<T>(callback: () => NotAFunction<T>) {
  const firstRun = useRef<boolean>(true);
  if (firstRun.current) {
    firstRun.current = false;
    const callbackReturn = callback();

    if (typeof callbackReturn === "function") {
      /* c8 ignore next 4 -- we dont run coverage in production */
      if (Config.nodeEnv === "production") {
        captureException(
          new Error("useOnFirstRender callback returned a function"),
        );
      } else {
        throw new Error(
          "useOnFirstRender does not allow functions to be returned.",
        );
      }
    }
  }
}
