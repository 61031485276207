import { format as formatDate, type DateFormatOptions } from "@web-monorepo/dates";
import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";
import FocusTrap from "focus-trap-react";
import { useState } from "react";
import WithClickOutside from "../../components/misc/WithClickOutside";
import { IconTextField } from "../../nessie";
import { CalendarIcon } from "../../nessie/icons";
import Calendar from "./Calendar";

const getDateOrNull = (dateOrMoment: Date | null | undefined) => (dateOrMoment ? dateOrMoment : null);
const positionConfigs = { top: { bottom: "110%" }, bottom: { top: "110%" } };

export default function DatePicker({
  externalValidation = true,
  date,
  minDate = new Date(),
  maxDate,
  calendarZIndex = 2,
  position = "bottom",
  dataName,
  format = { month: "2-digit", day: "2-digit", year: "numeric" },
  "aria-label": ariaLabel,
  onChange,
}: {
  onChange: (v: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  calendarZIndex?: number;
  externalValidation?: boolean;
  date: Date | null;
  format?: DateFormatOptions;
  dataName?: string;
  "aria-label": string;
  position?: "top" | "bottom";
}) {
  const [showCalendar, setShowCalendar] = useState(false);

  const handleCalendarClick = () => {
    if (showCalendar) return;
    setShowCalendar(true);
  };

  const handleCalendarKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
    event.stopPropagation();
    if (event.key === "Enter" && !showCalendar) setShowCalendar(true);
  };

  const handleCalendarChange = (date: Date) => {
    setShowCalendar(false);
    onChange(date);
  };

  return (
    <WithClickOutside onClickOutside={() => setShowCalendar(false)}>
      <div sx={{ position: "relative" }}>
        <IconTextField
          value={date ? formatDate(date, /* TODO: Update this moment format string: */ format) : ""}
          valid={externalValidation}
          data-name={`${dataName}_input`}
          aria-label={ariaLabel}
          onClick={handleCalendarClick}
          onKeyDown={handleCalendarKeyPress}
          readOnly
          role="combobox"
          aria-expanded={showCalendar}
          aria-haspopup="dialog"
          aria-controls="DataPickerDialog"
          placeholder={autoTranslate("Enter date")}
          leftIcon={<CalendarIcon />}
        />
        {showCalendar && (
          <FocusTrap
            focusTrapOptions={{
              allowOutsideClick: true,
              escapeDeactivates: () => {
                setShowCalendar(false);
                return true;
              },
            }}
            active={showCalendar}
          >
            <div
              role="dialog"
              sx={{
                zIndex: calendarZIndex,
                position: "absolute",
                left: 0,
                ...(positionConfigs[position] || positionConfigs.bottom),
              }}
              data-name={`${dataName}_calendar`}
              id="DataPickerDialog"
              aria-label="Choose date"
              aria-modal="true"
            >
              <Calendar
                onChange={handleCalendarChange}
                value={getDateOrNull(date)}
                minDate={minDate}
                maxDate={getDateOrNull(maxDate) ?? undefined}
              />
            </div>
          </FocusTrap>
        )}
      </div>
    </WithClickOutside>
  );
}
