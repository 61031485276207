import { fetchWithoutCredentials } from "@web-monorepo/infra";
import {
  LocaleMapping,
  Namespaces,
} from "@web-monorepo/vite-auto-translate-plugin/runtime";

// set from https://youmightnotneed.com/lodash, but modified to work with typescript
// so that jest stops yelling at us about the guts of lodash...n
const set = (obj: any, path: string, value: any) => {
  // Regex explained: https://regexr.com/58j0k
  const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);

  pathArray?.reduce((acc: any, key: any, i: number) => {
    if (acc[key] === undefined) acc[key] = {};
    if (i === pathArray.length - 1) acc[key] = value;
    return acc[key];
  }, obj);
};

const loadPath = `${
  Config.bundler === "jest" ? Config.apiEndpoint : ""
}/locales/resources.json?lng=__lng__&ns=__ns__&build=${
  Config.buildNumber || Date.now()
}`;

async function loadApiNamespaces(
  languages: string[],
  namespaces: string[],
  callback: (err: any, translations?: any) => void
) {
  try {
    const response = await fetchWithoutCredentials({
      path: loadPath
        .replace("__lng__", languages.join("+"))
        .replace("__ns__", namespaces.join("+")),
    });

    if (response.ok) {
      const translations = (await response.body) as Record<
        string,
        Record<string, Record<string, string>>
      >;

      // TODO: Remove this shim once translations ship for the changes to remove `:` from keys:
      // Should be anytime after 2024-08-01 (should be no-op by then, will fail Cypress tests if
      // it's not ready):
      for (const language in translations) {
        for (const namespace in translations[language]) {
          for (const key in translations[language][namespace]) {
            if (key.includes(":")) {
              const newKey = key.split(":").join(".");
              set(
                translations[language][namespace],
                newKey,
                translations[language][namespace][key]
              );
            }
          }
        }
      }

      callback(null, translations);
    } else {
      callback(new Error("Failed to fetch translations"), null);
    }
  } catch (x) {
    callback(x);
  }
}

function loadLocalNamespace(
  lng: string,
  ns: string,
  callback: (err: any, translations?: any) => void
) {
  const file = LocaleMapping[lng]?.[ns];
  if (file) {
    fetchWithoutCredentials({ path: file })
      .then(({ body }) => {
        callback(null, body);
      })
      .catch((error) => callback(error));
  } else {
    callback(null, {});
  }
}

export const Backend = {
  init() {},
  readMulti: async function (
    languages: string[],
    namespaces: string[],
    callback: (err: any, translations?: any) => void
  ) {
    const localNamespaces = [];
    const apiNamespaces = [];

    for (const ns of namespaces) {
      if (Namespaces.includes(ns)) {
        localNamespaces.push(ns);
      } else {
        apiNamespaces.push(ns);
      }
    }

    if (apiNamespaces.length > 0) {
      loadApiNamespaces(languages, apiNamespaces, callback);
    }
    if (localNamespaces.length > 0) {
      for (const ns of localNamespaces) {
        for (const lng of languages) {
          loadLocalNamespace(lng, ns, (error, body) => {
            if (error) callback(error);
            callback(null, { [lng]: { [ns]: body } });
          });
        }
      }
    }
  },
};
