import { ThemeUIStyleObject } from "@classdojo/web/nessie/stylingLib";
import { GrandstanderText, GrandstanderTextProps } from "./GrandstanderText";

const headlineTextLevels = [1, 2, 3] as const;
export type HeadlineTextLevelsType = (typeof headlineTextLevels)[number];

export type HeadlineTextProps = {
  level?: HeadlineTextLevelsType;
} & GrandstanderTextProps;

export function IslandsHeadlineText({
  level = 1,
  ...props
}: HeadlineTextProps) {
  return <GrandstanderText sx={headlineTextStyles[level]} {...props} />;
}

const headlineTextStyles: Record<HeadlineTextLevelsType, ThemeUIStyleObject> = {
  1: {
    fontSize: "28px",
    lineHeight: "34px",
    fontWeight: "dt_extraBold",
    letterSpacing: "-0.1px",
  },
  2: {
    fontSize: "23px",
    lineHeight: "28px",
    fontWeight: "dt_extraBold",
    letterSpacing: "-0.1px",
  },
  3: {
    fontSize: "20px",
    lineHeight: "25px",
    fontWeight: "dt_extraBold",
    letterSpacing: "-0.1px",
  },
};
