import { useEffect, useRef } from "react";

type EventMap = WindowEventMap & HTMLElementEventMap & DocumentEventMap;

export function useEventListener<K extends keyof EventMap>(
  eventName: K,
  handler: null | ((event: EventMap[K]) => void),
  element?: HTMLElement | Window | Document | null,
  options?: boolean | AddEventListenerOptions,
) {
  const savedHandler = useRef(handler);

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const targetElement = element ?? window;

    if (!targetElement?.addEventListener) {
      console.warn(
        "useEventListener listenting to element without addEventListener",
        targetElement,
      );
      return;
    }

    const eventListener: EventListener = (...args) => {
      if (savedHandler.current) {
        // @ts-expect-error Type 'Event' is missing the following properties from type 'DeviceMotionEvent': acceleration, accelerationIncludingGravity, interval, rotationRatets
        savedHandler.current(...args);
      }
    };

    targetElement.addEventListener(eventName, eventListener, options);

    return () => {
      targetElement.removeEventListener(eventName, eventListener, options);
    };
  }, [eventName, element, options]);
}
