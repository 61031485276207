import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { IconCheck } from "@web-monorepo/dds-icons";
import * as React from "react";

import classes from "./DDSCheckbox.module.css";
import { DDSBodyText } from "../DDSBodyText/DDSBodyText";

type DDSInternalCheckboxProps = Omit<
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>,
  "children" | "className"
> & {
  ["data-name"]: string;
};

export const DDSInternalCheckbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  DDSInternalCheckboxProps
>(({ "data-name": dataName, ...rest }, ref) => {
  return (
    <CheckboxPrimitive.Root ref={ref} data-name={dataName} className={classes.checkboxRoot} {...rest}>
      <CheckboxPrimitive.CheckboxIndicator className={classes.checkboxIndicator}>
        <IconCheck className={classes.check} />
      </CheckboxPrimitive.CheckboxIndicator>
    </CheckboxPrimitive.Root>
  );
});

export const LabelPositions = ["before", "after"] as const;
export const Widths = ["fit", "fill"] as const;

type DDSCheckboxProps = Omit<
  React.ComponentPropsWithoutRef<typeof DDSInternalCheckbox>,
  // The label property is required and is always rendered. Prevent overwriting it.
  "aria-label" | "aria-labelledby"
> & {
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  ["data-name"]: string;

  /**
   * Element ID. This value must be unique on the page being rendered.
   */
  id: string;

  /**
   * The label for the checkbox.
   */
  label: string;

  /**
   * The description of the checkbox. The element containing this text will be referenced by the checkbox's
   * `aria-describedby` property.
   */
  description?: string;

  /**
   * The visual position of the text relative to the checkbox. Note that this does not affect the DOM order, in which
   * the text is always positioned after the textbox.
   * @default "after"
   */
  labelPosition?: (typeof LabelPositions)[number];

  /**
   * Determines whether the component is rendered inline ("fit") or as a full-width block ("fill")
   * @default "fit"
   */
  width?: (typeof Widths)[number];
};

export const DDSCheckbox = React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, DDSCheckboxProps>(
  ({ description, id, label, labelPosition = "after", width = "fit", ...rest }, ref) => {
    return (
      <div className={`${classes.container} ${classes[labelPosition]} ${classes[width]}`}>
        <DDSInternalCheckbox
          ref={ref}
          id={id}
          {...(description ? { ["aria-describedby"]: `${id}-description` } : {})}
          {...rest}
        />
        <div className={classes.textContainer}>
          <DDSBodyText level={1} as="label" htmlFor={id}>
            {label}
          </DDSBodyText>
          {description ? (
            <DDSBodyText level={3} className={classes.description} id={`${id}-description`}>
              {description}
            </DDSBodyText>
          ) : null}
        </div>
      </div>
    );
  },
);
