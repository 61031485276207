import combineActionHandlers from "@web-monorepo/infra/combineActionHandlers";
import { PodInstallFunction } from "@web-monorepo/shared/podInfra";
import { makeApiMutation } from "@web-monorepo/shared/reactQuery";
import type { AnyAction } from "redux";
import { useCreateFromListOperation } from "app/pods/student";

const STATE_KEY = "addEditClassroom";

export type ListCreateRequest = {
  error: Error;
  loading: boolean;
  success: boolean;
};

const initialState = {
  listCreateRequest: { error: null, loading: false, success: null },
};

// Selectors
export function selectListCreateRequest(state: Record<string, { listCreateRequest: ListCreateRequest }>) {
  return state[STATE_KEY].listCreateRequest;
}

// Reducers
const createFromListHandler = (state: Record<string, { listCreateRequest: ListCreateRequest }>, action: AnyAction) => {
  if (
    useCreateFromListOperation.isStartAction(action) ||
    useCreateFromListOperation.isDoneAction(action) ||
    useCreateFromListOperation.isErrorAction(action)
  ) {
    const data = {
      error: useCreateFromListOperation.isErrorAction(action) ? action.payload.error : null,
      loading: useCreateFromListOperation.isStartAction(action),
      success: useCreateFromListOperation.isDoneAction(action) ? action.payload : null,
    };
    return { ...state, listCreateRequest: data };
  }
  return state;
};

// Sagas
const finalReducer = combineActionHandlers(initialState, [createFromListHandler]);

const install: PodInstallFunction = (installReducer) => {
  installReducer(STATE_KEY, finalReducer);
};

export default install;

export const useParseNamesOperation = makeApiMutation({
  name: "parseNames",
  path: "/api/parsedNames",
  method: "post",
});
