/** arrayToObject(["foo", "bar"] as const) === { FOO: "foo", BAR: "bar" } as const */
function arrayToObject<T extends string>(names: readonly T[]) {
  return names.reduce((r, n) => ({ ...r, [n.toUpperCase()]: n }), {} as { [K in T as Uppercase<K>]: K });
}

// The reason for having the feature switches in an isolated file is so Cypress can
// import them to use in the tests without having any other imports with aliases (absolute paths).
// That is because Cypress is not configured to understand aliases or absolute paths.

export const TEACHER_FEATURE_SWITCHES = {
  ...arrayToObject([
    "web_ai_tools_retry",
    "web_pcr_email_required_enabled",
    "web_teach_become_mentor_2024",
    "web_teach_dojo_training_tile",
    "web_teach_mentor_bts_2024_tile",
    "web_teach_re_opt_in_teachers",
    "web_teach_verifyEmail_toast_signup",
    "web_teacher_school_points_report_export",
    "web_teacher_sl_landing_state_holdout",
    "web_teach_mentor_resource_kit_push_modal",
    "web_teach_encourage_school_invites_modal",
    "web_teach_mv_mkt_eligible_teachers_2024",
    "teach_schoolwidepoints_date_range",
    "teach_school_dropdown_in_class_creation",
    "web_teach_mentor_letter_invite_2024_q3_en",
    "web_teach_directory_student",
    "web_teach_virtual_info_session_q32024_modal",
    "web_teach_teacher_community_holdout_perteacher_2024",
    "web_story_sidekick",
    "web_teach_fill_out_survey_q3_modal",
    "web_teach_number_shores_modal",
    "web_teach_eoy_activation_aunz_modal",
    "web_teach_more_for_mentors_2024q4_followup_modal",
    "web_teach_monster_of_the_year_2025_modal",
  ]),
  WEB_TEACHER_SKIP_ONBOARDING_VALUES: "web_teacher_skip_onboarding_values_v2",
  TEACHER_MAGIC_ASSISTANT_ROLLOUT: "teacher_magic_assistant_rollout_v2",
  WEB_MENTOR_LETTER_SCHOOLWIDE_2024_MODAL: "web_teach_mentor_letter_schoolwide_2024_v3",
  WEB_TEACH_SL_VIDEO_ON_BLURRED: "web_teach_sl_video_on_blurred_v2",
  WEB_TEACH_TRUST_SIGNALS_UI_V3: "web_teach_trust_signals_ui_v3",
  DISTRICT_DOWNLOADS: "web_district_leader_downloads",
  WEB_TEACH_ROSTER_UPLOAD_RESTART: "web_teach_roster_upload_restart",
  DISTRICT_BULLETINS: "web_district_leader_bulletins",
  WEB_TEACH_STORY_ADD_STUDENTS: "web_teach_story_add_students",
  WEB_TEACH_CREATE_EVENTS_ON_STORY_FEED: "web_teach_create_events_on_story_feed",
  WEB_TEACH_OLD_EVENTS: "web_teach_old_events",
} as const;

export const SCHOOL_FEATURE_SWITCHES = {
  ...arrayToObject([
    "teach_web_unified_school_story_feed",
    "web_schoolwide_points_see_more",
    "web_teach_bts_2024_campaigns_holdout",
    "web_teach_unified_inbox_nux",
    "web_teach_teacher_community_holdout_2024",
    "web_teach_high_school_mode",
    "teach_schoolwidepoints_redemption",
    "web_school_show_schoolwide_balance_setting",
  ]),
  WEB_TEACH_ALREADY_SCHOOLWIDE_NEED_1ON1_MODAL: "web_teach_already_schoolwide_need_1on1_modal_v2",
  WEB_SCHOOLS_TO_HIDE_1ON1_BOX: "web_teach_list_schools_to_hide_1on1_box",
  FAMILY_MESSAGING: "web_familyDojo_parents_only",
} as const;

export const CLASSROOM_FEATURE_SWITCHES = {
  MV_NUMBER_SHORES: "monsterverseMathV2Activity",
} as const;

// these feature switches will apply to any user session,
// whether the user is logged in or not
export const SESSION_FEATURE_SWITCHES = {
  WEB_TEACH_OIDC_CONSOLIDATION: "web_teach_oidc_consolidation2",
  WEB_TEACH_OIDC_GOOGLE_PROMPTS: "web_teach_oidc_google_prompts",
} as const;

export const FEATURE_SWITCHES = {
  ...TEACHER_FEATURE_SWITCHES,
  ...SCHOOL_FEATURE_SWITCHES,
  ...SESSION_FEATURE_SWITCHES,
} as const;

export type SchoolExperimentKey = keyof typeof SCHOOL_FEATURE_SWITCHES;
export type SchoolExperiment = (typeof SCHOOL_FEATURE_SWITCHES)[SchoolExperimentKey];

export type TeacherExperimentKey = keyof typeof TEACHER_FEATURE_SWITCHES;
export type TeacherExperiment = (typeof TEACHER_FEATURE_SWITCHES)[TeacherExperimentKey];

export type SessionExperimentKey = keyof typeof SESSION_FEATURE_SWITCHES;
export type SessionExperiment = (typeof SESSION_FEATURE_SWITCHES)[SessionExperimentKey];

export type Experiment = SchoolExperiment | TeacherExperiment | SessionExperiment;
