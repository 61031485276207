import { CalendarDate, getLocalTimeZone } from "@internationalized/date";
import { AriaButtonProps, useButton } from "@react-aria/button";
import { I18nProvider, useDateFormatter } from "@react-aria/i18n";
import { CalendarState, RangeCalendarState } from "@react-stately/calendar";
import { DOMAttributes, useRef } from "react";
import HeadlineText from "../../../nessie/components/typography/headlineText";
import { ArrowLeftIcon, ArrowRightIcon } from "../../../nessie/icons";
import { ThemeUIStyleObject } from "../../../nessie/stylingLib";
import { UnstyledButton } from "../../buttons";
import { CalendarGrid } from "../calendar/CalendarGrid";

export default function CalendarContent({
  className,
  state,
  prevButtonProps,
  nextButtonProps,
  calendarProps,
  containerRef,
}: {
  className?: string;
  state: CalendarState | RangeCalendarState;
  prevButtonProps: AriaButtonProps<"button">;
  nextButtonProps: AriaButtonProps<"button">;
  calendarProps: DOMAttributes<HTMLDivElement>;
  containerRef?: React.RefObject<HTMLDivElement>;
}) {
  const { localeInfo, userCalendar, calendarLocale } = getLocaleInfo();

  const formatter = useDateFormatter({
    month: "long",
    year: localeInfo.year as "numeric" | "2-digit",
    calendar: userCalendar,
  });

  // buttons
  const previousRef = useRef(null);
  const nextRef = useRef(null);
  const { buttonProps: prevProps } = useButton(prevButtonProps, previousRef);
  const { buttonProps: nextProps } = useButton(nextButtonProps, previousRef);

  return (
    <I18nProvider locale={calendarLocale}>
      <div {...calendarProps} sx={calendarStyles} className={className} ref={containerRef}>
        <div sx={headerStyles}>
          <HeadlineText
            textTransform="onlyFirst"
            as="h2"
            level={2}
            sx={{ marginRight: "dt_xs", width: "230px" }}
            aria-live="polite"
          >
            {formatter.format(state.focusedDate.toDate(getLocalTimeZone()))}
          </HeadlineText>

          <UnstyledButton
            data-name="calendar:arrow_left_icon"
            ref={previousRef}
            {...prevProps}
            sx={buttonStyles}
            aria-label="Previous month"
          >
            <ArrowLeftIcon color={prevProps.disabled ? "dt_content_disabled" : "dt_content_primary"} />
          </UnstyledButton>

          <UnstyledButton
            data-name="calendar:arrow_right_icon"
            ref={nextRef}
            {...nextProps}
            sx={buttonStyles}
            aria-label="Next month"
          >
            <ArrowRightIcon color={nextProps.disabled ? "dt_content_disabled" : "dt_content_primary"} />
          </UnstyledButton>
        </div>
        <CalendarGrid state={state} />
      </div>
    </I18nProvider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function getCalendarDate(date: Date): CalendarDate {
  return new CalendarDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
}

const getLocaleInfo = () => {
  const localeInfo = new Intl.DateTimeFormat().resolvedOptions();

  //   the as any was necessary because the type definition for Intl.Locale is wrong
  const IntlLocale = new Intl.Locale(localeInfo.locale) as any;

  const userCalendar: string =
    IntlLocale.calendar || IntlLocale.calendars?.[1] || IntlLocale.calendars?.[0] || localeInfo.calendar || "gregory";

  const calendarLocale = new Intl.Locale(localeInfo.locale, { calendar: userCalendar }).toString();

  return { localeInfo, userCalendar, calendarLocale };
};

const calendarStyles: ThemeUIStyleObject = {
  border: "dt_card",
  borderRadius: "dt_radius_s",
  backgroundColor: "dt_background_primary",
  width: "fit-content",
  padding: "dt_m",
};
const headerStyles: ThemeUIStyleObject = {
  display: "flex",
  alignItems: "center",
};
const buttonStyles: ThemeUIStyleObject = {
  width: "40px",
  height: "40px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
