type LocaleChangeHandler = (locale: string | undefined) => void;

const onLocaleChangeHandlers: LocaleChangeHandler[] = [];
export function onLocaleChange(handler: LocaleChangeHandler) {
  onLocaleChangeHandlers.push(handler);
}

export function emitLocaleChange(locale: string | undefined) {
  onLocaleChangeHandlers.forEach((handler) => handler(locale));
}
