import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";
import { BadgeKinds, NumberBadge, NumberBadgeProps } from "../../nessie";

export type BehaviorCircleProps = Omit<NumberBadgeProps, "value"> & {
  behavior: { points: number | null; positive: boolean };
  absent?: boolean;
  hideIfZero?: boolean;
};

export default function BehaviorCircle({
  behavior: { points, positive },
  absent,
  kind,
  hideIfZero,
  ...rest
}: BehaviorCircleProps) {
  if (points === null || (hideIfZero && !points)) return null;

  let circleType: BadgeKinds;
  if (kind) {
    circleType = kind;
  } else if (absent) {
    circleType = "secondary";
  } else if (points === 0) {
    circleType = "warning";
  } else if (positive) {
    circleType = "success";
  } else {
    circleType = "danger";
  }

  const content = !positive && points > 0 ? points * -1 : points;

  return (
    <NumberBadge
      kind={circleType}
      aria-label={
        points === 1
          ? autoTranslate("__count__ point", { count: String(content) })
          : autoTranslate("__count__ points", { count: String(content) })
      }
      {...rest}
      value={content}
    />
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function getPointsFromObject(
  object: { currentPoints?: number; positivePoints?: number; negativePoints?: number },
  bubbleType?: "combinedTotal" | "separateTotals" | "dontShowPoints",
) {
  if (bubbleType === "separateTotals") return object.positivePoints || 0;
  else if (bubbleType === "combinedTotal") return (object.positivePoints || 0) - Math.abs(object.negativePoints || 0);
  else if (bubbleType === "dontShowPoints") return null;
  else return object.negativePoints || 0;
}
