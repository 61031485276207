import { components, paths } from "@classdojo/ts-api-types/api";
import callApi, { CallApiDefaultResponse } from "@web-monorepo/infra/callApi";
import combineActionHandlers from "@web-monorepo/infra/combineActionHandlers";
import {
  APIRequestBody,
  APIResponse,
  CollectionFetcherParamsType,
  CollectionFetcherReturnType,
  EndpointQueryParameters,
} from "@web-monorepo/shared/api/apiTypesHelper";
import { useAllClassroomFetcher } from "@web-monorepo/shared/classroom";
import { PodInstallFunction } from "@web-monorepo/shared/podInfra";
import {
  makeCollectionQuery,
  makeMemberQuery,
  NOOP,
  WAITING_FOR_DEPENDENCIES,
  makeApiMutation,
  makeMutation,
} from "@web-monorepo/shared/reactQuery";
import { CollectionQueryType } from "@web-monorepo/shared/reactQuery/collectionQuery";
import maxBy from "lodash/maxBy";
import omit from "lodash/omit";
import setWith from "lodash/setWith";
import sortBy from "lodash/sortBy";
import { useCallback, useEffect, useRef, useState } from "react";
import type { AnyAction } from "redux";
import { useInterval } from "@web-monorepo/hooks";
import {
  CalendarEventMemberFetcherResponse,
  useClassCalendarEventCommentsFetcher,
  useSchoolCalendarEventCommentsFetcher,
} from "app/pods/calendarEvents";
import { useClassroomFetcher, useUpdateClassroomStoryCommentOperation } from "app/pods/classroom";
import { usePortfolioAllPostsFetcher } from "app/pods/portfolio";
import { useSchoolFetcher } from "app/pods/school";
import { StoryPost, TempPost } from "app/pods/story/types";
import { useSaveMetadataOperation, useUserConfigFetcher } from "app/pods/userConfig";
import { metrics } from "app/utils/metrics";
import { useFeatureSeen } from "@web-monorepo/shared/userSeen";
import { useGetTeacherMFAState } from "app/pods/userProfile";
import { useSessionFetcher } from "app/pods/auth";

export const STATE_KEY = "story";

export type ClassPermalinkedPost = APIResponse<"/api/dojoClass/{classId}/storyFeed/{postId}", "get"> & {
  calendarEvent?: CalendarEventMemberFetcherResponse;
};

export type TempComment = {
  _id: string;
  body: string;
};

type StoryState = {
  forbidden?: Record<string, boolean>;
  tempPosts?: Record<string, Record<string, TempPost>>;
  tempComments?: Record<string, Record<string, TempComment>>;
};

const resetState: StoryState = {
  forbidden: {}, // by targetId, whether user is forbidden from seeing target
  tempPosts: {}, // currently creating, by targetId and then by tempId
  tempComments: {}, // currently creating, by postId and then by tempId
};

const initialState: StoryState = {
  ...resetState,
};

//
// ----------------------------
// HANDLERS
//

const createStoryPostHandler = (state: StoryState, action: AnyAction) => {
  if (useCreateStoryPostOperation.isStartAction(action)) {
    const { targetId, body, attachments, tempId, tags } = action.payload.params;
    const tempPost = {
      tempId,
      time: new Date().toISOString(),
      tags,
      contents: {
        body,
        attachments,
      },
    };
    state = setWith(state, `tempPosts.${targetId}.${tempId}`, tempPost, Object);
  }

  if (useCreateStoryPostOperation.isDoneAction(action)) {
    const { targetId, tempId } = action.payload.params;
    state = omit(state, `tempPosts.${targetId}.${tempId}`);
  }

  return state;
};

const createStoryPostCommentHandler = (state: StoryState, action: AnyAction) => {
  if (useCreateStoryPostCommentOperation.isStartAction(action)) {
    const { postId, tempId, body } = action.payload.params;
    const tempComment = { _id: tempId, body };
    state = setWith(state, `tempComments.${postId}.${tempId}`, tempComment, Object);
  }

  if (useCreateStoryPostCommentOperation.isDoneAction(action)) {
    const { postId, tempId } = action.payload.params;
    state = omit(state, `tempComments.${postId}.${tempId}`);
  }

  return state;
};

const fetchStoryPostsHandler = (state: StoryState) => {
  return state;
};

const finalReducer = combineActionHandlers(initialState, [
  fetchStoryPostsHandler,
  createStoryPostHandler,
  createStoryPostCommentHandler,
]);

//
// -----------------------------------------
// SELECTORS
//

const sortPosts = (ps: object) => sortBy(ps, (post: TempPost) => -1 * Date.parse(post.time));

type StorySlice = {
  [STATE_KEY]: StoryState;
};

export const selectTempPostsForClassroomId = (state: StorySlice, classroomId: string): TempPost[] =>
  sortPosts(state[STATE_KEY].tempPosts?.[classroomId] || []);

export const selectTempPostsForSchoolId = (state: StorySlice, schoolId: string) =>
  sortPosts(state[STATE_KEY].tempPosts?.[schoolId] || []);

export const selectTempComments = (state: StorySlice) => state[STATE_KEY].tempComments || {};

export const selectIsForbiddenForSchoolId = (state: StorySlice, schoolId?: string) =>
  !!schoolId && !!state[STATE_KEY].forbidden?.[schoolId];

//
// -------------------------------
// Setup
//

const install: PodInstallFunction = (installReducer) => {
  installReducer(STATE_KEY, finalReducer);
};

export default install;

export const useShowStudentCommentNux = (classroomId: string, posts?: ClassStoryPost[]) => {
  const [showStudentCommentNux, setShowStudentCommentNux] = useState(false);
  const [postCountBaseline, setPostCountBaseline] = useState(0);
  const { data: userConfig } = useUserConfigFetcher({});
  const { data: classroom } = useClassroomFetcher({ id: classroomId });
  const saveMetadataOperation = useSaveMetadataOperation();
  const { mutate: updateStoryComment } = useUpdateClassroomStoryCommentOperation();

  useEffect(() => {
    if (userConfig && posts && classroom) {
      const hasDisabledComments = classroom?.preferences?.storyCommentsDisabled;
      const hasEnabledStudentComments = classroom?.preferences?.studentStoryCommentsEnabled;
      if (hasDisabledComments) {
        // bulk ignoring existing errors
        // eslint-disable-next-line @web-monorepo/no-setState-in-useEffect
        setPostCountBaseline(posts.length);
      }
      const hasDismissedNUX = (
        userConfig?.metaData?.seenStudentCommentNUXByClassId as undefined | Record<string, boolean>
      )?.[classroomId];

      const showStudentCommentNUX =
        !hasDismissedNUX && posts.length > postCountBaseline && !hasDisabledComments && !hasEnabledStudentComments;
      if (!showStudentCommentNUX) {
        return;
      }
      const timer = setTimeout(
        () =>
          // bulk ignoring existing errors
          // eslint-disable-next-line @web-monorepo/no-setState-in-useEffect
          setShowStudentCommentNux(showStudentCommentNUX),
        2000000,
      );
      return () => clearTimeout(timer);
    }
  }, [userConfig, classroomId, posts, classroom, postCountBaseline]);

  const handleNuxSuccessClick = useCallback(() => {
    const seenStudentCommentNUXByClassId = userConfig?.metaData.seenStudentCommentNUXByClassId;
    saveMetadataOperation.mutate({
      path: {
        metaDataName: "seenStudentCommentNUXByClassId",
      },
      body: {
        value: {
          ...(typeof seenStudentCommentNUXByClassId === "object" &&
          !Array.isArray(seenStudentCommentNUXByClassId) &&
          seenStudentCommentNUXByClassId !== null
            ? seenStudentCommentNUXByClassId
            : {}),
          [classroomId]: true,
        },
      },
    });
    updateStoryComment({
      classroomId,
      storyCommentsDisabled: !!classroom?.preferences?.storyCommentsDisabled,
      studentStoryCommentsEnabled: true,
      studentAccountConsent: true,
    });
    setShowStudentCommentNux(false);
  }, [setShowStudentCommentNux, saveMetadataOperation, userConfig, classroomId, updateStoryComment, classroom]);

  const handleNuxCancelClick = useCallback(() => {
    const seenStudentCommentNUXByClassId = userConfig?.metaData.seenStudentCommentNUXByClassId;
    saveMetadataOperation.mutate({
      path: { metaDataName: "seenStudentCommentNUXByClassId" },
      body: {
        value: {
          ...(typeof seenStudentCommentNUXByClassId === "object" &&
          !Array.isArray(seenStudentCommentNUXByClassId) &&
          seenStudentCommentNUXByClassId !== null
            ? seenStudentCommentNUXByClassId
            : {}),
          [classroomId]: true,
        },
      },
    });
    setShowStudentCommentNux(false);
  }, [setShowStudentCommentNux, saveMetadataOperation, userConfig, classroomId]);

  return { showStudentCommentNux, handleNuxSuccessClick, handleNuxCancelClick };
};

export const useReadPostsTracker = ({
  targetType,
  targetId,
  posts,
}: {
  targetType: MarkStoryPostsReadParams["targetType"];
  targetId?: MarkStoryPostsReadParams["targetId"];
  posts?: (SchoolPermalinkedPost | ClassStoryPost)[];
}) => {
  // use references so callbacks/effect don't get re-created/re-run
  // when current values change
  const pendingReads = useRef<string[]>([]);
  const initialReadDispatched = useRef(false);

  const _readPost = useCallback((postId: string) => {
    pendingReads.current.push(postId);
  }, []);

  const { mutate: markStoryPostsRead } = useMarkStoryPostsReadOperation();

  const _pushReadsToAPI = useCallback(() => {
    if (pendingReads.current.length > 0 && targetId) {
      markStoryPostsRead({ targetType, targetId, postIds: pendingReads.current });
      pendingReads.current = [];
    }
  }, [markStoryPostsRead, targetId, targetType]);

  useInterval(
    () => {
      _pushReadsToAPI();
    },
    // don't push reads by default if we are running on Cypress to avoid
    // flaky test failures from API calls done after test completes,
    // but before page is cleared

    window.Cypress && window.Cypress._onlyForCypressPushStoryPostReads !== true ? null : 1000,
  );

  useEffect(() => {
    // We want to be absolutely sure that we clear the unread post count
    // cached value when we first navigate to class story. So, dispatch a
    // read for our first post regardless of whether it's actually been
    // read. Double reads don't matter anyway.
    if (!initialReadDispatched.current && posts && posts.length > 0) {
      const latestPost = maxBy(Object.values(posts), (p: SchoolPermalinkedPost | ClassStoryPost) => Date.parse(p.time));
      typeof latestPost !== "number" && latestPost && "_id" in latestPost && _readPost(latestPost._id);
      initialReadDispatched.current = true;
    }
  }, [_readPost, posts]);

  return { _readPost };
};

export const useDisableSchoolStoryCommentsOperation = makeApiMutation({
  name: "disableSchoolStoryComments",
  path: "/api/dojoSchool/{schoolId}/disableStoryComments",
  method: "post",
  onMutate: (params) => {
    useSchoolFetcher.setQueriesData(
      (draft) => {
        if ("storyCommentsDisabled" in draft) {
          draft.storyCommentsDisabled = true;
        }
      },
      { id: params.path.schoolId },
    );
  },
  onSuccess: (_data, params) => {
    useSchoolStoryPostsFetcher.invalidateQueries({ schoolId: params.path.schoolId });
    useSchoolFetcher.invalidateQueries({ id: params.path.schoolId });
  },
});

type ClassStoryPostLikesResponse = APIResponse<"/api/dojoClass/{classId}/storyFeed/{postId}/likes", "get">;
type SchoolStoryPostLikesResponse = APIResponse<"/api/dojoSchool/{schoolId}/storyFeed/{postId}/likes", "get">;

export type StoryPostLikesResponse =
  | ClassStoryPostLikesResponse["_items"][number]
  | SchoolStoryPostLikesResponse["_items"][number];

const _useClassStoryPostLikesInternalFetcher = makeCollectionQuery({
  path: "/api/dojoClass/{classId}/storyFeed/{postId}/likes",
  query: { withStudentCommentsAndLikes: "true" },
  fetcherName: "classStoryPostLikes",
  onSuccess: (likes, params) => {
    useClassStoryPermalinkedPostFetcher.setQueriesData(
      (draft) => {
        draft.likeCount = likes.length;
      },
      { postId: params.postId },
    );
    useClassStoryPostsFetcher.setQueriesData(
      (draft) => {
        const post = draft.find((post) => post._id === params.postId);
        if (post) {
          post.likeCount = likes.length;
        }
      },
      { classId: params.classId },
    );
  },
});

const _useSchoolStoryPostLikesInternalFetcher = makeCollectionQuery({
  path: "/api/dojoSchool/{schoolId}/storyFeed/{postId}/likes",
  fetcherName: "schoolStoryPostLikes",
  onSuccess: (likes, params) => {
    useSchoolStoryPermalinkedPostFetcher.setQueriesData(
      (draft) => {
        draft.likeCount = likes.length;
      },
      { postId: params.postId },
    );
    useSchoolStoryPostsFetcher.setQueriesData(
      (draft) => {
        const post = draft.find((post) => post._id === params.postId);
        if (post) {
          post.likeCount = likes.length;
        }
      },
      { schoolId: params.schoolId },
    );
  },
});

export type CombinedTargetTypeFetcher<
  Params extends Record<string, unknown>,
  Type extends CollectionQueryType<Path, QueryParams>,
  Path extends keyof paths,
  QueryParams extends keyof EndpointQueryParameters<Path> = never,
> = (
  params: Params | typeof NOOP | typeof WAITING_FOR_DEPENDENCIES,
  // This should be Partial<QueryObserverOptions> eventually.
  reactQueryOptions?: { staleTime?: number },
) => ReturnType<Type>;

type UseStoryPostLikesFetcherParams = {
  targetType: "class" | "school";
  targetId: string;
  postId: string;
};

export const useStoryPostLikesFetcher: CombinedTargetTypeFetcher<
  UseStoryPostLikesFetcherParams,
  typeof _useClassStoryPostLikesInternalFetcher | typeof _useSchoolStoryPostLikesInternalFetcher,
  "/api/dojoClass/{classId}/storyFeed/{postId}/likes" | "/api/dojoSchool/{schoolId}/storyFeed/{postId}/likes"
> = (params, options) => {
  let classParams: CollectionFetcherParamsType<typeof _useClassStoryPostLikesInternalFetcher> = NOOP;
  let schoolParams: CollectionFetcherParamsType<typeof _useSchoolStoryPostLikesInternalFetcher> = NOOP;
  if (params === NOOP || params === WAITING_FOR_DEPENDENCIES) {
    classParams = params;
    schoolParams = params;
  } else if (params.targetType === "class") {
    classParams = { classId: params.targetId, postId: params.postId };
  } else if (params.targetType === "school") {
    schoolParams = { schoolId: params.targetId, postId: params.postId };
  } else {
    throw new Error(`Invalid story post type: ${params.targetType}`);
  }
  const classResults = _useClassStoryPostLikesInternalFetcher(classParams, options);
  const schoolResults = _useSchoolStoryPostLikesInternalFetcher(schoolParams, options);

  return params === NOOP || params === WAITING_FOR_DEPENDENCIES || params.targetType === "class"
    ? classResults
    : schoolResults;
};

type LikeStoryPostParams = {
  targetId: string;
  targetType: "class" | "school" | "homeActivity";
  postId: string;
  schoolId?: string | undefined;
};

export const useLikeStoryPostOperation = makeMutation<LikeStoryPostParams, CallApiDefaultResponse>({
  name: "likeStoryPost",
  fn: async ({ targetType, targetId, postId }) => {
    return await callApi({
      method: "POST",
      path: "/api/storyBatchAction",
      body: {
        actions: [{ action: "like", targetType, targetId, postId }],
      },
    });
  },
  onMutate: (params) => {
    const updatePost = (draft: components["schemas"]["StoryClassFeedCollectionGetResponse"]["_items"][number]) => {
      draft.likeCount = draft.likeCount + 1;
      if ("likeButton" in draft) {
        draft.likeButton = "liked";
      }
    };
    const findAndUpdatePost = (
      draft: Array<components["schemas"]["StoryClassFeedCollectionGetResponse"]["_items"][number]>,
    ) => {
      const post = draft.find((post) => post._id === params.postId);
      if (post) {
        updatePost(post);
      }
    };
    useClassStoryPostsFetcher.setQueriesData(findAndUpdatePost, { classId: params.targetId });
    useSchoolStoryPostsFetcher.setQueriesData(findAndUpdatePost, { schoolId: params.schoolId ?? params.targetId });
    useClassStoryPermalinkedPostFetcher.setQueriesData(updatePost, { postId: params.postId });
    useSchoolStoryPermalinkedPostFetcher.setQueriesData(updatePost, { postId: params.postId });
  },
});

type UseUnlikeStoryPostParams = {
  targetId: string;
  targetType: "class" | "school" | "homeActivity";
  postId: string;
  schoolId?: string | undefined;
};

export const useUnlikeStoryPostOperation = makeMutation<UseUnlikeStoryPostParams, CallApiDefaultResponse>({
  name: "unlikeStoryPost",
  fn: async ({ targetType, targetId, postId }) => {
    return await callApi({
      method: "POST",
      path: "/api/storyBatchAction",
      body: {
        actions: [{ action: "unlike", targetType, targetId, postId }],
      },
    });
  },
  onMutate: (params) => {
    const updatePost = (draft: components["schemas"]["StoryClassFeedCollectionGetResponse"]["_items"][number]) => {
      draft.likeCount = draft.likeCount - 1;
      if ("likeButton" in draft) {
        draft.likeButton = "unliked";
      }
    };
    const findAndUpdatePost = (
      draft: Array<components["schemas"]["StoryClassFeedCollectionGetResponse"]["_items"][number]>,
    ) => {
      const post = draft.find((post) => post._id === params.postId);
      if (post) {
        updatePost(post);
      }
    };
    useClassStoryPostsFetcher.setQueriesData(findAndUpdatePost, { classId: params.targetId });
    useSchoolStoryPostsFetcher.setQueriesData(findAndUpdatePost, { schoolId: params.schoolId ?? params.targetId });
    useClassStoryPermalinkedPostFetcher.setQueriesData(updatePost, { postId: params.postId });
    useSchoolStoryPermalinkedPostFetcher.setQueriesData(updatePost, { postId: params.postId });
  },
});

export type StoryPostReadsResponse =
  | CollectionFetcherReturnType<typeof _useStoryClassPostReadsInternalFetcher>
  | CollectionFetcherReturnType<typeof _useStorySchoolPostReadsInternalFetcher>;

const _useStoryClassPostReadsInternalFetcher = makeCollectionQuery({
  path: "/api/dojoClass/{targetId}/storyFeed/{postId}/views",
  query: { withStudentCommentsAndLikes: "true" },
  fetcherName: "classStoryPostReads",
  onSuccess: (reads, params) => {
    const updatePost = (draft: components["schemas"]["RenderedStoryPostResponse"]) => {
      const readCount = reads.filter((r) => r.readAt).length; // no readAt field means hasn't read.
      // no readAt field means hasn't read.
      draft.readCount = readCount;
    };
    const findAndUpdatePost = (draft: Array<components["schemas"]["RenderedStoryPostResponse"]>) => {
      const post = draft.find((post) => post._id === params.postId);
      if (post) {
        updatePost(post);
      }
    };
    useClassStoryPostsFetcher.setQueriesData(findAndUpdatePost, { classId: params.targetId });
    useClassStoryPermalinkedPostFetcher.setQueriesData(updatePost, { postId: params.postId });
  },
});

const _useStorySchoolPostReadsInternalFetcher = makeCollectionQuery({
  path: "/api/dojoSchool/{targetId}/storyFeed/{postId}/views",
  fetcherName: "schoolStoryPostReads",
  onSuccess: (reads, params) => {
    const updatePost = (draft: components["schemas"]["RenderedStoryPostResponse"]) => {
      const readCount = reads.filter((r) => r.readAt).length; // no readAt field means hasn't read.
      // no readAt field means hasn't read.
      draft.readCount = readCount;
    };
    const findAndUpdatePost = (draft: Array<components["schemas"]["RenderedStoryPostResponse"]>) => {
      const post = draft.find((post) => post._id === params.postId);
      if (post) {
        updatePost(post);
      }
    };
    useSchoolStoryPostsFetcher.setQueriesData(findAndUpdatePost, { schoolId: params.targetId });
    useSchoolStoryPermalinkedPostFetcher.setQueriesData(updatePost, { postId: params.postId });
  },
});

type UseStoryPostReadsParams = {
  targetType: "class" | "school";
  targetId: string;
  postId: string;
};

export const useStoryPostReadsFetcher: CombinedTargetTypeFetcher<
  UseStoryPostReadsParams,
  typeof _useStoryClassPostReadsInternalFetcher | typeof _useStorySchoolPostReadsInternalFetcher,
  "/api/dojoClass/{targetId}/storyFeed/{postId}/views" | "/api/dojoSchool/{targetId}/storyFeed/{postId}/views"
> = (params, options) => {
  let classParams: CollectionFetcherParamsType<typeof _useStoryClassPostReadsInternalFetcher> = NOOP;
  let schoolParams: CollectionFetcherParamsType<typeof _useStorySchoolPostReadsInternalFetcher> = NOOP;
  if (params === NOOP || params === WAITING_FOR_DEPENDENCIES) {
    classParams = params;
    schoolParams = params;
  } else if (params.targetType === "class") {
    classParams = { targetId: params.targetId, postId: params.postId };
  } else if (params.targetType === "school") {
    schoolParams = { targetId: params.targetId, postId: params.postId };
  } else {
    throw new Error(`Invalid story post type: ${params.targetType}`);
  }
  const classResults = _useStoryClassPostReadsInternalFetcher(classParams, options);
  const schoolResults = _useStorySchoolPostReadsInternalFetcher(schoolParams, options);

  return params === NOOP || params === WAITING_FOR_DEPENDENCIES || params.targetType === "class"
    ? classResults
    : schoolResults;
};

type MarkStoryPostsReadParams = {
  targetType: string;
  targetId: string;
  postIds: string[];
};

export const useMarkStoryPostsReadOperation = makeMutation<MarkStoryPostsReadParams, CallApiDefaultResponse>({
  name: "markStoryPostsRead",
  fn: async ({ targetType, targetId, postIds }) => {
    return await callApi({
      method: "POST",
      path: "/api/storyBatchAction",
      body: {
        actions: postIds.map((postId: string) => ({
          action: "read",
          targetType,
          targetId,
          postId,
        })),
      },
    });
  },
  onMutate: (params) => {
    useSchoolFetcher.setQueriesData(
      (draft) => {
        // [TSM] there are 3 possible _types and unreadStoryPostCount only appears on MemberGetVerifiedTeacherResponse
        if ("unreadStoryPostCount" in draft) {
          draft.unreadStoryPostCount = 0;
        }
      },
      { id: params.targetId },
    );
    useClassroomFetcher.setQueriesData((draft) => {
      if (params.targetId === draft._id) {
        draft.unreadStoryPostCount = 0;
      }
    });
    useAllClassroomFetcher.setQueriesData((draft) => {
      const classroomIndexToUpdate = draft.findIndex((classroom) => classroom._id === params.targetId);
      if (classroomIndexToUpdate !== -1) {
        draft[classroomIndexToUpdate] = { ...draft[classroomIndexToUpdate], unreadStoryPostCount: 0 };
      }
    });

    params.postIds.forEach((postId) => {
      useClassStoryPermalinkedPostFetcher.setQueriesData(
        (draft) => {
          draft.read = true;
        },
        { postId },
      );
      useSchoolStoryPermalinkedPostFetcher.setQueriesData(
        (draft) => {
          draft.read = true;
        },
        { postId },
      );
      useClassStoryPostsFetcher.setQueriesData(
        (draft) => {
          const post = draft.find((post) => post._id === postId);
          if (post && "read" in post) {
            post.read = true;
          }
        },
        { classId: params.targetId },
      );
      useSchoolStoryPostsFetcher.setQueriesData(
        (draft) => {
          const post = draft.find((post) => post._id === postId);
          if (post && "read" in post) {
            post.read = true;
          }
        },
        { schoolId: params.targetId },
      );
    });
  },
});

export type StoryPostCommentsResponse =
  | CollectionFetcherReturnType<typeof _useClassStoryPostCommentsInternalFetcher>
  | CollectionFetcherReturnType<typeof _useSchoolStoryPostCommentsInternalFetcher>;

const _useClassStoryPostCommentsInternalFetcher = makeCollectionQuery({
  path: "/api/dojoClass/{classId}/storyFeed/{postId}/comments",
  query: { withStudentCommentsAndLikes: "true" },
  fetcherName: "classStoryPostComments",
  onSuccess: (comments, params) => {
    const updateCommentCount = (draft: { commentCount: number }) => {
      draft.commentCount = comments.length;
    };
    const findAndUpdateCommentCount = (draft: { _id: string; commentCount: number }[]) => {
      const post = draft.find((post) => post._id === params.postId);
      if (post) {
        updateCommentCount(post);
      }
    };
    useClassStoryPermalinkedPostFetcher.setQueriesData(updateCommentCount, { postId: params.postId });
    useClassStoryPostsFetcher.setQueriesData(findAndUpdateCommentCount, { classId: params.classId });
  },
});

const _useSchoolStoryPostCommentsInternalFetcher = makeCollectionQuery({
  path: "/api/dojoSchool/{schoolId}/storyFeed/{postId}/comments",
  query: { withStudentCommentsAndLikes: "true" },
  fetcherName: "schoolStoryPostComments",
  onSuccess: (comments, params) => {
    const updateCommentCount = (draft: { commentCount: number }) => {
      draft.commentCount = comments.length;
    };
    const findAndUpdateCommentCount = (draft: { _id: string; commentCount: number }[]) => {
      const post = draft.find((post) => post._id === params.postId);
      if (post) {
        updateCommentCount(post);
      }
    };
    useSchoolStoryPermalinkedPostFetcher.setQueriesData(updateCommentCount, { postId: params.postId });
    useSchoolStoryPostsFetcher.setQueriesData(findAndUpdateCommentCount, { schoolId: params.schoolId });
  },
});

type UseStoryPostCommentsFetcherParams = {
  targetType: "class" | "school";
  targetId: string;
  postId: string;
};

export type Comment = components["schemas"]["RenderedComment"];

export const useStoryPostCommentsFetcher: CombinedTargetTypeFetcher<
  UseStoryPostCommentsFetcherParams,
  typeof _useClassStoryPostCommentsInternalFetcher | typeof _useSchoolStoryPostCommentsInternalFetcher,
  "/api/dojoClass/{classId}/storyFeed/{postId}/comments" | "/api/dojoSchool/{schoolId}/storyFeed/{postId}/comments"
> = (params, options) => {
  let classParams: CollectionFetcherParamsType<typeof _useClassStoryPostCommentsInternalFetcher> = NOOP;
  let schoolParams: CollectionFetcherParamsType<typeof _useSchoolStoryPostCommentsInternalFetcher> = NOOP;
  if (params === NOOP || params === WAITING_FOR_DEPENDENCIES) {
    classParams = params;
    schoolParams = params;
  } else if (params.targetType === "class") {
    classParams = { classId: params.targetId, postId: params.postId };
  } else if (params.targetType === "school") {
    schoolParams = { schoolId: params.targetId, postId: params.postId };
  } else {
    throw new Error(`Invalid story post type: ${params.targetType}`);
  }
  const classResults = _useClassStoryPostCommentsInternalFetcher(classParams, options);
  const schoolResults = _useSchoolStoryPostCommentsInternalFetcher(schoolParams, options);

  return params === NOOP || params === WAITING_FOR_DEPENDENCIES || params.targetType === "class"
    ? classResults
    : schoolResults;
};

type CreateClassStoryPostCommentOperationRequest = APIRequestBody<
  "/api/dojoClass/{classId}/storyFeed/{postId}/comments",
  "post"
>;
type CreateSchoolStoryPostCommentOperationRequest = APIRequestBody<
  "/api/dojoSchool/{schoolId}/storyFeed/{postId}/comments",
  "post"
>;

type CreateStoryPostCommentParams = {
  targetType: "class" | "school";
  targetId: string;
  postId: string;
  tempId: string;
  body: CreateClassStoryPostCommentOperationRequest["body"] | CreateSchoolStoryPostCommentOperationRequest["body"];
};

export const useCreateStoryPostCommentOperation = makeMutation<CreateStoryPostCommentParams, CallApiDefaultResponse>({
  name: "createStoryPostComment",
  fn: async ({ targetType, targetId, postId, body /*, tempId */ }) => {
    const targetPrefix = targetType === "class" ? "dojoClass" : "dojoSchool";
    return await callApi({
      method: "POST",
      path: `/api/${targetPrefix}/${targetId}/storyFeed/${postId}/comments`,
      body: {
        body,
      },
    });
  },
  onMutate: (params) => {
    useClassStoryPermalinkedPostFetcher.setQueriesData(
      (draft) => {
        draft.commentCount = draft.commentCount + 1;
      },
      { postId: params.postId },
    );
    useSchoolStoryPermalinkedPostFetcher.setQueriesData(
      (draft) => {
        draft.commentCount = draft.commentCount + 1;
      },
      { postId: params.postId },
    );
    useClassStoryPostsFetcher.setQueriesData(
      (draft) => {
        const post = draft.find((post) => post._id === params.postId);
        if (post) {
          post.commentCount = post.commentCount + 1;
        }
      },
      { classId: params.targetId },
    );
    useSchoolStoryPostsFetcher.setQueriesData(
      (draft) => {
        const post = draft.find((post) => post._id === params.postId);
        if (post) {
          post.commentCount = post.commentCount + 1;
        }
      },
      { schoolId: params.targetId },
    );
  },
  onSuccess: (data, params) => {
    _useClassStoryPostCommentsInternalFetcher.setQueriesData(
      (draft) => {
        return draft.concat(data.body);
      },
      { postId: params.postId },
    );
    _useSchoolStoryPostCommentsInternalFetcher.setQueriesData(
      (draft) => {
        return draft.concat(data.body);
      },
      { postId: params.postId },
    );
  },
});

export const useTranslateStoryPostCommentOperation = makeApiMutation({
  name: "translateStoryPostComment",
  path: "/api/storyPost/{postId}/comment/{commentId}/translate",
  method: "post",
  onSuccess: (data, params) => {
    _useClassStoryPostCommentsInternalFetcher.setQueriesData((draft) => {
      const comment = draft.find((comment) => comment._id === params.path.commentId);
      if (comment) {
        comment.translatedContents = data.body.translatedContents;
      }
    });
    _useSchoolStoryPostCommentsInternalFetcher.setQueriesData((draft) => {
      const comment = draft.find((comment) => comment._id === params.path.commentId);
      if (comment) {
        comment.translatedContents = data.body.translatedContents;
      }
    });
    useClassCalendarEventCommentsFetcher.setQueriesData((draft) => {
      const comment = draft.find((comment) => comment._id === params.path.commentId);
      if (comment) {
        comment.translatedContents = data.body.translatedContents;
      }
    });
    useSchoolCalendarEventCommentsFetcher.setQueriesData((draft) => {
      const comment = draft.find((comment) => comment._id === params.path.commentId);
      if (comment) {
        comment.translatedContents = data.body.translatedContents;
      }
    });
  },
});

type DeleteStoryPostCommentOperationParams = {
  targetType: "school" | "class";
  targetId: string;
  postId: string;
  commentId: string;
};

type DeleteClassStoryPostCommentOperationResponse = APIResponse<
  "/api/dojoClass/{classId}/storyFeed/{postId}/comments/{commentId}",
  "delete"
>;
type DeleteSchoolStoryPostCommentOperationResponse = APIResponse<
  "/api/dojoSchool/{schoolId}/storyFeed/{postId}/comments/{commentId}",
  "delete"
>;

type DeleteStoryPostCommentOperationResponse =
  | DeleteClassStoryPostCommentOperationResponse
  | DeleteSchoolStoryPostCommentOperationResponse;

export const useDeleteStoryPostCommentOperation = makeMutation<
  DeleteStoryPostCommentOperationParams,
  DeleteStoryPostCommentOperationResponse
>({
  name: "deleteStoryPostComment",
  fn: async ({ targetType, targetId, postId, commentId }) => {
    const targetPrefix = targetType === "class" ? "dojoClass" : "dojoSchool";
    try {
      return await callApi({
        method: "DELETE",
        path: `/api/${targetPrefix}/${targetId}/storyFeed/${postId}/comments/${commentId}`,
      });
      // eslint-disable-next-line no-catch-all/no-catch-all
    } catch (err: any) {
      return err;
    }
  },
  onMutate: (params) => {
    useClassStoryPermalinkedPostFetcher.setQueriesData(
      (draft) => {
        draft.commentCount = draft.commentCount - 1;
      },
      { postId: params.postId },
    );
    useSchoolStoryPermalinkedPostFetcher.setQueriesData(
      (draft) => {
        draft.commentCount = draft.commentCount - 1;
      },
      { postId: params.postId },
    );
    _useClassStoryPostCommentsInternalFetcher.setQueriesData(
      (draft) => {
        return draft.filter((comment) => comment._id !== params.commentId);
      },
      { postId: params.postId },
    );
    _useSchoolStoryPostCommentsInternalFetcher.setQueriesData(
      (draft) => {
        return draft.filter((comment) => comment._id !== params.commentId);
      },
      { postId: params.postId },
    );
    useClassStoryPostsFetcher.setQueriesData(
      (draft) => {
        const post = draft.find((post) => post._id === params.postId);
        if (post) {
          post.commentCount = post.commentCount - 1;
        }
      },
      { classId: params.targetId },
    );
    useSchoolStoryPostsFetcher.setQueriesData(
      (draft) => {
        const post = draft.find((post) => post._id === params.postId);
        if (post) {
          post.commentCount = post.commentCount - 1;
        }
      },
      { schoolId: params.targetId },
    );
  },
});

type CreateSchoolPostRequestBody = APIRequestBody<"/api/dojoSchool/{schoolId}/storyFeed", "post">;
type CreateClassPostRequestBody = APIRequestBody<"/api/dojoClass/{classId}/storyFeed", "post">;
type CreatePostRequestBody = CreateSchoolPostRequestBody | CreateClassPostRequestBody;

export type UseCreateStoryPostParams = Pick<
  CreatePostRequestBody,
  "targetId" | "body" | "attachments" | "tags" | "time" | "scheduled"
> & {
  targetType: "class" | "school";
  tempId: string;
};
export const useCreateStoryPostOperation = makeMutation<UseCreateStoryPostParams, CallApiDefaultResponse>({
  name: "createStoryPost",
  fn: async ({ targetType, targetId, body, attachments, tags, time, scheduled }) => {
    const targetPrefix = targetType === "class" ? "dojoClass" : "dojoSchool";
    return await callApi({
      method: "POST",
      path: `/api/${targetPrefix}/${targetId}/storyFeed`,
      body: {
        targetType,
        targetId,
        body,
        attachments,
        private: tags && tags.length > 0,
        // origin: "capture",
        // ^^ uncomment this to mimic posts sent from capture app
        tags: tags || undefined,
        time: time || undefined,
        scheduled: scheduled || undefined,
      },
    });
  },
  onSuccess: (data, params) => {
    usePortfolioAllPostsFetcher.invalidateQueries();
    useClassStoryScheduledPostsFetcher.invalidateQueries({ classId: params.targetId });
    useSchoolStoryScheduledPostsFetcher.invalidateQueries({ schoolId: params.targetId });
    if (!data.body.scheduled) {
      useClassStoryPostsFetcher.setQueriesData(
        (draft) => {
          const post = data.body;
          return [post, ...draft];
        },
        { classId: params.targetId },
      );
      useSchoolStoryPostsFetcher.setQueriesData(
        (draft) => {
          const post = data.body;
          return [post, ...draft];
        },
        { schoolId: params.targetId },
      );
    }
  },
});

type EditClassStoryPostRequestBody = APIRequestBody<"/api/dojoClass/{classId}/storyFeed/{postId}", "put">;
type EditSchoolStoryPostRequestBody = APIRequestBody<"/api/dojoSchool/{schoolId}/storyFeed/{postId}", "put">;

type EditStoryPostParams = {
  targetType: "school" | "class";
  targetId: string;
  postId: string;
  body: EditClassStoryPostRequestBody["body"] | EditSchoolStoryPostRequestBody["body"];
  attachments: components["schemas"]["Attachment"][];
  existingPost: StoryPost;
  time?: string;
  scheduled?: boolean;
};

export const useEditStoryPostOperation = makeMutation<EditStoryPostParams, CallApiDefaultResponse>({
  name: "editStoryPost",
  fn: async ({ existingPost, targetType, targetId, postId, body, attachments, time, scheduled }) => {
    // if attachment hasn't actually changed. don't send attachments to API and it knows to keep the old ones around
    const attachmentsChanged = !existingPost || existingPost.contents.attachments[0] !== attachments?.[0];

    const targetPrefix = targetType === "class" ? "dojoClass" : "dojoSchool";

    return await callApi({
      method: "PUT",
      path: `/api/${targetPrefix}/${targetId}/storyFeed/${postId}`,
      body: {
        body,
        attachments: attachmentsChanged ? attachments : undefined,
        time: time || undefined,
        scheduled: scheduled || undefined,
      },
    });
  },
  onMutate: (params) => {
    const updatePost = (draft: components["schemas"]["StoryClassFeedCollectionGetResponse"]["_items"][number]) => {
      const { body, attachments } = params;
      draft.contents.body = body || "";
      if (attachments) {
        draft.contents.attachments = attachments;
      }
    };
    const findAndUpdatePost = (
      draft: Array<components["schemas"]["StoryClassFeedCollectionGetResponse"]["_items"][number]>,
    ) => {
      const post = draft.find((post) => post._id === params.postId);
      if (post) {
        updatePost(post);
      }
    };

    useClassStoryPostsFetcher.setQueriesData(findAndUpdatePost, { classId: params.targetId });
    useSchoolStoryPostsFetcher.setQueriesData(findAndUpdatePost, { schoolId: params.targetId });
    useClassStoryPermalinkedPostFetcher.setQueriesData(updatePost, { postId: params.postId });
    useSchoolStoryPermalinkedPostFetcher.setQueriesData(updatePost, { postId: params.postId });
    useClassStoryScheduledPostsFetcher.setQueriesData(findAndUpdatePost, { classId: params.targetId });
    useSchoolStoryScheduledPostsFetcher.setQueriesData(findAndUpdatePost, { schoolId: params.targetId });
  },
  onSuccess: (data, params) => {
    useClassStoryPermalinkedPostFetcher.setQueriesData(data.body, { postId: params.postId });
    useSchoolStoryPermalinkedPostFetcher.setQueriesData(data.body, { postId: params.postId });
    useClassStoryPostsFetcher.setQueriesData(
      (draft) => {
        const { postId } = params;

        const postIndex = draft.findIndex((post) => post._id === postId);
        if (postIndex !== -1) {
          const post = data.body;
          draft[postIndex] = post;
        }
      },
      { classId: params.targetId },
    );
    useSchoolStoryPostsFetcher.setQueriesData(
      (draft) => {
        const { postId } = params;

        const postIndex = draft.findIndex((post) => post._id === postId);
        if (postIndex !== -1) {
          const post = data.body;
          draft[postIndex] = post;
        }
      },
      { schoolId: params.targetId },
    );
    useClassStoryScheduledPostsFetcher.setQueriesData(
      (draft) => {
        const { postId } = params;
        const postIndex = draft.findIndex((post) => post._id === postId);
        if (postIndex !== -1) {
          const post = data.body;
          draft[postIndex] = post;
        }
      },
      { classId: params.targetId },
    );
    useSchoolStoryScheduledPostsFetcher.setQueriesData((draft) => {
      const { postId } = params;

      const postIndex = draft.findIndex((post) => post._id === postId);
      if (postIndex !== -1) {
        const post = data.body;
        draft[postIndex] = post;
      }
    });
  },
});

type RemoveStoryPostParams = {
  targetType: "class" | "school";
  targetId: string;
  postId: string;
};

export const useRemoveStoryPostOperation = makeMutation<RemoveStoryPostParams, CallApiDefaultResponse>({
  name: "removeStoryPost",
  fn: async ({ targetType, targetId, postId }) => {
    const targetPrefix = targetType === "class" ? "dojoClass" : "dojoSchool";
    return await callApi({
      method: "DELETE",
      path: `/api/${targetPrefix}/${targetId}/storyFeed/${postId}`,
    });
  },
  onMutate: (params) => {
    useClassStoryPermalinkedPostFetcher.setQueriesData(null, { postId: params.postId });
    useSchoolStoryPermalinkedPostFetcher.setQueriesData(null, { postId: params.postId });
    useClassStoryScheduledPostsFetcher.setQueriesData(
      (draft) => {
        return draft.filter((post) => post._id !== params.postId);
      },
      { classId: params.targetId },
    );
    useSchoolStoryScheduledPostsFetcher.setQueriesData(
      (draft) => {
        return draft.filter((post) => post._id !== params.postId);
      },
      { schoolId: params.targetId },
    );
    useClassStoryPostsFetcher.setQueriesData(
      (draft) => {
        const { postId } = params;
        return draft.filter((post) => post._id !== postId);
      },
      { classId: params.targetId },
    );
    useSchoolStoryPostsFetcher.setQueriesData(
      (draft) => {
        const { postId } = params;
        return draft.filter((post) => post._id !== postId);
      },
      { schoolId: params.targetId },
    );
  },
});

export const useClassStoryPermalinkedPostFetcher = makeMemberQuery({
  path: "/api/dojoClass/{classId}/storyFeed/{postId}",
  fetcherName: "classStoryPermalinkedPost",
  dontThrowOnStatusCodes: [404],
});

export type SchoolPermalinkedPost = APIResponse<"/api/dojoSchool/{schoolId}/storyFeed/{postId}", "get">;
export const useSchoolStoryPermalinkedPostFetcher = makeMemberQuery({
  path: "/api/dojoSchool/{schoolId}/storyFeed/{postId}",
  fetcherName: "schoolStoryPermalinkedPost",
  dontThrowOnStatusCodes: [404],
});

export type ClassStoryPost = APIResponse<"/api/dojoClass/{classId}/storyFeed", "get">["_items"][number] & {
  calendarEvent?: CalendarEventMemberFetcherResponse;
};

function uniqPosts<T extends { senderName?: string; _id: string; time: string }>(posts: T[], target: string) {
  const results: Record<string, T> = {};
  let dupesFound = 0;

  for (const item of posts) {
    const isSyntheticPost = item.senderName === "Mojo";

    if (!results[item._id]) {
      results[item._id] = item;
    } else {
      if (!isSyntheticPost) {
        dupesFound++;
      }
    }
  }

  if (dupesFound > 0) {
    metrics.increment("web.teach.story_feed.duplicate_posts", { target });
  } else {
    metrics.increment("web.teach.story_feed.no_duplicate_posts", { target });
  }

  return sortBy(Object.values(results), (elem) => -1 * +new Date(elem.time));
}

export const useClassStoryPostsFetcher = makeCollectionQuery({
  path: "/api/dojoClass/{classId}/storyFeed",
  query: { supportsCalendarEvents: "true", withStudentCommentsAndLikes: "true" },
  fetcherName: "classStoryPosts",
  onSuccess: (_data, params) => {
    useClassStoryPostsFetcher.setQueriesData(
      (draft) => {
        // Sometimes, the load more mechanism still causes duplicate API requests to be made
        // for the same data page, which makes the loaded data to contain duplicate story posts.
        // This side-steps the problem by discarding any duplicates, since I've been unable so far
        // to prevent the issue from happening in the first place
        return uniqPosts(draft, "class");
      },
      { classId: params.classId },
    );
  },
});

export const useClassStoryScheduledPostsFetcher = makeCollectionQuery({
  path: "/api/dojoClass/{classId}/storyFeedScheduled",
  fetcherName: "classStoryScheduledPosts",
});

export type SchoolStoryPost = APIResponse<"/api/dojoSchool/{schoolId}/storyFeed", "get">["_items"][number];
export const useSchoolStoryPostsFetcher = makeCollectionQuery({
  path: "/api/dojoSchool/{schoolId}/storyFeed",
  query: { supportsCalendarEvents: "true" },
  queryParams: ["unified"],
  fetcherName: "schoolStoryPosts",
  dontThrowOnStatusCodes: [403],
  onSuccess: (_data, params) => {
    useSchoolStoryPostsFetcher.setQueriesData(
      (draft) => {
        // Sometimes, the load more mechanism still causes duplicate API requests to be made
        // for the same data page, which makes the loaded data to contain duplicate story posts.
        // This side-steps the problem by discarding any duplicates, since I've been unable so far
        // to prevent the issue from happening in the first place
        return uniqPosts(draft, "school");
      },
      { schoolId: params.schoolId },
    );
  },
});

export const useSchoolStoryScheduledPostsFetcher = makeCollectionQuery({
  path: "/api/dojoSchool/{schoolId}/storyFeedScheduled",
  fetcherName: "schoolStoryScheduledPosts",
});

export const useStoryPostFetcher = makeMemberQuery({
  path: "/api/storyPost/{postId}",
  fetcherName: "memberStoryPost",
});

export const useShowMfaPromotionBanner = () => {
  const [isBannerVisible, setIsBannerVisible] = useState(true);
  const { data: session } = useSessionFetcher({});
  const teacher = session && session.teacher;

  if (!teacher) {
    throw new Error("Teacher session not found");
  }

  const { data } = useGetTeacherMFAState({ teacherId: teacher._id });
  const isMfaEnabled = data?.enabled;

  const featureSeen = useFeatureSeen("opt_in_mfa_promotion");
  const featureLastSeen = featureSeen?.data?.lastSeen;

  let lastChecked30DaysAgo = true;
  if (featureLastSeen) {
    const dateToCheck = new Date(featureLastSeen);
    lastChecked30DaysAgo = isDate30DaysOlder(dateToCheck);
  }

  const isSSOLogin = !!session.oidc;

  const onDismissMfaPromoBanner = () => {
    setIsBannerVisible(false);
    featureSeen.increment();
  };

  const shouldShowMfaPromotion = isBannerVisible && !isMfaEnabled && lastChecked30DaysAgo && !isSSOLogin;
  return { shouldShowMfaPromotion, onDismissMfaPromoBanner };
};

function isDate30DaysOlder(dateToCheck: Date) {
  // Ensure dateToCheck is a Date object
  if (!(dateToCheck instanceof Date) || isNaN(dateToCheck.getTime())) {
    throw new Error("Invalid date provided");
  }

  const today = new Date();

  const thirtyDaysAgo = new Date(today);
  thirtyDaysAgo.setDate(today.getDate() - 30);
  return dateToCheck < thirtyDaysAgo;

  // FOR TESTING PURPOSES
  // const oneMinuteAgo = new Date(today);
  // oneMinuteAgo.setMinutes(today.getMinutes() - 1);
  // return dateToCheck < oneMinuteAgo;
}
