import createAction from "@web-monorepo/infra/createAction";
import createActionHandler from "@web-monorepo/infra/createActionHandler";
import { AwardState } from "app/pods/award";

export const GIVE_RANDOM = createAction("award/give-random");

export const giveRandom = (studentId: string, classroomId: string, local?: boolean) => ({
  type: GIVE_RANDOM,

  payload: {
    classroomId,
    studentId,
    local: local === undefined ? true : local,
  },
});

type RandomHandlerProps = {
  studentId: string;
  local: boolean;
};

export const giveRandomHandler = createActionHandler(
  GIVE_RANDOM,
  ({ studentId, local }: RandomHandlerProps) =>
    (state: AwardState): AwardState => ({
      ...state,
      randomSelection: !studentId
        ? undefined
        : {
            studentId,
            local,
          },
    }),
);
