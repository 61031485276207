import { T } from "app/utils/T";
import { StudentAvatar } from "@classdojo/web";
import { ThemeUIStyleObject, RAW_cssValue } from "@classdojo/web/nessie/stylingLib";
import sortBy from "lodash/sortBy";
import { ClassStudent } from "app/pods/student";

type GroupAvataProps = {
  students: ClassStudent[];
};

const GroupAvatar = (props: GroupAvataProps) => {
  const _renderAvatar = (student: ClassStudent) => {
    return <StudentAvatar key={student._id} src={student.avatar} sx={avatarStyle} />;
  };

  const students = sortBy(props.students, "_id").slice(0, 4);

  if (students.length % 2 === 1) {
    return (
      <div sx={containerStyle}>
        <div sx={avatarContainerStyle}>{students.filter(evenIdx).map(_renderAvatar)}</div>
        <div sx={{ ...avatarContainerStyle, ...avatarContainerDownStyle }}>
          {students.filter(oddIdx).map(_renderAvatar)}
        </div>
      </div>
    );
  } else if (students.length > 0) {
    return (
      <div sx={containerStyle}>
        <div sx={{ ...avatarContainerStyle, ...avatarContainerRightStyle }}>
          {students.filter(evenIdx).map(_renderAvatar)}
        </div>
        <div sx={{ ...avatarContainerStyle, ...avatarContainerLeftStyle, ...avatarContainerDownStyle }}>
          {students.filter(oddIdx).map(_renderAvatar)}
        </div>
      </div>
    );
  } else {
    return (
      <div sx={containerStyle}>
        <div sx={avatarContainerStyle}>
          <span sx={{ fontSize: "5rem" }}>
            <T str="dojo.teacher_web:group_avatar.no_students" fallback="?" />
          </span>
        </div>
      </div>
    );
  }
};

function evenIdx(_: ClassStudent, i: number) {
  return i % 2 === 0;
}

function oddIdx(_: ClassStudent, i: number) {
  return i % 2 === 1;
}

export default GroupAvatar;

const containerStyle: ThemeUIStyleObject = {
  textAlign: "center",
};

const avatarContainerStyle: ThemeUIStyleObject = {
  position: "absolute",
  width: "100%",
  textAlign: "center",
  top: "2rem",
};

const avatarContainerDownStyle: ThemeUIStyleObject = {
  paddingTop: RAW_cssValue("0.2rem"),
};

const avatarContainerLeftStyle: ThemeUIStyleObject = {
  marginLeft: RAW_cssValue("-1.5rem"),
};

const avatarContainerRightStyle: ThemeUIStyleObject = {
  marginLeft: RAW_cssValue("1.5rem"),
};

const avatarStyle: ThemeUIStyleObject = {
  margin: RAW_cssValue("0 0.5rem"),
};
