import { components } from "@classdojo/ts-api-types/api";
import callApi, { CallApiDefaultResponse } from "@web-monorepo/infra/callApi";
import {
  APIRequestBody,
  APIRequestParameters,
  CollectionFetcherReturnType,
  MemberFetcherReturnType,
} from "@web-monorepo/shared/api/apiTypesHelper";
import { useClassMessageThreadsFetcher } from "@web-monorepo/shared/messaging/hooks";
import { PodInstallFunction } from "@web-monorepo/shared/podInfra";
import { makeCollectionQuery, makeMemberQuery, makeMutation } from "@web-monorepo/shared/reactQuery";
import partition from "lodash/partition";
import type { AnyAction } from "redux";
import { useSchoolMinimalStudentsFetcher, useSchoolStudentsFetcher } from "app/pods/schoolStudents";
import { useStudentsFetcher } from "app/pods/student/fetchers";

//
// -----------------------------------
// STATE
//
const STATE_KEY = "studentMerge";

type Slice = {
  [STATE_KEY]: {
    oldToNewStudentIdMap: Record<string, string>;
  };
};

const initialState = {
  oldToNewStudentIdMap: {},
};

//
// ----------------------------
// HANDLERS
//
function studentMergeReducer(state = initialState, action: AnyAction) {
  if (useMergeStudentsOperation.isDoneAction(action) || useRejectStudentMergeSuggestionOperation.isDoneAction(action)) {
    const apiResponse = action.payload.data.body || {};
    const { studentIds } = action.payload.params;
    const [[newId], [oldId]] = partition(studentIds, (id) => id === apiResponse._id);
    return {
      oldToNewStudentIdMap: {
        ...state.oldToNewStudentIdMap,
        [oldId]: newId,
      },
    };
  }
  return state;
}

//
// -----------------------------------------
// SELECTORS
//
export const selectOldToNewStudentIdMap = (state: Slice) => state?.[STATE_KEY]?.oldToNewStudentIdMap;

//
// -------------------------------
// Setup
//

const install: PodInstallFunction = (installReducer) => {
  installReducer(STATE_KEY, studentMergeReducer);
};

export default install;

export type PotentialMerge = MemberFetcherReturnType<typeof usePotentialMergeFetcher>;

export const usePotentialMergeFetcher = makeMemberQuery({
  path: "/api/dojoStudent/{studentId}/merge",
  queryParams: ["with"],
  fetcherName: "studentPotentialMerge",
  dontThrowOnStatusCodes: [400],
});

export type MergeSuggestion = CollectionFetcherReturnType<typeof useClassroomMergeSuggestionsFetcher>;

export type MergeSuggestionStudent = MergeSuggestion["students"][number];

export const useClassroomMergeSuggestionsFetcher = makeCollectionQuery({
  path: "/api/dojoSchool/{schoolId}/dojoClass/{classId}/mergeSuggestion",
  fetcherName: "classroomMergeSuggestions",
  dontThrowOnStatusCodes: [400],
});

export type MergeStudentsParams = APIRequestBody<"/api/dojoStudent/{studentId}/merge", "post"> & {
  schoolId?: string;
  classroomId?: string;
};

const removeMergeSuggestions = (
  mergeSuggestions: components["schemas"]["ItemsMergeSuggestionObject"]["_items"],
  studentIds: string[],
) => {
  const idsToRemove = [...studentIds].sort().join("-");

  return mergeSuggestions.filter((mergeSuggestion) => {
    const suggestionIds = mergeSuggestion.students
      .map((s) => s._id)
      .sort()
      .join("-");

    return suggestionIds !== idsToRemove;
  });
};

export const useMergeStudentsOperation = makeMutation<MergeStudentsParams, CallApiDefaultResponse>({
  name: "mergeStudents",
  async fn({ studentIds /*, schoolId, classroomId */ }) {
    const [targetId, otherId] = studentIds;
    return await callApi({
      method: "POST",
      path: `/api/dojoStudent/${targetId}/merge`,
      body: { studentIds: [otherId] },
    });
  },
  onSuccess: (data, params) => {
    useStudentsFetcher.invalidateQueries({ classId: params.classroomId });
    useSchoolMinimalStudentsFetcher.invalidateQueries({ schoolId: params.schoolId });
    useClassMessageThreadsFetcher.invalidateQueries({ classId: params.classroomId });
    useClassroomMergeSuggestionsFetcher.setQueriesData((draft) => removeMergeSuggestions(draft, params.studentIds), {
      classId: params.classroomId,
      schoolId: params.schoolId,
    });
    useSchoolMinimalStudentsFetcher.invalidateQueries({ schoolId: params.schoolId });
    useSchoolStudentsFetcher.setQueriesData((draft) => {
      if (params.schoolId === draft[0].schoolId) {
        const [targetId, otherId] = params.studentIds;
        const { body } = data;
        // remove merged students
        // add merge result student
        return draft.filter((student) => student._id !== targetId && student._id !== otherId).concat(body);
      }
    });
  },
});

export type RejectMergeSuggestionParams = APIRequestParameters<
  "/api/dojoSchool/{schoolId}/mergeRejection",
  "post"
>["path"] &
  APIRequestBody<"/api/dojoSchool/{schoolId}/mergeRejection", "post"> & { classroomId: string };

export const useRejectStudentMergeSuggestionOperation = makeMutation<
  RejectMergeSuggestionParams,
  CallApiDefaultResponse
>({
  name: "rejectStudentMergeSuggestion",
  async fn({ schoolId, studentIds /*, classroomId */ }) {
    return await callApi({
      method: "POST",
      path: `/api/dojoSchool/${schoolId}/mergeRejection`,
      body: { studentIds },
    });
  },
  onSuccess: (_data, params) => {
    useClassroomMergeSuggestionsFetcher.setQueriesData((draft) => removeMergeSuggestions(draft, params.studentIds), {
      classId: params.classroomId,
      schoolId: params.schoolId,
    });
  },
});
