import { format, set } from "@web-monorepo/dates";
import { autoTranslate } from "@web-monorepo/vite-auto-translate-plugin/runtime";
import { useEffect, useState } from "react";
import { ComboBox } from "../../nessie";
import { ClockIcon } from "../../nessie/icons";

export function TimePicker({
  date,
  setDate,
  modalId,
  contentSide,
  labelText,
}: {
  date: Date;
  setDate: (date: Date) => void;
  labelText?: string;
  modalId?: string;
  contentSide?: "top" | "bottom" | "left" | "right";
}) {
  const [container, setContainer] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const containerEl = modalId ? document.getElementById(modalId) : null;
    if (containerEl) {
      // bulk ignoring existing errors
      // eslint-disable-next-line @web-monorepo/no-setState-in-useEffect
      setContainer(containerEl);
    }
  }, [modalId]);
  const timeOptions = getHalfHourIntervals(date).map((date) => {
    return { label: format(date, { timeStyle: "short" }), value: date.toString() };
  });

  return (
    <ComboBox
      labelText={labelText || autoTranslate("Time")}
      selectedValue={date.toString()}
      labelPosition="hidden"
      options={timeOptions}
      onSelectOption={(option) => setDate(new Date(option.value))}
      icon={<ClockIcon />}
      portalContainer={container}
      contentSide={contentSide}
    />
  );
}

function getHalfHourIntervals(selectedDate: Date) {
  const now = new Date();
  return Array.from({ length: 48 }, (_, halfHour) =>
    set(selectedDate, {
      hours: Math.floor(halfHour / 2),
      minutes: halfHour % 2 ? 30 : 0,
      seconds: 0,
      milliseconds: 0,
    }),
  ).filter((date) => date > now);
}
