import callApi from "@web-monorepo/infra/callApi";
import { APIRequestBody, APIResponse, CollectionFetcherReturnType } from "@web-monorepo/shared/api/apiTypesHelper";
import { makeCollectionQuery, makeApiMutation, makeMutation } from "@web-monorepo/shared/reactQuery";
import { useEffect, useCallback } from "react";
import errors from "app/errorTypes";
import { Classroom } from "app/pods/classroom";
import { downloadStudentReportCSV, downloadClassroomReportCSV } from "app/pods/reports/util/ReportGeneration";
import { useCheckUntilDownloadLinkIsReady } from "app/pods/shared/util/useCheckUntilDownloadLinkIsReady";
import type { StudentsWithClassPreferences as Students } from "app/pods/student";
import { parse } from "@web-monorepo/dates";

export const useStudentAwardsFetcher = makeCollectionQuery({
  fetcherName: "studentAwardsWithDate",
  path: "/api/award",
  queryParams: ["studentId", "from", "to"],
});

export const useClassroomAwardsFetcher = makeCollectionQuery({
  path: "/api/award",
  queryParams: ["classId", "from", "to"],
  fetcherName: "classroomAwards",
});
export type ClassroomAward = CollectionFetcherReturnType<typeof useClassroomAwardsFetcher>;

export const useClassroomAndStudentAwardsFetcher = makeCollectionQuery({
  path: "/api/award",
  queryParams: ["classId", "studentId", "from", "to"],
  fetcherName: "classroomAndStudentAwards",
});

export const useRedemptionClassroomFetcher = makeCollectionQuery({
  path: "/api/teacherPointRedemptionReport",
  queryParams: ["classId", "from", "to"],
  fetcherName: "classroomRedemptions",
});

export const useRedemptionClassroomAndStudentFetcher = makeCollectionQuery({
  path: "/api/teacherPointRedemptionReport",
  queryParams: ["classId", "from", "to", "studentId"],
  fetcherName: "classroomAndStudentRedemptions",
});

export const useRedemptionStudentFetcher = makeCollectionQuery({
  path: "/api/teacherPointRedemptionReport",
  queryParams: ["from", "to", "studentId"],
  fetcherName: "StudentRedemptions",
});

export const useClassroomStudentNotesFetcher = makeCollectionQuery({
  path: "/api/comment",
  queryParams: ["classId", "from", "to", "studentId"],
  fetcherName: "classStudentNotes",
});

type AddNoteBody = Omit<APIRequestBody<"/api/commentBatch", "post">, "classId"> & { classroomId: string };
export const useAddNoteOperation = makeMutation<AddNoteBody, unknown>({
  name: "addNote",
  fn: async ({ classroomId, studentIds, text, date }) =>
    await callApi({
      method: "POST",
      path: "/api/commentBatch",
      body: {
        classId: classroomId,
        studentIds,
        text,
        date,
      },
    }),
  onSuccess: () => {
    useClassroomStudentNotesFetcher.invalidateQueries();
  },
});

export const useRemoveNoteOperation = makeApiMutation({
  name: "removeNote",
  path: "/api/comment/{id}",
  method: "delete",
  onSuccess: () => {
    useClassroomStudentNotesFetcher.invalidateQueries();
  },
});

export const useDownloadReportPdf = () => {
  // -------------------------
  // Report PDF Download logic:
  // We first need to retrieve the 'link' to the PDF
  // Second we use a 'retry' hook that will check every 'n' seconds if the link returns 200, when it does so
  // we consider the PDF as being ready in the backend and we navigate with the browser to that URL to let the
  // browser download the link.
  const {
    data: reportLink,
    mutate: getReportPDFLink,
    isLoading: reportLinkLoading,
    error: reportLinkError,
    isSuccess: reportLinkDone,
  } = useGetReportPDFLinkOperation();

  const checkLinkResult = useCheckUntilDownloadLinkIsReady(reportLink);

  const downloadReport = useCallback(
    ({ classroomId, start, end, locale, ...props }: GetReportPDFBody) => {
      getReportPDFLink({
        classroomId,
        start,
        end,
        locale,
        ...props,
      });
    },
    [getReportPDFLink],
  );

  return {
    mutate: downloadReport,
    isLoading: reportLinkLoading || checkLinkResult.isLoading,
    isSuccess: reportLinkDone && checkLinkResult.isSuccess,
    data: (reportLinkDone && checkLinkResult.isSuccess && reportLink) || null,
    error: reportLinkError || checkLinkResult.error,
  };
};

export const useDownloadWholeClassAwardsReportCsv = () => {
  // -------------------------
  // Report CSV Download logic:
  // We first need to retrieve the all the data for the report
  // Second, we format the data as CSV and trigger a file download on the browser
  // with the generated content
  const {
    mutate: getReportData,
    isLoading: reportLoading,
    isSuccess: reportDone,
    error: reportError,
    data: reportData,
  } = useGetWholeClassReportCsvDataOperation();

  const downloadReport = useCallback(
    ({ classroom, students, start, end }: WholeClassReportCsvDataParameters) => {
      getReportData({ classroom, students, start, end });
    },
    [getReportData],
  );

  useEffect(() => {
    if (reportData) {
      // renders the classroom CSV report and forces its download
      downloadClassroomReportCSV({
        behaviors: [], // expected by #downloadClassroomReportCSV but not part of reportData
        ...reportData,
      });
    }
  }, [reportData]);

  return { mutate: downloadReport, isLoading: reportLoading, isSuccess: reportDone, error: reportError };
};

export const useDownloadStudentAwardsReportCsv = () => {
  // -------------------------
  // Report CSV Download logic:
  // We first need to retrieve the all the data for the report
  // Second, we format the data as CSV and trigger a file download on the browser
  // with the generated content
  const {
    mutate: getReportData,
    isLoading: reportLoading,
    isSuccess: reportDone,
    error: reportError,
    data: reportData,
  } = useGetStudentReportCsvDataOperation();

  const downloadReport = useCallback(
    ({ classroom, student, start, end }: StudentReportCsvDataParameters) => {
      getReportData({ classroom, student, start, end });
    },
    [getReportData],
  );

  useEffect(() => {
    if (reportData) {
      // const student = reportData.students.find((s) => s._id === reportData.studentId);
      // renders the student CSV report and forces its download
      downloadStudentReportCSV(reportData);
    }
  }, [reportData]);

  return { mutate: downloadReport, isLoading: reportLoading, isSuccess: reportDone, error: reportError };
};

//
// Util operations
//
type GetReportPDFBody = {
  classroomId?: string;
  start?: string | undefined;
  end?: string | undefined;
  studentIds?: string[] | undefined;
  locale?: string | undefined;
  includePointStream?: boolean | undefined;
};

const useGetReportPDFLinkOperation = makeMutation<GetReportPDFBody, string>({
  name: "getReportPDFLink",
  fn: async ({ classroomId, start, end, studentIds, locale, includePointStream }) => {
    try {
      let queryObj = undefined;
      if (global.forcePrince) {
        queryObj = { forcePrince: !!global.forcePrince || false };
      }
      const reportsPDFResponse = await callApi({
        method: "POST",
        path: "/api/reportsPDF",
        query: queryObj,
        body: {
          studentIds,
          locale,
          includePointStream,
          classId: classroomId,
          from: start,
          to: end,
        },
      });

      // API populates both fields, body._links and header.location
      // For some reason the old code was checking on both (here in the client) so we keep it just in case :/
      const link = reportsPDFResponse.body._links.expectedLocation.href || reportsPDFResponse.header.location;

      if (!link) {
        throw new Error("Missing report link");
      }
      return link;
      // eslint-disable-next-line no-catch-all/no-catch-all
    } catch {
      return errors.report.genericError();
    }
  },
});

type WholeClassReportCsvDataParameters = {
  classroom: Classroom;
  students: Students;
  start: string;
  end: string;
};

export type Award = APIResponse<"/api/award", "get">["_items"][number];
export type WholeClassReportCsvDataResponse = {
  classroom: WholeClassReportCsvDataParameters["classroom"];
  students: WholeClassReportCsvDataParameters["students"];
  start: WholeClassReportCsvDataParameters["start"];
  end: WholeClassReportCsvDataParameters["end"];
  attendance: APIResponse<"/api/attendance", "get">["_items"];
  awards: Award[];
};

const useGetWholeClassReportCsvDataOperation = makeMutation<
  WholeClassReportCsvDataParameters,
  WholeClassReportCsvDataResponse
>({
  name: "getWholeClassReportCsvData",
  fn: async ({ classroom, students, start, end }) => {
    const classroomId = classroom._id;
    const {
      body: { _items: attendance },
    } = await callApi({
      method: "GET",
      path: "/api/attendance",
      query: {
        classId: classroomId,
        from: start ? parse(start).toISOString() : new Date("2011-05-05").toISOString(),
        to: parse(end || undefined).toISOString(),
        showLeftEarly: true,
      },
    });

    const {
      body: { _items: awards },
    } = await callApi({
      method: "GET",
      path: "/api/award",
      query: {
        classId: classroomId,
        from: start,
        to: end,
      },
    });

    return { classroom, students, start, end, attendance, awards };
  },
});

type StudentReportCsvDataParameters = {
  classroom: Classroom;
  student: Students[0];
  start: string;
  end: string;
};

export type Note = APIResponse<"/api/comment", "get">["_items"][number];
export type StudentReportCsvDataResponse = {
  classroom: StudentReportCsvDataParameters["classroom"];
  student: StudentReportCsvDataParameters["student"];
  start: StudentReportCsvDataParameters["start"];
  end: StudentReportCsvDataParameters["end"];
  attendance: APIResponse<"/api/attendance", "get">["_items"];
  awards: Award[];
  notes: Note[];
};

const useGetStudentReportCsvDataOperation = makeMutation<StudentReportCsvDataParameters, StudentReportCsvDataResponse>({
  name: "getStudentReportCsvData",
  fn: async ({ classroom, student, start, end }) => {
    const classroomId = classroom._id;
    const studentId = student._id;

    const {
      body: { _items: attendance },
    } = await callApi({
      method: "GET",
      path: "/api/attendance",
      query: {
        classId: classroomId,
        from: start ? parse(start).toISOString() : new Date("2011-05-05").toISOString(),
        to: parse(end || undefined).toISOString(),
        showLeftEarly: true,
      },
    });

    const {
      body: { _items: awards },
    } = await callApi({
      method: "GET",
      path: "/api/award",
      query: {
        studentId,
        classId: classroomId,
        from: start,
        to: end,
      },
    });

    const {
      body: { _items: notes },
    } = await callApi({
      method: "GET",
      path: "/api/comment",
      query: {
        classId: classroomId,
        studentId,
        from: start,
        to: end,
      },
    });

    return { classroom, student, start, end, attendance, awards, notes };
  },
});

export const useAwardDeleteStudent = makeApiMutation({
  name: "awardDeleteStudent",
  path: "/api/dojoClass/{classId}/award/{awardId}/student/{studentId}",
  method: "delete",
  onSuccess: () => {
    useStudentAwardsFetcher.invalidateQueries();
    useClassroomAwardsFetcher.invalidateQueries();
    useClassroomAndStudentAwardsFetcher.invalidateQueries();
  },
});

export const useReportRemovePointsRedemption = makeApiMutation({
  name: "reportRemovePointsRedemption",
  path: "/api/dojoClass/{classId}/student/{studentId}/pointRedemption/{redemptionId}",
  method: "delete",
  onSuccess: () => {
    useRedemptionClassroomFetcher.invalidateQueries();
    useRedemptionClassroomAndStudentFetcher.invalidateQueries();
    useRedemptionStudentFetcher.invalidateQueries();
  },
});

// helper types to make it more readable in clients
export type Awards = NonNullable<ReturnType<typeof useClassroomAwardsFetcher>["data"]>;
export type StudentNotes = NonNullable<ReturnType<typeof useClassroomStudentNotesFetcher>["data"]>;
export type StudentRedemptions = NonNullable<ReturnType<typeof useRedemptionClassroomAndStudentFetcher>["data"]>;
