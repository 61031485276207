import React from "react";
import { IslandsAccentText, type AccentTextLevel } from "app/pods/dojoIslands/typography/IslandsAccentText";
import { ddsColors } from "@classdojo/web/nessie/components/ddsColors";

const styles = {
  boxWrapper: {
    display: "inline-block",
    padding: 0,
    perspective: "12rem",
    perspectiveOrigin: "30% 50%",
  },
  box: {
    backgroundColor: ddsColors.cloud_90,
    padding: "2px",
    borderRadius: "5px",
    transform: "rotateX(12deg) rotateY(-12deg)",
    boxShadow: "0px 2px 0px rgba(0, 0, 0, 1.0)",
  },
  innerBox: {
    backgroundColor: "#ffb800",
    width: "100%",
    borderRadius: "3px",
    perspective: "8rem",
    perspectiveOrigin: "30% 50%",
  },
  textBox: {
    paddingTop: "dt_xxs",
    paddingLeft: "dt_xs",
    paddingRight: "4px",
    transform: "rotate(2deg) rotateX(-5deg) rotateY(5deg)",
  },
} as const;

const DojoIslandsHighlightLabel: React.FC<{
  children: React.ReactNode;
  level?: AccentTextLevel;
}> = ({ children, level = 1 }) => {
  return (
    <div sx={styles.boxWrapper}>
      <div sx={styles.box}>
        <div sx={styles.innerBox}>
          <div sx={styles.textBox}>
            <IslandsAccentText level={level}>{children}</IslandsAccentText>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DojoIslandsHighlightLabel;
