import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { DDSButton } from "../DDSButton/DDSButton";
import { DDSHeadlineText } from "../DDSHeadlineText/DDSHeadlineText";
import { DDSBodyText } from "../DDSBodyText/DDSBodyText";
import classes from "./DDSAlertDialog.module.css";
import { FC, type ReactNode } from "react";

const DDSAlertDialogRoot: FC<{
  children?: React.ReactNode;
  open?: boolean;
  defaultOpen?: boolean;
  onOpenChange?(open: boolean): void;
}> = AlertDialog.Root;

const DDSAlertDialogTrigger = AlertDialog.Trigger;
const DDSAlertDialogPortal = AlertDialog.Portal;

function DDSAlertDialogContent({ children, ...props }: AlertDialog.AlertDialogContentProps) {
  return (
    <DDSAlertDialogPortal>
      <AlertDialog.Overlay className={classes.overlay} />
      <AlertDialog.Content className={classes.container} {...props}>
        <div className={classes.content}>{children}</div>
      </AlertDialog.Content>
    </DDSAlertDialogPortal>
  );
}

function DDSAlertDialogHeader({ children }: { children: ReactNode }) {
  return <div className={classes.header}>{children}</div>;
}

function DDSAlertDialogFooter({ children }: { children: ReactNode }) {
  return <div className={classes.footer}>{children}</div>;
}

function DDSAlertDialogTitle({ children }: { children: ReactNode }) {
  return (
    <AlertDialog.Title asChild>
      <DDSHeadlineText level={3} as="h3" className={classes.title}>
        {children}
      </DDSHeadlineText>
    </AlertDialog.Title>
  );
}

function DDSAlertDialogDescription({ children }: { children: ReactNode }) {
  return (
    <AlertDialog.Description asChild>
      <div className={classes.description}>
        <DDSBodyText level={2}>{children}</DDSBodyText>
      </div>
    </AlertDialog.Description>
  );
}

type DDSActionProps = {
  children: ReactNode;
  ["data-name"]: string;
  variant?: "primary" | "destructive";
  disabled?: boolean;
  onClick?: () => void;
};

function DDSAlertDialogAction({
  children,
  variant = "primary",
  disabled,
  onClick,
  "data-name": dataName,
}: DDSActionProps & { "data-name": string }) {
  return (
    <AlertDialog.Action asChild>
      <DDSButton data-name={dataName} variant={variant} size="medium" disabled={disabled} onClick={onClick}>
        {children}
      </DDSButton>
    </AlertDialog.Action>
  );
}

function DDSAlertDialogCancel({ children, "data-name": dataName }: { children: ReactNode; "data-name": string }) {
  return (
    <AlertDialog.Cancel asChild>
      <DDSButton data-name={dataName} variant="secondary" size="medium">
        {children}
      </DDSButton>
    </AlertDialog.Cancel>
  );
}

export {
  DDSAlertDialogRoot as DDSAlertDialog,
  DDSAlertDialogTrigger,
  DDSAlertDialogContent,
  DDSAlertDialogHeader,
  DDSAlertDialogFooter,
  DDSAlertDialogTitle,
  DDSAlertDialogDescription,
  DDSAlertDialogAction,
  DDSAlertDialogCancel,
};
